import React from 'react';

interface Props {
  fill?: string;
  height?: string;
}

const fileUpload: React.FC<Props> = ({ fill = '#343D4B', height = '15' }) => {
  return (
    <svg
      height={height}
      viewBox="0 0 13 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Frame 498">
        <path
          id="Vector"
          d="M2.33309 0.107703C1.13359 0.107703 0.149756 1.09154 0.149756 2.29104V12.7077C0.149756 13.9072 1.13359 14.891 2.33309 14.891H7.54142C7.70608 14.891 7.86399 14.8256 7.98042 14.7092C8.09685 14.5928 8.16226 14.4349 8.16226 14.2702C8.16226 14.1055 8.09685 13.9476 7.98042 13.8312C7.86399 13.7148 7.70608 13.6494 7.54142 13.6494H2.33309C1.80707 13.6494 1.39142 13.2337 1.39142 12.7077V2.29104C1.39142 1.76501 1.80707 1.34937 2.33309 1.34937H7.96226V3.3327C7.96226 4.2447 8.71276 4.9952 9.62476 4.9952H11.6081V12.7077C11.6081 13.2337 11.1924 13.6494 10.6664 13.6494H9.62476C9.4601 13.6494 9.30219 13.7148 9.18576 13.8312C9.06933 13.9476 9.00392 14.1055 9.00392 14.2702C9.00392 14.4349 9.06933 14.5928 9.18576 14.7092C9.30219 14.8256 9.4601 14.891 9.62476 14.891H10.6664C11.8659 14.891 12.8498 13.9072 12.8498 12.7077V4.37437C12.8498 3.87649 12.6517 3.39845 12.2996 3.04637L9.91109 0.657825C9.55901 0.305742 9.08097 0.107703 8.58309 0.107703H2.33309ZM9.20392 3.3327V1.70642L11.251 3.75354H9.62476C9.38259 3.75354 9.20392 3.57487 9.20392 3.3327Z"
          fill={fill}
          stroke={fill}
          strokeWidth="0.2"
        />
        <path
          id="Vector_2"
          d="M6.49943 5.83817C6.33478 5.83817 6.17686 5.90358 6.06044 6.02001C5.94401 6.13644 5.8786 6.29435 5.8786 6.459V7.9215H4.4161C4.25144 7.9215 4.09353 7.98691 3.9771 8.10334C3.86067 8.21977 3.79526 8.37768 3.79526 8.54234C3.79526 8.70699 3.86067 8.86491 3.9771 8.98133C4.09353 9.09776 4.25144 9.16317 4.4161 9.16317H5.8786V10.6257C5.8786 10.7903 5.94401 10.9482 6.06044 11.0647C6.17686 11.1811 6.33478 11.2465 6.49943 11.2465C6.66409 11.2465 6.822 11.1811 6.93843 11.0647C7.05486 10.9482 7.12026 10.7903 7.12026 10.6257V9.16317H8.58276C8.74742 9.16317 8.90533 9.09776 9.02176 8.98133C9.13819 8.86491 9.2036 8.70699 9.2036 8.54234C9.2036 8.37768 9.13819 8.21977 9.02176 8.10334C8.90533 7.98691 8.74742 7.9215 8.58276 7.9215H7.12026V6.459C7.12026 6.29435 7.05486 6.13644 6.93843 6.02001C6.822 5.90358 6.66409 5.83817 6.49943 5.83817Z"
          fill={fill}
          stroke={fill}
          strokeWidth="0.2"
        />
      </g>
    </svg>
  );
};

export default fileUpload;
