import { useId } from 'react';
import TextInput from 'src/design-system/components/TextInput/TextInput';

interface TextInputWithLabelProps {
  label: string;
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  onEnterDown?: () => void;
}

function TextInputWithLabel({
  label,
  value,
  onChange,
  placeholder,
  onEnterDown,
}: TextInputWithLabelProps) {
  const id = useId();

  return (
    <div className="gap-20px flex items-center">
      <label
        htmlFor={id}
        className="text-medium-s whitespace-nowrap text-gray-500"
      >
        {label}
      </label>
      <TextInput
        className="w-300px"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        onEnterDown={onEnterDown}
      />
    </div>
  );
}

export default TextInputWithLabel;
