import { isString } from 'lodash';
import { COLUMN_MAPPING_GUIDES } from './columnMappingGuide';
import { getMappedEntries } from './getMappedEntries';

export function findHeaderIndex(rows: string[][]): number {
  return rows.findIndex((row) => {
    const stringRow = row.filter(isString);
    const entries = getMappedEntries(stringRow, COLUMN_MAPPING_GUIDES);
    return entries.length > 1;
  });
}
