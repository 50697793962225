import { z } from 'zod';
import { DispatchedOrder } from '../type/order/DispatchedOrder';

const DISPATCH_ORDER_URL = '/dispatch-order';

export const DISPATCH_ORDER_POllING_URL = `${DISPATCH_ORDER_URL}/fetch-orders-polling`;

export const DispatchOrderPollingQuery = z.object({
  requestId: z.string(),
});

export type DispatchOrderPollingQuery = z.infer<
  typeof DispatchOrderPollingQuery
>;

export type DispatchOrderPollingData =
  | { status: 'ON_GOING' }
  | {
      status: 'FINISH';
      data: DispatchedOrder[];
    };

export const DISPATCH_ORDER_SEARCH_URL = `${DISPATCH_ORDER_URL}/orders/search`;

export const DispatchOrderSearchQuery = z.object({
  shoppingMall: z.string().optional(),
  orderNumber: z.string().optional(),
  contactNumber: z.string().optional(),
  name: z.string().optional(),
});

export type DispatchOrderSearchQuery = z.infer<typeof DispatchOrderSearchQuery>;

export type DispatchOrderSearchData = DispatchedOrder[];
