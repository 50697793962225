import { useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
//https://github.com/VikLiegostaiev/react-page-scroller
//https://github.com/subtirelumihail/react-fullpage?tab=readme-ov-file
//https://github.com/zwug/react-full-page?tab=readme-ov-file
//https://github.com/alvarotrigo/fullPage.js#options
import { useNavigate } from 'react-router-dom';
import Button from '../components/buttons/Button';
import NewFooter from '../components/footer/NewFooter';
import examplePDF2 from '../files/ali.pdf';
import examplePDF from '../files/ex.pdf';
import useGoogleTag from '../hooks/useGoogleTag';
import closeIcon from '../icons/close.png';
import {
  imageLogo11,
  imageLogoAliexpress,
  imageLogoAuction,
  imageLogoGmarket,
  imageLogoKakao,
  imageLogoNaver,
  imgDispatch1,
  imgDispatch2,
  imgLogoAllways,
  imgPart2Section1Img1,
  imgPart2Section1Img3,
  imgPart2Section2Img1,
  imgPart2Section2Img1Mobile,
  imgPart2Section2Img2,
  imgPart2Section2Img2Mobile,
  imgPart2Section3Img1,
  imgPart2Section3Img1Mobile,
  imgPart2Section3Img2,
  imgPart2Section3Img2Mobile,
} from '../images';
// visual
import Sweep from '../images/Sweep.png';

const App = () => {
  useGoogleTag('AW-16638455187');
  const fp = window.fullpage;
  const [showHeader, setShowHeader] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [destinationPage, setDestinationPage] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [direction, setDirection] = useState('');
  const [showSecondContent, setShowSecondContent] = useState(false);
  const [showSecondContent2, setShowSecondContent2] = useState(false);

  const navigate = useNavigate();
  const [animate, setAnimate] = useState(true);
  const onStop = () => setAnimate(false);
  const onRun = () => setAnimate(true);
  const showSecondContentRef = useRef(showSecondContent);
  const showSecondContentRef2 = useRef(showSecondContent2);

  const recatch_iframe_link =
    'https://sweepingoms.recatch.cc/workflows/nsidynrpek?theme=light&primaryColor=%232a7cef';
  // const [isMobile, setIsMobile] = useState(false);
  const isMobile = useMediaQuery({
    query: '(max-width: 1099px) or (orientation: portrait)',
  });
  const isTablet = useMediaQuery({
    query: '(min-width: 834px) and (orientation: portrait)',
  });
  const downloadPDF2 = () => {
    const link = document.createElement('a');
    link.href = examplePDF2;
    link.setAttribute(
      'download',
      '(스윕) 알리익스프레스 주문관리 솔루션 소개서.pdf'
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const feedbackOn = true;
  useEffect(() => {
    new fp('#fullpage', {
      navigation: true,
      lockAnchors: false,
      anchors: ['first', 'second', 'third', 'fourth', 'fifth', 'footer'],
      normalScrollElements: '.scrollable-content',
      navigationPosition: 'right',
      showActiveTooltip: true,
      scrollOverflow: false,
      fixedElements: '#fixedElement',
      // sectionsColor: ['#ff5f45', '#0798ec', '#fc6c7c'],
      menu: '#menu',
      // responsiveHeight: 500,
      scrollingSpeed: 700,
      loopBottom: true,
      afterLoad: (origin, destination, direction) => {
        setDirection('');
        setCurrentPage(destination.index);
      },
      beforeLeave: (origin, destination, direction, trigger) => {
        if (!isMobile) {
          return true;
        }
        if (origin.index === 2 && direction === 'down') {
          if (!showSecondContentRef.current) {
            console.log('showing second content');
            showSecondContentRef.current = true;
            setShowSecondContent(true);
            return false;
          } else {
            return true;
          }
        } else if (origin.index === 3 && direction === 'up') {
          showSecondContentRef.current = false;
          setShowSecondContent(false);
          setTimeout(() => {
            window.fullpage_api.moveTo('second');
          });
          return true;
        }
        if (origin.index === 4 && direction === 'down') {
          if (!showSecondContentRef2.current) {
            console.log('showing second content');
            showSecondContentRef2.current = true;
            setShowSecondContent2(true);
            return false;
          } else {
            return true;
          }
        } else if (origin.index === 5 && direction === 'up') {
          showSecondContentRef2.current = false;
          setShowSecondContent2(false);
          setTimeout(() => {
            window.fullpage_api.moveTo('fourth');
          });
          return true;
        }
      },
      onLeave: (origin, destination, direction) => {
        console.log(
          'leaving from ' +
            origin.index +
            ' to ' +
            destination.index +
            ' ' +
            direction
        );
        setDirection(direction);
        setDestinationPage(destination.index);
        if (direction === 'down') {
          setShowHeader(false);
        } else if (direction === 'up') {
          setShowHeader(true);
        }
        if (destination.index === 0) {
          setShowHeader(true);
        }
      },
    });
    window.fullpage_api.silentMoveTo('first');
    return () => {
      if (window.fullpage_api) {
        window.fullpage_api.destroy('all');
      }
    };
  }, [isMobile, isTablet]);
  const Mobile = ({ children }) => {
    return <>{isMobile && children}</>;
  };

  const PC = ({ children }) => {
    return <>{!isMobile && children}</>;
  };
  const [iframeLoading, setIframeLoading] = useState(true);

  const handleIframeLoad = () => {
    setIframeLoading(false);
  };

  useEffect(() => {
    console.log('current page ' + currentPage);
  }, [currentPage]);

  const downloadPDF = () => {
    const link = document.createElement('a');
    link.href = examplePDF;
    link.setAttribute('download', '(스윕)서비스소개서.pdf');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handleEscapePress = (e) => {
    if (e.key === 'Escape') {
      setIsModalOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleEscapePress);
    return () => {
      document.removeEventListener('keydown', handleEscapePress);
    };
  }, []);
  useEffect(() => {
    (function (r, e, c, a, t, ch) {
      var h = r.getElementsByTagName(e)[0],
        i = r.createElement(c);
      i.async = true;
      i.id = 'recatch-embed-script';
      i.src =
        'https://cdn.recatch.cc/recatch-embed.iife.js?t=' +
        a[0] +
        '&b=' +
        a[1] +
        '&c=' +
        t +
        '&tr=true&th=' +
        ch +
        '&mode=sdk&pc=%23426cdb';
      h.appendChild(i);
    })(
      document,
      'head',
      'script',
      ['sweepingoms', 'nsidynrpek'],
      'recatch-form',
      'light'
    );
  }, []);
  const images = [
    imageLogoKakao,
    imageLogoNaver,
    imageLogo11,
    imageLogoAuction,
    imageLogoGmarket,
    imageLogoAliexpress,
    imgLogoAllways,
  ];
  const hasBanner = false;
  return (
    <div className="flex flex-col">
      {!isMobile && (
        <>
          <header
            className={`fixed z-10  flex w-full flex-col items-center border-b border-[#E0E7ED] bg-white font-pretendard transition-transform duration-300 ${
              showHeader
                ? 'translate-y-0 transform'
                : '-translate-y-full transform'
            }`}
          >
            {hasBanner && (
              <div className="h-[141px] w-full">
                <div className="flex h-[121px] justify-center bg-[#35B7FF]">
                  <img
                    src={BannerLarge}
                    alt="banner"
                    className="absolute top-0 h-[141px] object-cover"
                  />
                </div>
              </div>
            )}
            <div
              className={`flex w-full max-w-[1200px] flex-col items-center justify-between px-12 py-4 md:flex-row ${hasBanner ? 'pb-4' : 'py-4'}`}
              style={hasBanner ? { paddingTop: 'calc(1rem - 17px)' } : {}}
            >
              <div
                className="logo mx-auto md:mx-0"
                onClick={() => navigate('/')}
              >
                <img
                  src={Sweep}
                  alt="service icon"
                  className="h-auto w-[95px]"
                />
              </div>
              <div className="flex items-center gap-[16px]">
                <div
                  onClick={() => navigate('/sign-in')}
                  className="text-md cursor-pointer rounded-lg px-[16px] py-[10px] text-[16px] font-semibold text-blue-500 hover:bg-blue-100"
                >
                  로그인
                </div>
                <div className="hidden md:block">
                  <Button
                    name="도입 문의하기"
                    type="button"
                    onClick={() => setIsModalOpen(true)}
                    className="text-sm"
                  />
                </div>
              </div>
            </div>
          </header>
          <div className="flex flex-col" id="fullpage">
            <div className="section" id="screen1">
              <section className="relative flex h-full h-screen flex-col items-center justify-center space-y-[50px] ">
                <div className="mt-[30px] flex flex-col space-y-[12px] text-center">
                  <span className="text-[40px] font-bold text-[#343D4B]">
                    쇼핑몰 주문 관리 스윕을 통해 자동으로
                  </span>
                  <div className="flex flex-col  text-[18px] font-medium text-[#708AA2]">
                    <span>
                      개별 쇼핑몰별 판매자 페이지에 로그인하고, 주문서를
                      다운로드 받아
                      <br /> 수기 작업으로 통합 엑셀 양식으로 변경할 필요 없이
                      단 한 번의 클릭만으로 최신주문을 업데이트하고 <br />
                      원하는 통합 엑셀 양식으로 변환, 취소 관리, 송장 입력 전
                      과정을 자동화하세요.
                    </span>
                  </div>
                </div>
                <div className="flex w-[1200px] gap-[20px]">
                  <div className="rounded-[16px] bg-[#F2F6FA] px-[80px] py-[60px]">
                    <img
                      src={imgDispatch1}
                      className="w-[573px] rounded-[16px]"
                    ></img>
                  </div>
                  <div className="flex flex-col gap-[20px]">
                    <div className="flex h-full w-[446px] flex-col justify-center rounded-[16px] bg-[#F2F6FA] px-[80px]">
                      <div className="flex flex-col gap-[20px] overflow-hidden p-4">
                        <div
                          className={
                            'skidrow flex items-center' +
                            (feedbackOn ? '' : ' hidden')
                          }
                          onMouseEnter={onStop}
                          onMouseLeave={onRun}
                        >
                          <div
                            className={
                              'skid original flex items-center' +
                              (animate ? '' : ' stop')
                            }
                          >
                            {images.map((img, i) => (
                              <div
                                key={i}
                                className={`mr-[20px] flex h-[82px] w-[82px] flex-none items-center rounded-[20px] bg-white transition-all duration-500 ease-in-out hover:scale-125`}
                                style={{
                                  boxShadow:
                                    '0px 1px 2px 0px rgba(20, 64, 104, 0.10)',
                                }}
                              >
                                <img
                                  src={img}
                                  className={`w-full ${i === 6 ? 'h-[15px] px-2' : ''}`}
                                ></img>
                              </div>
                            ))}
                          </div>
                          <div
                            className={
                              'skid clone flex items-center' +
                              (animate ? '' : ' stop')
                            }
                          >
                            {images.map((img, i) => (
                              <div
                                key={i}
                                className={`mr-[20px] flex h-[82px] w-[82px] flex-none items-center rounded-[20px] bg-white transition-all duration-500 ease-in-out hover:scale-125`}
                                style={{
                                  boxShadow:
                                    '0px 1px 2px 0px rgba(20, 64, 104, 0.10)',
                                }}
                              >
                                <img
                                  src={img}
                                  className={`w-full ${i === 6 ? 'h-[15px] px-2' : ''}`}
                                ></img>{' '}
                              </div>
                            ))}
                          </div>
                        </div>
                        <div
                          className={
                            'skidrowReverse flex items-center' +
                            (feedbackOn ? '' : ' hidden')
                          }
                          onMouseEnter={onStop}
                          onMouseLeave={onRun}
                        >
                          <div
                            className={
                              'skidReverse original flex items-center' +
                              (animate ? '' : ' stop')
                            }
                          >
                            {images.reverse().map((img, i) => (
                              <div
                                key={i}
                                className={`mr-[20px] flex h-[82px] w-[82px] flex-none items-center rounded-[20px] bg-white transition-all duration-500 ease-in-out hover:scale-125`}
                                style={{
                                  boxShadow:
                                    '0px 1px 2px 0px rgba(20, 64, 104, 0.10)',
                                }}
                              >
                                <img
                                  src={img}
                                  className={`w-full ${i === 0 ? 'h-[15px] px-2' : ''}`}
                                ></img>
                              </div>
                            ))}
                          </div>
                          <div
                            className={
                              'skidReverse clone flex items-center' +
                              (animate ? '' : ' stop')
                            }
                          >
                            {images.map((img, i) => (
                              <div
                                key={i}
                                className={`mr-[20px] flex h-[82px] w-[82px] flex-none items-center rounded-[20px] bg-white transition-all duration-500 ease-in-out hover:scale-125`}
                                style={{
                                  boxShadow:
                                    '0px 1px 2px 0px rgba(20, 64, 104, 0.10)',
                                }}
                              >
                                <img
                                  src={img}
                                  className={`w-full ${i === 0 ? 'h-[15px] px-2' : ''}`}
                                ></img>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-center rounded-[16px] bg-[#F2F6FA]  py-[25px]">
                      <img
                        src={imgDispatch2}
                        className="w-[300px] rounded-[16px]"
                      ></img>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div className="section" id="screen2">
              <section className="relative flex h-full h-screen flex-col items-center justify-center space-y-[50px] ">
                <div className="flex flex-col space-y-[12px] text-center">
                  <span className="text-[40px] font-bold text-[#343D4B]">
                    판매자 페이지 로그인 없이 쉽고 간편한 주문 통합
                  </span>
                  <div className="flex flex-col  text-[18px] font-medium text-[#708AA2]">
                    <span>
                      알리익스프레스부터 국내 주요 쇼핑몰까지 판매자 페이지에
                      직접 로그인하는 번거로움 없이
                      <br />단 몇번의 클릭만으로 주문을 통합하고, 원하는 발주서
                      양식으로 변환하세요.{' '}
                    </span>
                  </div>
                </div>
                <div className="flex w-[1200px] gap-[40px]">
                  <div className="flex w-1/2 flex-col gap-[40px]">
                    <div className="flex items-center ">
                      <img src={imgPart2Section2Img1} className="w-full"></img>
                    </div>
                    <div className="flex flex-col ">
                      <span className="mb-[16px] text-[24px] font-semibold text-[#263142]">
                        알리익스프레스 주문 관리도 스윕을 통해 자동으로
                      </span>
                      <span className="mb-[24px] text-[18px] font-medium text-[#708AA2]">
                        합주문 건 분리, 상품명 및 옵션 한글 표기부터 주소 오류
                        자동 수정, 전화번호
                        <br />
                        오류 건 별도 표기까지. 복잡한 알리익스프레스 주문 관리도
                        자동으로 처리하세요.
                      </span>
                      <span
                        onClick={downloadPDF2}
                        className="cursor-pointer text-[18px] font-bold text-[#EC4A5C] underline"
                      >
                        알리익스프레스 주문관리 소개 다운받기
                      </span>
                    </div>
                  </div>
                  <div className="flex w-1/2 flex-col gap-[40px]">
                    <div className="flex">
                      <img src={imgPart2Section2Img2} className=" w-full"></img>
                    </div>
                    <div className="flex flex-col ">
                      <span className="mb-[16px] text-[24px] font-semibold text-[#263142]">
                        번거로운 수기 작업 없이 통합 엑셀 양식 변환도 자동으로
                      </span>
                      <span className="mb-[24px] text-[18px] font-medium text-[#708AA2]">
                        알리익스프레스 뿐만 아니라 국내 주요 쇼핑몰들의 주문도
                        스윕을 통해 단 몇 번의 <br />
                        클릭만으로 통합하고, 원하는 통합 엑셀 양식으로
                        변환하세요.{' '}
                      </span>
                      <span
                        onClick={downloadPDF}
                        className="cursor-pointer text-[18px] font-bold text-blue-500 underline"
                      >
                        발주 관리 기능 자세히 알아보기
                      </span>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div className="section" id="screen3">
              <section className="relative flex h-full h-screen flex-col items-center justify-center space-y-[50px] ">
                <div className="flex flex-col space-y-[12px] text-center">
                  <span className="text-[40px] font-bold text-[#343D4B] ">
                    송장 등록 및 취소 건 관리도 스윕을 통해 자동으로
                  </span>
                  <div className="flex flex-col  text-[18px] font-medium text-[#708AA2]">
                    <span>
                      쇼핑몰별로 로그인 해 취소 건을 확인하고, 송장 발급 후
                      쇼핑몰 별 송장 일괄 등록 양식에 맞춰 분리하는
                      <br />
                      번거로움 없이, 취소 건 관리 및 송장 발급 후 쇼핑몰 송장
                      일괄 등록도 자동으로 처리하세요.
                    </span>
                  </div>
                </div>
                <div className="flex w-[1200px] gap-[40px]">
                  <div className="flex w-1/2 flex-col gap-[40px]">
                    <div className="flex items-center ">
                      <img src={imgPart2Section3Img1} className="w-full"></img>
                    </div>
                    <div className="flex flex-col ">
                      <span className="mb-[16px] text-[24px] font-semibold text-[#263142]">
                        쇼핑몰 운송장 일괄등록
                      </span>
                      <span className="mb-[24px] text-[18px] font-medium text-[#708AA2]">
                        공급처 또는 택배사 페이지에서 송장을 발급한 이후 송장
                        일괄 등록 양식에 맞춰
                        <br />
                        쇼핑몰 별로 분리하고 업르도 할 필요 없이 운송장을
                        일괄등록하세요.
                      </span>
                    </div>
                  </div>
                  <div className="flex w-1/2 flex-col gap-[40px]">
                    <div className="flex">
                      <img src={imgPart2Section3Img2} className=" w-full"></img>
                    </div>
                    <div className="flex flex-col ">
                      <span className="mb-[16px] text-[24px] font-semibold text-[#263142]">
                        취소 승인 및 출고 건 취소 거부
                      </span>
                      <span className="mb-[24px] text-[18px] font-medium text-[#708AA2]">
                        주문서 다운로드 이전 및 발주 업무 과정에서 발생한 취소
                        요청 건들을 개별적으로 <br />
                        확인하고 승인 또는 거부할 필요 없이 자동으로 처리하세요.{' '}
                      </span>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div className="section fp-auto-height">
              <NewFooter isMobile={false} />
            </div>
          </div>
        </>
      )}
      {isMobile && (
        <>
          <div className="flex flex-col" id="fullpage">
            <div className="section" id="screen1">
              <section className=" relative relative flex h-full  h-screen flex-col items-center">
                <div
                  className="flex h-[64px] w-full items-center justify-between px-[40px]"
                  onClick={() => navigate('/')}
                >
                  <img src={Sweep} className="h-[24px]"></img>
                </div>
                <div className="flex w-full  flex-col items-center justify-center">
                  <div className="mb-[10px] flex w-full flex-col space-y-[16px] px-[40px]">
                    <span className="text-[28px] font-bold leading-tight text-[#343D4B]">
                      쇼핑몰 주문 관리
                      <br /> 스윕을 통해 자동으로
                    </span>
                    <div className="flex flex-col  text-[18px] font-medium text-[#708AA2]">
                      <span>
                        단 한 번의 클릭만으로 최신주문을
                        <br />
                        업데이트하고 원하는 통합 엑셀 양식으로
                        <br /> 변환, 취소 관리, 송장 입력 전 과정을
                        <br />
                        자동화하세요.
                      </span>
                    </div>
                  </div>
                  <div className="flex w-full flex-col gap-[12px] px-[40px]">
                    <img src={imgPart2Section1Img1}></img>
                    <div className="w-full rounded-[12px] bg-[#F2F6FA] px-[20px]">
                      <div
                        className={
                          'skidrow my-[20px] flex items-center overflow-hidden' +
                          (feedbackOn ? '' : ' hidden')
                        }
                        onMouseEnter={onStop}
                        onMouseLeave={onRun}
                      >
                        <div
                          className={
                            'skid original flex items-center' +
                            (animate ? '' : ' stop')
                          }
                        >
                          {images.map((img, i) => (
                            <div
                              key={i}
                              className={`mr-[10px] flex h-[40px] w-[40px] flex-none items-center rounded-[10px] bg-white transition-all duration-500 ease-in-out hover:scale-125`}
                              style={{
                                boxShadow:
                                  '0px 1px 2px 0px rgba(20, 64, 104, 0.10)',
                              }}
                            >
                              <img
                                src={img}
                                className={`w-full ${i === 6 ? 'h-[8px] px-1' : ''}`}
                              ></img>
                            </div>
                          ))}
                        </div>
                        <div
                          className={
                            'skid clone flex items-center' +
                            (animate ? '' : ' stop')
                          }
                        >
                          {images.map((img, i) => (
                            <div
                              key={i}
                              className={`mr-[10px] flex h-[40px] w-[40px] flex-none items-center rounded-[10px] bg-white transition-all duration-500 ease-in-out hover:scale-125`}
                              style={{
                                boxShadow:
                                  '0px 1px 2px 0px rgba(20, 64, 104, 0.10)',
                              }}
                            >
                              <img
                                src={img}
                                className={`w-full ${i === 6 ? 'h-[15px] px-2' : ''}`}
                              ></img>{' '}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>

                    <img src={imgPart2Section1Img3}></img>
                  </div>
                </div>
              </section>
            </div>
            <div className="section" id="screen2">
              <section className="relative relative flex h-full h-screen flex-col  items-center space-y-[40px]">
                <div className="flex w-full flex-col space-y-[16px] px-[40px] pt-[20px]">
                  <span className="text-[28px] font-bold leading-tight text-[#343D4B]">
                    판매자 페이지 로그인 없이 <br />
                    쉽고 간편한 주문 통합
                  </span>
                  <div className="flex flex-col  text-[18px] font-medium text-[#708AA2]">
                    <span>
                      알리익스프레스부터 국내 주요 쇼핑몰까지
                      <br /> 판매자 페이지에 직접 로그인하는
                      <br />
                      번거로움 없이 주문을 통합하고, 원하는
                      <br />
                      발주서 양식으로 변환하세요.
                      <br />
                    </span>
                  </div>
                </div>
                <div className="flex w-full  flex-col gap-[12px] px-[40px]">
                  <img src={imgPart2Section2Img1Mobile}></img>

                  <img src={imgPart2Section2Img2Mobile}></img>
                </div>
              </section>
            </div>
            <div className="section fp-auto-height fp-noscroll " id="screen3">
              <div className="flex flex-col px-[40px] pb-[30px]">
                {!showSecondContent && (
                  <div className="flex h-full w-full animate-slideindown flex-col">
                    {' '}
                    <span className="mb-[16px] text-[20px] font-semibold text-[#263142]">
                      알리익스프레스 주문 관리도 스윕을 통해 자동으로
                    </span>
                    <span className="mb-[24px] text-[18px] font-medium text-[#708AA2]">
                      합주문 건 분리, 상품명 및 옵션 한글 표기부터 주소 오류
                      자동 수정, 전화번호
                      <br />
                      오류 건 별도 표기까지. 복잡한 알리익스프레스 주문 관리도
                      자동으로 처리하세요.
                    </span>
                    <span
                      onClick={downloadPDF2}
                      className="text-[18px] font-bold text-[#EC4A5C] underline"
                    >
                      알리익스프레스 주문관리 소개 다운받기
                    </span>
                  </div>
                )}
                {showSecondContent && (
                  <div className="flex h-full w-full animate-slideindown flex-col">
                    <span className="mb-[16px] text-[20px] font-semibold text-[#263142]">
                      번거로운 수기 작업 없이 통합 엑셀 양식 변환도 자동으로
                    </span>
                    <span className="mb-[24px] text-[18px] font-medium text-[#708AA2]">
                      알리익스프레스 뿐만 아니라 국내 주요 쇼핑몰들의 주문도
                      스윕을 통해 단 몇 번의 <br />
                      클릭만으로 통합하고, 원하는 통합 엑셀 양식으로 변환하세요.{' '}
                    </span>
                    <span
                      onClick={downloadPDF}
                      className="text-[18px] font-bold text-blue-500 underline"
                    >
                      발주 관리 기능 자세히 알아보기
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className="section" id="screen4">
              <section className="relative relative flex h-full h-screen flex-col  items-center  space-y-[40px] pt-[20px]">
                <div className="flex w-full flex-col space-y-[16px] px-[40px]">
                  <span className="text-[28px] font-bold leading-tight text-[#343D4B]">
                    송장 등록 및 취소 관리도 <br />
                    스윕을 통해 자동으로
                  </span>
                  <div className="flex flex-col  text-[18px] font-medium text-[#708AA2]">
                    <span>
                      쇼핑몰별로 로그인 해 취소 건을 확인하고,
                      <br /> 송장 발급 후 쇼핑몰 별 송장 일괄 등록 <br />
                      양식에 맞춰 분리하는 번거로움 없이, <br />
                      취소 건 관리 및 송장 발급 후 쇼핑몰 송장
                      <br /> 일괄 등록도 자동으로 처리하세요.
                    </span>
                  </div>
                </div>
                <div className="flex w-full flex-col gap-[12px] px-[40px]">
                  <img src={imgPart2Section3Img1Mobile}></img>

                  <img src={imgPart2Section3Img2Mobile}></img>
                </div>
              </section>
            </div>
            <div className="section fp-auto-height fp-noscroll " id="screen5">
              <div className="flex flex-col px-[40px] pb-[30px]">
                {!showSecondContent2 && (
                  <div className="flex h-full w-full animate-slideindown flex-col">
                    {' '}
                    <span className="mb-[16px] text-[20px] font-semibold text-[#263142]">
                      취소 승인 및 출고 건 취소 거부
                    </span>
                    <span className="mb-[24px] text-[18px] font-medium text-[#708AA2]">
                      주문서 다운로드 이전 및 발주 업무
                      <br />
                      과정에서 발생한 취소 요청 건들을
                      <br />
                      개별적으로 확인하고 승인 또는 거부할
                      <br />
                      필요 없이 자동으로 처리하세요.
                      <br />
                    </span>
                  </div>
                )}
                {showSecondContent2 && (
                  <div className="flex h-full w-full animate-slideindown flex-col">
                    <span className="mb-[16px] text-[20px] font-semibold text-[#263142]">
                      쇼핑몰 운송장 일괄등록
                    </span>
                    <span className="mb-[24px] text-[18px] font-medium text-[#708AA2]">
                      공급처 또는 택배사 페이지에서 송장을
                      <br />
                      발급한 이후 송장 일괄 등록 양식에 맞춰
                      <br />
                      쇼핑몰 별로 분리하고 업르도 할 필요 없이 운송장을
                      일괄등록하세요.
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className="section fp-auto-height">
              <NewFooter isMobile={false} />
            </div>
          </div>
        </>
      )}
      {isModalOpen && (
        <div
          onClick={() => setIsModalOpen(false)}
          className={`fixed inset-0 z-40 flex h-full w-full items-center justify-center overflow-visible bg-gray-700 bg-opacity-50`}
        >
          <div
            className={`h-[820px] w-full scale-75  rounded-3xl border bg-white pt-[30px] shadow-lg md:w-[530px]`}
          >
            <div className="relative h-full w-full px-[10px]">
              <button
                className="modal-close absolute right-7 top-[-5px] z-40 cursor-pointer"
                onClick={() => setIsModalOpen(false)}
              >
                <img src={closeIcon} alt="close" className="h-[32px]" />
              </button>
              {iframeLoading && (
                <p className="fixed inset-0 z-50 flex items-center justify-center">
                  기다려 주세요...
                </p>
              )}
              <iframe
                className="z-10 h-full w-full pb-10"
                src={recatch_iframe_link}
                onLoad={handleIframeLoad}
                style={{
                  display: iframeLoading ? 'none' : 'block',
                }}
              ></iframe>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default App;
