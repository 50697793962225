import { createContext, useContext } from 'react';
import { noop } from 'utils/function/noop';

export interface TooltipContextValue {
  open: boolean;
  refs: {
    setFloating: (node: HTMLElement | null) => void;
    setReference: (node: HTMLElement | null) => void;
  };
  getReferenceProps?: (
    userProps?: React.HTMLProps<Element>
  ) => Record<string, unknown>;
  getFloatingProps?: (
    userProps?: React.HTMLProps<HTMLElement>
  ) => Record<string, unknown>;
  floatingStyles: React.CSSProperties;
}

const tooltipContext = createContext<TooltipContextValue>({
  open: false,
  refs: {
    setFloating: noop,
    setReference: noop,
  },
  floatingStyles: {},
});

export const TooltipContextProvider = tooltipContext.Provider;

export const useTooltipContext = () => useContext(tooltipContext);
