import { BeatLoader } from 'react-spinners';

const LoadingIndicator = () => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-opacity-20 bg-black z-30">
      <BeatLoader size={35} color="#4880EE" loading={true} />
    </div>
  );
};

export default LoadingIndicator;
