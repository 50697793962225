import { action, makeObservable, observable } from 'mobx';

class LoadingStore {
  isLoading = false;

  constructor() {
    makeObservable(this, {
      isLoading: observable,

      setIsLoading: action,
    });
  }

  setIsLoading(isLoading) {
    this.isLoading = isLoading;
  }
}

export default new LoadingStore();
