import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useOMSStore } from 'src/hooks/useOMSStore';
import LoadingIndicator from '../../components/LoadingIndicator';
import OMSContainer from '../../components/OMSContainer';
import DailySettlementSummaryTable from '../../components/Tables/DailySettlementSummaryTable';
import useSettlement from '../../hooks/useSettlement';
import DownloadArrow from '../../icons/DownloadArrow';
import LoadingStore from '../../stores/LoadingStore';
import UserStore from '../../stores/UserStore';
import backendApi from '../../utils/backendApi';
import { createDailySettlementSummary } from '../../utils/createDailySettlementSummary';
import { areArraysEqual, areObjectsEqual, isValid } from '../../utils/utils';

const getUpdatedOrNewObjects = (newArray: any[], existingArrays: any[]) => {
  const oldArrayMap = new Map(existingArrays.map((item) => [item._id, item]));

  const updatedObjects = newArray.filter((item) => {
    const existingItem = oldArrayMap.get(item._id);
    return !existingItem || !areObjectsEqual(item, existingItem);
  });

  return updatedObjects;
};

const DailySettlementManagementScreen = observer(
  ({ userType = 'retailer' }: { userType?: 'supplier' | 'retailer' }) => {
    const oms = useOMSStore();
    const [settlementData, setSettlementData] = useState<any[]>([]);
    const [priceList, setPriceList] = useState(UserStore?.priceInfos || []);
    const [initialPriceList, setInitialPriceList] = useState(
      UserStore?.priceInfos || []
    );

    const { downloadDailySettlementFile } = useSettlement();

    const summarizeDailySettlementData = () => {
      const data = createDailySettlementSummary(oms.order.normalizedOrders, {
        userType:
          oms.user.setting?.settlementType ||
          oms.user.companyType ||
          'retailer',
      });

      setSettlementData(data);
    };

    const fetchDailySettlementData = async () => {
      try {
        if (!isValid(oms.order.normalizedOrders)) {
          return;
        }

        LoadingStore?.setIsLoading(true);
        const res = await backendApi.getDailySettlementData();

        if (res.success) {
          setSettlementData(res.data.settlementData);
        } else {
          summarizeDailySettlementData();
        }
      } catch (err) {
        console.error('Error while fetching daily settlement data :', err);
      } finally {
        LoadingStore?.setIsLoading(false);
      }
    };

    const getPriceData = async () => {
      try {
        if (isValid(UserStore?.priceInfos)) {
          return;
        }

        LoadingStore?.setIsLoading(true);
        const res = await backendApi.getPriceInfo();

        if (res.success) {
          UserStore?.setPriceInfos(res.data);
          setPriceList(res.data);
          setInitialPriceList(res.data);
        }
      } catch (err) {
        console.error('Error while fetching price infos :', err);
      } finally {
        LoadingStore?.setIsLoading(false);
      }
    };

    useEffect(() => {
      fetchDailySettlementData();
      getPriceData();
    }, [oms.order.normalizedOrders]);

    const getPartnerName = (partnerId: string) => {
      const partnerInfo = oms.partner.partners.find((p) => p._id === partnerId);
      return partnerInfo ? partnerInfo.name : '기타';
    };

    const hasPartnerData = settlementData.some((item) => item.partnerId);
    const settings = {
      userType,
      hasPartnerData,
      priceList,
    };

    const handleDownloadFile = async (data: any) => {
      if (!isValid(data)) {
        return;
      }

      // 파트너사 이름 추가
      const enhancedData = data.map((item: any) => ({
        ...item,
        partnerName: getPartnerName(item.partnerId),
      }));

      await downloadDailySettlementFile(enhancedData, settings);

      // price 중 data 내 productId가 productInfo의 productId에 없는 것들을 삭제
      const newPriceList = priceList.filter((item) => {
        const productIds = oms.product.products.map((p) => p._id) || [];

        // comp: composition
        return (
          item.data &&
          item.data.every(
            (comp: any) => comp.productId && productIds.includes(comp.productId)
          )
        );
      });

      if (!areArraysEqual(newPriceList, initialPriceList)) {
        if (
          !window.confirm(
            `정산 과정에서 변경하신 상품 별 가격 정보를 저장할까요?`
          )
        ) {
          return;
        }

        const updatedPriceList = getUpdatedOrNewObjects(
          priceList,
          initialPriceList
        );

        const priceUpdateRes =
          await backendApi.updatePriceInfo(updatedPriceList);

        if (priceUpdateRes.success) {
          UserStore?.setPriceInfos(priceList);
          setInitialPriceList(priceList);
          alert('가격 정보가 저장되었습니다.');
        } else {
          alert('가격 정보 저장에 실패했습니다. 잠시 후 다시 시도해 주세요.');
        }
      }
    };

    return (
      <OMSContainer bgColor="bg-[#F2F6FA]">
        <div className="flex items-center justify-between">
          <h1 className="font-pretendard text-[24px] font-bold leading-[30px] text-[color:var(--Gray-600,#343D4B)]">
            일일 정산 관리
          </h1>

          <div className="flex items-center justify-end">
            <div className="flex h-[42px] cursor-pointer flex-row rounded-md bg-[#1991FF]">
              <>
                <div
                  role="button"
                  onClick={() => handleDownloadFile(settlementData)}
                  className="flex items-center justify-center pl-5 pr-4"
                >
                  <span className="font-pretendard text-[16px] font-bold leading-[19px] text-white">
                    정산 파일
                  </span>
                  <div>
                    <DownloadArrow />
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>

        <div className="my-[40px] rounded-[8px] bg-white p-[20px]">
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-row items-center gap-[10px] overflow-x-hidden whitespace-nowrap">
              {/* <Button
                name={`정산 내역 저장`}
                onClick={() => {
                  if (!isValid(settlementData)) return;
                  saveDailySettlementData(settlementData);
                }}
                color='black'
                innerComponent={
                  <div className='flex items-center gap-[6px]'>
                    <img
                      src={fileIcon}
                      alt='File'
                      className='w-auto h-[14px]'
                    />
                    <span>정산 내역 저장</span>
                  </div>
                }
                className={`${
                  !isValid(settlementData)
                    ? 'cursor-not-allowed'
                    : 'cursor-pointer'
                }`}
              /> */}
            </div>
          </div>
          <div className="flex max-h-[calc(100vh-200px)] max-w-full overflow-x-auto break-all">
            <DailySettlementSummaryTable
              settlementData={settlementData}
              setSettlementData={setSettlementData}
              priceList={priceList}
              setPriceList={setPriceList}
            />
          </div>
        </div>

        {LoadingStore?.isLoading && <LoadingIndicator />}
      </OMSContainer>
    );
  }
);

export default DailySettlementManagementScreen;
