import { Order } from '@sweep/contract';
import { AbstractPlugin } from '../../interface';

export class RemoveZeroQuantityPlugin extends AbstractPlugin {
  transform = (orders: Order[]): Promise<Order[]> => {
    return Promise.resolve(
      orders.filter((order) => !this.isZeroQuantity(order))
    );
  };

  isZeroQuantity(order: Order) {
    const quantity = order.quantity;
    if (quantity == null) {
      return false;
    }
    return quantity.toString() === '0';
  }
}
