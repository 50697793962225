import { uniq } from 'src/utils/array/uniq';
import { translationRegions } from './translationRegions';

const REGION_RANK = ['도', '시', '군', '구', '읍', '면'];

export function translateAddress(address: string): string {
  const addressList = (address ?? '')
    .split(' ')
    .map((address) => {
      for (const key of translationRegions) {
        if (key[0] === address) {
          return key[1];
        }
      }
      return address;
    })
    .map((address) => {
      if (address === '세종시') {
        return '세종특별자치시';
      }
      return address;
    });

  const uniqueAddressList = uniq(addressList);

  const sortedAddressList = uniqueAddressList.sort((a, b) => {
    const findAInDict = translationRegions.find((item) => item[1] === a);
    const findBInDict = translationRegions.find((item) => item[1] === b);
    if (findAInDict && findBInDict) {
      return (
        REGION_RANK.indexOf(a.slice(-1)) - REGION_RANK.indexOf(b.slice(-1))
      );
    } else if (findAInDict) {
      return -1;
    } else if (findBInDict) {
      return 1;
    } else {
      return 0;
    }
  });

  let resultAddress = sortedAddressList.join(' ');

  for (const key of translationRegions) {
    resultAddress = resultAddress.replace(new RegExp(key[0], 'g'), key[1]);
  }
  resultAddress = resultAddress.replace(/Other/g, '');

  return resultAddress;
}
