import { readExcelOnServer } from 'network/file';
import { readExcelOnBrowser } from './readExcelOnBrowser';
import { toExcelJson } from './toExcelJson';

export async function readUnProtectedExcel(file: File) {
  const response = await readExcelOnServer(file);
  if (response.success) {
    return toExcelJson(response.data);
  }

  return await readExcelOnBrowser(file);
}
