declare global {
  interface ImportMeta {
    env: {
      VITE_NODE_ENV?: 'development' | 'production';
      VITE_BACKEND_URL_DEVELOPMENT: string;
      VITE_BACKEND_URL_PRODUCTION: string;
      VITE_ENABLE_API_MOCK: 'true' | 'false';
      VITE_AMPLITUDE_API_KEY: string;
      VITE_SENTRY_DSN_FOR_FRONTEND: string;
      VITE_DEPLOY_ENVIRONMENT: string;
    };
  }
}

export const NODE_ENV = import.meta.env.VITE_NODE_ENV ?? 'production';

const BACKEND_URL_DEVELOPMENT = import.meta.env.VITE_BACKEND_URL_DEVELOPMENT;
const BACKEND_URL_PRODUCTION = import.meta.env.VITE_BACKEND_URL_PRODUCTION;
export const BACKEND_URL =
  NODE_ENV === 'development' ? BACKEND_URL_DEVELOPMENT : BACKEND_URL_PRODUCTION;

export const AMPLITUDE_API_KEY = import.meta.env.VITE_AMPLITUDE_API_KEY;
