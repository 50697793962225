import { createContext, useContext } from 'react';
import { noop } from 'utils/function/noop';

export interface ShoppingMallSelectContextValue {
  value: string[];
  onChange: (value: string[]) => void;
}

const shoppingMallSelectContext = createContext<ShoppingMallSelectContextValue>(
  {
    value: [],
    onChange: noop,
  }
);

export const ShoppingMallSelectContextProvider =
  shoppingMallSelectContext.Provider;

export const useShoppingMallSelectContext = () =>
  useContext(shoppingMallSelectContext);
