import { Button } from 'design-system/components/Button';
import { useOMSStore } from 'hooks/useOMSStore';
import { observer } from 'mobx-react-lite';
import { MatchedProduct } from 'models/CompositionMatching';
import { CMStatus, ProductMatchingWithStatus } from '../../interface';
import { transformToCMProducts } from '../../services/transformToCMProducts';
import CMInput from '../common/CMInput';
import CMOrderLabel from '../common/CMOrderLabel';
import { UnmatchedCMFormStore } from './UnmatchedCMFormStore';

interface UnmatchedCMFormInputProps {
  store: UnmatchedCMFormStore;
  matching: ProductMatchingWithStatus;
  index: number;
}

function UnmatchedCMFormInput({
  store,
  matching,
  index,
}: UnmatchedCMFormInputProps) {
  const oms = useOMSStore();
  const { cmOrder, products, status } = matching;

  const onChange = (value: ProductMatchingWithStatus) => {
    store.handleMatchingChange(index, value);
  };

  const handleProductsChange = (value: Partial<MatchedProduct>[]) => {
    const products = transformToCMProducts(value, oms);
    onChange({
      ...matching,
      products: value,
      status: products.length === 0 ? null : status,
    });
  };

  const handleStatusChange = (status: CMStatus) => {
    if (status === 'confirm') {
      const products = transformToCMProducts(matching.products, oms);
      if (products.length === 0) {
        alert('상품, 옵션이 선택되었는지 확인해주세요.');
        return;
      }

      onChange({
        ...matching,
        status,
        products,
      });
      return;
    }

    onChange({ ...matching, status });
  };

  return (
    <>
      <div className="flex justify-between gap-[32px] py-4">
        <div className="flex flex-1 gap-[42px]">
          <CMOrderLabel cmOrder={cmOrder} />
          <CMInput
            value={products}
            onChange={(value) => handleProductsChange(value)}
          />
        </div>
        <div className="flex gap-[8px]">
          <Button
            color="blue"
            variant={status === 'confirm' ? 'solid' : 'line'}
            onClick={() => handleStatusChange('confirm')}
          >
            확정
          </Button>
          <Button
            color="gray"
            variant={status === 'pending' ? 'solid' : 'line'}
            onClick={() => handleStatusChange('pending')}
          >
            보류
          </Button>
        </div>
      </div>
      <hr className="h-px border-0 bg-gray-200" />
    </>
  );
}

export default observer(UnmatchedCMFormInput);
