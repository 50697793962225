export const CJ_Doseosangan: [string, string][] = [
  ['15654', '15654'],
  ['23008', '23010'],
  ['23100', '23116'],
  ['23124', '23136'],
  ['32133', '32133'],
  ['40200', '40240'],
  ['52570', '52571'],
  ['53031', '53033'],
  ['53088', '53104'],
  ['56347', '56349'],
  ['57068', '57069'],
  ['58760', '58761'],
  ['58804', '58804'],
  ['58809', '58809'],
  ['58817', '58818'],
  ['58826', '58826'],
  ['58843', '58866'],
  ['58953', '58958'],
  ['59127', '59127'],
  ['59137', '59145'],
  ['59149', '59170'],
  ['59421', '59421'],
  ['59563', '59563'],
  ['59650', '59650'],
  ['59766', '59766'],
  ['59781', '59781'],
  ['59783', '59790'],
  ['63000', '63365'],
  ['63500', '63644'],
];

export const LOTTE_Doseosangan: [string, string][] = [
  ['15654', '15654'],
  ['23008', '23010'],
  ['23100', '23116'],
  ['23124', '23136'],
  ['33411', '33411'],
  ['40200', '40240'],
  ['52571', '52571'],
  ['53031', '53032'],
  ['53089', '53104'],
  ['54000', '54000'],
  ['56347', '56349'],
  ['57068', '57069'],
  ['58760', '58760'],
  ['58804', '58804'],
  ['58809', '58810'],
  ['58816', '58817'],
  ['58843', '58866'],
  ['58953', '58958'],
  ['59137', '59145'],
  ['59149', '59166'],
  ['59169', '59170'],
  ['59563', '59563'],
  ['59650', '59650'],
  ['59782', '59783'],
  ['59785', '59790'],
  ['63000', '63365'],
  ['63500', '63644'],
];
