import { Order } from '@sweep/contract';
import { AbstractPlugin } from '../../interface';

interface Rule {
  target: string;
}

interface NormalizePhoneNumberPluginConfig {
  rules: Rule[];
}

export class NormalizePhoneNumberPlugin extends AbstractPlugin<NormalizePhoneNumberPluginConfig> {
  transform = (orders: Order[]): Promise<Order[]> => {
    return Promise.resolve(orders.map((order) => this.transformOrder(order)));
  };

  transformOrder = (order: Order): Order => {
    return this.config.rules.reduce((acc, rule) => {
      return this.normalizePhoneNumber(acc, rule);
    }, order);
  };

  normalizePhoneNumber = (order: Order, rule: Rule): Order => {
    const phoneNumber = order[rule.target]?.toString().trim();
    if (phoneNumber == null) {
      return order;
    }

    if (phoneNumber.length === 10 && phoneNumber.slice(0, 2) === '10') {
      return {
        ...order,
        contactNumber: '0' + phoneNumber,
      };
    }

    if (phoneNumber.length === 12 && phoneNumber.slice(0, 4) === '8210') {
      return {
        ...order,
        contactNumber: '0' + phoneNumber.slice(2),
      };
    }

    return order;
  };
}
