import { createContext, useContext } from 'react';
import { noop } from 'utils/function/noop';

export interface MultiSelectcontextValue {
  open: boolean;
  value: string[];
  onChange: (value: string[]) => void;
}

const multiSelectContext = createContext<MultiSelectcontextValue>({
  open: false,
  value: [],
  onChange: noop,
});

export const MultiSelectContextProvider = multiSelectContext.Provider;

export const useMultiSelectContext = () => useContext(multiSelectContext);
