import {
  DispatchedOrder,
  isDispatchedOrder,
  Order,
  OrderStatus,
} from '@sweep/contract';
import { AbstractPlugin } from '../../interface';
import { isAliExpressOrder } from '../../services/isAliExpressOrder';
import { formatAliDate } from './formatAliDate';
import { translateAddress } from './translateAddress';

export class AliDispatchPlugin extends AbstractPlugin {
  transform = (orders: Order[]): Promise<Order[]> => {
    return Promise.resolve(orders.map((order) => this.transformOrder(order)));
  };

  transformOrder = (order: Order): Order => {
    if (!isAliExpressOrder(order, this.oms)) {
      return order;
    }

    if (!isDispatchedOrder(order)) {
      return order;
    }

    const dateFormatedOrder = this.formatOrderDate(order);
    const translatedOrder = this.translateOrderAddress(dateFormatedOrder);
    const normalizedOrder = this.normalize(translatedOrder);
    return normalizedOrder;
  };

  formatOrderDate = (order: DispatchedOrder): DispatchedOrder => {
    return {
      ...order,
      orderDate:
        order.orderDate != null ? formatAliDate(order.orderDate) : undefined,
      paymentDate:
        order.paymentDate != null
          ? formatAliDate(order.paymentDate)
          : undefined,
    };
  };

  translateOrderAddress = (order: DispatchedOrder): DispatchedOrder => {
    const address = order.address;
    if (address == null) {
      return order;
    }

    return {
      ...order,
      address: translateAddress(address),
    };
  };

  normalize(order: DispatchedOrder): DispatchedOrder {
    const newOrder = { ...order };

    if (newOrder.orderStatus === OrderStatus.partialDelivery) {
      newOrder.orderStatus = OrderStatus.productPreparingPartialDelivery;
    }

    newOrder.price = this.parseNumber(order.price);
    newOrder.shippingPrice = this.parseNumber(order.shippingPrice);

    if (newOrder.paymentNumber != null) {
      newOrder.childOrderNumber = newOrder.orderNumber;
      newOrder.orderNumber = newOrder.paymentNumber;
      delete newOrder.paymentNumber;
    }

    if (newOrder.sweepedShippingCompany != null) {
      newOrder.shippingCompany = newOrder.sweepedShippingCompany;
    }

    if (newOrder.sweepedShippingNumber != null) {
      newOrder.shippingNumber = newOrder.sweepedShippingNumber;
    }

    return newOrder;
  }

  parseNumber = (
    value: string | number | undefined | null
  ): number | undefined => {
    if (value == null) {
      return undefined;
    }

    if (typeof value === 'number') {
      return value;
    }

    return parseFloat(value);
  };
}
