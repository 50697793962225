import { DispatchedOrder , OrderStatus } from '@sweep/contract';
import { http, HttpHandler, HttpResponse } from 'msw';
import { BACKEND_URL } from 'src/env';
import {
  ConfirmAndUpdateOrderResponse,
  ConfirmedOrder,
} from 'src/network/order/confirmOrder';
import { isNotNil } from 'src/utils/function';
import { sleep } from 'src/utils/function/sleep';
import { LocalStorageValue } from 'src/utils/mobx';
import { LOCALSTORAGE_DISPATCH_PREFIX } from '../../../common/constants';

const CONFIRM_ORDER_URL = `${BACKEND_URL}/confirm-order`;

export function createConfirmOrderHandlers(): HttpHandler[] {
  return [createConfirmOrderHandler()].filter(isNotNil);
}

export type ConfirmUpdateOrdersType =
  | 'success'
  | 'partial-fail'
  | 'fail'
  | 'none';
export const confirmUpdateOrderTypeStorage =
  new LocalStorageValue<ConfirmUpdateOrdersType>({
    key: `${LOCALSTORAGE_DISPATCH_PREFIX}/confirm-update-order-type`,
    defaultValue: 'none',
  });

function createConfirmOrderHandler() {
  const confirmOrderType = confirmUpdateOrderTypeStorage.value;

  if (confirmOrderType === 'none') {
    return null;
  }

  return http.post(
    `${CONFIRM_ORDER_URL}/confirm-update-orders`,
    async ({ request }) => {
      await sleep(1000);
      const { infoArray: orders } = (await request.json()) as {
        infoArray: DispatchedOrder[];
      };

      switch (confirmOrderType) {
        case 'success':
          return HttpResponse.json<ConfirmAndUpdateOrderResponse>({
            success: true,
            result: orders.map<ConfirmedOrder>((order) => ({
              ...order,
              success: true,
              orderStatus: OrderStatus.productPreparing,
            })),
          });

        case 'partial-fail':
          return HttpResponse.json<ConfirmAndUpdateOrderResponse>({
            success: true,
            result: mapPartialFailCanceledOrders(orders),
          });

        case 'fail':
          return HttpResponse.json<ConfirmAndUpdateOrderResponse>({
            success: false,
            result: { err: 'msw-fail' },
          });
      }
    }
  );
}

function mapPartialFailCanceledOrders(orders: DispatchedOrder[]) {
  return orders.map<ConfirmedOrder>((order, index) =>
    index % 2 === 0
      ? { ...order, success: true, orderStatus: OrderStatus.inDelivery }
      : {
          ...order,
          success: false,
          failReason: 'msw-order-error',
        }
  );
}
