import Sweep from '../images/Sweep.png';

const Footer = () => {
  return (
    <footer className="bg-gray-100 text-gray-400 text-sm p-10 font-pretendard">
      <div className="container mx-auto">
        <div className="flex flex-col items-start">
          {/* 로고 및 회사명 */}
          <div className="mb-6">
            <img src={Sweep} alt="회사 로고" className="w-20 h-auto" />
          </div>

          {/* 회사 정보 */}
          <div className="mb-6">
            <div className="flex items-center space-x-1">
              <p>스윕</p>
              <p>|</p>
              <p>서울특별시 관악로 158, 스프링캠프 5층</p>
            </div>
            <div className="flex items-center space-x-1">
              <p>사업자등록번호 : 491-87-02864</p>
              <p>|</p>
              <p>통신판매업 신고번호: 제2024-서울관악-0637호</p>
            </div>
            <div className="flex items-center space-x-1">
              <p>대표: 윤현일</p>
              <p>|</p>
              <p>010-8451-2670</p>
            </div>
          </div>
        </div>

        {/* 이용약관 및 개인정보처리방침 */}
        <div className="flex">
          <a
            href="https://sweepoms.notion.site/476a63cf5c3842569775438ae80be500?pvs=4"
            className="mr-4 hover:text-blue-500"
            target="_blank"
            rel="noopener noreferrer"
          >
            서비스 이용약관
          </a>
          <a
            href="https://sweepoms.notion.site/587bc67c6247485eab6b6972bef1a586?pvs=4"
            className="hover:text-blue-500"
            target="_blank"
            rel="noopener noreferrer"
          >
            개인정보 처리방침
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
