import { useState } from 'react';
import { IconInfo } from 'src/icons';

function HelpInfo() {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen(!open);

  return (
    <button
      className="relative flex h-fit items-center gap-1"
      onClick={toggleOpen}
    >
      <IconInfo />
      <p className="text-regular-xs text-gray-700 underline">
        문제가 반복된다면?
      </p>
      {open && (
        <div className="absolute right-0 top-[24px] rounded-[8px] bg-white px-[16px] py-[10px] shadow-[0_1px_8px_0_rgba(0,0,0,0.1)]">
          <p className="text-medium-xs text-nowrap text-left text-gray-700">
            카카오톡 혹은 010-8451-2670으로
            <br />
            문의 주시면 빠르게 해결해드리겠습니다.
          </p>
        </div>
      )}
    </button>
  );
}

export default HelpInfo;
