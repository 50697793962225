import LoadingStore from '../stores/LoadingStore';

const useUpload = () => {
  const validateFiles = async (e, uploadedFiles = []) => {
    // To do: 오류 발생 시 로딩 상태를 비활성화하는 것을 보장
    e.preventDefault();
    let uploadingFiles = e.dataTransfer?.files || e.target?.files;

    uploadingFiles = Array.from(uploadingFiles);
    const allowedExtensions = ['xlsx', 'xls', 'csv'];
    const exitsingFilesNames = uploadedFiles.map((file) =>
      file.name.normalize('NFC')
    );
    const uploadingfileNames = uploadingFiles.map((file) =>
      file.name.normalize('NFC')
    );
    const intersectionFiles = // 위 두 배열의 교집합
      exitsingFilesNames.filter((name) => uploadingfileNames.includes(name));
    if (intersectionFiles.length === uploadingFiles.length) {
      alert('이미 업로드된 파일입니다.');
      return [];
    } else if (intersectionFiles.length > 0) {
      const errorMessage =
        `'${intersectionFiles[0]}'${
          intersectionFiles.length === 1
            ? ''
            : ' 및 ' + (intersectionFiles.length - 1) + '개'
        }` +
        `파일은 이미 업로드되었어요. 나머지 ${
          uploadingFiles.length - intersectionFiles.length
        }개 파일이라도 업로드할까요?`;
      const isConfirmed = window.confirm(errorMessage);
      if (!isConfirmed) {
        return [];
      }
      uploadingFiles = uploadingFiles.filter(
        (file) => !intersectionFiles.includes(file.name.normalize('NFC'))
      );
    }
    LoadingStore?.setIsLoading(true);
    let validFiles = [];
    let invalidFiles = [];
    for (let i = 0; i < uploadingFiles.length; i++) {
      const file = uploadingFiles[i];
      const filename = file.name.normalize('NFC');
      const ext = filename.split('.').pop().toLowerCase();
      if (!allowedExtensions.includes(ext)) {
        invalidFiles.push({
          name: file.name,
          reason: '엑셀이 아닌 파일',
        });
        continue;
      }

      validFiles.push(file);
    }
    LoadingStore?.setIsLoading(false);
    if (invalidFiles.length > 0) {
      alert(
        `다음 파일들은 엑셀 파일이 아니므로 업로드할 수 없습니다. \n\n파일이름: ${invalidFiles.map(
          (file) => file.name
        )}`
      );
    }
    return validFiles;
  };

  return { validateFiles };
};

export default useUpload;
