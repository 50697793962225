import { ReactNode } from 'react';
import { TooltipContent } from './TooltipContent';
import { TooltipContextProvider } from './TooltipContext';
import { TooltipTrigger } from './TooltipTrigger';
import { useTooltip } from './useTooltip';

interface TooltipOptions {
  initialOpen?: boolean;
  placement?: 'top' | 'bottom';
  open?: boolean;
  delay?: { open?: number; close?: number };
  onOpenChange?: (open: boolean) => void;
}

export function Tooltip({
  children,
  ...options
}: { children: ReactNode } & TooltipOptions) {
  const tooltip = useTooltip(options);

  return (
    <TooltipContextProvider value={tooltip}>{children}</TooltipContextProvider>
  );
}

Tooltip.Trigger = TooltipTrigger;
Tooltip.Content = TooltipContent;
