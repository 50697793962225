import { Order } from '@sweep/contract';
import { AbstractPlugin } from '../../interface';
import { NormalizePhoneNumberPlugin } from '../normalize-phone-number';

export class AddZeroToContactNumberPlugin extends AbstractPlugin {
  plugin = new NormalizePhoneNumberPlugin(this.oms, {
    rules: [{ target: 'contactNumber' }],
  });

  transform = (orders: Order[]): Promise<Order[]> => {
    return this.plugin.transform(orders);
  };
}
