import { NormalizedOrder } from '@sweep/contract';
import { If } from '@sweep/utils';
import { observer } from 'mobx-react-lite';
import { useMemo, useState } from 'react';
import { Button } from 'src/design-system/components/Button';
import { ColumnDef } from 'src/design-system/components/Table';
import { SelectableTable } from 'src/design-system/components/Table/SelectableTable';
import { useOMSStore } from 'src/hooks/useOMSStore';
import OrderAggregationTableCell from './OrderAggregationTableCell';

interface OrderAggregationTableProps {
  orders: NormalizedOrder[];
  selectedOrders: NormalizedOrder[];
  onSelect: (orders: NormalizedOrder[]) => void;
}

const THRESHOLD = 100;

function OrderAggregationTable({
  orders,
  selectedOrders,
  onSelect,
}: OrderAggregationTableProps) {
  const oms = useOMSStore();
  const headerKeys = oms.user.excelHeaderKeys;
  const columnTranslation = oms.user.excelColumnMapping;

  const [isVisible, setIsVisible] = useState(false);
  const isOverThreshold = orders.length > THRESHOLD;

  const handleVisible = () => {
    setIsVisible(!isVisible);
  };

  const columns = useMemo(
    () =>
      headerKeys.map<ColumnDef<NormalizedOrder>>((key) => ({
        header: columnTranslation[key],
        cell: ({ row, lastRow, lastColumn }) => (
          <OrderAggregationTableCell
            order={row}
            orderKey={key}
            lastColumn={lastColumn}
            lastRow={lastRow}
          />
        ),
      })),
    [columnTranslation, headerKeys]
  );

  return (
    <div className="gap-20px flex flex-col">
      <SelectableTable
        className="max-h-[calc(100vh-200px)] max-w-full overflow-x-auto"
        items={orders}
        columns={columns}
        range={isVisible ? undefined : [0, THRESHOLD]}
        getKey={(order) => order.uniqueCodeAfterCustomization}
        selectedItems={selectedOrders}
        onSelect={onSelect}
      />

      <If is={isOverThreshold}>
        <Button color="lightGray" onClick={handleVisible} className="w-fit">
          {isVisible
            ? `${THRESHOLD}개 주문만 보기`
            : `${orders.length}개 주문 전부 보기`}
        </Button>
      </If>
    </div>
  );
}

export default observer(OrderAggregationTable);
