import { IconCheck } from 'src/icons';
import { cva } from 'src/third-parties/tailwind';

interface SelectIconProps {
  selected?: boolean;
}

function SelectIcon({ selected = false }: SelectIconProps) {
  return <IconCheck className={icon({ selected })} />;
}

const icon = cva('', {
  variants: {
    selected: {
      true: 'text-blue-500',
      false: 'text-gray-300',
    },
  },
});

export default SelectIcon;
