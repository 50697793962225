import { useEffect } from 'react';

export function useRecatchScript() {
  useEffect(() => {
    (function (r, e, c, a, t, ch) {
      const h = r.getElementsByTagName(e)[0],
        i = r.createElement(c) as HTMLScriptElement;
      i.async = true;
      i.id = 'recatch-embed-script';
      i.src =
        'https://cdn.recatch.cc/recatch-embed.iife.js?t=' +
        a[0] +
        '&b=' +
        a[1] +
        '&c=' +
        t +
        '&tr=true&th=' +
        ch +
        '&mode=sdk&pc=%23426cdb';
      h.appendChild(i);
    })(
      document,
      'head',
      'script',
      ['sweepingoms', 'nsidynrpek'],
      'recatch-form',
      'light'
    );
  }, []);
}
