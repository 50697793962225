import { ReactNode } from 'react';
import { IconXDeleteModal } from 'src/icons';
import { cva } from 'src/third-parties/tailwind';
import { useModalContext } from './context/ModalContext';

export interface ModalTitleProps {
  children: ReactNode;
  className?: string;
}

export function ModalTitle({ children, className }: ModalTitleProps) {
  const { onClose } = useModalContext();

  return (
    <div className={container({ className })}>
      <p className="text-extrabold-l text-gray-700">{children}</p>
      <button onClick={onClose}>
        <IconXDeleteModal className="text-gray-700" />
      </button>
    </div>
  );
}

const container = cva('flex items-center justify-between');
