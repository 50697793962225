export function warningNotLinkedShoppingMall() {
  const isConfirmed = window.confirm(
    '연동된 쇼핑몰이 없습니다. 쇼핑몰을 연동하고 스윕에서 쇼핑몰 주문을 자동으로 수집하시겠습니까?'
  );

  if (isConfirmed) {
    alert(
      `쇼핑몰 연동은 온보딩 매니저에게 문의해 주세요.\n온보딩 매니저 연락처: 윤현일, 010-8451-2670`
    );
  }
}
