// stores
import AuthStore from '../stores/AuthStore';
import LoadingStore from '../stores/LoadingStore';
import UserStore from '../stores/UserStore';
// custom hooks
// utils
import backendApi from '../utils/backendApi';
import { isValid } from '../utils/utils';

const useAuthentication = () => {
  const checkLoginStatus = async () => {
    LoadingStore.setIsLoading(true);

    const isLoggedIn = await AuthStore?.loadToken();
    if (isLoggedIn) {
      await UserStore?.initializeUserData();

      // version check
      const latestVersion = await backendApi.fetchServiceVersion();
      const currentVersion = localStorage.getItem('@version');

      if (
        isValid(latestVersion) &&
        isValid(currentVersion) &&
        currentVersion !== latestVersion
      ) {
        localStorage.setItem('@version', latestVersion);
        window.location.reload(true);
      }
    }
    LoadingStore.setIsLoading(false);
    return isLoggedIn;
  };

  return { checkLoginStatus };
};

export default useAuthentication;
