import { action, computed, makeObservable, observable } from 'mobx';
import { OMSStore } from 'src/stores/OMSStore';
import { CMOrder, ProductMatchingWithStatus } from '../../interface';
import { getInitialProducts } from '../../services/getInitialProducts';

export class UnmatchedCMFormStore {
  matchings: ProductMatchingWithStatus[] = [];

  get matchedCount() {
    const confirmedMatchings = this.matchings.filter(
      (matching) => matching.status === 'confirm'
    );

    return confirmedMatchings.length;
  }

  constructor(cmOrders: CMOrder[], oms: OMSStore) {
    makeObservable(this, {
      matchings: observable,

      matchedCount: computed,

      handleMatchingChange: action.bound,
    });

    this.matchings = cmOrders.map((cmOrder) => ({
      cmOrder,
      products: getInitialProducts(cmOrder, oms),
      status: null,
    }));
  }

  handleMatchingChange = (index: number, value: ProductMatchingWithStatus) => {
    this.matchings[index] = value;
  };
}
