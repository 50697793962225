import { EvaluateOperatorResult, Token } from '../interface';
import {
  isVariableArgumentOperator,
  Operator,
  parseVariableArgumentOperator,
} from '../operator';
import { INVALID_EXPRESSION } from './error';
import { evaluateOperatorAmpersand } from './fixed/evaluateOperatorAmpersand';
import { evaluateOperatorDivide } from './fixed/evaluateOperatorDivide';
import { evaluateOperatorEqual } from './fixed/evaluateOperatorEqual';
import { evaluateOperatorGreater } from './fixed/evaluateOperatorGreater';
import { evaluateOperatorGreaterOrEqual } from './fixed/evaluateOperatorGreaterOrEqual';
import { evaluateOperatorLess } from './fixed/evaluateOperatorLess';
import { evaluateOperatorLessOrEqual } from './fixed/evaluateOperatorLessOrEqual';
import { evaluateOperatorMultiple } from './fixed/evaluateOperatorMultiple';
import { evaluateOperatorPlus } from './fixed/evaluateOperatorPlus';
import { evaluateOperatorSubtract } from './fixed/evaluateOperatorSubtract';
import { evaluateOperatorAnd } from './variable/evaluateOperatorAnd';
import { evaluateOperatorIf } from './variable/evaluateOperatorIf';
import { evaluateOperatorOr } from './variable/evaluateOperatorOr';
import { evaluateOperatorRound } from './variable/evaluateOperatorRound';
import { evaluateOperatorRoundUp } from './variable/evaluateOperatorRoundDown';
import { evaluateOperatorRoundDown } from './variable/evaluateOperatorRoundUp';

export function evaluateOperator(
  operator: Operator,
  stack: Token[]
): EvaluateOperatorResult {
  switch (operator) {
    case '&':
      return evaluateOperatorAmpersand(stack);
    case '+':
      return evaluateOperatorPlus(stack);
    case '-':
      return evaluateOperatorSubtract(stack);
    case '*':
      return evaluateOperatorMultiple(stack);
    case '/':
      return evaluateOperatorDivide(stack);
    case '=':
      return evaluateOperatorEqual(stack);
    case '>':
      return evaluateOperatorGreater(stack);
    case '<':
      return evaluateOperatorLess(stack);
    case '>=':
      return evaluateOperatorGreaterOrEqual(stack);
    case '<=':
      return evaluateOperatorLessOrEqual(stack);
    case '(':
    case ')':
    case ',':
      return INVALID_EXPRESSION;
  }

  if (isVariableArgumentOperator(operator)) {
    const parsed = parseVariableArgumentOperator(operator);
    if (parsed == null) {
      return INVALID_EXPRESSION;
    }

    const [token, length] = parsed;
    switch (token) {
      case 'IF':
        return evaluateOperatorIf(stack, length);
      case 'AND':
        return evaluateOperatorAnd(stack, length);
      case 'OR':
        return evaluateOperatorOr(stack, length);
      case 'ROUND':
        return evaluateOperatorRound(stack, length);
      case 'ROUNDUP':
        return evaluateOperatorRoundUp(stack, length);
      case 'ROUNDDOWN':
        return evaluateOperatorRoundDown(stack, length);
    }
  }

  return INVALID_EXPRESSION;
}
