export const DISPATCHED_ORDER_HEADER = [
  '주문시간',
  '결제시간',
  '주문상태',
  '상품명',
  '상품옵션',
  '상품관리코드',
  '수량',
  '수령인',
  '휴대폰번호',
  '전화번호',
  '주소',
  '우편번호',
  '배송요청사항',
  '주문번호',
  '상세주문번호',
  '정산예정금액',
  '배송비',
  '배송업체',
  '상품코드(쇼핑몰)',
  '쇼핑몰',
  '주문자id',
  '운송장번호',
  '스윕고유번호',
];

export const DISPATCHED_ORDER_COLUMN_MAPPING: Record<string, string> = {
  orderDate: '주문시간',
  orderStatus: '주문상태',
  paymentDate: '결제시간',
  productName: '상품명',
  option: '상품옵션',
  optionCode: '상품관리코드',
  quantity: '수량',
  name: '수령인',
  contactNumber: '휴대폰번호',
  telephoneNumber: '전화번호',
  address: '주소',
  postCode: '우편번호',
  deliveryMessage: '배송요청사항',
  storeName: '스토어이름',
  orderNumber: '주문번호',
  productCode: '상품코드(쇼핑몰)',
  marketName: '쇼핑몰',
  sweepShippingCompany: '배송업체',
  childOrderNumber: '상세주문번호',
  price: '정산예정금액',
  shippingPrice: '배송비',
  buyerId: '주문자id',
  shippingNumber: '운송장번호',
  uniqueCode: '스윕고유번호',
};

export const DISPATCHED_ORDER_HEADER_KEYS = DISPATCHED_ORDER_HEADER.map(
  (header) =>
    Object.keys(DISPATCHED_ORDER_COLUMN_MAPPING).find(
      (key) => DISPATCHED_ORDER_COLUMN_MAPPING[key] === header
    )!
);

export const CANCEL_REQUESTED_ORDER_HEADER = [
  '주문상태',
  '쇼핑몰',
  '스윕고유번호',
  '주문번호',
  '상품명',
  '옵션',
  '수량',
  '주문금액',
  '배송비',
  '수취인',
  '휴대폰번호',
  '송장번호',
  '우편번호',
  '주소',
  '배송메시지',
];

export const CANCEL_REQUESTED_ORDER_COLUMN_MAPPING = {
  orderStatus: '주문상태',
  shoppingMall: '쇼핑몰',
  uniqueCode: '스윕고유번호',
  orderNumber: '주문번호',
  productName: '상품명',
  option: '옵션',
  quantity: '수량',
  price: '주문금액',
  name: '수취인',
  shippingNumber: '송장번호',
  배송비: '배송비',
  contactNumber: '휴대폰번호',
  postCode: '우편번호',
  address: '주소',
  deliveryMessage: '배송메시지',
};
