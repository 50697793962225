const TEXT_WEIGHTS = [
  'extrabold',
  'bold',
  'semibold',
  'medium',
  'regular',
] as const;
type TextWeight = (typeof TEXT_WEIGHTS)[number];

const TEXT_SIZES = ['xs', 's', 'm', 'l', 'xl', '2xl'] as const;
type TextSize = (typeof TEXT_SIZES)[number];

type TextStyle = `text-${TextWeight}-${TextSize}`;
export const TEXT_STYLES: TextStyle[] = TEXT_WEIGHTS.flatMap((weight) =>
  TEXT_SIZES.map((size) => `text-${weight}-${size}` as const)
);
