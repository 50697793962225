import { readExcelOnServer } from 'network/file';
import { getFilename } from 'services/file/getFilename';
import { toExcelJson } from './toExcelJson';

export async function readProtectedExcel(file: File) {
  const filename = getFilename(file);
  let isAlreadyTried = false;

  while (true) {
    const message = isAlreadyTried
      ? `${filename}의 암호가 틀렸습니다. 다시 입력해주세요. 모른다면 취소를 눌러주세요.`
      : `${filename}의 암호를 입력해주세요. 모른다면 취소를 눌러주세요.`;
    const password = window.prompt(message);
    isAlreadyTried = true;

    if (password == null) {
      return null;
    }

    const response = await readExcelOnServer(file, password);
    if (response.success) {
      return toExcelJson(response.data);
    }
  }
}
