// TODO(@이지원): 엑셀 값을 서버에서 JSON으로 내려준 후에는 없어질 함수
export function decodeUnicodeEscapeSequence(input: string): string {
  return input.replace(/\\u([\dA-Fa-f]{4})/g, (match, p1) =>
    String.fromCharCode(parseInt(p1, 16))
  );
}

export function parseUnicodeEscapeSequnce(
  rawDataString: string
): unknown | null {
  const decodedString = decodeUnicodeEscapeSequence(rawDataString);

  try {
    return JSON.parse(decodedString);
  } catch (error) {
    return null;
  }
}
