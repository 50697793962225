import { observer } from 'mobx-react-lite';
import PartnerPluginSection from './sections/PartnerPluginSection';
import SupplierPluginSection from './sections/SupplierPluginSection';
import UserDispatchPluginSection from './sections/UserDispatchPluginSection';
import UserMergeDispatchPluginSection from './sections/UserMergeDispatchPluginSection';
import UserPluginSection from './sections/UserPluginSection';

function PluginSettingScreen() {
  return (
    <div className="flex w-full flex-col gap-4 px-6 py-4">
      <UserDispatchPluginSection />
      <UserMergeDispatchPluginSection />
      <PartnerPluginSection />
      <UserPluginSection />
      <SupplierPluginSection />
    </div>
  );
}

export default observer(PluginSettingScreen);
