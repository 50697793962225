import React, { useEffect, useRef, useState } from 'react';

interface SimpleTableProps {
  orders: Record<string, any>[];
  header: string[];
  addressColumn: number;
  postCodeColumn: number;
}

const SimpleTable: React.FC<SimpleTableProps> = ({
  orders,
  header,
  addressColumn,
  postCodeColumn,
}) => {
  const [columnWidths, setColumnWidths] = useState<number[]>([]);
  const cellWidthRefs = useRef<(HTMLDivElement | null)[]>([]);
  const scrollRef = useRef<HTMLDivElement>(null);

  const getTextWidth = (text: string, fontSize: number): number => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (!context) {
      return 0;
    }

    context.font = `${fontSize}px Pretendard`;
    const width = context.measureText(text).width;

    return width;
  };
  useEffect(() => {
    const calculateColumnWidths = () => {
      const widths: number[] = [];

      header.forEach((columnName, columnIndex) => {
        let maxWidth = getTextWidth(columnName, 14) + 34; // 기본 너비 설정, 여유 공간 추가
        orders.forEach((order) => {
          const cellValue =
            order[columnIndex] !== null ? order[columnIndex].toString() : '';
          const cellWidth = getTextWidth(cellValue, 14) + 34; // 기본 너비 설정, 여유 공간 추가
          maxWidth = Math.max(maxWidth, cellWidth);
        });

        widths.push(maxWidth);
      });

      return widths;
    };

    const widths = calculateColumnWidths();
    setColumnWidths(widths);
  }, [header, orders]);
  const hasScrolled = useRef(false);

  useEffect(() => {
    if (!hasScrolled.current && scrollRef.current && columnWidths.length > 0) {
      const ColumnOffset = columnWidths
        .slice(0, Math.min(addressColumn, postCodeColumn))
        .reduce((acc, width) => acc + width, 0);
      scrollRef.current.scrollLeft = ColumnOffset - 100;

      hasScrolled.current = true;
    }
    hasScrolled.current = false;
  }, [columnWidths, addressColumn, postCodeColumn]);
  function numberToExcelColumn(number: number) {
    let column = '';
    while (number > 0) {
      const remainder = (number - 1) % 26;
      column = String.fromCharCode(65 + remainder) + column;
      number = Math.floor((number - 1) / 26);
    }
    return column;
  }

  return (
    <div
      ref={scrollRef}
      className="max-w-full overflow-auto text-[14px] font-normal transition-all duration-1000 ease-in-out"
    >
      <table className="w-full table-fixed border-collapse">
        <thead>
          <tr className="bg-[#EBF1F5]">
            {header.map((columnName, index) => (
              <th
                key={columnName}
                className="border px-4 py-1 text-left font-semibold text-blue-500"
                style={{ width: columnWidths[index] }}
              >
                {numberToExcelColumn(index + 1)}
              </th>
            ))}
          </tr>
        </thead>
        <thead>
          <tr className="bg-[#EBF1F5]">
            {header.map((columnName, index) => (
              <th
                key={columnName}
                className="border px-4 py-1 text-left font-semibold"
                style={{ width: columnWidths[index] }}
              >
                {columnName}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {orders.map((order, rowIndex) => (
            <tr key={rowIndex} className="border-b border-gray-200">
              {order.map((cell: any, cellIndex: number) => (
                <td
                  key={cellIndex}
                  className={`border-r border-gray-200 px-4 py-1 text-left ${cellIndex === addressColumn || cellIndex === postCodeColumn ? 'bg-blue-200' : ''}`}
                  ref={(el) => (cellWidthRefs.current[cellIndex] = el)}
                  style={{
                    width: columnWidths[cellIndex],
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {cell}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SimpleTable;
