import { z } from 'zod';
import { UserSetting, ZUserSetting } from '../type/UserSetting';

export const UPDATE_USER_SETTING_URL = '/user/setting';

export const ZUpdateUserSettingDTO = ZUserSetting.omit({
  _id: true,
}).partial();

export type UpdateUserSettingDTO = z.infer<typeof ZUpdateUserSettingDTO>;

export type UpdateUserSettingResponse = UserSetting;
