import { Fragment } from 'react/jsx-runtime';
import { cva } from 'src/third-parties/tailwind';
import TableCell from './TableCell';
import TableHeaderCell from './TableHeaderCell';

export interface ColumnDef<T> {
  header: React.ReactNode;
  accessorFn?: (row: T) => string | null | undefined;
  cell?: (context: {
    row: T;
    lastRow: boolean;
    lastColumn: boolean;
  }) => React.ReactNode;
}

export interface TableProps<T> {
  items: T[];
  columns: ColumnDef<T>[];
  range?: [number, number];
  className?: string;
}

export function Table<T>({ items, columns, range, className }: TableProps<T>) {
  const headers = columns
    .map((column) => column.header)
    .map((header, index) =>
      typeof header === 'string' ? (
        <TableHeaderCell key={header} lastColumn={index === columns.length - 1}>
          {header}
        </TableHeaderCell>
      ) : (
        <Fragment key={index}>{header}</Fragment>
      )
    );

  const displayedItems = range ? items.slice(range[0], range[1]) : items;

  return (
    <div className={tableContainer({ className })}>
      <table className={table()}>
        <thead>
          <tr className={rowClass({ type: 'header' })}>{headers}</tr>
        </thead>
        <tbody>
          {displayedItems.map((row, rowIndex) => (
            <tr key={rowIndex} className={rowClass()}>
              {columns.map((column, index) => {
                const cell = column.cell?.({
                  row,
                  lastRow: rowIndex === displayedItems.length - 1,
                  lastColumn: index === columns.length - 1,
                });
                if (cell != null) {
                  return <Fragment key={index}>{cell}</Fragment>;
                }

                const value = column.accessorFn?.(row);
                return (
                  <TableCell
                    key={index}
                    lastRow={rowIndex === displayedItems.length - 1}
                    lastColumn={index === columns.length - 1}
                  >
                    {value}
                  </TableCell>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

const tableContainer = cva('relative border border-gray-200');

const table = cva('border-separate border-spacing-0');

const rowClass = cva('h-36px', {
  variants: {
    type: {
      header: 'sticky top-0 z-[1]',
      row: '',
    },
  },
});

Table.HeaderCell = TableHeaderCell;
Table.Cell = TableCell;
