import { useOMSStore } from 'hooks/useOMSStore';
import { IconControlTrashcan } from 'icons/index';
import { observer } from 'mobx-react-lite';
import { MatchedProduct } from 'models/CompositionMatching';
import { toast } from 'sonner';
import { openProductCreateFormModal } from 'src/forms/product/ProductCreateFormModal/openProductCreateFormModal';
import { openProductUpdateFormModal } from 'src/forms/product/ProductUpdateFormModal/openProductUpdateFormModal';
import SearchableSelect from './SearchableSelect';

interface CMProductInputProps {
  value: Partial<MatchedProduct>;
  onChange: (value: Partial<MatchedProduct> | null) => void;
  required?: boolean;
}

function CMProductInput({ value, onChange, required }: CMProductInputProps) {
  const oms = useOMSStore();
  const product =
    value.productId != null ? oms.product.getProduct(value.productId) : null;
  const productName = product?.productName;
  const productUnitnames = product?.units?.map((unit) => unit.unit) ?? [];

  const handleProductNameChange = (newProductName: string) => {
    const productId = oms.product.productIdByName.get(newProductName);
    const product =
      productId != null ? oms.product.getProduct(productId) : null;
    if (product == null) {
      onChange({ ...value, productId: undefined, unit: undefined });
      return;
    }

    const productUnits = product.units?.map((unit) => unit.unit) ?? [];
    const isNotValidUnit =
      productUnits.length === 0 ||
      (value.unit != null && !productUnits.includes(value.unit));

    if (isNotValidUnit) {
      onChange({ ...value, productId, unit: undefined });
      return;
    }

    onChange({ ...value, productId });
  };

  const handleUnitChange = (newUnit: string) => {
    if (newUnit == null) {
      onChange({ ...value, unit: undefined });
      return;
    }

    onChange({ ...value, unit: newUnit });
  };

  const onProductCreate = async (productName: string) => {
    const product = await openProductCreateFormModal(productName);
    if (product == null) {
      return;
    }

    const registeredProduct = await oms.product.create(product);
    if (registeredProduct == null) {
      return;
    }

    onChange({ ...value, productId: registeredProduct._id });
  };

  const onProductUnitCreate = async () => {
    if (product == null) {
      return;
    }

    const updatedProduct = await openProductUpdateFormModal(product);
    if (updatedProduct == null) {
      return;
    }

    const result = await oms.product.update(product._id, updatedProduct);

    if (result == null) {
      toast.error('상품 수정에 실패했습니다. 잠시 후 다시 시도해주세요.');
      return;
    }
  };

  return (
    <div className="flex flex-wrap gap-[10px]">
      <SearchableSelect
        name="상품"
        className="min-w-[200px] flex-1"
        value={productName}
        onChange={handleProductNameChange}
        onCreate={onProductCreate}
        options={oms.product.productNames}
        required={required}
      />

      {productUnitnames.length > 0 && (
        <SearchableSelect
          name="옵션"
          className="min-w-[100px] flex-1"
          value={value.unit}
          onChange={handleUnitChange}
          onCreate={onProductUnitCreate}
          options={productUnitnames}
          required={required}
        />
      )}

      <div className="flex h-10 w-[72px] rounded-lg border border-gray-300 py-2 pl-[16px] pr-1 shadow-sm focus-within:border-blue-400">
        <label>x</label>
        <input
          className={'w-full outline-none'}
          type="number"
          min={1}
          max={9999}
          value={value.quantity ?? 1}
          onChange={(event) => {
            const quantity = parseInt(event.target.value);
            onChange({ ...value, quantity });
          }}
          onBlur={(event) => {
            const quantity = parseInt(event.target.value);
            if (isNaN(quantity)) {
              onChange({ ...value, quantity: 1 });
            }
          }}
        />
      </div>
      <button
        className="flex size-10 items-center justify-center rounded-[5px] bg-[#ebf0f5]"
        onClick={() => onChange(null)}
      >
        <IconControlTrashcan className="text-gray-500" width={20} height={20} />
      </button>
    </div>
  );
}

export default observer(CMProductInput);
