const TEXT_COLORS = [
  'gray',
  'blue',
  'red',
  'orange',
  'green',
  'purple',
] as const;

const TEXT_COLORS_VARIANTS = [
  '900',
  '800',
  '700',
  '600',
  '500',
  '400',
  '300',
  '200',
  '100',
  '50',
] as const;

export const TEXT_COLOR_STYLES = TEXT_COLORS.flatMap((color) =>
  TEXT_COLORS_VARIANTS.map((variant) => `text-${color}-${variant}` as const)
);
