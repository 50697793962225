import { useCallback, useRef } from 'react';
import { usePreservedCallback } from './usePreservedCallback';

type Timeout = ReturnType<typeof setTimeout>;

export function useDebounce<T extends unknown[]>(
  fn: (...args: T) => void,
  delay: number
) {
  const preservedFn = usePreservedCallback(fn);
  const timer = useRef<Timeout | null>(null);

  const debouncedFn = useCallback(
    (...args: T) => {
      if (timer.current) {
        clearTimeout(timer.current);
      }

      timer.current = setTimeout(() => {
        preservedFn(...args);
      }, delay);
    },
    [preservedFn, delay]
  );

  return debouncedFn;
}
