export const translationRegions: [string, string][] = [
  ['Chungcheongbuk-do', '충청북도'],
  ['Chungcheongnam-do', '충청남도'],
  ['Gyeongsangbuk-do', '경상북도'],
  ['Gyeongsangnam-do', '경상남도'],
  ['Yeongdeungpo-gu', '영등포구'],
  ['Pyeongchang-gun', '평창군'],
  ['Jeungpyeong-gun', '증평군'],
  ['Changnyeong-gun', '창녕군'],
  ['Gwangmyeong-si', '광명시'],
  ['Dongducheon-si', '동두천시'],
  ['Yangpyeong-gun', '양평군'],
  ['Hoengseong-gun', '횡성군'],
  ['Cheongyang-gun', '청양군'],
  ['Yeonggwang-gun', '영광군'],
  ['Cheongsong-gun', '청송군'],
  ['Masanhoewon-gu', '마산회원구'],
  ['Dongdaemun-gu', '동대문구'],
  ['Pyeongtaek-si', '평택시'],
  ['Yeoncheon-gun', '연천군'],
  ['Jeongseon-gun', '정선군'],
  ['Hongcheon-gun', '홍천군'],
  ['Yeongdong-gun', '영동군'],
  ['Hongseong-gun', '홍성군'],
  ['Jangseong-gun', '장성군'],
  ['Jangheung-gun', '장흥군'],
  ['Hampyeong-gun', '함평군'],
  ['Yeongcheon-si', '영천시'],
  ['Yeongdeok-gun', '영덕군'],
  ['Yeongyang-gun', '영양군'],
  ['Sancheong-gun', '산청군'],
  ['Cheongwon-gun', '청원군'],
  ['Masanhappo-gu', '마산합포구'],
  ['Geumcheon-gu', '금천구'],
  ['Seodaemun-gu', '서대문구'],
  ['Seongdong-gu', '성동구'],
  ['Yangcheon-gu', '양천구'],
  ['Eunpyeong-gu', '은평구'],
  ['Geumjeong-gu', '금정구'],
  ['Dalseong-gun', '달성군'],
  ['Namyangju-si', '남양주시'],
  ['Uijeongbu-si', '의정부시'],
  ['Gapyeong-gun', '가평군'],
  ['Gangneung-si', '강릉시'],
  ['Chuncheon-si', '춘천시'],
  ['Yangyang-gun', '양양군'],
  ['Yeongwol-gun', '영월군'],
  ['Cheorwon-gun', '철원군'],
  ['Hwacheon-gun', '화천군'],
  ['Eumseong-gun', '음성군'],
  ['Jincheon-gun', '진천군'],
  ['Seocheon-gun', '서천군'],
  ['Sunchang-gun', '순창군'],
  ['Gwangyang-si', '광양시'],
  ['Gokseong-gun', '곡성군'],
  ['Gyeongsan-si', '경산시'],
  ['Mungyeong-si', '문경시'],
  ['Goryeong-gun', '고령군'],
  ['Cheongdo-gun', '청도군'],
  ['Tongyeong-si', '통영시'],
  ['Geochang-gun', '거창군'],
  ['Uiryeong-gun', '의령군'],
  ['Hapcheon-gun', '합천군'],
  ['Jeollabuk-do', '전라북도'],
  ['Jeollanam-do', '전라남도'],
  ['Hwaseong-gun', '화성군'],
  ['Heungdeok-gu', '흥덕구'],
  ['Cheongwon-gu', '청원구'],
  ['Ilsandong-gu', '일산동구'],
  ['Yeongtong-gu', '영통구'],
  ['Gangdong-gu', '강동구'],
  ['Gwangjin-gu', '광진구'],
  ['Seongbuk-gu', '성북구'],
  ['Jungnang-gu', '중랑구'],
  ['Busanjin-gu', '부산진구'],
  ['Haeundae-gu', '해운대구'],
  ['Ganghwa-gun', '강화군'],
  ['Bupyeong-gu', '부평구'],
  ['Gwangsan-gu', '광산구'],
  ['Gwacheon-si', '과천시'],
  ['Gwangmyeong', '광명시'],
  ['Dongducheon', '동두천시'],
  ['Seongnam-si', '성남시'],
  ['Hwaseong-si', '화성시'],
  ['Samcheok-si', '삼척시'],
  ['Goseong-gun', '고성군'],
  ['Pyeongchang', '평창군'],
  ['Cheongju-si', '청주시'],
  ['Danyang-gun', '단양군'],
  ['Okcheon-gun', '옥천군'],
  ['Jeungpyeong', '증평군'],
  ['Gyeryong-si', '계룡시'],
  ['Boryeong-si', '보령시'],
  ['Geumsan-gun', '금산군'],
  ['Jeongeup-si', '정읍시'],
  ['Gochang-gun', '고창군'],
  ['Suncheon-si', '순천시'],
  ['Gangjin-gun', '강진군'],
  ['Goheung-gun', '고흥군'],
  ['Damyang-gun', '담양군'],
  ['Boseong-gun', '보성군'],
  ['Yeongam-gun', '영암군'],
  ['Gyeongju-si', '경주시'],
  ['Gimcheon-si', '김천시'],
  ['Bonghwa-gun', '봉화군'],
  ['Seongju-gun', '성주군'],
  ['Yecheon-gun', '예천군'],
  ['Ulleung-gun', '울릉군'],
  ['Uiseong-gun', '의성군'],
  ['Chilgok-gun', '칠곡군'],
  ['Changwon-si', '창원시'],
  ['Changnyeong', '창녕군'],
  ['Hamyang-gun', '함양군'],
  ['Seogwipo-si', '서귀포시'],
  ['Gyeonggi-do', '경기도'],
  ['Deogyang-gu', '덕양구'],
  ['Gwonseon-gu', '권선구'],
  ['Gwangju-gun', '광주군'],
  ['Pocheon-gun', '포천군'],
  ['Sangdang-gu', '상당구'],
  ['Dangjin-gun', '당진군'],
  ['Namjeju-gun', '남제주군'],
  ['Bukjeju-gun', '북제주군'],
  ['Seongsan-gu', '성산구'],
  ['Ilsanseo-gu', '일산서구'],
  ['Michuhol-gu', '미추홀구'],
  ['Gangnam-gu', '강남구'],
  ['Gangbuk-gu', '강북구'],
  ['Gangseo-gu', '강서구'],
  ['Dongjak-gu', '동작구'],
  ['Yongsan-gu', '용산구'],
  ['Gijang-gun', '기장군'],
  ['Dongnae-gu', '동래구'],
  ['Suyeong-gu', '수영구'],
  ['Yeongdo-gu', '영도구'],
  ['Suseong-gu', '수성구'],
  ['Ongjin-gun', '옹진군'],
  ['Gyeyang-gu', '계양구'],
  ['Namdong-gu', '남동구'],
  ['Daedeok-gu', '대덕구'],
  ['Yuseong-gu', '유성구'],
  ['Gwangju-si', '광주시'],
  ['Bucheon-si', '부천시'],
  ['Siheung-si', '시흥시'],
  ['Anseong-si', '안성시'],
  ['Pyeongtaek', '평택시'],
  ['Pocheon-si', '포천시'],
  ['Yangpyeong', '양평군'],
  ['Donghae-si', '동해시'],
  ['Taebaek-si', '태백시'],
  ['Yanggu-gun', '양구군'],
  ['Hoengseong', '횡성군'],
  ['Jecheon-si', '제천시'],
  ['Chungju-si', '충주시'],
  ['Goesan-gun', '괴산군'],
  ['Dangjin-si', '당진시'],
  ['Cheonan-si', '천안시'],
  ['Cheongyang', '청양군'],
  ['Jangsu-gun', '장수군'],
  ['Yeonggwang', '영광군'],
  ['Haenam-gun', '해남군'],
  ['Hwasun-gun', '화순군'],
  ['Yeongju-si', '영주시'],
  ['Yeongcheon', '영천시'],
  ['Cheongsong', '청송군'],
  ['Miryang-si', '밀양시'],
  ['Sacheon-si', '사천시'],
  ['Yangsan-si', '양산시'],
  ['Namhae-gun', '남해군'],
  ['Hadong-gun', '하동군'],
  ['Gangwon-do', '강원도'],
  ['Bundang-gu', '분당구'],
  ['Sujeong-gu', '수정구'],
  ['Jungwon-gu', '중원구'],
  ['Yangju-gun', '양주군'],
  ['Yeongi-gun', '연기군'],
  ['Deokjin-gu', '덕진구'],
  ['Sangnok-gu', '상록구'],
  ['Uichang-gu', '의창구'],
  ['Dongnam-gu', '동남구'],
  ['Giheung-gu', '기흥구'],
  ['Gwanak-gu', '관악구'],
  ['Dobong-gu', '도봉구'],
  ['Seocho-gu', '서초구'],
  ['Songpa-gu', '송파구'],
  ['Jongno-gu', '종로구'],
  ['Sasang-gu', '사상구'],
  ['Yeonje-gu', '연제구'],
  ['Dalseo-gu', '달서구'],
  ['Yeonsu-gu', '연수구'],
  ['Goyang-si', '고양시'],
  ['Namyangju', '남양주시'],
  ['Anyang-si', '안양시'],
  ['Yangju-si', '양주시'],
  ['Yongin-si', '용인시'],
  ['Uiwang-si', '의왕시'],
  ['Uijeongbu', '의정부시'],
  ['Icheon-si', '이천시'],
  ['Yeoncheon', '연천군'],
  ['Gangneung', '강릉시'],
  ['Sokcho-si', '속초시'],
  ['Chuncheon', '춘천시'],
  ['Jeongseon', '정선군'],
  ['Hongcheon', '홍천군'],
  ['Boeun-gun', '보은군'],
  ['Yeongdong', '영동군'],
  ['Gongju-si', '공주시'],
  ['Nonsan-si', '논산시'],
  ['Seosan-si', '서산시'],
  ['Buyeo-gun', '부여군'],
  ['Yesan-gun', '예산군'],
  ['Taean-gun', '태안군'],
  ['Hongseong', '홍성군'],
  ['Gunsan-si', '군산시'],
  ['Namwon-si', '남원시'],
  ['Jeonju-si', '전주시'],
  ['Wanju-gun', '완주군'],
  ['Imsil-gun', '임실군'],
  ['Jinan-gun', '진안군'],
  ['Gwangyang', '광양시'],
  ['Gurye-gun', '구례군'],
  ['Sinan-gun', '신안군'],
  ['Wando-gun', '완도군'],
  ['Jangseong', '장성군'],
  ['Jangheung', '장흥군'],
  ['Jindo-gun', '진도군'],
  ['Hampyeong', '함평군'],
  ['Gyeongsan', '경산시'],
  ['Mungyeong', '문경시'],
  ['Sangju-si', '상주시'],
  ['Andong-si', '안동시'],
  ['Pohang-si', '포항시'],
  ['Gunwi-gun', '군위군'],
  ['Yeongdeok', '영덕군'],
  ['Yeongyang', '영양군'],
  ['Uljin-gun', '울진군'],
  ['Gimhae-si', '김해시'],
  ['Tongyeong', '통영시'],
  ['Sancheong', '산청군'],
  ['Haman-gun', '함안군'],
  ['Sejong-si', '세종특별자치시'],
  ['Ojeong-gu', '오정구'],
  ['Jangan-gu', '장안구'],
  ['Paldal-gu', '팔달구'],
  ['Dongan-gu', '동안구'],
  ['Yeoju-gun', '여주군'],
  ['Wansan-gu', '완산구'],
  ['Hoewon-gu', '회원구'],
  ['Jinhae-si', '진해시'],
  ['Danwon-gu', '단원구'],
  ['Jinhae-gu', '진해구'],
  ['Seowon-gu', '서원구'],
  ['Seobuk-gu', '서북구'],
  ['Cheoin-gu', '처인구'],
  ['Nowon-gu', '노원구'],
  ['Dalseong', '달성군'],
  ['Ulju-gun', '울주군'],
  ['Gwacheon', '과천시'],
  ['Gunpo-si', '군포시'],
  ['Gimpo-si', '김포시'],
  ['Seongnam', '성남시'],
  ['Suwon-si', '수원시'],
  ['Ansan-si', '안산시'],
  ['Yeoju-si', '여주시'],
  ['Hanam-si', '하남시'],
  ['Hwaseong', '화성시'],
  ['Gapyeong', '가평군'],
  ['Samcheok', '삼척시'],
  ['Wonju-si', '원주시'],
  ['Yangyang', '양양군'],
  ['Yeongwol', '영월군'],
  ['Inje-gun', '인제군'],
  ['Cheorwon', '철원군'],
  ['Hwacheon', '화천군'],
  ['Cheongju', '청주시'],
  ['Eumseong', '음성군'],
  ['Jincheon', '진천군'],
  ['Gyeryong', '계룡시'],
  ['Boryeong', '보령시'],
  ['Seocheon', '서천군'],
  ['Gimje-si', '김제시'],
  ['Iksan-si', '익산시'],
  ['Jeongeup', '정읍시'],
  ['Muju-gun', '무주군'],
  ['Buan-gun', '부안군'],
  ['Sunchang', '순창군'],
  ['Mokpo-si', '목포시'],
  ['Suncheon', '순천시'],
  ['Yeosu-si', '여수시'],
  ['Gokseong', '곡성군'],
  ['Muan-gun', '무안군'],
  ['Gyeongju', '경주시'],
  ['Gimcheon', '김천시'],
  ['Goryeong', '고령군'],
  ['Cheongdo', '청도군'],
  ['Geoje-si', '거제시'],
  ['Jinju-si', '진주시'],
  ['Changwon', '창원시'],
  ['Geochang', '거창군'],
  ['Uiryeong', '의령군'],
  ['Hapcheon', '합천군'],
  ['Seogwipo', '서귀포시'],
  ['Ilsan-gu', '일산구'],
  ['Wonmi-gu', '원미구'],
  ['Manan-gu', '만안구'],
  ['Masan-si', '마산시'],
  ['Happo-gu', '합포구'],
  ['Guro-gu', '구로구'],
  ['Mapo-gu', '마포구'],
  ['Jung-gu', '중구'],
  ['Dong-gu', '동구'],
  ['Saha-gu', '사하구'],
  ['Ganghwa', '강화군'],
  ['Gwangju', '광주시'],
  ['Guri-si', '구리시'],
  ['Bucheon', '부천시'],
  ['Siheung', '시흥시'],
  ['Anseong', '안성시'],
  ['Osan-si', '오산시'],
  ['Paju-si', '파주시'],
  ['Pocheon', '포천시'],
  ['Donghae', '동해시'],
  ['Taebaek', '태백시'],
  ['Goseong', '고성군'],
  ['Jecheon', '제천시'],
  ['Chungju', '충주시'],
  ['Danyang', '단양군'],
  ['Okcheon', '옥천군'],
  ['Dangjin', '당진시'],
  ['Asan-si', '아산시'],
  ['Cheonan', '천안시'],
  ['Geumsan', '금산군'],
  ['Gochang', '고창군'],
  ['Naju-si', '나주시'],
  ['Gangjin', '강진군'],
  ['Goheung', '고흥군'],
  ['Damyang', '담양군'],
  ['Boseong', '보성군'],
  ['Yeongam', '영암군'],
  ['Gumi-si', '구미시'],
  ['Yeongju', '영주시'],
  ['Bonghwa', '봉화군'],
  ['Seongju', '성주군'],
  ['Yecheon', '예천군'],
  ['Ulleung', '울릉군'],
  ['Uiseong', '의성군'],
  ['Chilgok', '칠곡군'],
  ['Miryang', '밀양시'],
  ['Sacheon', '사천시'],
  ['Yangsan', '양산시'],
  ['Hamyang', '함양군'],
  ['Jeju-si', '제주시'],
  ['Incheon', '인천광역시'],
  ['Daejeon', '대전광역시'],
  ['Jeju-do', '제주도'],
  ['Sosa-gu', '소사구'],
  ['Suji-gu', '수지구'],
  ['Gijang', '기장군'],
  ['Nam-gu', '남구'],
  ['Buk-gu', '북구'],
  ['Seo-gu', '서구'],
  ['Ongjin', '옹진군'],
  ['Goyang', '고양시'],
  ['Anyang', '안양시'],
  ['Yangju', '양주시'],
  ['Yongin', '용인시'],
  ['Uiwang', '의왕시'],
  ['Icheon', '이천시'],
  ['Sokcho', '속초시'],
  ['Yanggu', '양구군'],
  ['Goesan', '괴산군'],
  ['Gongju', '공주시'],
  ['Nonsan', '논산시'],
  ['Seosan', '서산시'],
  ['Gunsan', '군산시'],
  ['Namwon', '남원시'],
  ['Jeonju', '전주시'],
  ['Jangsu', '장수군'],
  ['Haenam', '해남군'],
  ['Hwasun', '화순군'],
  ['Sangju', '상주시'],
  ['Andong', '안동시'],
  ['Pohang', '포항시'],
  ['Gimhae', '김해시'],
  ['Namhae', '남해군'],
  ['Hadong', '하동군'],
  ['sejong', '세종특별자치시'],
  ['Sejong', '세종특별자치시'],
  ['Gunpo', '군포시'],
  ['Gimpo', '김포시'],
  ['Suwon', '수원시'],
  ['Ansan', '안산시'],
  ['Yeoju', '여주시'],
  ['Hanam', '하남시'],
  ['Wonju', '원주시'],
  ['Boeun', '보은군'],
  ['Buyeo', '부여군'],
  ['Yesan', '예산군'],
  ['Taean', '태안군'],
  ['Gimje', '김제시'],
  ['Iksan', '익산시'],
  ['Wanju', '완주군'],
  ['Imsil', '임실군'],
  ['Jinan', '진안군'],
  ['Mokpo', '목포시'],
  ['Yeosu', '여수시'],
  ['Gurye', '구례군'],
  ['Sinan', '신안군'],
  ['Wando', '완도군'],
  ['Jindo', '진도군'],
  ['Gunwi', '군위군'],
  ['Uljin', '울진군'],
  ['Geoje', '거제시'],
  ['Jinju', '진주시'],
  ['Haman', '함안군'],
  ['Seoul', '서울특별시'],
  ['Busan', '부산광역시'],
  ['Daegu', '대구광역시'],
  ['Ulsan', '울산광역시'],
  ['Ulju', '울주군'],
  ['Guri', '구리시'],
  ['Osan', '오산시'],
  ['Paju', '파주시'],
  ['Inje', '인제군'],
  ['Asan', '아산시'],
  ['Muju', '무주군'],
  ['Buan', '부안군'],
  ['Naju', '나주시'],
  ['Muan', '무안군'],
  ['Gumi', '구미시'],
  ['Jeju', '제주시'],
  ['Sejong', '세종시'],
];
