import { NormalizedOrder, Order } from '@sweep/contract';
import { Workbook } from 'exceljs';
import copy from 'fast-copy';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import readXlsxFile from 'read-excel-file';
import { StoreOriginValuePlugin } from 'stores/plugin/plugins/store-origin-value';
import * as XLSX from 'xlsx';
import { AddSupplierPlugin } from 'src/stores/plugin/plugins/add-supplier';
import { OrderXX, RawOrderXX } from '../models/OrderXX';
import LoadingStore from '../stores/LoadingStore';
import backendApi from '../utils/backendApi';
import { convertArrayKeysToValues } from '../utils/headerColumnMapping';
import { headerTranslationMap } from '../utils/mappingArrays';
import { createUniqeCode, isValid } from '../utils/utils';
import useApplyCustomSettings from './fileHandling/useApplyCustomSettings';
import useInterpretOrder from './fileHandling/useInterpretOrder';
import useCreateExcel, { SPECIFIC_COLUMN_WIDTHS } from './useCreateExcel';
import useMallOrderManagement from './useMallOrderManagement';
import { useOMSStore } from './useOMSStore';

const EXCEL_MIME_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

const EXCEL_XLS_MIME_TYPE = 'application/vnd.ms-excel';

const useFileHandling = () => {
  const oms = useOMSStore();

  const { addWorkSheetByOrders } = useCreateExcel();

  const {
    addPlatformName,
    applyExcelFuntion,
    applyJSLogic,
    addSupplierName,
    applyPrefixToOption,
    applyPlatformNameToOption,
    convertSpecificCompositionsExpression,
    addDefaultShippingCompany,
    applyFieldValueToAnother,
    applyCustomMultiplier,
    formatOrders,
    addFixedColumnValue,
    applyMergedCellValues,
    addConditionalColumnValues,
    sortOrders,
  } = useApplyCustomSettings();

  const {
    combineOrder3,
    interpretOrder,
    separateOrders,
    hightlightCombinedOrders,
  } = useInterpretOrder();

  const { createOrderExcel, createOrderExcelBuffer } = useCreateExcel();

  const { autoUpdateShippingInfoToMall } = useMallOrderManagement();

  const cancelTableKoreanHeader = [
    '주문상태',
    '쇼핑몰',
    '스윕고유번호',
    '주문번호',
    '상품명',
    '옵션',
    '수량',
    '주문금액',
    '배송비',
    '수취인',
    '휴대폰번호',
    '송장번호',
    '우편번호',
    '주소',
    '배송메시지',
  ];

  const cancelTableColumnMapping = {
    orderStatus: '주문상태',
    shoppingMall: '쇼핑몰',
    uniqueCode: '스윕고유번호',
    orderNumber: '주문번호',
    productName: '상품명',
    option: '옵션',
    quantity: '수량',
    price: '주문금액',
    name: '수취인',
    shippingNumber: '송장번호',
    배송비: '배송비',
    contactNumber: '휴대폰번호',
    postCode: '우편번호',
    address: '주소',
    deliveryMessage: '배송메시지',
  };

  const removeFile = (fileName: string) => {
    oms.order.removeOrderByFilename(fileName);
    backendApi.saveOrderData(
      oms.order.mergedOrders,
      oms.order.normalizedOrders
    );
  };

  const addProductNumber = (orders: OrderXX[]) => {
    const productInfo = oms.product.products || [];
    const supplierInfo = oms.supplier.suppliers || [];
    return orders.map((order) => {
      if (isValid(order.productNumber)) {
        return order;
      }
      for (let i = 0; i < order.data.length; i++) {
        const product = productInfo.find(
          (product) => product._id === order.data[i].productId
        );
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        if (product?.supplierName) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          order.productNumber = product.supplierName;
        } else if (product?.supplierId) {
          order.productNumber = supplierInfo.find(
            (supplier) => supplier._id === product.supplierId
          )?.name;
        } else {
          continue;
        }
        return order;
      }
      return order;
    });
  };

  const processCustomizingOrders = async (orders: RawOrderXX[]) => {
    if (!isValid(orders)) {
      return [];
    }
    const rawNewOrders = [...orders];

    const customizedOrders: RawOrderXX[] = [];
    const notCustomizedOrders: RawOrderXX[] = [];
    rawNewOrders.forEach((order) => {
      if (isValid(order.uniqueCodeAfterCustomization)) {
        customizedOrders.push(order);
      } else {
        notCustomizedOrders.push(order);
      }
    });

    // TODO(@이지원): 커스텀 로직 모두 대체되면, normalizeOrders로 통일
    const storeOriginValuePlugin = new StoreOriginValuePlugin(oms, undefined);
    let newOrders = (await storeOriginValuePlugin.transform(
      notCustomizedOrders as unknown as Order[]
    )) as unknown as OrderXX[];

    // Tip: UserStore?.customExcelSettings 관련된 거의 모든 것들은 커스텀 로직과 관련되어있음 - 전체 흐름 읽을 때는 무시해도 됨
    const combinationCustomSetting = oms.user.setting?.combinationCustomSetting;
    const isCombine = combinationCustomSetting?.enabled;

    // 1. 해석(CM) - interpretOrders (expected output: data 필드)
    // 2. 합배송 및 분리 - separateOrders, combineOrder3: 해석이 기반되어야 함. 어떤 상품(productId에 어떤 Unit이 들어있는지 알아야, 그걸 기준에 따라서 처리할 수 있음)
    // 3. 플러그인(including formatOrders) + 기타 커스텀 - 없애고, 더하고, 변환하고, 정렬하기 등
    // 관련 질문 - 뭘? 없애야하는지?

    newOrders = addConditionalColumnValues(newOrders); // 엑셀함수

    newOrders = (await interpretOrder(newOrders)) as unknown as OrderXX[]; // CM 등

    if (localStorage.getItem('@userId') === '65b0e228a56125abfebd8b51') {
      newOrders = addProductNumber(newOrders); // 엑셀함수
    }

    if (
      isCombine &&
      !combinationCustomSetting?.separateCombineProcess?.enabled
    ) {
      newOrders = separateOrders(newOrders);
      newOrders = copy(newOrders); // deep copy cus aliasing of interpretedProductCache
      if (!combinationCustomSetting?.justSeparate) {
        newOrders = combineOrder3(newOrders);
      }
    }

    // 여기서부터는 다 커스텀 로직
    newOrders = addDefaultShippingCompany(newOrders) as OrderXX[];
    newOrders = hightlightCombinedOrders(newOrders);
    newOrders = addPlatformName(newOrders);
    newOrders = addSupplierName(newOrders);
    newOrders = await processFormatOrders(newOrders);
    newOrders = addFixedColumnValue(newOrders);
    newOrders = addConditionalColumnValues(newOrders);
    newOrders = applyMergedCellValues(newOrders);
    newOrders = applyFieldValueToAnother(newOrders);
    newOrders = applyCustomFormats(newOrders);
    newOrders = applyExcelFuntion(newOrders);
    newOrders = applyJSLogic(newOrders);

    newOrders = sortOrders(newOrders);

    newOrders = (await oms.order._normalize.normalizeOrders(
      newOrders as unknown as Order[]
    )) as unknown as OrderXX[];

    if (
      oms.user.setting?.interpreteOrderSettings?.useOptionCodeAsRawProductName
    ) {
      newOrders = newOrders.map((order) => {
        if (!order?.needToInterpret) {
          order.option = order.optionCode || order.option;
        }
        return order;
      });
    }

    return newOrders.concat(customizedOrders as OrderXX[]);
  };

  const processFormatOrders = async (newOrders: OrderXX[]) => {
    // TODO(@이지원): 모든 normalize-custom-logic이 통일되면 default plugin으로 추가
    const addSupplierPlugin = new AddSupplierPlugin(oms, undefined);
    newOrders = (await addSupplierPlugin.transform(
      newOrders as Order[]
    )) as OrderXX[];

    const formatOrdersSettings = oms.user.setting?.formatOrdersSettings;
    if (formatOrdersSettings?.enabled) {
      newOrders = formatOrders(newOrders, formatOrdersSettings);
    } else {
      newOrders = formatOrders(newOrders);
    }

    (oms.supplier.suppliers || []).forEach((supplier) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      if (supplier?.formatOrdersSettings?.enabled) {
        newOrders = newOrders.map((order) => {
          if (
            (supplier?.productIds || []).some((id) =>
              order.data.some((d) => d.productId === id)
            )
          ) {
            return formatOrders([order], {
              ...formatOrdersSettings,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              ...supplier.formatOrdersSettings,
            })[0];
          }
          return order;
        });
      }
    });

    // 특정 상품군의 ${특정 단위x수량} 구성 표현을 바꾸기
    newOrders = convertSpecificCompositionsExpression(newOrders);
    return newOrders;
  };

  const applyCustomFormats = (orders: OrderXX[]) => {
    let newOrders = orders;
    newOrders = applyPlatformNameToOption(newOrders);
    newOrders = applyPrefixToOption(newOrders);
    newOrders = applyCustomMultiplier(newOrders);
    return newOrders;
  };

  const customizeOrders = async (orders: OrderXX[]) => {
    if (!isValid(orders)) {
      return;
    }

    const newOrders = await processCustomizingOrders(copy(orders));

    // 전역변수에 업데이트
    let updatedCustomizedOrders = [
      ...oms.order.normalizedOrders,
      ...newOrders,
    ] as unknown as OrderXX[];

    updatedCustomizedOrders = updatedCustomizedOrders.map((order) => {
      if (!order.uniqueCodeAfterCustomization) {
        order.uniqueCodeAfterCustomization = createUniqeCode();
      }
      return order;
    });

    updatedCustomizedOrders = sortOrders(updatedCustomizedOrders);

    oms.order.setNormalizedOrders(
      updatedCustomizedOrders as unknown as NormalizedOrder[]
    );

    // BE에 반영
    backendApi.updateCustomizedOrders(updatedCustomizedOrders);

    return updatedCustomizedOrders;
  };

  const createExcelForFailedRows = async (failedRows: any[]) => {
    // 현재 날짜를 YYYY.MM.DD 형식으로 가져오기
    const date = new Date();
    const year = String(date.getFullYear()).slice(-2);
    const formattedDate = `${year}.${String(date.getMonth() + 1).padStart(
      2,
      '0'
    )}.${String(date.getDate()).padStart(2, '0')}`;

    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(
      `${formattedDate} 운송장등록실패주문`
    );

    const koreanHeaderNames = [...oms.user.excelHeaderKeys, '실패사유'].map(
      (col) => oms.user.excelColumnMapping[col] || col
    );

    const headerRow = worksheet.addRow(koreanHeaderNames);

    headerRow.eachCell((cell) => {
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
      cell.font = { bold: true };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D9D9D9' },
      };
    });

    worksheet.views = [{ state: 'frozen', ySplit: 1 }];

    worksheet.autoFilter = {
      from: 'A1',
      to: `${String.fromCharCode(64 + oms.user.excelHeaderKeys.length)}1`,
    };

    worksheet.columns = oms.user.excelHeaderKeys.map((col, index) => {
      const columnObject = {
        key: col,
        width: SPECIFIC_COLUMN_WIDTHS[col] || 20,
        hidden: false,
      };

      const hiddenColumns = ['uniqueCode'];

      const cutOffIndex = 5;

      if (index >= cutOffIndex && hiddenColumns.includes(col)) {
        columnObject.hidden = true;
      }

      return columnObject;
    });

    failedRows.forEach((row) => {
      const rowArray = Object.values(row);
      const newRow = worksheet.addRow(rowArray);

      const textColumns = [
        '주문번호',
        '상품코드',
        '연락처',
        '우편번호',
        '옵션',
        '개수',
        '운송장번호',
      ];
      textColumns.forEach((textCol) => {
        const colNum =
          oms.user.excelHeaderKeys.indexOf(
            oms.user.excelColumnMapping[textCol]
          ) + 1;
        if (colNum > 0) {
          newRow.getCell(colNum).numFmt = '@'; // '@' indicates text format
        }
      });
    });

    const buffer = await workbook.xlsx.writeBuffer();

    const blob = new Blob([buffer], {
      type: EXCEL_MIME_TYPE,
    });

    saveAs(blob, `${formattedDate}_운송장등록실패주문.xlsx`);
  };

  const createExcel = async (orders: Order[], fileName: string) => {
    if (oms.user.setting?.extType === 'xls') {
      await createXlsFile(orders, fileName);
    } else {
      await createXlsxFile(orders, fileName);
    }
  };

  async function fixCommentStyle(buffer: ArrayBuffer) {
    const zip = await JSZip.loadAsync(buffer);
    for (let i = 1; i <= 2; i++) {
      const vmlDrawing2FileName = `xl/drawings/vmlDrawing${i}.vml`;
      const vmlDrawing2 = zip.file(vmlDrawing2FileName);
      if (vmlDrawing2) {
        const xml = await vmlDrawing2.async('string');
        const placeholder =
          '<v:textbox style="mso-direction-alt:auto;mso-fit-shape-to-text:t"';
        const newXml = xml.replace(
          /<v:textbox style="mso-direction-alt:auto"/g,
          placeholder
        );
        const newXml2 = newXml.replace(
          '<xml xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel"><o:shapelayout v:ext="edit"><o:idmap v:ext="edit" data="1"/></o:shapelayout><v:shapetype id="_x0000_t202" coordsize="21600,21600" o:spt="202" path="m,l,21600r21600,l21600,xe"><v:stroke joinstyle="miter"/><v:path gradientshapeok="t" o:connecttype="rect"/></v:shapetype><v:shape id="_x0000_s1025" type="#_x0000_t202" style="position:absolute; margin-left:105.3pt;margin-top:10.5pt;width:97.8pt;height:59.1pt;z-index:1;visibility:hidden" fillcolor="infoBackground [80]" strokecolor="none [81]" o:insetmode="auto"><v:fill color2="infoBackground [80]"/><v:shadow color="none [81]" obscured="t"/><v:path o:connecttype="none"/><v:textbox style="mso-direction-alt:auto;mso-fit-shape-to-text:t" inset="1.3mm,1.3mm,2.5mm,2.5mm"><div style="text-align:left"/></v:textbox><x:ClientData ObjectType="Note"><x:MoveWithCells/><x:SizeWithCells/><x:Anchor>4, 6, 0, 14, 6, 2, 4, 16</x:Anchor><x:Locked>True</x:Locked><x:AutoFill>False</x:AutoFill><x:LockText>True</x:LockText><x:Row>1</x:Row><x:Column>3</x:Column></x:ClientData></v:shape></xml>',
          '<xml xmlns:ns0="urn:schemas-microsoft-com:office:office" xmlns:ns1="urn:schemas-microsoft-com:vml" xmlns:ns2="urn:schemas-microsoft-com:office:excel"><ns0:shapelayout ns1:ext="edit"><ns0:idmap ns1:ext="edit" data="1" /></ns0:shapelayout><ns1:shapetype id="_x0000_t202" coordsize="21600,21600" ns0:spt="202" path="m,l,21600r21600,l21600,xe"><ns1:stroke joinstyle="miter" /><ns1:path gradientshapeok="t" ns0:connecttype="rect" /></ns1:shapetype><ns1:shape type="#_x0000_t202" style="position:absolute; margin-left:59.25pt;margin-top:1.5pt;width:400px;height:400px;z-index:1;visibility:hidden" fillcolor="#ffffe1" ns0:insetmode="auto" id="_x0000_s1026"><ns1:fill color2="#ffffe1" /><ns1:shadow color="black" obscured="t" /><ns1:path ns0:connecttype="none" /><ns1:textbox style="mso-direction-alt:auto"><div style="text-align:left" /></ns1:textbox><ns2:ClientData ObjectType="Note"><ns2:MoveWithCells /><ns2:SizeWithCells /><ns2:AutoFill>False</ns2:AutoFill><ns2:Row>1</ns2:Row><ns2:Column>3</ns2:Column></ns2:ClientData></ns1:shape></xml>'
        );
        // Write back to the file
        zip.file(vmlDrawing2FileName, newXml2);
        return await zip.generateAsync({ type: 'arraybuffer' });
      }
    }
    return buffer;
    // Get content as string
  }
  const createXlsxFile = async (orders: Order[], fileName: string) => {
    const workbook = addWorkSheetByOrders(new Workbook(), orders, fileName, {
      englishHeaderNames: oms.user.excelHeaderKeys,
      columnMapping: oms.user.excelColumnMapping,
    });
    let buffer = await workbook.xlsx.writeBuffer();
    buffer = await fixCommentStyle(buffer);

    const blob = new Blob([buffer], {
      type: EXCEL_MIME_TYPE,
    });

    saveAs(blob, `${fileName}.xlsx`);
  };

  const createXlsFile = async (orders: Order[], fileName: string) => {
    const workbook = XLSX.utils.book_new();

    const data = orders.map((row) =>
      oms.user.excelHeaderKeys.map((col) => {
        let value = row[col];

        if (col === 'name' && row[col] != null && row[col]?.length === 1) {
          value += '님';
        }

        if (col === 'contactNumber' && !isValid(row[col])) {
          value = row['telephoneNumber'];
        }

        return value;
      })
    );

    const koreanHeaderNames = oms.user.excelHeaderKeys.map(
      (col) => oms.user.excelColumnMapping[col] || col
    );

    data.unshift(koreanHeaderNames);

    const worksheet = XLSX.utils.aoa_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheet, `${fileName}`);

    XLSX.writeFile(workbook, `${fileName}.xls`);
  };

  const exportToExcel = async () => {
    try {
      // 현재 날짜를 YY.MM.DD 형식으로 가져오기
      const date = new Date();
      const year = String(date.getFullYear()).slice(-2);
      const formattedDate = `${year}.${String(date.getMonth() + 1).padStart(
        2,
        '0'
      )}.${String(date.getDate()).padStart(2, '0')}`;
      const fileName = `${formattedDate} 발주통합`;
      await createExcel(oms.order.normalizedOrders, fileName);
    } catch (err) {
      console.error('Error extracting excel:', err);
    }
  };

  const splitOrders = async () => {
    try {
      LoadingStore?.setIsLoading(true);

      const uniqueCodeSet = new Set();
      oms.order.normalizedOrders.forEach((order) => {
        order.uniqueCode.split(',').forEach((uniqueCode: string) => {
          uniqueCodeSet.add(uniqueCode);
        });
      });
      // 1) be에서 splitorder를 한 다음, 스윕을 통해 주문수집한 주문들을 return
      const dispatchedFromSweep = await backendApi.splitOrders(
        Array.from(uniqueCodeSet)
      );

      // 스윕에서 주문수집한 주문건들이 있으면, 운송장 업로드도 스윕에서 자동 처리해줄지 물어보고 운송장 등록
      if (isValid(dispatchedFromSweep)) {
        if (
          window.confirm(
            '스윕에서 수집된 주문이 있습니다. 자동으로 송장을 업로드하시겠습니까?'
          )
        ) {
          await autoUpdateShippingInfo(dispatchedFromSweep);
        }
      }

      LoadingStore?.setIsLoading(false);
    } catch (error) {
      console.error('Error splitting files:', error);
    }
  };

  interface orderUpdateResponse {
    success: boolean;
    [key: string]: any;
  }
  const autoUpdateShippingInfo = async (shippingInfos: RawOrderXX[]) => {
    const { failedOrders } = (await autoUpdateShippingInfoToMall(
      shippingInfos
    )) as {
      successOrders: orderUpdateResponse[];
      failedOrders: orderUpdateResponse[];
    };

    // create failedOrders Excel
    if (isValid(failedOrders)) {
      const date = new Date();
      const year = String(date.getFullYear()).slice(-2);
      const formattedDate = `${year}.${String(date.getMonth() + 1).padStart(
        2,
        '0'
      )}.${String(date.getDate()).padStart(2, '0')}`;

      await createOrderExcel(
        failedOrders as unknown as Order[],
        `${formattedDate} 운송장 등록 실패 건`,
        ['failReason', ...cancelTableKoreanHeader],
        { failReason: '실패 사유', ...cancelTableColumnMapping },
        { returnNullIfNotInColumnMapping: false }
      );
    }
  };

  const formatDate = (format: string = '{YY}.{MM}.{DD}') => {
    const date = new Date();
    const year = String(date.getFullYear()).slice(-2);
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return format
      .replace('{YY}', year)
      .replace('{MM}', month)
      .replace('{DD}', day);
  };

  const separateOrdersIntoOrderFiles = async () => {
    try {
      LoadingStore?.setIsLoading(true);
      let newOrders = [...oms.order.normalizedOrders] as unknown as OrderXX[];

      // 여기서 유저란 고객사를 뜻함
      const userName = oms.user.name;

      // ordersSupplierDict = { supplierId: [orders], ... }
      // 나중에 Map으로 바꿔도 될듯
      const ordersSupplierDict: {
        [key: string]: OrderXX[];
      } = {};

      // ordersSupplierDict에 supplierId 별 orders Array를 만듬
      newOrders.forEach((order) => {
        // CM하면서 data에 상품 붙으면서, 해당 상품의 supplierId가 붙는 것으로 추정
        let supplierId;

        if (isValid(order?.supplierId)) {
          supplierId = order?.supplierId;
        }

        if (isValid(order?.data?.[0]?.supplierId)) {
          supplierId = order?.data[0]?.supplierId;
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        if (!ordersSupplierDict[supplierId]) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          ordersSupplierDict[supplierId] = [];
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        ordersSupplierDict[supplierId].push(order);
      });

      for await (const [supplierId, orders] of Object.entries(
        ordersSupplierDict
      )) {
        const transformedOrders = await oms.order._divide.transform(
          orders as unknown as Order[],
          supplierId
        );
        ordersSupplierDict[supplierId] =
          transformedOrders as unknown as OrderXX[];
      }

      const bufferList = [];
      const settingList: any[] = []; // 엑셀 자체에 수정을 가하는 로직이고, 곧 없어질 예정

      // 나중에 파일명 만들기 위한 날짜 정보
      // formattedDate의 가장 적절한 위치는 어디일까?
      const formattedDate = formatDate();
      for (const supplier of oms.supplier.suppliers) {
        const supplierOrders = ordersSupplierDict[supplier._id] || [];
        const supplierName = supplier.name || '';

        let fileName = `${formattedDate} ${supplierName} 발주서`;
        // 공급사의 파일명 형식에 맞게 파일명 생성
        const customFileNameFormat =
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          supplier?.customSettings?.customFileFormat || {};
        if (
          customFileNameFormat &&
          Object.keys(customFileNameFormat).length > 0
        ) {
          const customformattedDate = formatDate(
            customFileNameFormat?.dateFormat
          );

          fileName =
            customFileNameFormat?.fileNameFormat ||
            '{formattedDate} {supplierName} 발주서';
          fileName = fileName.replace('{formattedDate}', customformattedDate);
          fileName = fileName.replace('{supplierName}', supplierName);
          fileName = fileName.replace('{userName}', userName);
        }

        const headers = [...(supplier.header || [])];
        const columnMapping = {
          ...(supplier.columnMapping || {}),
        };
        const customizedSettings = { ...(supplier.customizedSettings || {}) };
        const buffer = createOrderExcelBuffer(
          supplierOrders as Order[],
          fileName,
          headers,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          columnMapping,
          customizedSettings
        );
        // 발주서 하나 당 buffer 1, setting 1이 만들어지는데, 이를 다수로 관리하기 위해 List에 추가
        bufferList.push(buffer);
        settingList.push({
          fileName,
          customizedSettings,
        });

        newOrders = newOrders.filter(
          (order) => !supplierOrders.includes(order)
        );
      }

      // supplier에 따라 분류되지 않은 주문들은 '발주 분리 실패주문'이라는 파일을 만들어서, 통합 엑셀 양식에 따라 저장
      // newOrders를 unSeparateOrders라는 이름으로 변경해야할듯
      if (newOrders.length > 0) {
        const failedOrdersFileName = `${formattedDate} 발주 분리 실패주문`;

        const buffer = createOrderExcelBuffer(
          newOrders as unknown as Order[],
          failedOrdersFileName,
          convertArrayKeysToValues(
            oms.user.excelHeaderKeys,
            oms.user.excelColumnMapping
          ),
          oms.user.excelColumnMapping
        );

        bufferList.push(buffer);
        settingList.push({
          fileName: failedOrdersFileName,
        });
      }

      // 엑셀 파일들이 들어있는 zip파일을 만드는 과정
      // 함수로 분리하면 깔끔할듯
      const zip = new JSZip();
      const bufferPromise = Promise.all(bufferList).then((buffers) => {
        buffers.forEach((buffer, index) => {
          if (buffer === undefined) {
            return;
          }
          const setting = settingList[index];
          if (setting.customizedSettings?.usesXlsFile) {
            const blob = new Blob([buffer], { type: EXCEL_XLS_MIME_TYPE });
            zip.file(`${setting.fileName}.xls`, blob);
          } else {
            zip.file(`${setting.fileName}.xlsx`, buffer);
          }
        });

        // zip 파일 다운로드
        zip.generateAsync({ type: 'blob' }).then((content) => {
          saveAs(content, `${formattedDate} 발주서.zip`);
        });
      });

      await bufferPromise;

      if (newOrders.length > 0) {
        alert('분류되지 않은 주문이 있습니다. 해당 상품들을 분류해주세요.');
      }

      LoadingStore?.setIsLoading(false);
    } catch (error) {
      console.error('Error splitting files:', error);
    }
  };

  const uploadShippingInfo = async (file: any) => {
    try {
      // 추가해야 하는 로직 - Header에 additonalShippingFee가 있으면 해당 정보까지 같이 업데이트치는 로직
      LoadingStore?.setIsLoading(true);
      await readXlsxFile(file).then(async (rows) => {
        const headers = rows[0];
        const shippingHeaders = [
          'shippingCompany',
          'shippingNumber',
          'uniqueCode',
        ];

        // 고객사 엑셀 양식에 shippingCompany 관련 열이 없는 경우 해당 내용 없이도 업로드 가능하도록 하는 로직
        if (isValid(oms.user.setting?.omitShippingCompany?.enabled)) {
          // 'shippingCompany' 요소의 인덱스 찾기
          const shippingCompanyIndex =
            shippingHeaders.indexOf('shippingCompany');

          // 'shippingCompany'가 배열에 존재하는 경우에 제거
          if (shippingCompanyIndex > -1) {
            shippingHeaders.splice(shippingCompanyIndex, 1);
          }
        }

        const customHeaderTranslationMap: any =
          oms.user.setting?.columnTranslation || headerTranslationMap;

        const englishHeaders = headers.map((header) => {
          const matchingKey = Object.keys(customHeaderTranslationMap).find(
            (key) => customHeaderTranslationMap[key] === header
          );
          return matchingKey || header;
        });

        const hasShippingHeaders = shippingHeaders.every((header) =>
          englishHeaders.includes(header)
        );

        if (!hasShippingHeaders) {
          alert(
            `업로드하시는 엑셀의 제목행에 택배사, 운송장번호, 스윕고유번호가 있는지 확인해 주세요.\n스윕고유번호가 없는 경우, 통합 엑셀을 다시 발주 양식으로 분리할 수 없어요.\n스윕고유번호를 엑셀 양식에 등록하고 싶으신 경우 관리자에게 문의해 주세요.`
          );

          return false;
        }

        const shippingInfoArray = [];
        const failedRows = [];

        const shippingInfoHeaders = shippingHeaders;

        if (englishHeaders.includes('additionalShippingFee')) {
          shippingInfoHeaders.push('additionalShippingFee');
        }

        for (let i = 1; i < rows.length; i++) {
          let row: {
            [key: string]: any;
          } = rows[i];
          const shippingObj: {
            [key: string]: any;
          } = {};

          let isRowValid = true;

          shippingInfoHeaders.forEach((header) => {
            const index = englishHeaders.indexOf(header);

            if (
              ((header === 'shippingNumber' || header === 'uniqueCode') &&
                row[index] == null) ||
              row[index] === ''
            ) {
              isRowValid = false;
              console.warn(`Row ${i + 1} has missing values for ${header}.`);
              row = {
                ...row,
                failReason: `${customHeaderTranslationMap[header]}의 내용이 없어요.`,
              };
            } else {
              shippingObj[header] = row[index];
            }
          });

          if (isRowValid) {
            shippingInfoArray.push(shippingObj);
          }
          if (!isRowValid) {
            failedRows.push(row);
          }
        }

        if (shippingInfoArray.length > 0) {
          const result = await backendApi.uploadShippingInfo(shippingInfoArray);

          const uploadFailedUniqueCodes = result.failedRows.map(
            (row: any) => row.uniqueCode
          );

          if (isValid(uploadFailedUniqueCodes)) {
            // 실패한 행만 필터링

            const uniqueCodeIndex = englishHeaders.indexOf('uniqueCode');
            let uploadFailedRows = rows.filter((row) =>
              uploadFailedUniqueCodes.includes(row[uniqueCodeIndex])
            );

            // 실패 이유를 추가
            uploadFailedRows = uploadFailedRows.map((row) => {
              const maxKey = Math.max(...Object.keys(row).map(Number));
              return {
                ...row,
                [maxKey + 1]: '발주 통합 시 수합된 주문이 아니에요.',
              };
            });

            failedRows.push(...uploadFailedRows);
          }

          if (failedRows.length > 0) {
            // 실패한 열이 있다면 엑셀 파일 생성 로직
            alert(
              `운송장 등록에 실패한 주문건이 있습니다. 자세한 내용은 생성된 엑셀 파일 및 파일의 '실패사유' 열을 확인해주세요.`
            );
            createExcelForFailedRows(failedRows);
          }
        } else {
          alert('올바른 데이터가 없습니다.');
        }
      });
      LoadingStore?.setIsLoading(false);
      return true;
    } catch (err) {
      console.error('Error -ing ... :', err);
      LoadingStore?.setIsLoading(false);
      throw err;
    }
  };

  return {
    exportToExcel,
    separateOrdersIntoOrderFiles,
    uploadShippingInfo,
    splitOrders,
    customizeOrders,
    removeFile,
    processCustomizingOrders,
    createExcel,
  };
};

export default useFileHandling;
