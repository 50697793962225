import { EvaluateResult, Token } from '../interface';
import { isOperand, isOperator } from '../operator';
import { evaluateOperator } from './evaluateOperator';

export function evaluatePostfix(postfix: Token[]): EvaluateResult {
  let stack: Token[] = [];

  for (const token of postfix) {
    if (isOperand(token)) {
      stack.push(token);
      continue;
    }

    if (!isOperator(token)) {
      return {
        success: false,
        message: 'Invalid expression',
      };
    }

    const result = evaluateOperator(token, stack);
    if (result.success === false) {
      return result;
    }

    stack = result.stack;
  }

  const result = stack.pop();

  if (result == null) {
    return {
      success: false,
      message: 'Invalid expression',
    };
  }

  return {
    success: true,
    result,
  };
}
