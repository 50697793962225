import { Label } from 'design-system/components/Label';
import { observer } from 'mobx-react-lite';
import { CMOrder } from '../../interface';

interface CMOrderLabelProps {
  cmOrder: CMOrder;
}

function CMOrderLabel({ cmOrder }: CMOrderLabelProps) {
  return (
    <div className="flex w-[320px] shrink-0 flex-col gap-[8px] px-[20px]">
      <p className="text-medium-m break-keep text-gray-600">
        {cmOrder.productName}
      </p>
      <div className="flex flex-wrap gap-[8px]">
        {cmOrder.option && (
          <p className="text-medium-s break-keep text-gray-500">
            {cmOrder.option}
          </p>
        )}
        {cmOrder.optionCode && <Label color="gray">{cmOrder.optionCode}</Label>}
      </div>
    </div>
  );
}

export default observer(CMOrderLabel);
