export function replaceVariable(expression: string, object: Record<string, unknown>) {
  const alphabet = 'a-zA-Z';
  const numeric = '0-9';
  /**
   * \uAC00-\uD7A3 : 가-힣 (음계)
   * \u3131-\u314E : ㄱ-ㅎ (자음)
   * \u314F-\u3163 : ㅏ-l (모음)
   */
  const koreanCharacters = '\uAC00-\uD7A3\u3131-\u314E\u314F-\u3163';
  const specialCharacters = ['.', '_', '(', ')', '{', '}', ',']
    .map((char) => `\\${char}`)
    .join('');

  const combined = [
    alphabet,
    numeric,
    koreanCharacters,
    specialCharacters,
  ].join('');

  const regexPattern = new RegExp(`\\{([${combined}]+)\\}`, 'g');

  return expression.replace(regexPattern, (_, field) => {
    const value = object[field];
    if (typeof value === 'number') {
      return value.toString();
    }

    if (typeof value === 'boolean') {
      return value.toString();
    }

    return `"${value ?? ''}"`;
  });
}
