import { NormalizedOrder, Order, OrderStatus } from '@sweep/contract';
import _ from 'lodash';
import { FulfilledOrderResult } from 'src/network/order/fullfillOrder';
import { toast } from 'src/third-parties/toast';
import { isNotNil } from 'src/utils/function';
import { RawOrderXX } from '../models/OrderXX';
import backendApi from '../utils/backendApi';
import {
  extractEnglishShippingHeaders,
  mapKeysByColumnMapping,
  removeEndNulls,
} from '../utils/headerColumnMapping';
import { shoppingMallTemps } from '../utils/mappingArrays';
import { readExcelFile } from '../utils/readExcelFile';
import { areArraysEqual, areMutualSubsequences, isValid } from '../utils/utils';
import { detectEnglishByKoreanHeader } from './fileHandling/utils/fileHandlePartnerUtils';
import useCreateExcel from './useCreateExcel';
import useMallOrderManagement from './useMallOrderManagement';
import { useOMSStore } from './useOMSStore';

const useShippingUpload = () => {
  const oms = useOMSStore();
  const { updateShippingInfoToMall } = useMallOrderManagement();
  const { createOrderExcel } = useCreateExcel();

  const cancelTableKoreanHeader = [
    '주문상태',
    '쇼핑몰',
    '스윕고유번호',
    '주문번호',
    '상품명',
    '옵션',
    '수량',
    '주문금액',
    '배송비',
    '수취인',
    '휴대폰번호',
    '송장번호',
    '우편번호',
    '주소',
    '배송메시지',
  ];

  const cancelTableColumnMapping = {
    orderStatus: '주문상태',
    shoppingMall: '쇼핑몰',
    uniqueCode: '스윕고유번호',
    orderNumber: '주문번호',
    productName: '상품명',
    option: '옵션',
    quantity: '수량',
    price: '주문금액',
    name: '수취인',
    shippingNumber: '송장번호',
    배송비: '배송비',
    contactNumber: '휴대폰번호',
    postCode: '우편번호',
    address: '주소',
    deliveryMessage: '배송메시지',
  };

  const extractShippingInfo = async (files: File[]) => {
    let orders: { [key: string]: string | number | undefined }[] = [];

    for (const file of files) {
      let rows = [];

      const readExcelSettings = {
        isOrderFile: false,
      };
      rows = await readExcelFile(file, readExcelSettings);

      let koreanHeaderRow = removeEndNulls(rows[0]) || [];
      let englishHeaderRow: (string | null)[] =
        extractEnglishShippingHeaders(koreanHeaderRow) || [];

      const userCustomExcelFormat = [...oms.user.excelHeaderKeys];

      const shippingExcelTemplate =
        oms.user.setting?.shippingExcelTemplate || {};

      if (
        _.isEqual(
          koreanHeaderRow,
          userCustomExcelFormat.map(
            (english) => oms.user.excelColumnMapping[english]
          )
        )
      ) {
        englishHeaderRow = userCustomExcelFormat;
      } else if (
        isValid(shippingExcelTemplate) &&
        areMutualSubsequences(koreanHeaderRow, shippingExcelTemplate.headers)
      ) {
        englishHeaderRow = mapKeysByColumnMapping(
          koreanHeaderRow,
          shippingExcelTemplate.headerMatchingMap
        );
      }

      for (const partnerInfo of oms.partner.partners) {
        const temp = detectEnglishByKoreanHeader(koreanHeaderRow, partnerInfo);
        if (temp) {
          englishHeaderRow = temp;
          break;
        }
      }

      for (const shoppingMallTemp of shoppingMallTemps) {
        if (areArraysEqual(koreanHeaderRow, shoppingMallTemp.headers)) {
          if (['랭킹닭컴', '웰스토리'].includes(shoppingMallTemp.mallName)) {
            rows.splice(1, 1);
            koreanHeaderRow = shoppingMallTemp.realHeaders || [];
          }

          englishHeaderRow = mapKeysByColumnMapping(
            koreanHeaderRow,
            shoppingMallTemp.headerTranslationMap
          );
        }
      }

      const newOrders = rows.slice(1).map((row: string[]) => {
        const entry: { [key: string]: string | number } = {};
        row.forEach((cell, i) => {
          const key = englishHeaderRow[i];
          if (key && isValid(cell)) {
            entry[key] = cell;
          }

          const defaultShippingCompany: string =
            oms.user.setting?.defaultShippingCompany || '';

          if (isValid(defaultShippingCompany)) {
            entry['shippingCompany'] = defaultShippingCompany;
          }
        });

        return entry;
      });

      orders = [...orders, ...newOrders];
    }

    return orders;
  };

  const retailerUploadShippingInfo = async (files: File[]) => {
    try {
      const res = await backendApi.retailerUploadShippingInfo(files);

      if (isValid(res?.failedOrders)) {
        toast.error(
          '운송장 등록에 실패한 주문이 있습니다. 실패 사유는 엑셀 파일을 확인해주세요.'
        );
      } else {
        toast.success('운송장 등록이 완료되었습니다.');
      }

      const successOrderMap: Map<string, RawOrderXX> = new Map(
        res.successOrders.map((order: RawOrderXX) => [order.uniqueCode, order])
      );

      const successOrderMapByOrderNumber: Map<string, RawOrderXX> = new Map(
        res?.successOrders?.map((order: RawOrderXX) => [
          order.orderNumber,
          order,
        ])
      );

      let updatedOrders = [...oms.order.normalizedOrders] as RawOrderXX[];

      updatedOrders = updatedOrders.map((order: RawOrderXX) => {
        const updateInfo: RawOrderXX | undefined = successOrderMap.get(
          order.uniqueCode
        );
        if (updateInfo) {
          order.shippingCompany = updateInfo.shippingCompany || '';
          order.shippingNumber = updateInfo.shippingNumber || '';
        } else {
          const updateInfoWithOrderNumber: RawOrderXX | undefined =
            order.orderNumber
              ? successOrderMapByOrderNumber.get(order.orderNumber)
              : undefined;
          if (updateInfoWithOrderNumber) {
            order.shippingCompany =
              updateInfoWithOrderNumber.shippingCompany || '';
            order.shippingNumber =
              updateInfoWithOrderNumber.shippingNumber || '';
          }
        }
        return order;
      });

      oms.order.setNormalizedOrders(updatedOrders as NormalizedOrder[]);

      if (isValid(res?.failedOrders)) {
        const date = new Date();
        const year = String(date.getFullYear()).slice(-2);
        const formattedDate = `${year}.${String(date.getMonth() + 1).padStart(
          2,
          '0'
        )}.${String(date.getDate()).padStart(2, '0')}`;

        await createOrderExcel(
          res?.failedOrders,
          `${formattedDate} 운송장 등록 실패 건`,
          ['failReason', ...oms.user.excelHeaderKeys],
          { failReason: '실패 사유', ...oms.user.excelColumnMapping },
          { returnNullIfNotInColumnMapping: false }
        );
      }
    } catch (err) {
      console.error('Error uploading shipping info for retailer:', err);
      throw err;
    }
  };

  const supplierUploadShippingInfo = async (files: File[]) => {
    try {
      const res = await backendApi.supplierUploadShippingInfo(files);

      if (isValid(res?.failedOrders)) {
        toast.error(
          '운송장 등록에 실패한 주문이 있습니다. 실패 사유는 엑셀 파일을 확인해주세요.'
        );
      } else {
        toast.success('운송장 등록이 완료되었습니다.');
      }

      const successOrderMap: Map<string, RawOrderXX> = new Map(
        res.successOrders.map((order: RawOrderXX) => [order.uniqueCode, order])
      );

      const successOrderMapByOrderNumber: Map<string, RawOrderXX> = new Map(
        res?.successOrders?.map((order: RawOrderXX) => [
          order.orderNumber,
          order,
        ])
      );

      let updatedOrders = [...oms.order.normalizedOrders] as RawOrderXX[];

      updatedOrders = updatedOrders.map((order: RawOrderXX) => {
        const updateInfo: RawOrderXX | undefined = successOrderMap.get(
          order.uniqueCode
        );
        if (updateInfo) {
          order.shippingCompany = updateInfo.shippingCompany || '';
          order.shippingNumber = updateInfo.shippingNumber || '';
        } else {
          const updateInfoWithOrderNumber: RawOrderXX | undefined =
            order.orderNumber
              ? successOrderMapByOrderNumber.get(order.orderNumber)
              : undefined;
          if (updateInfoWithOrderNumber) {
            order.shippingCompany =
              updateInfoWithOrderNumber.shippingCompany || '';
            order.shippingNumber =
              updateInfoWithOrderNumber.shippingNumber || '';
          }
        }
        return order;
      });

      oms.order.setNormalizedOrders(updatedOrders as NormalizedOrder[]);

      if (isValid(res?.failedOrders)) {
        const date = new Date();
        const year = String(date.getFullYear()).slice(-2);
        const formattedDate = `${year}.${String(date.getMonth() + 1).padStart(
          2,
          '0'
        )}.${String(date.getDate()).padStart(2, '0')}`;

        await createOrderExcel(
          res?.failedOrders,
          `${formattedDate} 운송장 등록 실패 건`,
          ['failReason', ...oms.user.excelHeaderKeys],
          { failReason: '실패 사유', ...oms.user.excelColumnMapping },
          { returnNullIfNotInColumnMapping: false }
        );
      }
    } catch (err) {
      console.error('Error uploading shipping info for supplier:', err);
      throw err;
    }
  };

  const mallUploadShippingInfo = async (files: File[]) => {
    // extractOrdersFromFiles
    let shippingInfos = await extractShippingInfo(files);

    // shippingInfos[0]의 key 중에 shippingNumber, shippingCompany, uniqueCode가 없으면 return
    if (
      shippingInfos.length === 0 ||
      !shippingInfos[0].shippingNumber ||
      !shippingInfos[0].shippingCompany ||
      (!shippingInfos[0].uniqueCode && !shippingInfos[0].orderNumber)
    ) {
      alert(
        '송장번호, 택배사, 스윕고유번호 또는 주문번호가 포함된 엑셀 파일을 업로드해주세요.'
      );
      return;
    }
    shippingInfos = shippingInfos.flatMap((info: any) => {
      if (!info.uniqueCode && !info.orderNumber) {
        return [];
      }
      if (isValid(info.uniqueCode)) {
        return info.uniqueCode.split(',').map((uniqueCode: string) => ({
          ...info,
          uniqueCode,
          shippingNumber: info.shippingNumber?.toString()?.split(',')?.[0],
        }));
      } else {
        return info;
      }
    });

    //uniqueCode가 중복되는 경우 제거
    shippingInfos = shippingInfos.filter(
      (info: any, index: number, self: any[]) =>
        index ===
        self.findIndex(
          (t: any) =>
            t.uniqueCode === info.uniqueCode &&
            t.orderNumber === info.orderNumber
        )
    );

    await updateAndHandleShippingInfo(shippingInfos);
  };

  const mallUploadShippingInfoManually = async (shippingInfos: any[]) => {
    // shippingNumber 내용 없으면 return
    if (!shippingInfos[0].shippingNumber) {
      alert('운송장번호를 입력 후 업로드 버튼을 눌러주세요.');
      return;
    }

    await updateAndHandleShippingInfo(shippingInfos);
  };

  const updateAndHandleShippingInfo = async (shippingInfos: any[]) => {
    try {
      const { successOrders, failedOrders } = (await updateShippingInfoToMall(
        shippingInfos
      )) ?? { successOrders: [], failedOrders: [] };
      await handleShippingInfoResponse(successOrders, failedOrders);
    } catch (error) {
      console.error('Error updating shipping info:', error);
      alert('업로드 중 오류가 발생했습니다.');
    }
  };

  const handleShippingInfoResponse = async (
    successOrders: FulfilledOrderResult[],
    failedOrders: FulfilledOrderResult[]
  ) => {
    if (isValid(failedOrders)) {
      const date = new Date();
      const year = String(date.getFullYear()).slice(-2);
      const formattedDate = `${year}.${String(date.getMonth() + 1).padStart(
        2,
        '0'
      )}.${String(date.getDate()).padStart(2, '0')}`;

      await createOrderExcel(
        failedOrders as unknown as Order[],
        `${formattedDate} 운송장 등록 실패 건`,
        ['failReason', ...cancelTableKoreanHeader],
        { failReason: '실패 사유', ...cancelTableColumnMapping },
        { returnNullIfNotInColumnMapping: false }
      );
    }

    if (successOrders.length === 0) {
      return;
    }

    const fulfilledOrders = new Map(
      successOrders
        .map((order) => {
          const key = order.uniqueCode ?? order.orderNumber;
          if (key == null) {
            return null;
          }
          return [
            key,
            {
              shippingCompany: order.shippingCompany,
              shippingNumber: order.shippingNumber,
            },
          ] as const;
        })
        .filter(isNotNil)
    );

    const updatedOrders = oms.order.dispatchedOrders.map((order) => {
      const fulfilledOrder =
        fulfilledOrders.get(order.uniqueCode) ??
        (order.orderNumber != null
          ? fulfilledOrders.get(order.orderNumber)
          : null);
      if (fulfilledOrder == null) {
        return order;
      }

      return {
        ...order,
        shippingCompany: fulfilledOrder.shippingCompany,
        shippingNumber: fulfilledOrder.shippingNumber,
        orderStatus: OrderStatus.inDelivery,
      };
    });

    oms.order.dispatch.setDispatchedOrders(updatedOrders);
  };

  return {
    retailerUploadShippingInfo,
    supplierUploadShippingInfo,
    mallUploadShippingInfo,
    mallUploadShippingInfoManually,
    extractShippingInfo,
  };
};

export default useShippingUpload;
