import { Order } from '@sweep/contract';
import { CMOrder } from '../interface';

export function transformToCMOrder(order: Order): CMOrder {
  return {
    productName: order.productName,
    option: order.option,
    optionCode: order.optionCode,
  };
}
