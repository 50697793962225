import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import { Product } from 'models/Product';
import {
  CreateProduct,
  createProduct,
  deleteProduct,
  getProducts,
  UpdateProduct,
  updateProduct,
  UpdateProductMany,
  updateProductMany,
} from 'network/product';
import { isEmptyString } from 'src/utils/string';
import { OMSStore } from './OMSStore';

export class ProductStore {
  products: Product[] = [];

  get productNames() {
    return this.products.map((product) => product.productName);
  }

  get productIdByName() {
    return new Map<string, string>(
      this.products.map((product) => [product.productName, product._id])
    );
  }

  getProduct(id: string) {
    return this.products.find((product) => product._id === id);
  }

  getProductByName(name: string) {
    return this.products.find((product) => product.productName === name);
  }

  getSupplierId(productId: string, unitname?: string) {
    const product = this.getProduct(productId);
    if (product == null) {
      return null;
    }

    if (unitname == null || product.useSupplierByOption !== true) {
      // TODO(@이지원): productManagement deploy가 모두 나가면 isEmptyString 삭제
      return isEmptyString(product.supplierId) ? null : product.supplierId;
    }

    const unit = product.units?.find((unit) => unit.unit === unitname);
    if (unit == null) {
      return null;
    }

    return unit.supplierId;
  }

  constructor(private oms: OMSStore) {
    makeObservable(this, {
      products: observable,

      productNames: computed,
      productIdByName: computed,

      init: action.bound,
      loadProducts: action.bound,
      create: action.bound,
      update: action.bound,
      deleteMany: action.bound,
    });
  }

  async init() {
    await this.loadProducts();
  }

  loadProducts = async () => {
    const response = await getProducts();
    runInAction(() => {
      const products = response.products ?? [];
      this.products = products.sort((p1, p2) =>
        p1.productName.localeCompare(p2.productName)
      );
    });
  };

  async create(product: CreateProduct) {
    const response = await createProduct(product);
    if (response.success === false) {
      return null;
    }
    const registeredProduct = response.data;

    runInAction(() => {
      this.products = [registeredProduct, ...this.products];
    });

    return registeredProduct;
  }

  async update(
    productId: string,
    product: UpdateProduct
  ): Promise<Product | null> {
    const index = this.products.findIndex(
      (product) => product._id === productId
    );
    if (index === -1) {
      return null;
    }

    const prevProduct = this.products[index];
    this.products[index] = {
      ...prevProduct,
      ...product,
    };

    const response = await updateProduct(productId, product);
    if (response?.success !== true) {
      this.products[index] = prevProduct;
      return null;
    }

    return this.products[index];
  }

  async updateMany(products: UpdateProductMany[]) {
    this.products = this.products.map((product) => {
      const updatedProduct = products.find((p) => p._id === product._id);
      if (updatedProduct == null) {
        return product;
      }

      return {
        ...product,
        ...updatedProduct,
      };
    });

    const response = await updateProductMany(products);
    if (response?.success !== true) {
      await this.loadProducts();

      return false;
    }

    return true;
  }

  async deleteMany(productIds: string[]) {
    this.products = this.products.filter(
      (product) => !productIds.includes(product._id)
    );

    const deletePromises = productIds.map((productId) =>
      deleteProduct(productId)
    );

    const responses = await Promise.all(deletePromises);

    if (responses.some((response) => response?.success !== true)) {
      await this.loadProducts();
      return false;
    }

    return true;
  }
}
