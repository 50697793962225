import { cva } from 'src/third-parties/tailwind';
import { ShoppingMallSelectContextProvider } from './ShoppingMallSelectContext';
import ShoppingMallSelectMultiOption from './ShoppingMallSelectMultiOption';
import ShoppingMallSelectOption from './ShoppingMallSelectOption';

interface ShoppingMallSelectProps {
  value: string[];
  onChange: (value: string[]) => void;
  children?: React.ReactNode;
}

function ShoppingMallSelect({
  value,
  onChange,
  children,
}: ShoppingMallSelectProps) {
  return (
    <ShoppingMallSelectContextProvider value={{ value, onChange }}>
      <div className={container()}>{children}</div>
    </ShoppingMallSelectContextProvider>
  );
}

const container = cva(
  'flex h-[404x] flex-col overflow-y-auto rounded-[8px] border border-gray-300'
);

ShoppingMallSelect.Option = ShoppingMallSelectOption;
ShoppingMallSelect.MultiOption = ShoppingMallSelectMultiOption;

export default ShoppingMallSelect;
