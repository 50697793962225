import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { toast } from 'sonner';
import { Button } from 'src/design-system/components/Button';
import { Modal } from 'src/design-system/components/Modal';
import { Product } from 'src/models/Product';
import OptionListInput, {
  OptionsWithSupplier,
} from '../common/OptionListInput';
import ProductNameInput from '../common/ProductNameInput';
import SupplierInput from '../common/SupplierInput';
import { useDuplicatedProductName } from '../common/useDuplicatedProductName';
import { openProductUpdateFormCloseDialog } from './openProductUpdateFormCloseDialog';

interface ProductUpdateFormModalProps {
  open: boolean;
  product: Product;
  onSubmit: (product: Product | null) => void;
}

function ProductUpdateFormModal({
  open,
  product: givenProduct,
  onSubmit: givenOnSubmit,
}: ProductUpdateFormModalProps) {
  const [product, setProduct] = useState(givenProduct);

  const isDuplicatedProductName = useDuplicatedProductName(
    product.productName,
    givenProduct.productName
  );

  const handleProductNameChange = (value: string) => {
    setProduct({ ...product, productName: value });
  };

  const optionsWithConfig: OptionsWithSupplier = {
    options: product.units ?? [],
    useSupplierByOption: product.useSupplierByOption ?? false,
    supplierId: product.supplierId,
  };

  const handleOptionsChange = (value: OptionsWithSupplier) => {
    setProduct({
      ...product,
      units: value.options,
      useSupplierByOption: value.useSupplierByOption,
      supplierId: value.supplierId,
    });
  };

  const handleSupplierChange = (value: string) => {
    setProduct({ ...product, supplierId: value });
  };

  const onClose = async () => {
    const isClose = await openProductUpdateFormCloseDialog();
    if (!isClose) {
      return;
    }
    givenOnSubmit(null);
  };

  const onSubmit = () => {
    const removedEmptyOptionProduct = {
      ...product,
      units: product.units?.filter((unit) => unit.unit !== ''),
    };

    const isDuplicatedOption =
      removedEmptyOptionProduct.units?.some(
        (unit, index, units) =>
          units.findIndex((u) => u.unit === unit.unit) !== index
      ) ?? false;

    if (product.productName === '') {
      toast.error('상품명은 비워둘 수 없습니다.');
      return;
    }

    if (isDuplicatedProductName || isDuplicatedOption) {
      toast.error('중복된 상품명 혹은 옵션이 있습니다.');
      return;
    }

    givenOnSubmit(removedEmptyOptionProduct);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      dismiss={{ outsidePress: false, escapeKey: false }}
      className="max-h-600px"
    >
      <Modal.Title className="mb-24px">상품 수정하기</Modal.Title>
      <div className="gap-8px pb-52px flex flex-col overflow-y-auto">
        <ProductNameInput
          value={product.productName}
          onChange={handleProductNameChange}
          isDuplicated={isDuplicatedProductName}
        />
        <OptionListInput
          value={optionsWithConfig}
          onChange={handleOptionsChange}
        />
        {product.useSupplierByOption !== true && (
          <div className="mt-24px gap-8px flex flex-col">
            <p className="text-medium-m text-gray-700">공급사</p>
            <SupplierInput
              value={product.supplierId ?? ''}
              onChange={handleSupplierChange}
            />
          </div>
        )}
      </div>
      <div className="gap-12px flex-center flex">
        <Modal.Close className="w-200px" color="lightGray">
          닫기
        </Modal.Close>
        <Button className="w-200px" onClick={onSubmit}>
          수정하기
        </Button>
      </div>
    </Modal>
  );
}

export default observer(ProductUpdateFormModal);
