import { ReactNode } from 'react';
import { cva } from 'src/third-parties/tailwind';

interface NumberProps extends React.HTMLAttributes<HTMLDivElement> {
  active?: boolean;
  children: ReactNode;
}

export function Number({
  active = true,
  children,
  className,
  ...rest
}: NumberProps) {
  return (
    <div className={number({ active, className })} {...rest}>
      {children}
    </div>
  );
}

const number = cva(
  'text-medium-s flex size-[21px] items-center justify-center rounded-full text-white',
  {
    variants: {
      active: {
        true: 'bg-gray-600',
        false: 'bg-gray-300',
      },
    },
  }
);
