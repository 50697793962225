import { isEqualArray } from '@sweep/utils';
import { Partner } from 'models/Partner';
import { ExcelFile } from 'services/file/interface';

export function findPartnerCandidates(file: ExcelFile, partners: Partner[]) {
  const header = file.data[0];

  if (header == null) {
    return [];
  }

  return partners.filter((partner) => isCandidate(header, partner));
}

function isCandidate(header: string[], partner: Partner): boolean {
  const matchings = partner.matchings;
  if (matchings == null) {
    return false;
  }

  return matchings.some((matching) => isEqualArray(matching.header, header));
}
