import copy from 'fast-copy';
import { Supplier } from 'models/Partner';
import { OrderXX, RawOrderXX } from '../../models/OrderXX';
// UserStore를 import 합니다.
import { splitOptiosIntoParts } from '../../utils/divideOrder';
import {
  extractMultiplier,
  multiplyPattern,
} from '../../utils/multiplierUtils';
import { isValid, removeSpaces } from '../../utils/utils';
import { useOMSStore } from '../useOMSStore';

interface FormatSettings {
  deleteUnits?: boolean;
  setDataQuantity1?: boolean;
  setOrderQuantity1?: boolean;
  setQuantityWithOrig?: boolean;
  replaceAddToComma?: boolean;
  dontUseMultiplier?: boolean;
  applyQuantityToUnit?: boolean;
  useProductName?: boolean;
  showQuantityAlways?: boolean;
  customFormat?: string;
  customSeparator?: string;
  customQuantityFormat?: string;
  combineProductNameWithOptions?: boolean;
  suppliers?: SupplierSettings;
}

interface SupplierSettings {
  [key: string]: FormatSettings;
}

function useApplyCustomSettings() {
  const oms = useOMSStore();

  const addPlatformName = (orders: OrderXX[]) => {
    // 개인 설정이 활성화되어 있을 경우 로직을 적용합니다.
    if (!oms.user.setting?.platformNameSettings?.enabled) {
      return orders;
    }

    // matchingSet 배열을 반복하여 fileName과 일치하는 platformName을 찾습니다.
    const platformNameSettings = oms.user.setting?.platformNameSettings;
    const matchingSet = platformNameSettings.matchingSet || [];

    const newOrders = [...orders];
    for (const order of newOrders) {
      const cleanFileName = order.originFile
        .replace(/\..*$/, '')
        .normalize('NFC');

      if (isValid(matchingSet)) {
        for (const item of matchingSet) {
          if (item.matchingType === 'partnerId') {
            if (isValid(order?.partnerId)) {
              if (item.partnerId === order.partnerId) {
                if (isValid(item.platformName)) {
                  order.platformName = item.platformName;
                  break;
                } else {
                  const partnerName = oms.partner.partners.find(
                    (partner) => partner._id === order.partnerId
                  )?.name;

                  if (isValid(partnerName)) {
                    order.platformName = item.partnerName;
                    break;
                  }
                }
              }
            }
          } else if (cleanFileName.includes(item.fileKeyword)) {
            order.platformName = item.platformName;
            break;
          } else if (isValid(order?.partnerId)) {
            const partnerName = oms.partner.partners.find(
              (partner) => partner._id && partner._id === order.partnerId
            )?.name;
            if (isValid(partnerName)) {
              order.platformName = partnerName;
              break;
            }
          } else {
            if (
              isValid(platformNameSettings?.defaultForm) &&
              isValid(order?.shoppingMallName)
            ) {
              order.platformName = platformNameSettings?.defaultForm.replace(
                '{platformName}',
                order?.shoppingMallName
              );
            }
          }
        }
      } else if (isValid(order?.partnerId)) {
        const partnerName = oms.partner.partners.find(
          (partner) => partner._id && partner._id === order.partnerId
        )?.name;
        if (isValid(partnerName)) {
          order.platformName = partnerName;
        }
      } else if (isValid(order?.shoppingMall)) {
        order.platformName = isValid(platformNameSettings.defaultForm)
          ? platformNameSettings?.defaultForm.replace(
              '{platformName}',
              order?.shoppingMall
            )
          : order.shoppingMall;
      }
    }

    return newOrders;
  };

  const evaluateExpression = (order: OrderXX, func: string) => {
    // '+' 기준으로 분할하고 각 요소의 공백 제거
    const parts = func.split('+').map((part: string) => part.trim());

    // 각 요소 평가
    const results = parts.map((part: string) => {
      if (
        typeof part === 'string' &&
        part.startsWith('"') &&
        part.endsWith('"')
      ) {
        // 큰따옴표로 감싸진 문자열 처리
        return part.slice(1, -1) || '';
      }
      // else if (!isNaN(parseFloat(part))) {
      //   // 숫자 처리
      //   return parseFloat(part);
      // }
      else {
        // order 객체에서 필드 값 추출
        return order[part] || '';
      }
    });

    // 결과를 더하기
    return results.reduce((acc: string, cur: string) => {
      return (acc + cur).trim();
    }, '');
  };

  const applyExcelFunction = (orders: OrderXX[]) => {
    if (!oms.user.setting?.applyExcelFuntion?.enabled) {
      return orders;
    }

    const settings = oms.user.setting?.applyExcelFuntion?.settings || [];

    const newOrders = [...orders];

    newOrders.forEach((order) => {
      settings?.forEach(
        (setting: { targetField: string; expression: string }) => {
          const targetField = setting.targetField || '';
          const func = setting.expression || '';

          order[targetField] = evaluateExpression(order, func);
        }
      );
    });

    return newOrders;
  };

  // 위에 있는 applyExcelFuntion로 대체되어야 함 - 숫자 로직 디벨롭 더 해서
  const applyJSLogic = (orders: OrderXX[]) => {
    if (!oms.user.setting?.applyJSLogic?.enabled) {
      return orders;
    }

    const applyJSLogicSettings = oms.user.setting?.applyJSLogic;

    const newOrders = [...orders];

    newOrders.forEach((order) => {
      applyJSLogicSettings?.settings?.forEach(
        (setting: { targetField: string; expression: string }) => {
          const field = setting.targetField;
          const logic = setting.expression;
          // Create a function from the logic string
          // TODO(@이지원): 없애기
          try {
            const getValue = new Function(
              'order',
              `with (order) {return ${logic};}`
            );
            order[field] = getValue(order);
          } catch {
            console.error(`Failed to apply logic to ${field}`);
          }
        }
      );
    });

    return newOrders;
  };

  const addSupplierName = (orders: OrderXX[]) => {
    // 개인 설정이 활성화되어 있을 경우에만 로직을 적용합니다.
    if (!oms.user.setting?.addSupplierName?.enabled) {
      return orders;
    }

    // addSupplierName Object 내 condition이라는 object가 있다고 가정
    const condition = oms.user.setting?.addSupplierName?.condition || {};

    const newOrders = [...orders];

    for (const order of newOrders) {
      const supplierId = order?.data[0]?.supplierId;
      if (isValid(supplierId)) {
        const supplierName = oms.supplier.suppliers.find(
          (supplier) => supplier._id && supplier._id.toString() === supplierId
        )?.name;

        if (isValid(supplierName)) {
          if (isValid(condition?.expressionFormat)) {
            order.supplierName = condition.expressionFormat.replace(
              '{supplierName}',
              supplierName
            );
          } else {
            order.supplierName = supplierName;
          }
        }
      }
    }

    return newOrders;
  };

  const sortOrders = (orders: OrderXX[]) => {
    if (!oms.user.setting?.sortOrders?.enabled) {
      return orders;
    }

    const sortingCriteria = oms.user.setting?.sortOrders?.sortingCriteria || '';

    if (!isValid(sortingCriteria)) {
      return orders;
    }

    if (sortingCriteria === 'addressCount') {
      const addressCountMap = new Map();

      orders.forEach((order) => {
        const address = order.address;

        const count = addressCountMap.get(address) || 0;
        addressCountMap.set(address, count + 1);
      });

      return orders.sort((a, b) => {
        const countA = addressCountMap.get(a.address);
        const countB = addressCountMap.get(b.address);
        const isCountAMultiple = countA > 1;
        const isCountBMultiple = countB > 1;

        // 중복 주소 수가 1 초과인 경우 먼저 처리
        if (isCountAMultiple && !isCountBMultiple) {
          return -1; // A만 중복 주소 수가 1보다 크면 A를 앞으로
        } else if (!isCountAMultiple && isCountBMultiple) {
          return 1; // B만 중복 주소 수가 1보다 크면 B를 뒤로
        }

        // 중복 주소 수가 같으면 주소 이름을 기준으로 오름차순 정렬
        return a.address.localeCompare(b.address);
      });
    }

    // 도서산간, 제주도의 경우 맨 아래로
    if (sortingCriteria === 'remoteArea') {
      return orders.sort((a, b) => {
        const aIsRemote = a.address.startsWith('제주');
        const bIsRemote = b.address.startsWith('제주');

        if (aIsRemote && !bIsRemote) {
          return 1;
        } else if (!aIsRemote && bIsRemote) {
          return -1;
        }

        return a.address.localeCompare(b.address);
      });
    }

    return orders.sort((a, b) => {
      for (const i of sortingCriteria.split(',')) {
        const dir = i.startsWith('-') ? 1 : -1;
        const by = i.replace('-', '');
        if (a[by] < b[by]) {
          return dir;
        }
        if (a[by] > b[by]) {
          return dir * -1;
        }
      }
      return 0;
    });
  };

  const applyMultiplierToQuantity = (newOrders: OrderXX[]) => {
    if (!oms.user.setting?.applyMultiplierToQuantity?.enabled) {
      return newOrders;
    }

    return newOrders.map((order) => {
      const parts = splitOptiosIntoParts(order.option);
      if (parts.length > 1) {
        return order;
      }

      const multiplier = extractMultiplier(order.option);

      const multiplierExpression = order.option.match(multiplyPattern)?.[0];

      const newOption = order.option.replace(multiplierExpression || '', '');
      const newQuantity = order.quantity * multiplier;

      return {
        ...order,
        option: newOption,
        quantity: newQuantity,
      };
    });
  };

  /** shippingCompany field를 추가하고 택배사 넣음 */
  const addDefaultShippingCompany = (newOrders: RawOrderXX[]) => {
    const { enabled, defaultShippingCompany } =
      oms.user.setting?.addDefaultShippingCompanySettings || {};

    if (!enabled) {
      return newOrders;
    }

    return newOrders.map((order) => {
      return {
        ...order,
        shippingCompany: defaultShippingCompany,
      };
    });
  };

  const convertSpecificCompositionsExpression = (orders: OrderXX[]) => {
    if (
      !oms.user.setting?.convertSpecificCompositionExpressionSetting?.enabled
    ) {
      return orders;
    }

    return orders.map((order) => {
      if (!isValid(order?.data)) {
        return order;
      }

      const settings =
        oms.user.setting?.convertSpecificCompositionExpressionSetting?.settings;

      for (const setting of settings) {
        if (setting.type === 'productName') {
          if (
            order?.data.some(
              (data) =>
                data.productName &&
                setting.object &&
                data.productName.includes(setting.object.productName)
            ) &&
            ((order.origOption &&
              order.origOption.includes(setting.replacement)) ||
              (order.origName && order.origName.includes(setting.replacement)))
          ) {
            const convertedOption = order.option.replace(
              setting.object.productName,
              setting.replacement
            );

            return { ...order, option: convertedOption };
          } else if (
            setting.partnerId === order.partnerId &&
            order?.data.some((data) => data.productId === setting.productId)
          ) {
            const productName = oms.product.products.find(
              (product) => product._id === setting.productId
            )?.productName;

            const convertedOption = order.option.replace(
              productName ?? '',
              setting.replacement
            );

            return { ...order, option: convertedOption };
          }
        }
      }

      return order;
    });
  };

  const formatOrders = (orders: OrderXX[], settings: FormatSettings = {}) => {
    const newOrders = [...orders];

    // setDataQuantity1
    // 배경: quantity 내용이 두 군데에 나뉘어져 있음 1) {data: [{productName: 반숙란, unit: 30구, quantity: 2}], quantity: 3, ...}
    // data를 가지고 option을 만들면, {option: 반숙란 30구 x2, quantity: 3, ...} 이런식으로 만들어짐
    // setDataQuantity1은 data의 quantity를 1로 설정하고, order의 quantity를 data의 quantity를 곱해주는 것
    // 이 경우 {option: 반숙란 30구, quantity: 6, ...}가 결과값
    // for what? 고객사의 케이스는, 수량정보를 '수량' 열에 두는 사람이랑, 옵션열에 몰아서 넣는 사람 두 부류로 만들어짐
    // option에는 상품 정보(상품명 + 옵션)만 두고 싶다 = 수량 관련 정보를 '수량' 열에 몰아넣고 싶다! 하면 setDataQuantity1를 쓰는것
    // 반대로, '옵션' 열에는 상품 정보 뿐 아니라 수량 정보도 몰아넣고 싶다(상품명 + 옵션 + 수량) = order.quantity 정보, 즉 '수량' 열에 있는 수량 정보도 다 '옵션' 열에 몰아넣고 싶다 ! 하면 setOrderQuantity1 쓰는것
    // 발주 관리 단 + 주문수집 내 옵션 불러오기 + 알리 운송장 등록 scraping

    // 지환님 요청사항
    // 1. 지원님께, 해당 로직 관련해서 제공할 수 있는 최대한의 맥락들
    // 2. 해당 로직이 있다가 없을 때(껐다 켰을 때) 어떤 일들이 벌어지는지? 어떠한 결과의 차이가 발생하는지? -> 예시 데이터

    const {
      deleteUnits = false,
      setDataQuantity1 = false, // data의 수량을 1로 설정 (수량을 order.quantity에 넣음) - 1개 상품만 있는 경우
      setOrderQuantity1 = false, // order의 수량을 1로 설정 (수량을 data.quantity에 넣음)
      setQuantityWithOrig = false, // 원본 수량을 quantity에 넣음
      replaceAddToComma = false, // a1k, b2k x2, ...
      dontUseMultiplier = false, // a*2+b -> a+a+b
      applyQuantityToUnit = false, // 반숙란 30구 *2 -> 반숙란 60구
      useProductName = false, //productName 사용
      showQuantityAlways = false,
      customFormat = '',
      customSeparator = ' + ',
      customQuantityFormat = 'x{quantity}',
      combineProductNameWithOptions = true,
      suppliers = [],
    } = settings;

    newOrders.forEach((order) => {
      // 각 공급사별 설정 병합
      const supplierSettings =
        (settings.suppliers as any)?.find(
          (setting: any) => setting.supplierId === order.supplierId
        ) || {};

      const finalSettings = { ...settings, ...supplierSettings }; // 기본 설정에 공급사 설정을 병합

      const {
        deleteUnits = false,
        setDataQuantity1 = false,
        setOrderQuantity1 = false,
        setQuantityWithOrig = false,
        replaceAddToComma = false,
        dontUseMultiplier = false,
        applyQuantityToUnit = false,
        useProductName = false,
        showQuantityAlways = false,
        customFormat = '',
        customSeparator = ' + ',
        customQuantityFormat = 'x{quantity}',
        combineProductNameWithOptions = true,
      } = finalSettings;

      if (setDataQuantity1 || setOrderQuantity1 || setQuantityWithOrig) {
        order.data.forEach((data) => {
          data.quantity *= order.quantity;
        });
        order.quantity = 1;
        if (setDataQuantity1) {
          if (order.data.length !== 1) {
            console.warn("quntity in quantity but data's length is not 1");
          } else {
            order.quantity = order.data[0].quantity;
            order.data[0].quantity = 1;
          }
        } else if (setQuantityWithOrig) {
          const diff = order.origQuantity / order.quantity;
          order.data.forEach((data) => {
            data.quantity /= diff;
          });
          order.quantity = order.origQuantity;
        } else if (setOrderQuantity1) {
          // pass
        }
      }

      if (useProductName || combineProductNameWithOptions === false) {
        order.productName = order.data?.[0]?.productName || '';
      }

      // // Composition Matching이 이루어지지 않은 경우, option을 그대로 사용
      // // data[0].productId가 숫자와 소문자로 이루어지지 않은 경우

      // const isCompostionMatched = isValid(order.data[0].productId)
      //   ? /^[0-9a-z]+$/.test(order.data[0].productId)
      //   : false;

      // order.option = isCompostionMatched
      //   ? getOptionFromData(order.data, finalSettings)
      //   : order.option;
      order.option = getOptionFromData(order.data, finalSettings);
    });

    return newOrders;
  };

  const addFixedColumnValue = (orders: OrderXX[]) => {
    const fixedColumnValue = oms.user.setting?.addFixedColumnValue;

    const isEnabled = isValid(fixedColumnValue?.enabled);

    const newOrders = [...orders];
    if (isEnabled) {
      newOrders.forEach((order) => {
        for (const [key, value] of Object.entries(
          fixedColumnValue?.settings || {}
        )) {
          order[key] = value;
        }
      });
    }

    const supplierFixedDict: Record<string, any> = {};

    (oms.supplier.suppliers || []).forEach((supplier) => {
      supplierFixedDict[supplier._id] =
        supplier?.customSettings?.addFixedColumnValue;
    });

    newOrders.forEach((order) => {
      if (supplierFixedDict?.[order?.supplierId || '']?.enabled) {
        for (const [key, value] of Object.entries(
          supplierFixedDict[order?.supplierId || '']?.settings || {}
        )) {
          order[key] = value;
        }
      }
    });

    return newOrders;
  };

  const applyMergedCellValues = (orders: OrderXX[]) => {
    // 인식할때 셀이 합쳐져있었지만, read-excel-file 라이브러리가 합쳐진 셀을 인식하지 못하여 하나의 행에만 값이 들어가는 경우가 있습니다.
    // 이를 해결하기 위해 합쳐진 셀의 값을 다른 셀에도 적용하는 로직을 추가합니다.

    const applyMergedCellValues = oms.user.setting?.applyMergedCellValues;

    if (!isValid(applyMergedCellValues?.enabled)) {
      return orders;
    }

    const newOrders = [...orders];

    // 병합된 셀의 경우 read-excel-file의 첫 번째 셀에서만 값이 들어갑니다. 이를 이용하여 값을 복사합니다.
    let mergedCellValue = '';
    newOrders.forEach((order) => {
      const settings = applyMergedCellValues?.settings || [];

      for (const setting of settings) {
        if (order.partnerId === setting.partnerId) {
          if (isValid(order[setting.targetKey])) {
            mergedCellValue = order[setting.targetKey];
          } else {
            order[setting.targetKey] = mergedCellValue;
          }
        }
      }
    });

    return newOrders;
  };

  const addConditionalColumnValues = (orders: OrderXX[]) => {
    const addConditionalColumnValues =
      oms.user.setting?.addConditionalColumnValues;

    if (!isValid(addConditionalColumnValues?.enabled)) {
      return orders;
    }
    const newOrders = [...orders];

    newOrders.forEach((order) => {
      addConditionalColumnValues.rules.forEach(
        (rule: {
          matchCondition: Record<string, any>;
          result: Record<string, any>;
        }) => {
          if (!rule.matchCondition || !rule.result) {
            return order;
          }

          if (!Object.keys(rule.matchCondition).some((key) => order[key])) {
            return order;
          }

          if (
            Object.keys(rule.matchCondition).every((key) => {
              return (
                removeSpaces(order[key]) ===
                removeSpaces(rule.matchCondition[key])
              );
            })
          ) {
            Object.keys(rule.result).forEach((resultKey) => {
              order[resultKey] = rule.result[resultKey];
            });

            return order;
          }
        }
      );
    });

    return newOrders;
  };

  const applyFieldValueToAnother = (orders: OrderXX[]) => {
    const applyFieldValueToAnother = oms.user.setting?.applyFieldValueToAnother;

    const newOrders = [...orders];

    if (isValid(applyFieldValueToAnother?.enabled)) {
      newOrders.forEach((order) => {
        applyFieldValueToAnother.rules.forEach(
          (rule: { sourceField: string; targetField: string }) => {
            if (isValid(order[rule.sourceField])) {
              order[rule.targetField] = order[rule.sourceField];
            }
          }
        );
      });
    }

    // 공급업체 정보가 없거나 빈 배열인 경우 로직을 종료
    if (!isValid(oms.supplier.suppliers)) {
      return newOrders;
    }

    // 유효한 공급업체 설정이 있는지 먼저 확인
    const hasValidSupplierSettings = oms.supplier.suppliers.some((supplier) =>
      isValid(supplier?.customSettings?.applyFieldValueToAnother?.enabled)
    );

    // 모든 공급업체 정보에 유효한 설정이 없으면 로직 종료
    if (!hasValidSupplierSettings) {
      return newOrders;
    }

    const supplierRulesDict: Record<
      string,
      NonNullable<Supplier['customSettings']>['applyFieldValueToAnother']
    > = {};

    oms.supplier.suppliers.forEach((supplier: Supplier) => {
      if (supplier?.customSettings?.applyFieldValueToAnother) {
        supplierRulesDict[supplier._id] =
          supplier?.customSettings?.applyFieldValueToAnother;
      }
    });

    newOrders.forEach((order) => {
      if (order?.supplierId && supplierRulesDict?.[order.supplierId]?.enabled) {
        supplierRulesDict[order.supplierId]?.rules?.forEach((rule) => {
          if (isValid(order[rule.sourceField])) {
            order[rule.targetField] = order[rule.sourceField];
          }
        });
      }
    });

    return newOrders;
  };

  function multiplyNumbersInString(str: string, multiplier: number) {
    // 정규 표현식을 사용하여 문자열 내의 모든 숫자를 찾고, 이를 2배로 곱함
    return str.replace(/\d+/g, (match: string) => {
      const number = parseInt(match, 10);
      return (number * multiplier).toString();
    });
  }

  const getOptionFromData = (
    data: OrderXX['data'],
    settings: {
      deleteUnits?: boolean;
      replaceAddToComma?: boolean;
      dontUseMultiplier?: boolean;
      showQuantityAlways?: boolean;
      customFormat?: string;
      customSeparator?: string;
      customQuantityFormat?: string;
      applyQuantityToUnit?: boolean;
      useProductName?: boolean;
      combineProductNameWithOptions?: boolean;
    } = {}
  ) => {
    const {
      deleteUnits = false,
      replaceAddToComma = false, // a1k, b2k x2, ...
      dontUseMultiplier = false, // a*2+b -> a+a+b
      showQuantityAlways = false, // 수량이 1개인 경우에도 수량을 표시
      customFormat = '', // '{productName} {unit} x{quantity}'
      customSeparator = ' + ',
      customQuantityFormat = 'x{quantity}',
      applyQuantityToUnit = false,
      combineProductNameWithOptions = true,
    } = settings;

    let option = '';
    let newData = data ?? [];
    if (dontUseMultiplier) {
      newData = [];
      data.forEach((product) => {
        const newProduct = copy(product);
        newProduct.quantity = 1;
        for (let i = 0; i < product.quantity; i++) {
          newData.push(newProduct);
        }
      });
    }

    if (customFormat !== '') {
      newData.forEach((product) => {
        let customOption = customFormat;
        if (showQuantityAlways || product.quantity > 1) {
          customOption = customOption.replace(
            '{customQuantityFormat}',
            customQuantityFormat
          );
        } else {
          customOption = customOption.replace('{customQuantityFormat}', '');
        }

        if (applyQuantityToUnit) {
          customOption = customOption
            .replace(
              '{unit}',
              multiplyNumbersInString(product.unit, product.quantity)
            )
            .replace('{quantity}', '');
        }
        customOption =
          customOption
            .replace('{productName}', product.productName || '')
            .replace('{unit}', product.unit)
            .replace('{quantity}', product.quantity.toString()) +
          customSeparator;
        option += customOption;
      });

      option = option.slice(0, -customSeparator.length);
    } else {
      newData.forEach((product) => {
        if (combineProductNameWithOptions) {
          option += product.productName;
        }

        if (!replaceAddToComma) {
          option += ' ';
        }
        if (!deleteUnits) {
          option += `${product.unit || ''} `;
        }
        if (showQuantityAlways || product.quantity > 1) {
          option += customQuantityFormat.replace(
            '{quantity}',
            product.quantity.toString()
          );
        }

        if (replaceAddToComma) {
          option += ', ';
        } else {
          option += ' + ';
        }
      });

      if (replaceAddToComma) {
        option = option.replace(/,\s*$/, '');
      } else {
        option = option.replace(/\+\s*$/, '');
      }
    }
    option = option.trim();

    return option;
  };

  const applyPrefixToOption = (orders: OrderXX[]) => {
    return orders.map((order) => {
      if (!isValid(order.optionPrefix)) {
        return order;
      }
      if (order.option.indexOf(order.optionPrefix) === 0) {
        return order;
      }
      return {
        ...order,
        option: `${order.optionPrefix}${order.option}`,
      };
    });
  };

  const applyPlatformNameToOption = (orders: OrderXX[]) => {
    if (!oms.user.setting?.applyPlatformNameToOption?.enabled) {
      return orders;
    }

    return orders.map((order) => {
      if (isValid(order?.partnerId)) {
        const partnerName = oms.partner.partners.find(
          (partner) => partner._id === order.partnerId
        )?.name;

        const newOption = partnerName + '/' + order.option;

        return { ...order, option: newOption };
      } else if (isValid(order?.marketName)) {
        const newOption = order?.marketName + '/' + order.option;

        return { ...order, option: newOption };
      }

      return order;
    });
  };

  const applyCustomMultiplier = (orders: OrderXX[]) => {
    const customMultiplierExpression =
      oms.user.setting?.multiplierExpression || '';

    if (!isValid(customMultiplierExpression)) {
      return orders;
    }

    return orders.map((order) => {
      return {
        ...order,
        option: order.option.replace(
          /x(\d+)/g,
          `${customMultiplierExpression}$1`
        ),
      };
    });
  };

  return {
    addPlatformName,
    applyJSLogic,
    applyExcelFuntion: applyExcelFunction,
    addSupplierName,
    applyPrefixToOption,
    applyPlatformNameToOption,
    sortOrders,
    applyMultiplierToQuantity,
    convertSpecificCompositionsExpression,
    addDefaultShippingCompany,
    applyCustomMultiplier,
    formatOrders,
    addFixedColumnValue,
    applyMergedCellValues,
    applyFieldValueToAnother,
    addConditionalColumnValues,
    getOptionFromData,
  };
}

export default useApplyCustomSettings;
