import { ReactNode } from 'react';

interface Props<Case extends string | number> {
  caseBy: Partial<Record<Case, ReactNode | null>>;
  value: Case;
  defaultComponent?: ReactNode | null;
}

export function SwitchCase<Case extends string | number>({
  value,
  caseBy,
  defaultComponent: defaultComponent = null,
}: Props<Case>) {
  if (value == null) {
    return defaultComponent;
  }

  return caseBy[value] ?? defaultComponent;
}
