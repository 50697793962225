import { removeSpaces } from './utils';

export const commonColumns = [
  'productName',
  'option',
  'quantity',
  'optionCode',
  'name',
  'contactNumber',
  'phoneNumber',
  'postCode',
  'address',
  'deliveryMessage',
  'shippingCompany',
  'shippingNumber',
  'uniqueCode',
  'originFile',
  'orderNumber',
  'price',
  'platformName',
  'buyerName',
  'buyerContactNumber',
  'buyerPhoneNumber',
];

export const headerTranslationMap = {
  productName: '상품명',
  name: '받는분',
  contactNumber: '받는분휴대폰번호',
  phoneNumber: '받는분전화번호',
  postCode: '우편번호',
  address: '받는분주소',
  deliveryMessage: '배송메시지',
  shippingCompany: '택배사',
  shippingNumber: '운송장번호',
  uniqueCode: '스윕고유번호',
  option: '상품옵션',
  quantity: '개수',
  note: '비고',
  etc: '비고',
  originFile: '기존파일이름',
  orderNumber: '주문번호',
  productCode: '상품코드',
  platformCode: '플랫폼번호',
  price: '가격',
  senderName: '보내는분이름',
  senderPhoneNumber: '보내는분전화번호',
  senderAddress: '보내는분주소',
  buyerName: '주문자이름',
  buyerContactNumber: '주문자휴대폰번호',
  buyerPhoneNumber: '주문자전화번호',
  platformName: '판매처',
};

export const removeSpacesFromMappingArray = (mappingArray: {
  [key: string]: string[];
}) => {
  const newMappingArray: {
    [key: string]: string[];
  } = {};
  for (const [key, values] of Object.entries(mappingArray)) {
    newMappingArray[key] = values.map(removeSpaces);
  }
  return newMappingArray;
};

export const shippingHeaderTranslationMap = removeSpacesFromMappingArray({
  shippingCompany: ['택배사', '택배회사', '배송업체'],
  shippingNumber: ['운송장번호', '송장번호', '택배사번호'],
  uniqueCode: ['스윕고유번호'],
  orderNumber: ['주문번호', '주문아이디'],
});

export const columnMappingArray = removeSpacesFromMappingArray({
  productName: ['상품명', '상품정보', '품명', '품목명', '제품명', '주문상품'],
  option: ['상품옵션', '옵션', '필수선택정보', '옵션명'],
  quantity: ['개수', '수량', '주문품목 수량', '판매수량'],
  name: [
    '고객명',
    '수령인',
    '수령인명',
    '수령자',
    '수취인',
    '수취인명',
    '받는분성명',
    '수취인이름',
    '받는사람',
  ],
  contactNumber: [
    '연락처',
    '휴대폰번호',
    '핸드폰번호',
    '핸드폰',
    '수령인전화',
    '수령인연락처',
    '수령인핸드폰',
    '전화번호',
    '휴대전화',
    '받는분전화번호',
    '받는분기타연락처',
  ],
  postCode: ['우편번호'],
  address: [
    '주소',
    '수령인주소',
    '주소지',
    '배송지주소',
    '받는분주소(전체,분할)',
  ],
  deliveryMessage: ['배송메시지', '배송메세지', '배송시문구'],
  shippingCompany: ['택배사', '택배회사'],
  shippingNumber: ['운송장번호', '송장번호'],
  note: ['기타', '비고', '특이사항'],
  uniqueCode: ['고유번호', '스윕고유번호'],
  orderNumber: ['주문번호', '고유코드', '고유번호', '주문아이디'],
  productCode: ['상품번호', '마스터상품코드', '상품코드'],
  optionNumber: ['옵션주문번호'],
  platformCode: ['사방넷번호', '사방넷주문번호'],
  price: ['가격', '주문금액', '판매가'],
});

export const priorityInclusiveMappingArray = removeSpacesFromMappingArray({
  productName: ['상품정보', '품명'],
  option: ['옵션'],
  quantity: ['개수', '수량'],
  contactNumber: ['연락처', '핸드폰', '휴대폰', '휴대폰번호'],
  telephoneNumber: ['전화'],
  postCode: ['우편번호'],
  address: ['주소'],
  deliveryMessage: ['메시지', '메세지', '문구', '메모', '요청사항'],
  shippingNumber: ['송장번호'],
  note: ['기타', '비고', '특이사항', '특기사항'],
  productCode: ['상품번호', '상품코드'],
  platformCode: ['사방넷', '플레이오토', '플랫폼'],
  price: ['주문금액'],
});

export const inclusiveMappingArray = removeSpacesFromMappingArray({
  productName: ['상품정보', '품명'],
  name: [
    '고객명',
    '수령인',
    '수령자',
    '수취인',
    '주문자',
    '받는분',
    '성명',
    '인명',
    '이름',
    '구매자',
  ],
  quantity: ['주문갯수'],
  contactNumber: ['주문자전화', '주문자핸드폰', '주문자휴대폰'],
  address: ['배송지'],
  shippingCompany: ['택배사', '배송사명'],
  orderNumber: ['주문번호', '주문코드', '주문아이디'],
  price: ['가격'],
});

// 쇼핑몰별 +
// 쇼핑몰 주문 수집용
export const shoppingMallTemps: {
  mallName: string;
  headers: (string | null)[];
  realHeaders?: (string | null)[];
  headersForShipping?: string[];
  headerTranslationMap: { [key: string]: string };
}[] = [
  {
    mallName: '운송장등록-스윕',
    headers: ['스윕고유번호', '운송장번호', '택배사'],
    headerTranslationMap: {
      uniqueCode: '스윕고유번호',
      shippingNumber: '운송장번호',
      shippingCompany: '택배사',
    },
  },
  {
    mallName: '알리-운송장번호',
    headers: ['쇼핑몰', '상세주문번호', '운송장번호', '택배사', '비고'],
    headerTranslationMap: {
      shoppingMall: '쇼핑몰',
      orderNumber: '상세주문번호',
      shippingNumber: '운송장번호',
      shippingCompany: '택배사',
      description: '비고',
    },
  },
  {
    mallName: '주문수집',
    headers: [
      '주문시간',
      '결제시간',
      '주문상태',
      '상품명',
      '상품옵션',
      '상품관리코드',
      '수량',
      '수령인',
      '휴대폰번호',
      '전화번호',
      '주소',
      '우편번호',
      '배송요청사항',
      '주문번호',
      '상세주문번호',
      '정산예정금액',
      '배송비',
      '배송업체',
      '상품코드(쇼핑몰)',
      '쇼핑몰',
      '주문자id',
      '운송장번호',
      '스윕고유번호',
    ],
    headerTranslationMap: {
      orderDate: '주문시간',
      orderStatus: '주문상태',
      paymentDate: '결제시간',
      productName: '상품명',
      option: '상품옵션',
      optionCode: '상품관리코드',
      quantity: '수량',
      name: '수령인',
      contactNumber: '휴대폰번호',
      telephoneNumber: '전화번호',
      address: '주소',
      postCode: '우편번호',
      deliveryMessage: '배송요청사항',
      storeName: '스토어이름',
      orderNumber: '주문번호',
      productCode: '상품코드(쇼핑몰)',
      marketName: '쇼핑몰',
      shippingCompany: '배송업체',
      childOrderNumber: '상세주문번호',
      price: '정산예정금액',
      shippingPrice: '배송비',
      buyerId: '주문자id',
      shippingNumber: '운송장번호',
      uniqueCode: '스윕고유번호',
    },
  },
  {
    mallName: '쿠팡',
    headers: [
      '번호',
      '묶음배송번호',
      '주문번호',
      '택배사',
      '운송장번호',
      '분리배송 Y/N',
      '분리배송 출고예정일',
      '주문시 출고예정일',
      '출고일(발송일)',
      '주문일',
      '등록상품명',
      '등록옵션명',
      '노출상품명(옵션명)',
      '노출상품ID',
      '옵션ID',
      '최초등록등록상품명/옵션명',
      '업체상품코드',
      '바코드',
      '결제액',
      '배송비구분',
      '배송비',
      '도서산간 추가배송비',
      '구매수(수량)',
      '옵션판매가(판매단가)',
      '구매자',
      '구매자전화번호',
      '수취인이름',
      '수취인전화번호',
      '우편번호',
      '수취인 주소',
      '배송메세지',
      '상품별 추가메시지',
      '주문자 추가메시지',
      '배송완료일',
      '구매확정일자',
      '개인통관번호(PCCC)',
      '통관용수취인전화번호',
      '기타',
      '결제위치',
      '배송유형',
    ],
    headerTranslationMap: {
      번호: '번호',
      묶음배송번호: '묶음배송번호',
      orderNumber: '주문번호',
      shippingCompany: '택배사',
      shippingNumber: '운송장번호',
      '분리배송 Y/N': '분리배송 Y/N',
      '분리배송 출고예정일': '분리배송 출고예정일',
      '주문시 출고예정일': '주문시 출고예정일',
      '출고일(발송일)': '출고일(발송일)',
      주문일: '주문일',
      productName: '등록상품명',
      option: '등록옵션명',
      '노출상품명(옵션명)': '노출상품명(옵션명)',
      노출상품ID: '노출상품ID',
      옵션ID: '옵션ID',
      '최초등록등록상품명/옵션명': '최초등록등록상품명/옵션명',
      productCode: '업체상품코드',
      바코드: '바코드',
      결제액: '결제액',
      배송비구분: '배송비구분',
      배송비: '배송비',
      '도서산간 추가배송비': '도서산간 추가배송비',
      quantity: '구매수(수량)',
      '옵션판매가(판매단가)': '옵션판매가(판매단가)',
      구매자: '구매자',
      telephoneNumber: '구매자전화번호',
      name: '수취인이름',
      수취인전화번호: '',
      postCode: '우편번호',
      address: '수취인 주소',
      deliveryMessage: '배송메세지',
      '상품별 추가메시지': '상품별 추가메시지',
      '주문자 추가메시지': '주문자 추가메시지',
      배송완료일: '배송완료일',
      구매확정일자: '구매확정일자',
      '개인통관번호(PCCC)': '개인통관번호(PCCC)',
      통관용수취인전화번호: '통관용수취인전화번호',
      note: '기타',
      결제위치: '결제위치',
      배송유형: '배송유형',
      contactNumber: '수취인전화번호',
    },
  },
  {
    mallName: '11번가',
    headers: [
      '번호',
      '주문상태',
      '주문번호',
      '주문순번',
      '상품명',
      '옵션',
      '수량',
      '주문금액',
      '수취인',
      '배송방법',
      '송장번호',
      '배송비',
      '도서산간 배송비',
      '휴대폰번호',
      '전화번호',
      '우편번호',
      '주소',
      '배송메시지',
      '판매자 상품코드',
      '판매단가',
      '옵션가',
    ],
    headerTranslationMap: {
      번호: '번호',
      주문상태: '주문상태',
      orderNumber: '주문번호',
      주문순번: '주문순번',
      productName: '상품명',
      option: '옵션',
      quantity: '수량',
      price: '주문금액',
      name: '수취인',
      배송방법: '배송방법',
      shippingNumber: '송장번호',
      배송비: '배송비',
      '도서산간 배송비': '도서산간 배송비',
      contactNumber: '휴대폰번호',
      telephoneNumber: '전화번호',
      postCode: '우편번호',
      address: '주소',
      deliveryMessage: '배송메시지',
      productCode: '판매자 상품코드',
      판매단가: '판매단가',
      옵션가: '옵션가',
    },
  },
  {
    mallName: '티몬',
    headers: [
      '배송번호',
      '딜번호',
      '주문번호',
      '택배사',
      '운송장번호',
      '발송예정일',
      '출고마감일',
      '지연발송예정일',
      '지연사유',
      '지연사유상세',
      '발송지연 최종 안내일',
      '운송장등록시점',
      '주문자명',
      '아이디',
      '주문자 연락처',
      '딜명',
      '옵션명',
      '옵션가',
      '구매수량',
      '총 주문금액',
      '결제완료일',
      '추가문구',
      '수취인명',
      '개인통관고유부호',
      '수취인연락처',
      '수취인주소',
      '수취인우편번호',
      '배송요청메모',
      '배송상태',
      '출고일',
      '배송완료일',
      '파트너 CODE1',
      '파트너 CODE2',
      '파트너 CODE3',
      '파트너 CODE4',
      '파트너 CODE5',
      '추가송장',
      '옵션번호',
      '배송권역구분',
      '도서산간배송비',
      '도서산간선결제',
      '배송비조건',
      '고객부담배송비',
      '배송유형',
      '주문시 배송방법',
      '상품관리코드',
    ],
    headerTranslationMap: {
      배송번호: '배송번호',
      딜번호: '딜번호',
      orderNumber: '주문번호',
      shippingCompany: '택배사',
      shippingNumber: '운송장번호',
      발송예정일: '발송예정일',
      출고마감일: '출고마감일',
      지연발송예정일: '지연발송예정일',
      지연사유: '지연사유',
      지연사유상세: '지연사유상세',
      '발송지연 최종 안내일': '발송지연 최종 안내일',
      운송장등록시점: '운송장등록시점',
      주문자명: '주문자명',
      아이디: '아이디',
      contactNumber: '수취인연락처',
      productName: '딜명',
      option: '옵션명',
      옵션가: '옵션가',
      quantity: '구매수량',
      price: '총 주문금액',
      결제완료일: '결제완료일',
      deliveryMessage: '추가문구',
      name: '수취인명',
      개인통관고유부호: '개인통관고유부호',
      수취인연락처: '',
      address: '수취인주소',
      postCode: '수취인우편번호',
      배송요청메모: '배송요청메모',
      배송상태: '배송상태',
      출고일: '출고일',
      배송완료일: '배송완료일',
      '파트너 CODE1': '파트너 CODE1',
      '파트너 CODE2': '파트너 CODE2',
      '파트너 CODE3': '파트너 CODE3',
      '파트너 CODE4': '파트너 CODE4',
      '파트너 CODE5': '파트너 CODE5',
      추가송장: '추가송장',
      옵션번호: '옵션번호',
      배송권역구분: '배송권역구분',
      도서산간배송비: '도서산간배송비',
      도서산간선결제: '도서산간선결제',
      배송비조건: '배송비조건',
      고객부담배송비: '고객부담배송비',
      배송유형: '배송유형',
      '주문시 배송방법': '주문시 배송방법',
      상품관리코드: '상품관리코드',
    },
  },
  {
    mallName: '돌쇠네',
    headers: [
      '상품명',
      '주문수량',
      '수령인명',
      '우편번호',
      '주소',
      '수령인연락처',
      '배송시요청사항',
      '택배사**',
      '배송번호',
      '(공백)',
      '주문번호',
      '상품코드',
      '공급사배송비',
      '공급가',
      '판매사',
      '주문자명',
      '주문자연락처',
    ],
    headerTranslationMap: {
      productName: '상품명',
      quantity: '주문수량',
      name: '수령인명',
      postCode: '우편번호',
      address: '주소',
      contactNumber: '수령인연락처',
      deliveryMessage: '배송시요청사항',
      shippingCompany: '택배사**',
      shippingNumber: '배송번호',
      '(공백)': '(공백)',
      orderNumber: '주문번호',
      productCode: '상품코드',
      공급사배송비: '공급사배송비',
      공급가: '공급가',
      판매사: '판매사',
      주문자명: '주문자명',
      주문자연락처: '주문자연락처',
    },
  },
  {
    mallName: '돌쇠네농산물',
    headers: [
      '주문아이디',
      '상품아이디',
      '합배송아이디',
      '판매자 상품코드',
      '상품명',
      '옵션',
      '수량',
      '상품가격',
      '배송비',
      '추가지원금',
      '쿠폰할인금',
      '정산대상금액(수수료 제외)',
      '주소',
      '우편번호',
      '배송메세지',
      '수령 방법',
      '수령인',
      '수령인 연락처',
      '택배사',
      '주문 시점',
      '운송장번호',
      '고유 코드',
    ],
    headerTranslationMap: {
      orderNumber: '주문아이디',
      상품아이디: '상품아이디',
      합배송아이디: '합배송아이디',
      productCode: '판매자 상품코드',
      productName: '상품명',
      option: '옵션',
      quantity: '수량',
      price: '상품가격',
      배송비: '배송비',
      추가지원금: '추가지원금',
      쿠폰할인금: '쿠폰할인금',
      '정산대상금액(수수료 제외)': '정산대상금액(수수료 제외)',
      address: '주소',
      postCode: '우편번호',
      deliveryMessage: '배송메세지',
      '수령 방법': '수령 방법',
      name: '수령인',
      contactNumber: '수령인 연락처',
      shippingCompany: '택배사',
      '주문 시점': '주문 시점',
      shippingNumber: '운송장번호',
      '고유 코드': '고유 코드',
    },
  },
  {
    mallName: '토스',
    headers: [
      '주문일자',
      '주문번호',
      '주문상품번호',
      '주문자명',
      '전화번호',
      '수령인',
      '주소',
      '우편번호',
      '수령인전화번호',
      '요청사항',
      '상품명',
      '상품ID',
      '옵션',
      '옵션ID',
      '수량',
      '거래금액',
      '배송비',
      '주문상태',
      '택배사코드',
      '송장번호',
    ],
    headerTranslationMap: {
      주문일자: '주문일자',
      orderNumber: '주문번호',
      productCode: '주문상품번호',
      주문자명: '주문자명',
      contactNumber: '전화번호',
      name: '수령인',
      address: '주소',
      postCode: '우편번호',
      telephoneNumber: '수령인전화번호',
      deliveryMessage: '요청사항',
      productName: '상품명',
      상품ID: '상품ID',
      option: '옵션',
      옵션ID: '옵션ID',
      quantity: '수량',
      거래금액: '거래금액',
      배송비: '배송비',
      주문상태: '주문상태',
      shippingCompany: '택배사코드',
      shippingNumber: '송장번호',
    },
  },
  {
    mallName: '롯데ON',
    headers: [
      '주문번호',
      '주문순번',
      '주문상태',
      '발송예정일',
      '도착예정일',
      '발송지연여부',
      '배송희망일/배송예약일',
      '발송약속일',
      '합배송그룹번호',
      '합배송가능여부',
      '배송상품유형',
      '배송예약구분',
      '판매유형',
      '상품명',
      '상품번호',
      '옵션명',
      '단품번호',
      '판매단가',
      '수량',
      '입력형추가옵션',
      '배송메세지',
      '판매자 내부 상품번호',
      '판매자 내부 단품번호',
      '선물여부',
      '선물포장요청여부',
      '명절상품여부',
      '해외배송여부',
      '개인통관번호',
      '배송수단',
      '배송사',
      '송장번호',
      '운송장규칙 위배사유',
      '송장개수',
      '주문접수일시',
      '출고지시일시',
      '상품준비일시',
      '발송완료일시',
      '배송완료일시',
      '기배송',
      '우편번호',
      '우편번호순번',
      '수취인 배송지',
      '수취인명',
      '수취인 휴대폰번호',
      '주문자명',
      '주문자 휴대폰번호',
      '주문자ID',
      '판매가',
      '롯데온부담 할인금액',
      '판매자부담 할인금액',
      '판매자부담 지원할인 프로그램 할인금액',
      '상품별 총 할인금액',
      '실 결제금액',
      '배송비',
      '도서산간 추가여부',
      '제주지역 추가여부',
      '하위거래처',
    ],
    headerTranslationMap: {
      orderNumber: '주문번호',
      주문순번: '주문순번',
      주문상태: '주문상태',
      발송예정일: '발송예정일',
      도착예정일: '도착예정일',
      발송지연여부: '발송지연여부',
      '배송희망일/배송예약일': '배송희망일/배송예약일',
      발송약속일: '발송약속일',
      합배송그룹번호: '합배송그룹번호',
      합배송가능여부: '합배송가능여부',
      배송상품유형: '배송상품유형',
      배송예약구분: '배송예약구분',
      판매유형: '판매유형',
      productName: '상품명',
      productCode: '상품번호',
      option: '옵션명',
      단품번호: '단품번호',
      판매단가: '판매단가',
      quantity: '수량',
      입력형추가옵션: '입력형추가옵션',
      deliveryMessage: '배송메세지',
      '판매자 내부 상품번호': '판매자 내부 상품번호',
      '판매자 내부 단품번호': '판매자 내부 단품번호',
      선물여부: '선물여부',
      선물포장요청여부: '선물포장요청여부',
      명절상품여부: '명절상품여부',
      해외배송여부: '해외배송여부',
      개인통관번호: '개인통관번호',
      배송수단: '배송수단',
      shippingCompany: '배송사',
      shippingNumber: '송장번호',
      '운송장규칙 위배사유': '운송장규칙 위배사유',
      송장개수: '송장개수',
      주문접수일시: '주문접수일시',
      출고지시일시: '출고지시일시',
      상품준비일시: '상품준비일시',
      발송완료일시: '발송완료일시',
      배송완료일시: '배송완료일시',
      기배송: '기배송',
      postCode: '우편번호',
      우편번호순번: '우편번호순번',
      address: '수취인 배송지',
      name: '수취인명',
      contactNumber: '수취인 휴대폰번호',
      주문자명: '주문자명',
      '주문자 휴대폰번호': '주문자 휴대폰번호',
      주문자ID: '주문자ID',
      판매가: '판매가',
      '롯데온부담 할인금액': '롯데온부담 할인금액',
      '판매자부담 할인금액': '판매자부담 할인금액',
      '판매자부담 지원할인 프로그램 할인금액':
        '판매자부담 지원할인 프로그램 할인금액',
      '상품별 총 할인금액': '상품별 총 할인금액',
      '실 결제금액': '실 결제금액',
      배송비: '배송비',
      '도서산간 추가여부': '도서산간 추가여부',
      '제주지역 추가여부': '제주지역 추가여부',
      하위거래처: '하위거래처',
    },
  },
  {
    mallName: '네이버 스마트스토어',
    headers: [
      '상품주문번호',
      '주문번호',
      '배송속성',
      '풀필먼트사(주문 기준)',
      '배송방법(구매자 요청)',
      '배송방법',
      '택배사',
      '송장번호',
      '발송일',
      '판매채널',
      '구매자명',
      '구매자ID',
      '수취인명',
      '주문상태',
      '주문세부상태',
      '결제위치',
      '결제일',
      '상품번호',
      '상품명',
      '상품종류',
      '반품안심케어',
      '옵션정보',
      '옵션관리코드',
      '수량',
      '옵션가격',
      '상품가격',
      '상품별 할인액',
      '판매자 부담 할인액',
      '상품별 총 주문금액',
      '사은품',
      '발주확인일',
      '발송기한',
      '발송처리일',
      '송장출력일',
      '배송비 형태',
      '배송비 묶음번호',
      '배송비 유형',
      '배송비 합계',
      '제주/도서 추가배송비',
      '배송비 할인액',
      '판매자 상품코드',
      '판매자 내부코드1',
      '판매자 내부코드2',
      '수취인연락처1',
      '수취인연락처2',
      '통합배송지',
      '기본배송지',
      '상세배송지',
      '구매자연락처',
      '우편번호',
      '배송메세지',
      '출고지',
      '결제수단',
      '수수료 과금구분',
      '수수료결제방식',
      '네이버페이 주문관리 수수료',
      '매출연동 수수료',
      '정산예정금액',
      '매출연동수수료 유입경로',
      '개인통관고유부호',
      '주문일시',
      '구독신청회차',
      '구독진행회차',
      '배송희망일',
    ],
    headersForShipping: ['상품주문번호', '배송방법', '택배사', '송장번호'],
    headerTranslationMap: {
      상품주문번호: '상품주문번호',
      orderNumber: '주문번호',
      배송속성: '배송속성',
      '풀필먼트사(주문 기준)': '풀필먼트사(주문 기준)',
      '배송방법(구매자 요청)': '배송방법(구매자 요청)',
      배송방법: '배송방법',
      shippingCompany: '택배사',
      shippingNumber: '송장번호',
      발송일: '발송일',
      판매채널: '판매채널',
      구매자명: '구매자명',
      구매자ID: '구매자ID',
      name: '수취인명',
      주문상태: '주문상태',
      주문세부상태: '주문세부상태',
      결제위치: '결제위치',
      결제일: '결제일',
      productCode: '상품번호',
      productName: '상품명',
      상품종류: '상품종류',
      반품안심케어: '반품안심케어',
      option: '옵션정보',
      옵션관리코드: '옵션관리코드',
      quantity: '수량',
      옵션가격: '옵션가격',
      상품가격: '상품가격',
      '상품별 할인액': '상품별 할인액',
      '판매자 부담 할인액': '판매자 부담 할인액',
      price: '상품별 총 주문금액',
      사은품: '사은품',
      발주확인일: '발주확인일',
      발송기한: '발송기한',
      발송처리일: '발송처리일',
      송장출력일: '송장출력일',
      '배송비 형태': '배송비 형태',
      '배송비 묶음번호': '배송비 묶음번호',
      '배송비 유형': '배송비 유형',
      '배송비 합계': '배송비 합계',
      '제주/도서 추가배송비': '제주/도서 추가배송비',
      '배송비 할인액': '배송비 할인액',
      '판매자 상품코드': '판매자 상품코드',
      '판매자 내부코드1': '판매자 내부코드1',
      '판매자 내부코드2': '판매자 내부코드2',
      contactNumber: '수취인연락처1',
      수취인연락처2: '수취인연락처2',
      address: '통합배송지',
      기본배송지: '기본배송지',
      상세배송지: '상세배송지',
      구매자연락처: '구매자연락처',
      postCode: '우편번호',
      deliveryMessage: '배송메세지',
      출고지: '출고지',
      결제수단: '결제수단',
      '수수료 과금구분': '수수료 과금구분',
      수수료결제방식: '수수료결제방식',
      '네이버페이 주문관리 수수료': '네이버페이 주문관리 수수료',
      '매출연동 수수료': '매출연동 수수료',
      정산예정금액: '정산예정금액',
      '매출연동수수료 유입경로': '매출연동수수료 유입경로',
      개인통관고유부호: '개인통관고유부호',
      주문일시: '주문일시',
      구독신청회차: '구독신청회차',
      구독진행회차: '구독진행회차',
      배송희망일: '배송희망일',
    },
  },
  {
    mallName: '카카오',
    headers: [
      '결제번호',
      '배송지/수신자정보 입력일',
      '주문상태',
      '주문번호',
      '채널상품번호',
      '상품명',
      '옵션',
      '수량',
      '배송방법',
      '택배사코드',
      '송장번호',
      '수령인명',
      '수령인연락처1',
      '하이픈포함 수령인연락처1',
      '수령인연락처2',
      '하이픈포함 수령인연락처2',
      '배송지주소',
      '우편번호',
      '배송메세지',
      '배송예정일',
      '주문일',
      '상품금액',
      '옵션금액',
      '판매자할인금액',
      '판매자쿠폰할인금액',
      '정산기준금액',
      '기본수수료',
      '노출추가수수료',
      '추천리워드수수료',
      '수수료할인금액',
      '채널',
      '브랜드',
      '모델명',
      '판매자상품번호',
      '옵션코드',
      '최초배송비번호',
      '배송비지불방법',
      '기본배송비 유형',
      '기본배송비 금액',
      '도서산간 추가 배송비 금액',
      '유입경로',
      '톡딜여부',
      '상품유형',
      'biz판매여부',
      '이메일 정보',
    ],
    headerTranslationMap: {
      결제번호: '결제번호',
      '배송지/수신자정보 입력일': '배송지/수신자정보 입력일',
      주문상태: '주문상태',
      orderNumber: '주문번호',
      채널상품번호: '채널상품번호',
      productName: '상품명',
      option: '옵션',
      quantity: '수량',
      배송방법: '배송방법',
      택배사코드: '택배사코드',
      shippingNumber: '송장번호',
      name: '수령인명',
      contactNumber: '수령인연락처1',
      '하이픈포함 수령인연락처1': '하이픈포함 수령인연락처1',
      수령인연락처2: '수령인연락처2',
      '하이픈포함 수령인연락처2': '하이픈포함 수령인연락처2',
      address: '배송지주소',
      postCode: '우편번호',
      deliveryMessage: '배송메세지',
      배송예정일: '배송예정일',
      주문일: '주문일',
      상품금액: '상품금액',
      옵션금액: '옵션금액',
      판매자할인금액: '판매자할인금액',
      판매자쿠폰할인금액: '판매자쿠폰할인금액',
      정산기준금액: '정산기준금액',
      기본수수료: '기본수수료',
      노출추가수수료: '노출추가수수료',
      추천리워드수수료: '추천리워드수수료',
      수수료할인금액: '수수료할인금액',
      채널: '채널',
      브랜드: '브랜드',
      모델명: '모델명',
      판매자상품번호: '판매자상품번호',
      옵션코드: '옵션코드',
      최초배송비번호: '최초배송비번호',
      배송비지불방법: '배송비지불방법',
      '기본배송비 유형': '기본배송비 유형',
      '기본배송비 금액': '기본배송비 금액',
      '도서산간 추가 배송비 금액': '도서산간 추가 배송비 금액',
      유입경로: '유입경로',
      톡딜여부: '톡딜여부',
      상품유형: '상품유형',
      biz판매여부: 'biz판매여부',
      '이메일 정보': '이메일 정보',
    },
  },
  {
    mallName: '위메프',
    headers: [
      '배송번호',
      '주문번호',
      '옵션주문번호',
      '주문일',
      '주문상태',
      '발송기한',
      '구매번호',
      '상품번호',
      '상품명',
      '옵션',
      '수량',
      '상품금액',
      '총상품금액',
      '판매대행수수료',
      '위메프 지원할인',
      '위메프 제휴채널 수수료',
      '구매자',
      '구매자 휴대폰',
      '받는사람',
      '받는사람 연락처',
      '배송방법',
      '택배사',
      '송장번호',
      '배송예정일',
      '우편번호',
      '주소',
      '배송메세지',
      '배송비지급',
      '배송비',
      '도서산간 배송비',
      '결제일',
      '주문확인일',
      '2차발송기한',
      '업체상품코드',
      '업체옵션코드',
      '개인통관고유번호',
    ],
    headerTranslationMap: {
      배송번호: '배송번호',
      orderNumber: '주문번호',
      optionNumber: '옵션주문번호',
      주문일: '주문일',
      주문상태: '주문상태',
      발송기한: '발송기한',
      구매번호: '구매번호',
      productCode: '상품번호',
      productName: '상품명',
      option: '옵션',
      quantity: '수량',
      상품금액: '상품금액',
      총상품금액: '총상품금액',
      판매대행수수료: '판매대행수수료',
      '위메프 지원할인': '위메프 지원할인',
      '위메프 제휴채널 수수료': '위메프 제휴채널 수수료',
      구매자: '구매자',
      contactNumber: '받는사람 연락처',
      name: '받는사람',
      '받는사람 연락처': '',
      배송방법: '배송방법',
      shippingCompany: '택배사',
      shippingNumber: '송장번호',
      배송예정일: '배송예정일',
      postCode: '우편번호',
      address: '주소',
      deliveryMessage: '배송메세지',
      배송비지급: '배송비지급',
      배송비: '배송비',
      '도서산간 배송비': '도서산간 배송비',
      결제일: '결제일',
      주문확인일: '주문확인일',
      '2차발송기한': '2차발송기한',
      업체상품코드: '업체상품코드',
      업체옵션코드: '업체옵션코드',
      개인통관고유번호: '개인통관고유번호',
    },
  },
  {
    mallName: '이베이',
    headers: [
      '주문옵션',
      '상품명',
      '수량',
      '수령인명',
      '수령인 휴대폰',
      '수령인 전화번호',
      '주소',
      '우편번호',
      '배송시 요구사항',
      '주문번호',
      '정산예정금액',
      '배송비 금액',
      '아이디',
      '상품번호',
      '택배사명(발송방법)',
      '송장번호',
      '주문일자(결제확인전)',
      '결제완료일',
      '주문확인일자',
      '구매자명',
      '구매자ID',
      '구매자 휴대폰',
      '판매방식',
      '주문종류',
      '발송정책',
      '발송마감일',
      '선물주문여부',
      'SKU번호 및 수량',
      '장바구니번호(결제번호)',
      '판매금액',
      '판매자 상세관리코드',
    ],
    headerTranslationMap: {
      option: '주문옵션',
      productName: '상품명',
      quantity: '수량',
      name: '수령인명',
      contactNumber: '수령인 휴대폰',
      telephoneNumber: '수령인 전화번호',
      address: '주소',
      postCode: '우편번호',
      orderNumber: '주문번호',
      productCode: '상품번호',
      shippingCompany: '택배사명(발송방법)',
      shippingNumber: '송장번호',
      주문확인일자: '주문확인일자',
      구매자명: '구매자명',
      구매자ID: '구매자ID',
      '구매자 휴대폰': '구매자 휴대폰',
      판매방식: '판매방식',
      주문종류: '주문종류',
      발송정책: '발송정책',
      발송마감일: '발송마감일',
      선물주문여부: '선물주문여부',
      'SKU번호 및 수량': 'SKU번호 및 수량',
      '장바구니번호(결제번호)': '장바구니번호(결제번호)',
      판매금액: '판매금액',
      optionCode: '판매자 상세관리코드',
      deliveryMessage: '배송시 요구사항',
      marketName: '아이디',
      orderDate: '주문일자(결제확인전)',
      paymentDate: '결제완료일',
      price: '정산예정금액',
      shippingPrice: '배송비 금액',
    },
  },
  {
    mallName: '지마켓',
    headers: [
      '아이디',
      '주문일자(결제확인전)',
      '주문번호',
      '수령인명',
      '구매자명',
      '발송정책',
      '선물주문여부',
      '선물수락일시',
      '설치주문여부',
      '설치예약일',
      '주문상태',
      '발송마감일',
      '택배사명(발송방법)',
      '송장번호',
      '상품번호',
      '상품명',
      '수량',
      '주문옵션',
      '추가구성',
      '사은품',
      '사은품 관리코드',
      '덤',
      '덤 관리코드',
      '판매단가',
      '판매금액',
      '판매자 관리코드',
      '판매자 상세관리코드',
      '수령인 휴대폰',
      '수령인 전화번호',
      '우편번호',
      '주소',
      '배송시 요구사항',
      '배송비',
      '배송비 금액',
      '배송번호',
      '배송지연사유',
      '수령인 통관정보',
      'SKU번호 및 수량',
      '구매자ID',
      '구매자 휴대폰',
      '구매자 전화번호',
      '판매방식',
      '주문종류',
      '장바구니번호(결제번호)',
      '결제완료일',
      '주문확인일자',
      '발송예정일',
      '정산예정금액',
      '서비스이용료',
      '판매자쿠폰할인',
      '구매쿠폰적용금액',
      '(옥션)우수회원할인',
      '복수구매할인',
      '스마일캐시적립',
      '제휴사명',
    ],
    headerTranslationMap: {
      아이디: '아이디',
      '주문일자(결제확인전)': '주문일자(결제확인전)',
      orderNumber: '주문번호',
      name: '수령인명',
      구매자명: '구매자명',
      발송정책: '발송정책',
      선물주문여부: '선물주문여부',
      선물수락일시: '선물수락일시',
      설치주문여부: '설치주문여부',
      설치예약일: '설치예약일',
      주문상태: '주문상태',
      발송마감일: '발송마감일',
      shippingCompany: '택배사명(발송방법)',
      shippingNumber: '송장번호',
      productCode: '상품번호',
      productName: '상품명',
      quantity: '수량',
      option: '주문옵션',
      추가구성: '추가구성',
      사은품: '사은품',
      '사은품 관리코드': '사은품 관리코드',
      덤: '덤',
      '덤 관리코드': '덤 관리코드',
      판매단가: '판매단가',
      판매금액: '판매금액',
      '판매자 관리코드': '판매자 관리코드',
      '판매자 상세관리코드': '판매자 상세관리코드',
      contactNumber: '수령인 휴대폰',
      telephoneNumber: '수령인 전화번호',
      postCode: '우편번호',
      address: '주소',
      '배송시 요구사항': '배송시 요구사항',
      배송비: '배송비',
      '배송비 금액': '배송비 금액',
      배송번호: '배송번호',
      배송지연사유: '배송지연사유',
      '수령인 통관정보': '수령인 통관정보',
      'SKU번호 및 수량': 'SKU번호 및 수량',
      구매자ID: '구매자ID',
      '구매자 휴대폰': '구매자 휴대폰',
      '구매자 전화번호': '구매자 전화번호',
      판매방식: '판매방식',
      주문종류: '주문종류',
      '장바구니번호(결제번호)': '장바구니번호(결제번호)',
      결제완료일: '결제완료일',
      주문확인일자: '주문확인일자',
      발송예정일: '발송예정일',
      정산예정금액: '정산예정금액',
      서비스이용료: '서비스이용료',
      판매자쿠폰할인: '판매자쿠폰할인',
      구매쿠폰적용금액: '구매쿠폰적용금액',
      '(옥션)우수회원할인': '(옥션)우수회원할인',
      복수구매할인: '복수구매할인',
      스마일캐시적립: '스마일캐시적립',
      제휴사명: '제휴사명',
    },
  },
  {
    mallName: '올웨이즈',
    headers: [
      '주문아이디',
      '상품아이디',
      '합배송아이디',
      '판매자 상품코드',
      '상품명',
      '옵션',
      '수량',
      '상품가격',
      '배송비',
      '추가지원금',
      '올웨이즈 부담 쿠폰할인금',
      '판매자 부담 쿠폰할인금',
      '쿠폰할인금',
      '정산대상금액(수수료 제외)',
      '주소',
      '우편번호',
      '배송메세지',
      '수령 방법',
      '수령인',
      '수령인 연락처',
      '택배사',
      '주문 시점',
      '운송장번호',
      '고유 코드',
    ],
    headerTranslationMap: {
      orderNumber: '주문아이디',
      상품아이디: '상품아이디',
      합배송아이디: '합배송아이디',
      productCode: '',
      productName: '상품명',
      option: '옵션',
      quantity: '수량',
      price: '상품가격',
      배송비: '배송비',
      추가지원금: '추가지원금',
      '올웨이즈 부담 쿠폰할인금': '올웨이즈 부담 쿠폰할인금',
      '판매자 부담 쿠폰할인금': '판매자 부담 쿠폰할인금',
      쿠폰할인금: '쿠폰할인금',
      '정산대상금액(수수료 제외)': '정산대상금액(수수료 제외)',
      address: '주소',
      postCode: '우편번호',
      deliveryMessage: '배송메세지',
      '수령 방법': '수령 방법',
      name: '수령인',
      contactNumber: '수령인 연락처',
      shippingCompany: '택배사',
      '주문 시점': '주문 시점',
      shippingNumber: '운송장번호',
      '고유 코드': '고유 코드',
      '판매자 상품코드': '판매자 상품코드',
    },
  },
  {
    mallName: '랭킹닭컴',
    headers: [
      null,
      '주문번호',
      '상세보기',
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      '성명',
      '핸드폰번호',
      '성명',
      '핸드폰번호',
      '우편번호',
      '주소',
      '배송메시지',
    ],
    realHeaders: [
      'No',
      '주문번호',
      '상세보기',
      '운송장번호',
      '택배사',
      '주문일',
      '납기일',
      '배송일',
      '주문확정',
      '상품번호',
      '상품명',
      '규격',
      '수량',
      '판매금액',
      '결제금액',
      '배송방법',
      '주문자성명',
      '주문자핸드폰번호',
      '수취인성명',
      '수취인핸드폰번호',
      '우편번호',
      '주소',
      '배송메시지',
    ],
    headerTranslationMap: {
      No: 'No',
      orderNumber: '주문번호',
      상세보기: '상세보기',
      shippingNumber: '운송장번호',
      shippingCompany: '택배사',
      주문일: '주문일',
      납기일: '납기일',
      배송일: '배송일',
      주문확정: '주문확정',
      상품번호: '상품번호',
      productName: '상품명',
      option: '규격',
      quantity: '수량',
      판매금액: '판매금액',
      결제금액: '결제금액',
      배송방법: '배송방법',
      주문자성명: '주문자성명',
      주문자핸드폰번호: '주문자핸드폰번호',
      name: '수취인성명',
      contactNumber: '수취인핸드폰번호',
      postCode: '우편번호',
      address: '주소',
      deliveryMessage: '배송메시지',
    },
  },
  {
    mallName: '웰스토리',
    headers: [
      '주문번호',
      '상품유형',
      '상품순번',
      '상품번호',
      '상품명',
      '옵션번호',
      '옵션명',
      '주문상세번호',
      '배송번호',
      '배송순번',
      '주문상태',
      '배송상태',
      '배송취소',
      '실수량',
      '과세구분',
      '판매단가',
      '매입단가',
      'VAT별도',
      '판매금액',
      '매입금액',
      'VAT별도',
      '할인금액',
      '택배사',
      '송장번호',
      '배송지연',
      '리드타임',
      '배송예정일',
      '지연사유',
      '지연메모',
      '지연\n예정완료일자',
      '표준카테고리',
      '주문형태',
      '사이트',
      '업체상품번호',
      '업체명',
      '출고지명',
      '주문자명',
      '수취인정보',
      '수취인정보',
      '수취인정보',
      '수취인정보',
      '수취인정보',
      '개인통관고유부호',
      '배송희망일',
      '최종배송업데이트',
      '배송형태',
      '배송상품구분',
      '주문확인처리일시',
      '상품준비중일시',
      '출하완료일시',
      '배송완료일시',
      '거래완료일시',
      '추가컬럼1',
      '추가컬럼2',
      '추가컬럼3',
      '외부주문번호',
      '외부상품번호',
    ],
    realHeaders: [
      '주문번호',
      '상품유형',
      '상품순번',
      '상품번호',
      '상품명',
      '옵션번호',
      '옵션명',
      '주문상세번호',
      '배송번호',
      '배송순번',
      '주문상태',
      '배송상태',
      '배송취소',
      '실수량',
      '과세구분',
      '판매단가',
      '매입단가',
      'VAT별도',
      '판매금액',
      '매입금액',
      'VAT별도',
      '할인금액',
      '택배사',
      '송장번호',
      '배송지연',
      '리드타임',
      '배송예정일',
      '지연사유',
      '지연메모',
      '지연 예정완료일자',
      '표준카테고리',
      '주문형태',
      '사이트',
      '업체상품번호',
      '업체명',
      '출고지명',
      '주문자명',
      '수취인명',
      '전화번호',
      '휴대전화번호',
      '주소',
      '배송메시지',
      '개인통관고유부호',
      '배송희망일',
      '최종배송업데이트',
      '배송형태',
      '배송상품구분',
      '주문확인처리일시',
      '상품준비중일시',
      '출하완료일시',
      '배송완료일시',
      '거래완료일시',
      '추가컬럼1',
      '추가컬럼2',
      '추가컬럼3',
      '외부주문번호',
      '외부상품번호',
    ],
    headerTranslationMap: {
      orderNumber: '주문번호',
      상품유형: '상품유형',
      상품순번: '상품순번',
      상품번호: '상품번호',
      productName: '상품명',
      옵션번호: '옵션번호',
      option: '옵션명',
      주문상세번호: '주문상세번호',
      배송번호: '배송번호',
      배송순번: '배송순번',
      주문상태: '주문상태',
      배송상태: '배송상태',
      배송취소: '배송취소',
      quantity: '실수량',
      과세구분: '과세구분',
      판매단가: '판매단가',
      매입단가: '매입단가',
      VAT별도: 'VAT별도',
      판매금액: '판매금액',
      매입금액: '매입금액',
      할인금액: '할인금액',
      shippingCompany: '택배사',
      shippingNumber: '송장번호',
      배송지연: '배송지연',
      리드타임: '리드타임',
      배송예정일: '배송예정일',
      지연사유: '지연사유',
      지연메모: '지연메모',
      '지연 예정완료일자': '지연 예정완료일자',
      표준카테고리: '표준카테고리',
      주문형태: '주문형태',
      사이트: '사이트',
      업체상품번호: '업체상품번호',
      업체명: '업체명',
      출고지명: '출고지명',
      주문자명: '주문자명',
      name: '수취인명',
      telephoneNumber: '전화번호',
      contactNumber: '휴대전화번호',
      address: '주소',
      deliveryMessage: '배송메시지',
      개인통관고유부호: '개인통관고유부호',
      배송희망일: '배송희망일',
      최종배송업데이트: '최종배송업데이트',
      배송형태: '배송형태',
      배송상품구분: '배송상품구분',
      주문확인처리일시: '주문확인처리일시',
      상품준비중일시: '상품준비중일시',
      출하완료일시: '출하완료일시',
      배송완료일시: '배송완료일시',
      거래완료일시: '거래완료일시',
      추가컬럼1: '추가컬럼1',
      추가컬럼2: '추가컬럼2',
      추가컬럼3: '추가컬럼3',
      외부주문번호: '외부주문번호',
      외부상품번호: '외부상품번호',
    },
  },
  {
    mallName: '현대이지웰',
    headers: [
      '주문번호',
      '복지관주문번호',
      '상세주문번호',
      '판매채널',
      '온라인/오프라인 구분',
      '주문자',
      '마켓유저키',
      '복지관유저키',
      '주문자연락처',
      '주문상태',
      '관리분류',
      '상품번호',
      '상품명',
      '옵션',
      '으뜸상품 여부',
      '정상판매가',
      '매입단가',
      '판매가',
      '판매수량',
      '쿠폰할인',
      '배송비',
      '구매금액',
      '현금영수증발행여부',
      '상점코드',
      '상점명',
      '주문일',
      '주문시간',
      '배송시 요청사항',
      '택배사',
      '송장번호',
      '배송완료일',
      '정산배송완료일',
      '취소일',
      '정산취소일',
      '발송소요기간',
      '수령자',
      '전화번호',
      '우편번호',
      '주소',
      '과면세',
      '사원번호',
      '희망배송일',
      '취소처리자',
      '취소사유',
      '취소 상세사유',
      '라이브중구매여부',
      '연동코드',
    ],
    headerTranslationMap: {
      orderNumber: '주문번호',
      복지관주문번호: '복지관주문번호',
      상세주문번호: '상세주문번호',
      판매채널: '판매채널',
      '온라인/오프라인 구분': '온라인/오프라인 구분',
      주문자: '주문자',
      마켓유저키: '마켓유저키',
      복지관유저키: '복지관유저키',
      주문자연락처: '주문자연락처',
      주문상태: '주문상태',
      관리분류: '관리분류',
      productCode: '상품번호',
      productName: '상품명',
      option: '옵션',
      '으뜸상품 여부': '으뜸상품 여부',
      정상판매가: '정상판매가',
      매입단가: '매입단가',
      price: '판매가',
      quantity: '판매수량',
      쿠폰할인: '쿠폰할인',
      배송비: '배송비',
      구매금액: '구매금액',
      현금영수증발행여부: '현금영수증발행여부',
      상점코드: '상점코드',
      상점명: '상점명',
      주문일: '주문일',
      주문시간: '주문시간',
      deliveryMessage: '배송시 요청사항',
      shippingCompany: '택배사',
      shippingNumber: '송장번호',
      배송완료일: '배송완료일',
      정산배송완료일: '정산배송완료일',
      취소일: '취소일',
      정산취소일: '정산취소일',
      발송소요기간: '발송소요기간',
      name: '수령자',
      contactNumber: '전화번호',
      postCode: '우편번호',
      address: '주소',
      과면세: '과면세',
      사원번호: '사원번호',
      희망배송일: '희망배송일',
      취소처리자: '취소처리자',
      취소사유: '취소사유',
      '취소 상세사유': '취소 상세사유',
      라이브중구매여부: '라이브중구매여부',
      연동코드: '연동코드',
    },
    headersForShipping: [
      '주문일',
      '주문상태',
      '주문번호',
      '복지관주문번호',
      '주문상품순번',
      '상품코드',
      '상품명',
      '옵션명',
      '수량',
      '배송구분',
      '배송업체명',
      '운송장번호',
    ],
  },
  {
    mallName: '카페24',
    headers: [
      '주문번호',
      '품목별 주문번호',
      '배송메시지',
      '총 주문금액',
      '총 결제금액',
      '상품번호',
      '주문상품명',
      '주문상품명(옵션포함)',
      '수량',
      '판매가',
      '수령인',
      '수령인 휴대전화',
      '수령인 우편번호',
      '수령인 주소',
      '수령인 상세 주소',
      '결제구분',
      '결제수단',
      '발주일',
      '배송국가',
    ],
    headerTranslationMap: {
      orderNumber: '주문번호',
      '품목별 주문번호': '품목별 주문번호',
      deliveryMessage: '배송메시지',
      '총 주문금액': '총 주문금액',
      '총 결제금액': '총 결제금액',
      productCode: '상품번호',
      productName: '주문상품명',
      option: '주문상품명(옵션포함)',
      quantity: '수량',
      price: '판매가',
      name: '수령인',
      contactNumber: '수령인 휴대전화',
      postCode: '수령인 우편번호',
      addressConcat1: '수령인 주소',
      addressConcat2: '수령인 상세 주소',
      결제구분: '결제구분',
      결제수단: '결제수단',
      발주일: '발주일',
      배송국가: '배송국가',
      shipping: '운송장번호',
    },
    headersForShipping: ['주문번호', '품목별 주문번호', '운송장번호'],
  },
];

export const offlineMallTemps = [
  {
    mallName: '현대그린푸드',
    headers: [
      '사업장',
      '사업장명',
      '급식행사여부',
      '정산일자',
      '단품코드',
      '단품명',
      '규격',
      '단가',
      '수량',
      '면세금액',
      '과세금액',
      '세액',
      '합계(VAT별도)',
      '합계(VAT포함)',
      '발주구분',
      '고객사단품코드',
      '고객사단품명',
      'KAN',
      '주문비고',
    ],
  },
  {
    mallName: '삼성웰스토리',
    headers: [
      '일자',
      '구분',
      '품목코드',
      '품목명',
      '규격',
      null,
      '단위',
      '수량',
      '단가',
      '금액',
      '부가세',
      '합계',
      '비고',
    ],
  },
];
