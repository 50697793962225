import { If } from '@sweep/utils';
import { observer } from 'mobx-react-lite';
import { Button } from 'src/design-system/components/Button';
import { Dialog } from 'src/design-system/components/Dialog';
import { useOMSStore } from 'src/hooks/useOMSStore';
import { cva } from 'src/third-parties/tailwind';
import { isNotNil } from 'src/utils/function';

interface ProductDeleteDialogProps {
  productIds: string[];
  open: boolean;
  onClose: (result: boolean) => void;
}

function ProductDeleteDialog({
  productIds,
  open,
  onClose: givenOnClose,
}: ProductDeleteDialogProps) {
  const oms = useOMSStore();
  const onClose = () => givenOnClose(false);

  const products = productIds
    .slice(0, 5)
    .map((productId) => oms.product.getProduct(productId))
    .filter(isNotNil);

  return (
    <Dialog open={open} onClose={onClose}>
      <Dialog.Title className="mb-16px">선택한 상품을 삭제할까요?</Dialog.Title>
      <ul className={list()}>
        {products.map((product) => (
          <li key={product._id} className={listItem()}>
            {product.productName}
          </li>
        ))}
        <If is={productIds.length > 5}>
          <li className={listItem()}>외 {productIds.length - 5}건</li>
        </If>
      </ul>
      <Dialog.Footer>
        <Dialog.Close color="lightGray">취소</Dialog.Close>
        <Button className="flex-1" onClick={() => givenOnClose(true)}>
          삭제
        </Button>
      </Dialog.Footer>
    </Dialog>
  );
}

const list = cva('mb-20px pl-24px');

const listItem = cva('text-medium-s list-disc text-gray-600');

export default observer(ProductDeleteDialog);
