import { useMemo } from 'react';
import { ExpandedCheckbox } from '../Checkbox';
import { ColumnDef, Table, TableProps } from './Table';

interface SelectableTableProps<T> extends TableProps<T> {
  selectedItems: T[];
  onSelect: (items: T[]) => void;
  getKey: (data: T) => string;
}

export function SelectableTable<T>({
  items,
  columns: givenColumns,
  getKey,
  selectedItems,
  onSelect,
  ...rest
}: SelectableTableProps<T>) {
  const itemByKey = useMemo(
    () => new Map(items.map((item) => [getKey(item), item] as const)),
    [getKey, items]
  );

  const selectedKeySet = new Set(selectedItems.map(getKey));
  const isTotalSelected = items.every((row) => selectedKeySet.has(getKey(row)));
  const isSelected = (row: T) => selectedKeySet.has(getKey(row));

  const handleTotalSelectedChange = (checked: boolean) => {
    const keys = items.map(getKey);
    const keySet = new Set(keys);
    if (checked) {
      const newSelectedKeySet = new Set([...selectedKeySet, ...keys]);
      onSelect(Array.from(newSelectedKeySet).map((key) => itemByKey.get(key)!));

      return;
    }

    const newSelectedItems = selectedItems.filter(
      (item) => !keySet.has(getKey(item))
    );
    onSelect(newSelectedItems);
  };

  const handleSelectedChange = (row: T) => (checked: boolean) => {
    const key = getKey(row);
    if (checked) {
      const newSelectedItems = [...selectedItems, row];
      onSelect(newSelectedItems);
      return;
    }

    const newSelectedItems = selectedItems.filter(
      (item) => getKey(item) !== key
    );
    onSelect(newSelectedItems);
  };

  const columns: ColumnDef<T>[] = [
    {
      header: (
        <Table.HeaderCell className="sticky left-0 bg-gray-200 px-0">
          <ExpandedCheckbox
            className="z-[1]"
            checked={isTotalSelected}
            onCheckedChange={handleTotalSelectedChange}
          />
        </Table.HeaderCell>
      ),
      cell: ({ row, lastRow }) => (
        <Table.Cell className="sticky left-0 px-0" lastRow={lastRow}>
          <ExpandedCheckbox
            checked={isSelected(row)}
            onCheckedChange={handleSelectedChange(row)}
          />
        </Table.Cell>
      ),
    },
    ...givenColumns,
  ];

  return <Table items={items} columns={columns} {...rest} />;
}

SelectableTable.HeaderCell = Table.HeaderCell;
SelectableTable.Cell = Table.Cell;
