import { cva } from 'src/third-parties/tailwind';

interface TableHeaderCellProps {
  className?: string;
  lastColumn?: boolean;
  children: React.ReactNode;
}

function TableHeaderCell({
  lastColumn = false,
  className,
  children,
}: TableHeaderCellProps) {
  return <th className={headerCell({ lastColumn, className })}>{children}</th>;
}

const headerCell = cva(
  [
    'text-medium-s whitespace-nowrap text-left text-gray-700',
    'px-16px border-b border-gray-200 bg-gray-100 py-0',
  ],
  {
    variants: {
      lastColumn: {
        true: '',
        false: 'border-r',
      },
    },
  }
);

export default TableHeaderCell;
