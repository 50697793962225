import React, { useCallback } from 'react';

export function useRefs<T>(
  refs: Array<React.Ref<T> | undefined>
): React.RefCallback<T> {
  return useCallback((value: T) => {
    for (const ref of refs) {
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref != null) {
        (ref as React.MutableRefObject<T>).current = value;
      }
    }
  }, refs); // eslint-disable-line react-hooks/exhaustive-deps
}
