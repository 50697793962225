import { ReactNode } from 'react';
import { cva } from 'src/third-parties/tailwind';
import { uniq } from 'src/utils/array/uniq';
import { useMultiSelectContext } from './MultiSelectContext';
import SelectIcon from './SelectIcon';

interface MultiSelectOptionProps {
  value: string[];
  children?: ReactNode;
}

function MultiSelectMultiOption({ value, children }: MultiSelectOptionProps) {
  const { value: contextValue, onChange } = useMultiSelectContext();
  const selected = value.every((v) => contextValue.includes(v));

  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation();

    if (selected) {
      onChange(contextValue.filter((v) => !value.includes(v)));
      return;
    }

    onChange(uniq([...contextValue, ...value]));
  };

  return (
    <div onClick={handleClick} className={container({ selected })}>
      <SelectIcon selected={selected} />
      {children}
    </div>
  );
}

const container = cva(
  'text-medium-s flex h-[44px] shrink-0 items-center gap-[4px] px-[12px] text-gray-700',
  {
    variants: {
      selected: { true: 'bg-gray-100', false: 'bg-white hover:bg-gray-100' },
    },
  }
);

export default MultiSelectMultiOption;
