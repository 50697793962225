import { overlay } from 'overlay-kit';
import DispatchModal from '../components/DispatchModal/DispatchModal';
import { DispatchModalStore } from '../store/DispatchModalStore';

export function openDispatchDialog(store: DispatchModalStore) {
  const overlayId = overlay.open(({ isOpen, close }) => (
    <DispatchModal open={isOpen} onClose={close} store={store} />
  ));

  return () => overlay.close(overlayId);
}
