import { Order } from '@sweep/contract';
import { CreateMatching } from 'models/CompositionMatching';
import { isNotNil } from 'utils/function';
import { deepEqual } from 'utils/index';
import { isNotEmptyString } from 'src/utils/string';
import { AbstractPlugin } from '../../interface';
import { CMOrder, MatchedOrder, ProductMatching } from './interface';
import { openMatchedCMForm } from './openMatchedCMForm';
import { openUnmatchedCMForm } from './openUnmatchedCMForm';
import { matchByOptionCode } from './services/matchByOptionCode';
import { matchByProductNameOption } from './services/matchByProductNameOption';
import { separateOrdersByMatched } from './services/separateOrdersByMatched';
import { transformToCMOrder } from './services/transformToCMOrder';
import { transformToCMProduct } from './services/transformToCMProduct';
import { transformToCreateCompositionMatching } from './services/transformToCreateCompositionMatching';
import { transformToCreateOptionCodeMatching } from './services/transformToCreateOptionCodeMatching';
import { uniqueByOptionCode } from './services/uniqueByOptionCode';
import { uniqueByProductNameOption } from './services/uniqueByProductNameOption';

export interface CompositionMatchingConfig {
  optionCodeMatching?: boolean;
  unmatched?: {
    productName?: 'empty' | 'productNameOption';
  };
}

export class CompositionMatchingPlugin extends AbstractPlugin<
  CompositionMatchingConfig | undefined
> {
  get unmatchedProductName() {
    return this.config?.unmatched?.productName ?? 'productNameOption';
  }

  get usingOptionCodeMatching() {
    return this.config?.optionCodeMatching ?? false;
  }

  transform = async (orders: Order[]): Promise<Order[]> => {
    const { unmatched } = this.match(orders);

    if (unmatched.length !== 0) {
      const cmOrders = unmatched.map(transformToCMOrder);
      const uniqueCMOrders = this.getUniqueCMOrders(cmOrders);

      const createMatchings = await openUnmatchedCMForm(
        this.oms.order.render,
        uniqueCMOrders
      );
      if (createMatchings.length > 0) {
        await this.createMatchings(createMatchings);
      }
    }
    // TODO(@이지원): unmatched.length === 0 일 때는 계산하지 않도록
    const { matched: matchedAfter } = this.match(orders);

    const matchings = matchedAfter
      .map<ProductMatching | null>((order) =>
        order.data == null
          ? null
          : {
              cmOrder: transformToCMOrder(order),
              products: order.data.map(transformToCMProduct),
              optionCodeMatchingId: order.plugin?.cm?.optionCodeMatchingId,
              compositionMatchingId: order.plugin?.cm?.compositionMatchingId,
            }
      )
      .filter(isNotNil);
    const uniqueMatchings = this.getUniqueOrders(matchings);

    const confirmedMatchings = await openMatchedCMForm(
      this.oms.order.render,
      uniqueMatchings
    );
    await this.updateMatchings(confirmedMatchings, uniqueMatchings);
    const { matched: matchedFinal, unmatched: unmatchedFinal } =
      this.match(orders);

    return [
      ...matchedFinal.map((order) => ({
        ...order,
        data: order.data!.map((product) => ({
          ...product,
          // TODO(@이지원): 합배송, formatOrders를 ts로 전환한 후 없애기
          unit: product.unit ?? '',
        })),
      })),
      ...unmatchedFinal.map<Order>((order) => {
        const productNameOption = [order.productName, order.option]
          .filter(isNotNil)
          .join(' ');
        const productName =
          this.unmatchedProductName === 'empty' ? '' : productNameOption;

        return {
          ...order,
          data: [
            {
              productId: productNameOption,
              unit: '',
              productName,
              quantity: 1,
            },
          ],
        };
      }),
    ];
  };

  match(orders: Order[]): { matched: MatchedOrder[]; unmatched: Order[] } {
    if (this.usingOptionCodeMatching) {
      const optionCodeMatchedOrders = matchByOptionCode(orders, this.oms);
      const { matched: optionCodeMatched, unmatched: optionCodeUnmatched } =
        separateOrdersByMatched(optionCodeMatchedOrders);

      const productNameOptionMatchedOrders = matchByProductNameOption(
        optionCodeUnmatched,
        this.oms
      );

      const {
        matched: productNameOptionMatched,
        unmatched: productNameOptionUnMatched,
      } = separateOrdersByMatched(productNameOptionMatchedOrders);

      return {
        matched: [...optionCodeMatched, ...productNameOptionMatched],
        unmatched: productNameOptionUnMatched,
      };
    }

    const productNameOptionMatchedOrders = matchByProductNameOption(
      orders,
      this.oms
    );
    return separateOrdersByMatched(productNameOptionMatchedOrders);
  }

  getUniqueCMOrders(orders: CMOrder[]): CMOrder[] {
    const uniqueOrdersByProductNameOption = uniqueByProductNameOption(
      orders,
      (order) => order
    );

    if (this.usingOptionCodeMatching) {
      return uniqueByOptionCode(
        uniqueOrdersByProductNameOption,
        (order) => order
      );
    }

    return uniqueOrdersByProductNameOption;
  }

  getUniqueOrders<T extends { cmOrder: CMOrder }>(order: T[]): T[] {
    const uniqueOrdersByProductNameOption = uniqueByProductNameOption(
      order,
      (order) => order.cmOrder
    );

    if (this.usingOptionCodeMatching) {
      return uniqueByOptionCode(
        uniqueOrdersByProductNameOption,
        (order) => order.cmOrder
      );
    }

    return uniqueOrdersByProductNameOption;
  }

  createMatchings = async (
    createMatchings: CreateMatching[]
  ): Promise<void> => {
    if (this.usingOptionCodeMatching) {
      const optionCodeMatchings = createMatchings
        .filter((m) => isNotEmptyString(m.optionCode))
        .map(transformToCreateOptionCodeMatching);

      const compositionMatchings = createMatchings
        .filter((m) => !isNotEmptyString(m.optionCode))
        .map(transformToCreateCompositionMatching);

      if (optionCodeMatchings.length > 0) {
        await this.oms.cm.createOptionCodeMatchings(optionCodeMatchings);
      }

      if (compositionMatchings.length > 0) {
        await this.oms.cm.createCompositionMatchings(compositionMatchings);
      }

      return;
    }

    const compositionMatchings = createMatchings.map(
      transformToCreateCompositionMatching
    );

    if (compositionMatchings.length > 0) {
      await this.oms.cm.createCompositionMatchings(compositionMatchings);
    }
  };

  updateMatchings = async (
    matchings: ProductMatching[],
    prevMatchings: ProductMatching[]
  ) => {
    await Promise.all(
      matchings.map(async (matching, index) => {
        if (deepEqual(matching, prevMatchings.at(index))) {
          return;
        }

        if (matching.compositionMatchingId != null) {
          await this.oms.cm.updateCompositionMatching({
            _id: matching.compositionMatchingId,
            data: matching.products,
          });
        }

        if (matching.optionCodeMatchingId != null) {
          await this.oms.cm.updateOptionCodeMatching({
            _id: matching.optionCodeMatchingId,
            products: matching.products,
          });
        }
      })
    );
  };
}
