interface RegistrationMonthlyOrderCountSelectProps {
  options: string[];
  onChange: (value: string) => void;
}

function RegistrationMonthlyOrderCountSelect({
  options,
  onChange,
}: RegistrationMonthlyOrderCountSelectProps) {
  return (
    <div className="space-y-8px flex flex-col">
      <label>
        <span>월 평균 주문 건수</span>
        <span className="text-red-500"> *</span>
      </label>
      <select
        className="focus:shadow-outline w-full appearance-none rounded-lg border border-gray-300 p-4 leading-tight text-neutral-900 transition-all
        duration-200 ease-in-out hover:ring-2 hover:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
        onChange={(event) => onChange(event.target.value)}
      >
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
}

export default RegistrationMonthlyOrderCountSelect;
