import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useOMSStore } from 'src/hooks/useOMSStore';
import { amplitude } from 'src/utils';
import LoadingIndicator from '../components/LoadingIndicator';
import OMSContainer from '../components/OMSContainer';
import SelectableOrderTableByUniqueCodeSet from '../components/RenderTable/SelectableOrderTableByUniqueCodeSet';
import useFileHandling from '../hooks/useFileHandling';
import useShippingUpload from '../hooks/useShippingUpload';
import DownloadArrow from '../icons/DownloadArrow';
import FileUpload from '../icons/file-upload';
import LoadingStore from '../stores/LoadingStore';

const UploadShippingInfoScreen = observer(() => {
  const oms = useOMSStore();
  const { splitOrders } = useFileHandling();
  const { retailerUploadShippingInfo, supplierUploadShippingInfo } =
    useShippingUpload();
  const isRetailer = oms.user.companyType === 'retailer';

  const handleSplitOrderClick = () => {
    amplitude.track('Download Split-purchase-order');
    splitOrders();
  };

  const handleUploadShippingInfo = async (e) => {
    amplitude.track('Upload Shipping-info', {
      From: 'upload-shipping-info',
    });
    LoadingStore?.setIsLoading(true);
    try {
      e.preventDefault();

      const files = e.target.files;
      if (!files.length) {
        alert('파일을 선택해 주세요.');
        return;
      }

      const allowedExtensions = ['xlsx', 'xls', 'csv'];

      for (let file of files) {
        const ext = file.name.split('.').pop().toLowerCase();
        if (!allowedExtensions.includes(ext)) {
          alert(`"${file.name}"은 올바른 파일 양식이 아닙니다.`);
          return;
        }
      }

      if (isRetailer) {
        await retailerUploadShippingInfo(files);
      } else {
        await supplierUploadShippingInfo(files);
      }
    } catch (err) {
      console.error(`Error while uploading ${companyType} shipping info:`, err);
    } finally {
      LoadingStore?.setIsLoading(false);
      e.target.value = '';
    }
  };

  const [selectedUniqueCodeSet, setSelectedUniqueCodeSet] = useState(new Set());

  const [show100columns, setShow100columns] = useState(true);

  return (
    <OMSContainer bgColor="bg-[#F2F6FA]">
      <div className="flex items-center justify-between">
        <h1 className="font-pretendard text-[24px] font-bold leading-[30px] text-[color:var(--Gray-600,#343D4B)]">
          {isRetailer ? '공급사 운송장파일 업로드' : '운송장 등록하기'}
        </h1>

        <div className="flex items-center justify-end">
          <div className="flex h-[42px] cursor-pointer flex-row rounded-md bg-[#1991FF]">
            <div
              role="button"
              onClick={handleSplitOrderClick}
              className="flex items-center justify-center gap-[2px] border-[#7ABFFF] pl-5 pr-4"
            >
              <span className="font-pretendard text-[16px] font-bold leading-[19px] text-white">
                {isRetailer ? '주문서 분리' : '발주 분리'}
              </span>
              <div>
                <DownloadArrow />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="my-[40px] flex flex-col gap-[20px] rounded-[8px] bg-white p-[20px]">
        <div className="flex flex-row items-center justify-between">
          <div className="no-scrollbar flex flex-row items-center gap-[10px] overflow-x-hidden whitespace-nowrap">
            <label className="h-[39px] shrink-0 cursor-pointer rounded-lg border border-[#CED7E0] px-4 py-2 font-bold text-neutral-600">
              <input
                type="file"
                className="hidden"
                name="files"
                onChange={handleUploadShippingInfo}
                accept=".xlsx, .xls, .csv"
                multiple
              />
              <div className="flex h-full items-center">
                <FileUpload height={19} />
                <span className="whitespace-nowrap pl-[9px]">
                  운송장 업로드
                </span>
              </div>
            </label>
          </div>
        </div>
        <div className="flex max-h-[calc(100vh-200px)] max-w-full overflow-x-auto">
          <div>
            <SelectableOrderTableByUniqueCodeSet
              allOrders={oms.order.normalizedOrders}
              orders={
                show100columns
                  ? oms.order.normalizedOrders
                      .slice()
                      .sort((a, b) =>
                        b?.hasBeenTempStored === a?.hasBeenTempStored
                          ? 0
                          : b?.hasBeenTempStored
                            ? 1
                            : -1
                      )
                      .slice(0, 100)
                  : oms.order.normalizedOrders
              }
              origEnglishHeader={oms.user.excelHeaderKeys}
              columnMapping={oms.user.excelColumnMapping}
              selectedUniqueCodeSet={selectedUniqueCodeSet}
              setSelectedUniqueCodeSet={setSelectedUniqueCodeSet}
              uniqueCodeName="uniqueCodeAfterCustomization"
            />
          </div>
        </div>
        <div className="ml-4">
          {oms.order.normalizedOrders?.length > 100 && (
            <button
              onClick={() => setShow100columns(!show100columns)}
              className="rounded-lg bg-neutral-200 px-4 py-2 font-bold text-neutral-600"
            >
              {show100columns
                ? `${oms.order.normalizedOrders.length}개 주문 전부 보기`
                : '100개 주문만 보기'}
            </button>
          )}
        </div>
      </div>

      {LoadingStore?.isLoading && <LoadingIndicator />}
    </OMSContainer>
  );
});

export default UploadShippingInfoScreen;
