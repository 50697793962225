import { observer } from 'mobx-react-lite';
import MockSettingSelect from '../../../common/components/MockSettingSelect';
import {
  dispatchedOrderTypeStorage,
  shoppingMallTypeStorage,
} from '../DispatchStore';
import {
  acceptCancelTypeStorage,
  rejectCancelTypeStorage,
} from '../handlers/cancelOrderHandlers';
import { confirmUpdateOrderTypeStorage } from '../handlers/confirmOrderHandlers';
import { dispatchOrderTypeStorage } from '../handlers/dispatchOrderHandlers';
import { fulfillOrderTypeStorage } from '../handlers/fullfillOrderHandlers';

function DispatchSetting() {
  return (
    <div className="flex w-fit flex-col gap-2 rounded-lg border-2 border-gray-200 p-3">
      <p className="text-bold-l">쇼핑몰 주문수집 관련</p>
      <MockSettingSelect
        label="주문수집"
        value={dispatchOrderTypeStorage.value}
        onChange={dispatchOrderTypeStorage.setValue}
        options={[
          { value: 'success', label: '성공' },
          { value: 'fail-five', label: '가끔 실패' },
          { value: 'fail', label: '실패' },
          { value: 'none', label: '안함' },
        ]}
      />
      <MockSettingSelect
        label="수집된 주문 개수"
        value={dispatchedOrderTypeStorage.value}
        onChange={dispatchedOrderTypeStorage.setValue}
        options={[
          { value: 'some', label: '적당히' },
          { value: 'many', label: '많이' },
          { value: 'many-stress', label: '많이(스트레스)' },
        ]}
      />
      <MockSettingSelect
        label="쇼핑몰 개수"
        value={shoppingMallTypeStorage.value}
        onChange={shoppingMallTypeStorage.setValue}
        options={[
          { value: 'some', label: '적당히' },
          { value: 'many', label: '많이' },
        ]}
      />
      <hr className="my-[12px]" />
      <MockSettingSelect
        label="주문확인"
        value={confirmUpdateOrderTypeStorage.value}
        onChange={confirmUpdateOrderTypeStorage.setValue}
        options={[
          { value: 'success', label: '성공' },
          { value: 'partial-fail', label: '일부 실패' },
          { value: 'fail', label: '실패' },
          { value: 'none', label: '안함' },
        ]}
      />
      <MockSettingSelect
        label="취소승인"
        value={acceptCancelTypeStorage.value}
        onChange={acceptCancelTypeStorage.setValue}
        options={[
          { value: 'success', label: '성공' },
          { value: 'partial-fail', label: '일부 실패' },
          { value: 'fail', label: '실패' },
          { value: 'none', label: '안함' },
        ]}
      />
      <MockSettingSelect
        label="취소거부"
        value={rejectCancelTypeStorage.value}
        onChange={rejectCancelTypeStorage.setValue}
        options={[
          { value: 'success', label: '성공' },
          { value: 'partial-fail', label: '일부 실패' },
          { value: 'fail', label: '실패' },
          { value: 'none', label: '안함' },
        ]}
      />
      <hr className="my-[12px]" />
      <MockSettingSelect
        label="운송장등록"
        value={fulfillOrderTypeStorage.value}
        onChange={fulfillOrderTypeStorage.setValue}
        options={[
          { value: 'success', label: '성공' },
          { value: 'partial-fail', label: '일부 실패' },
          { value: 'fail', label: '실패' },
          { value: 'none', label: '안함' },
        ]}
      />
    </div>
  );
}

export default observer(DispatchSetting);
