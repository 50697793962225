export const multiplyPattern = /[\*xX×]\s*(\d+)(\s*\D+)?$/;

export const extractMultiplier = (part) => {
  const multiplyMatch = part.match(multiplyPattern);
  return multiplyMatch ? parseInt(multiplyMatch[1], 10) : 1;
};

export const multiplierMatch = (option) => {
  return option.match(multiplyPattern);
};

const multiplierString = (multiplier) => {
  return multiplier > 1 ? ` x${multiplier}` : '';
};

export const updateOptionMultiplier = (option, multiplier) => {
  // multiplierMatch를 사용해서, 기존 option에 quantity가 적용된 부분을 찾는다
  // 해당 부분이 없으면, 기존 option x${multiplier}를 붙여서 리턴
  if (!multiplierMatch(option)) {
    return `${option}${multiplierString(multiplier)}`;
  }

  const multiplierExpression = multiplierMatch(option)[0];
  const originalMultiplier = multiplierMatch(option)[1];

  // 해당 부분이 있으면 기존 option에서 quantity가 적용된 부분의 숫자부분을 multiplier로 바꿔서 리턴

  return option.replace(
    multiplierExpression,
    multiplier === 1
      ? ''
      : multiplierExpression.replace(originalMultiplier, multiplier)
  );
};

export const multiplierExpression = (option) => {
  const match = multiplierMatch(option);
  return match ? match[0] : '';
};
