import copy from 'fast-copy';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useOMSStore } from 'src/hooks/useOMSStore';
import Autocomplete from '../components/Autocomplete';
import Button from '../components/buttons/Button';
import { isValid } from '../utils/utils';
import OrderCombinationTypeSelector from './formElements/OrderCombinationTypeSelector';

const OrderCombinationForm = ({
  type = 'register',
  combinationInfo,
  onFormSubmit,
}) => {
  const oms = useOMSStore();
  const [productIds, setProductIds] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const [condition, setCondition] = useState(null);
  const [newUnit, setNewUnit] = useState('');
  const [newCount, setNewCount] = useState(0);
  const [combinationType, setCombinationType] = useState('maxCounts');
  const [unitQuantity, setUnitQuantity] = useState({});
  const handleTypeChange = (e) => {
    setCombinationType(e.target.value);

    if (e.target.value === 'maxUnits') {
      setNewUnit('');
      setNewCount(0);
    }

    setCondition(null);
  };

  const handleUnitQuantityChange = (unit, quantity) => {
    setUnitQuantity({ ...unitQuantity, [unit]: quantity });
  };

  const handleAddConditionByList = (unitList) => {
    if (!unitList.includes(newUnit)) {
      alert('구성을 선택해주세요.');
      return;
    }

    if (newCount === 0) {
      alert('해당 구성의 택배 당 최대 개수를 입력해주세요.');

      return;
    }

    // if (!isUnitFormat(newUnit)) {
    //   alert('구성은 숫자와 문자가 조합된 형태여야 합니다. (예시: 30구, 1.8L)');

    //   return;
    // }

    setCondition({ ...condition, [newUnit]: newCount });
    setNewUnit('');
    setNewCount(0);
  };

  const handleDeleteCondition = (keyToDelete) => {
    const updatedConditions = { ...condition };
    delete updatedConditions[keyToDelete];
    setCondition(updatedConditions);
  };

  const handleUnitChange = (index, newUnitValue) => {
    // condition 객체를 배열로 변환
    const entries = Object.entries(condition);
    // 새로운 unit 값을 적용
    entries[index] = [newUnitValue, entries[index][1]];

    // 배열을 다시 객체로 변환
    setCondition(Object.fromEntries(entries));
  };

  const handleCountChange = (index, newCountValue) => {
    // condition 객체를 배열로 변환
    const entries = Object.entries(condition);
    // 새로운 count 값을 적용
    entries[index] = [entries[index][0], newCountValue];

    // 배열을 다시 객체로 변환
    setCondition(Object.fromEntries(entries));
  };

  useEffect(() => {
    if (combinationInfo) {
      setCombinationType(combinationInfo.combinationType);
      setCondition(combinationInfo.condition);
      setProductIds(combinationInfo.productIds);
      if (combinationInfo.unitQuantity) {
        setUnitQuantity(combinationInfo.unitQuantity);
      }
    }
  }, [combinationInfo]);

  useEffect(() => {
    let unitSet = new Set();
    let noUnit = false;
    productIds.forEach((productId) => {
      const productInfo = oms.product.products.find(
        (info) => info._id === productId
      );
      if (productInfo) {
        productInfo.units.forEach((unit) => unitSet.add(unit.unit));
        if (productInfo.units.length === 0) {
          noUnit = true;
        }
      }
    });
    let unitList = Array.from(unitSet);
    if (noUnit) {
      unitList = ['구성 없음', ...unitList];
    }
    setUnitList(unitList);
    let unitQuantityTemp = copy(unitQuantity);
    unitList.forEach((unit) => {
      if (unitQuantityTemp[unit]) {
        return;
      }
      unitQuantityTemp[unit] = parseInt(unit.match(/\d+/)?.[0] || 1);
    });
    setUnitQuantity(unitQuantityTemp);
  }, [productIds]);

  const handleDeleteProduct = (index) => {
    // 현재 키워드 목록에서 인덱스에 해당하는 키워드를 제거
    const updatedProductIds = productIds.filter((_, idx) => idx !== index);
    setProductIds(updatedProductIds);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isValid(productIds)) {
      alert('합배송 기준을 적용할 상품을 선택해주세요.');
      return;
    }

    let formData = { combinationType, condition, productIds };
    if (combinationType === 'maxUnits') {
      formData = {
        ...formData,
        unitQuantity,
      };
    }

    if (newUnit && newCount > 0) {
      const confirmAdd = window.confirm(
        `현재 작성중인 합배송 기준을 추가하시겠습니까?\n\n구성: ${newUnit}, 택배 당 최대 개수: ${newCount}\n\n(추가하지 않으면 작성중인 구성은 저장되지 않습니다.)`
      );

      if (confirmAdd) {
        if (!unitList.includes(newUnit)) {
          alert('구성을 선택해 주세요!');
          return;
        }

        formData = {
          ...formData,
          condition: { ...formData.condition, [newUnit]: newCount },
        };
      }

      if (!confirmAdd) {
        if (!isValid(condition && (!newUnit || newCount === 0))) {
          alert(
            '합배송을 적용할 구성과 해당 구성의 택배 당 최대 개수를 입력해주세요.'
          );
          return;
        }
      }
    }
    // if (
    //   formData.condition['어떤 구성이든'] &&
    //   Object.keys(formData.condition).length > 1
    // ) {
    //   alert(
    //     '"어떤 구성이든" 합배송 기준이 있을 경우 다른 합배송 기준을 추가할 수 없습니다.'
    //   );
    //   return;
    // }
    // 부모 컴포넌트로 폼 데이터 전송
    onFormSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="w-full">
      <div className="mb-6">
        <label
          htmlFor="unit"
          className="mt-4 block text-lg font-semibold text-gray-700"
        >
          아래 상품들이 함께 배송될 수 있어요
        </label>
        <Autocomplete
          productInfos={oms.product.products}
          productIds={productIds}
          setProductIds={setProductIds}
        />
        <div className="mt-4">
          <ul>
            {productIds.map((productId, index) => (
              <li
                key={productId}
                className="mb-2 flex items-center justify-between rounded-lg border px-3.5 py-2.5 shadow-sm"
              >
                {
                  oms.product.products.find((info) => info._id === productId)
                    ?.productName
                }
                <button
                  type="button"
                  onClick={() => handleDeleteProduct(index)}
                  className="ml-3 font-medium text-gray-400 hover:text-gray-500"
                >
                  삭제
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <OrderCombinationTypeSelector
        combinationType={combinationType}
        setCombinationType={setCombinationType}
        condition={condition}
        setCondition={setCondition}
        newUnit={newUnit}
        setNewUnit={setNewUnit}
        newCount={newCount}
        setNewCount={setNewCount}
        handleTypeChange={handleTypeChange}
        handleAddCondition={() => handleAddConditionByList(unitList)}
        handleDeleteCondition={handleDeleteCondition}
        handleUnitChange={handleUnitChange}
        handleCountChange={handleCountChange}
        unitList={unitList}
        unitQuantity={unitQuantity}
        handleUnitQuantityChange={handleUnitQuantityChange}
      />

      <div className="mt-8 flex justify-end">
        <Button
          name={type === 'update' ? '수정하기' : '설정하기'}
          type="submit"
        />
      </div>
    </form>
  );
};

export default observer(OrderCombinationForm);
