import { parseUnicodeEscapeSequnce } from 'src/utils/string';
import { ExcelData, ParsedExcelData } from '../../interface';

export function toExcelJson(data: string): ExcelData | null {
  const parsed = parseUnicodeEscapeSequnce(data);
  if (isParsedExcelData(parsed)) {
    return parsed.map((row) => row.map((cell) => cell?.toString() ?? ''));
  }

  return null;
}

function isParsedExcelData(data: any): data is ParsedExcelData {
  if (!Array.isArray(data)) {
    return false;
  }

  if (data.length === 0) {
    return false;
  }

  return data.every(
    (row) =>
      Array.isArray(row) &&
      row.every(
        (cell) =>
          typeof cell === 'string' || typeof cell === 'number' || cell == null
      )
  );
}
