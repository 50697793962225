import {
  CompositionMatching,
  CreateCompositionMatching,
  UpdateCompositionMatching,
} from 'models/CompositionMatching';
import api, { APIResponse } from './api';

type GetCompositionMatchingResponse =
  | {
      success: true;
      data: CompositionMatching[];
    }
  | {
      success: false;
      message: string;
    };

export async function getCompositionMatchings() {
  const url = '/composition-matching/fetch';
  const response = await api.get<GetCompositionMatchingResponse>(url);

  return response.data;
}

export async function createCompositionMatching(
  compositionMatching: CreateCompositionMatching
) {
  const url = '/composition-matching/register';
  const response = await api.post<APIResponse<CompositionMatching>>(url, {
    productInfo: compositionMatching,
  });

  return response.data;
}

export async function createCompositionMatchings(
  compositionMatchings: CreateCompositionMatching[]
) {
  const url = '/composition-matching/register/many';
  const response = await api.post<APIResponse<CompositionMatching[]>>(url, {
    productInfos: compositionMatchings,
  });

  return response.data;
}

export async function updateCompositionMatching(
  compositionMatching: UpdateCompositionMatching
) {
  const url = `/composition-matching/update`;
  const response = await api.put<APIResponse<CompositionMatching>>(url, {
    composition: compositionMatching,
  });

  return response.data;
}