import { EvaluateOperatorResult, Token } from '../../interface';
import { CAN_NOT_CONVERT_TO_NUMBER, INVALID_EXPRESSION } from '../error';
import { parseNumber } from '../parseNumber';

export function evaluateOperatorSubtract(
  stack: Token[]
): EvaluateOperatorResult {
  const operand2 = stack.pop();
  const operand1 = stack.pop();

  if (operand1 == null || operand2 == null) {
    return INVALID_EXPRESSION;
  }

  const parsedOperand1 = parseNumber(operand1);
  const parsedOperand2 = parseNumber(operand2);
  if (parsedOperand1 == null) {
    return CAN_NOT_CONVERT_TO_NUMBER(operand1);
  }

  if (parsedOperand2 == null) {
    return CAN_NOT_CONVERT_TO_NUMBER(operand2);
  }

  return {
    success: true,
    stack: [...stack, parsedOperand1 - parsedOperand2],
  };
}
