import { NormalizedOrder, Order } from '@sweep/contract';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { getNormalizedOrders } from 'network/order/order';
import { OMSStore } from 'stores/OMSStore';
import {
  CompositionMatchingConfig,
  CompositionMatchingPlugin,
} from 'stores/plugin/features/composition-matching';
import { PluginExecutionService } from 'stores/plugin/PluginExecutionService';

export class NormalizeOrderStore {
  initialized = false;
  normalizedOrders: NormalizedOrder[] = [];

  constructor(private oms: OMSStore) {
    makeObservable(this, {
      initialized: observable,
      normalizedOrders: observable,

      setNormalizedOrders: action.bound,
      init: action.bound,
      loadNormalizedOrders: action.bound,
      normalizeOrders: action.bound,
      removeOrderByFilename: action.bound,
      removeByUniqueCodes: action.bound,
    });
  }

  /**
   * @deprecated OrderStore를 마이그레이션 하기 위한 임시 메서드
   */
  setNormalizedOrders(orders: NormalizedOrder[]) {
    this.normalizedOrders = orders;
  }

  async init() {
    await this.loadNormalizedOrders();
    runInAction(() => {
      this.initialized = true;
    });
  }

  async loadNormalizedOrders() {
    const normalizedOrders = await getNormalizedOrders();
    runInAction(() => {
      this.normalizedOrders = normalizedOrders;
    });
  }

  // TODO(@이지원): 커스텀 로직 모두 대체되면, normalizeOrders로 통일
  compositionMatching = async (orders: Order[]): Promise<Order[]> => {
    const cmPluginData = this.oms.user.plugins.find(
      (plugin) => plugin.pluginId === 'composition-matching'
    );
    if (cmPluginData == null) {
      return orders;
    }

    const cmPlugin = new CompositionMatchingPlugin(
      this.oms,
      cmPluginData.config as CompositionMatchingConfig | undefined
    );

    const compositionMatchingOrders = await cmPlugin.transform(orders);

    return compositionMatchingOrders;
  };

  normalizeOrders = async (orders: Order[]): Promise<Order[]> => {
    // TODO(@이지원): 커스텀 로직 모두 대체되면, compositionMatching filter 삭제
    const plugins = this.oms.user.plugins.filter(
      (plugin) => plugin.pluginId !== 'composition-matching'
    );
    const pluginExecutionService = new PluginExecutionService(
      this.oms,
      plugins,
      'normalize'
    );

    const transformedOrders = await pluginExecutionService.execute(orders);

    return transformedOrders;
  };

  removeOrderByFilename(filename: string) {
    this.normalizedOrders = this.normalizedOrders.filter(
      (order) => order.originFile !== filename
    );
  }

  removeByUniqueCodes(
    uniqueCodes: string[],
    standard: 'uniqueCode' | 'uniqueCodeAfterCustomization'
  ) {
    this.normalizedOrders = this.normalizedOrders.filter((order) => {
      const standardValue = order[standard];
      if (standardValue == null) {
        return true;
      }

      return !uniqueCodes.includes(standardValue);
    });
  }
}
