import { z } from 'zod';

const ZDoseosanganStandard = z.enum(['CJ', 'LOTTE']);

export type DoseosanganStandard = z.infer<typeof ZDoseosanganStandard>;

export const ZUserSetting = z.object({
  _id: z.string(),
  columnOrder: z.array(z.string()),
  columnTranslation: z.record(z.string()),
  doseosangan: z
    .object({
      enabled: z.boolean(),
      standard: ZDoseosanganStandard,
    })
    .optional(),

  platformNameSettings: z.any(),
  addSupplierName: z.any(),
  applyExcelFuntion: z.any(),
  applyJSLogic: z.any(),
  bundleQuantitySettings: z.any(),
  sortOrders: z.any(),
  applyMultiplierToQuantity: z.any(),
  addDefaultShippingCompanySettings: z.any(),
  convertSpecificCompositionExpressionSetting: z.any(),
  addFixedColumnValue: z.any(),
  applyMergedCellValues: z.any(),
  addConditionalColumnValues: z.any(),
  applyFieldValueToAnother: z.any(),
  applyPlatformNameToOption: z.any(),
  multiplierExpression: z.any(),

  preprocessSettings: z.any(),
  disableMultipleAddressHighlighting: z.any(),

  combinationCustomSetting: z.any(),
  interpreteOrderSettings: z.any(),
  formatOrdersSettings: z.any(),
  extType: z.any(),
  omitShippingCompany: z.any(),

  shippingExcelTemplate: z.any(),
  defaultShippingCompany: z.any(),

  settlementType: z.any(),
});

export type UserSetting = z.infer<typeof ZUserSetting>;
