interface RegistrationMemoTextareaProps {
  label?: string;
  value: string;
  onChange: (value: string) => void;
}

function RegistrationMemoTextarea({
  label,
  value,
  onChange,
}: RegistrationMemoTextareaProps) {
  return (
    <div className="space-y-8px flex flex-col">
      <label>{label ?? '기타 문의 사항'}</label>
      <textarea
        className="focus:shadow-outline w-full appearance-none rounded-lg border border-gray-300 p-4 leading-tight text-neutral-900 transition-all
        duration-200 ease-in-out hover:ring-2 hover:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
        value={value}
        onChange={(event) => onChange(event.target.value)}
      />
    </div>
  );
}

export default RegistrationMemoTextarea;
