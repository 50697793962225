import { DispatchedOrder } from '@sweep/contract';
import { hashToNumber } from '../random/hashToNumber';
import { randomDate } from '../random/randomDate';
import { randomFromArray } from '../random/randomFromArray';
import { randomName } from '../random/randomName';
import { randomPhoneNumber } from '../random/randomPhoneNumber';

const productNames = ['반숙란', '구운란', '맛있는 반숙란'];
const productOptions = ['20구', '30구'];

const MONTH = 1000 * 60 * 60 * 24 * 30;

export class OrderFactory {
  static pk = 0;

  static createDispatchedOrder(
    shoppingMall: string,
    index: number,
    partial?: Partial<DispatchedOrder>
  ): DispatchedOrder {
    const uniqueCodePrefix = getUniqueCodePrefix(shoppingMall);
    const number = hashToNumber(shoppingMall) + index;
    const optionNumber = number % productOptions.length;

    OrderFactory.pk += 1;
    return {
      orderNumber: `order-${index}(${shoppingMall})`,
      buyerLoginId: `buyer-${index}(${shoppingMall})`,
      orderStatus: '결제 완료',

      paymentDate: randomDate({ range: MONTH }).toISOString(),
      productName: productNames[number % productNames.length],
      option: productOptions[optionNumber],
      optionCode: `code-${productOptions[optionNumber]}`,
      quantity: randomFromArray([1, 2, 3, 4, 5]),
      contactNumber: randomPhoneNumber(),
      name: randomName(),

      shoppingMall,
      uniqueCode: `${uniqueCodePrefix}-${OrderFactory.pk.toString(36)}`,
      address: `address-${index}(${shoppingMall})`,
      orderDate: new Date().toISOString(),
      updatedAt: new Date().getTime(),
      ...partial,
    };
  }
}

function getUniqueCodePrefix(shoppingMall: string) {
  if (shoppingMall.startsWith('NAVER')) {
    return 'NSS';
  }

  if (shoppingMall.startsWith('ALI')) {
    return 'ALI';
  }

  if (shoppingMall.startsWith('11번가')) {
    return 'ELV';
  }

  return shoppingMall;
}
