import { DispatchedOrder } from '@sweep/contract';
import { amplitude } from 'src/utils';
import { tryCatch } from 'src/utils/function/tryCatch';
import api, { APIResponse } from '../api';

interface CommonCancelOrder {
  uniqueCode: string;
  shippingCompany?: string | null;
  shippingNumber?: string | null;
}

export type CanceledOrder =
  | ({ success: true } & CommonCancelOrder)
  | ({ success: false; failReason: string } & CommonCancelOrder);

export type AcceptCancelResponse = APIResponse<CanceledOrder[]>;

export async function acceptCanceledOrdes(orders: DispatchedOrder[]) {
  const url = '/cancel-order/accept-cancel';
  const response = await tryCatch(async () => {
    const res = await api.post<APIResponse<CanceledOrder[]>>(url, {
      infoArray: orders,
    });

    return res.data;
  });

  if (
    response?.success === true &&
    response.data.some((order) => order.success === true)
  ) {
    amplitude.track('Approve Order-cancellation (Server)', {
      'Order Count': orders.length,
    });
  }

  return response;
}

export type RejectCancelResponse = APIResponse<CanceledOrder[]>;

export async function rejectCanceledOrder(
  orders: DispatchedOrder[],
  rejectReason: string
) {
  const url = '/cancel-order/reject-cancel';
  const response = await tryCatch(async () => {
    const res = await api.post<RejectCancelResponse>(url, {
      infoArray: orders,
      rejectReason,
    });

    return res.data;
  });

  if (
    response?.success === true &&
    response.data.some((order) => order.success === true)
  ) {
    amplitude.track('Reject Order-cancellation (Server)', {
      'Order Count': orders.length,
    });
  }

  return response;
}
