import { Order } from '@sweep/contract';
import { OMSStore } from 'src/stores/OMSStore';

export function isAliExpressOrder(order: Order, oms: OMSStore): boolean {
  const partnerId = order.partnerId;
  if (partnerId != null) {
    const partner = oms.partner.getPartnerById(partnerId);
    const partnerName = partner?.name;

    if (partnerName == null) {
      return false;
    }

    return isAliExpressName(partnerName);
  }

  const shoppingMall = order.shoppingMall;
  if (shoppingMall != null) {
    return isAliExpressName(shoppingMall);
  }

  return false;
}

function isAliExpressName(name: string): boolean {
  return name.includes('알리') || name.toLowerCase().includes('ali');
}
