import { isDispatchedOrder, Order } from '@sweep/contract';
import { isNotEmptyString } from 'src/utils/string';
import { AbstractPlugin } from '../../interface';

export class DispatchOrderFieldPlugin extends AbstractPlugin {
  transform = (orders: Order[]): Promise<Order[]> => {
    return Promise.resolve(orders.map((order) => this.transformOrder(order)));
  };

  transformOrder = (order: Order): Order => {
    if (!isDispatchedOrder(order)) {
      return order;
    }

    return {
      ...order,
      optionCode: isNotEmptyString(order.detailOptionCode)
        ? order.detailOptionCode
        : order.optionCode,
      // TODO(@이지원): marketName 가능하면 없애기
      marketName: order.shoppingMall,
    };
  };
}
