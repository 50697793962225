/**
 * longest common continuous subsequence
 * @example
 * lccs('가나다라마', '다라바바') // 2('다라')
 */
export function lccs(a: string, b: string): number {
  let max = 0;
  for (let i = 0; i < a.length; i++) {
    const lccs = _compareLccs(a, b, i);
    if (lccs > max) {
      max = lccs;
    }
  }

  return max;
}

function _compareLccs(a: string, b: string, paddingB: number): number {
  let max = 0;
  let count = 0;

  for (let i = paddingB; i < a.length; i++) {
    if (i - paddingB >= b.length) {
      break;
    }

    if (a[i] === b[i - paddingB]) {
      count++;
    } else {
      if (count > max) {
        max = count;
      }
      count = 0;
    }
  }

  if (count > max) {
    max = count;
  }

  return max;
}

export function lccsWithoutSpace(a: string, b: string): number {
  const aWithoutSpace = a.replace(/\s/g, '');
  const bWithoutSpace = b.replace(/\s/g, '');

  return lccs(aWithoutSpace, bWithoutSpace);
}
