import { useEffect, useState } from 'react';
import { useOMSStore } from 'src/hooks/useOMSStore';

const ColumnMappingHandle = ({
  isBasedOnPartnerExcel,
  columnMapping,
  setColumnMapping,
  partnerKoreanHeader,
  exampleContents,
  concatDict = {},
  fixedColumnSettings = {},
}) => {
  const oms = useOMSStore();
  const findKeyByValue = (object, value) =>
    Object.keys(object).find((key) => object[key] === value);

  const [koreanUserHeader, setKoreanUserHeader] = useState(
    oms.user.excelHeaderKeys.map((key) => oms.user.excelColumnMapping[key])
  );

  const [userColumnMapping, setUserColumnMapping] = useState(
    oms.user.excelColumnMapping
  );

  const handleMappingChange = (userIndex, partnerIndex) => {
    let englishValue =
      Object.entries(userColumnMapping).find(
        ([key, value]) => value === koreanUserHeader[userIndex]
      )?.[0] || koreanUserHeader[userIndex];

    if (userIndex === -2) {
      englishValue = 'productName';
    }

    let updatedMapping = { ...columnMapping };

    let koreanValue = partnerKoreanHeader[partnerIndex];
    let existingKey = Object.keys(columnMapping).find(
      (key) => columnMapping[key] === koreanValue
    );
    updatedMapping[existingKey] = undefined;
    updatedMapping[englishValue] = koreanValue;
    setColumnMapping(updatedMapping);
  };

  const completeRequiredFields = (array, mapping) => {
    const requiredFields = [
      'option',
      'productName',
      'quantity',
      'name',
      'contactNumber',
      'address',
      'shippingCompany',
      'shippingNumber',
      'orderNumber',
      'postCode',
    ];
    const matching = {
      productName: '상품명',
      name: '고객명',
      contactNumber: '연락처',
      postCode: '우편번호',
      address: '주소',
      deliveryMessage: '배송메시지',
      shippingCompany: '택배사',
      shippingNumber: '운송장번호',
      option: '상품옵션',
      quantity: '개수',
      note: '비고',
      orderNumber: '주문번호',
    };

    let updatedArray = [...array];
    let updatedMapping = { ...mapping };
    // array 내 null 값 제거
    updatedArray = updatedArray.filter((field) => field);

    // array에 필수 필드가 없으면 필수 필드를 추가
    requiredFields.forEach((field) => {
      if (!updatedArray.includes(field)) {
        updatedArray.push(field);
        updatedMapping[field] = matching[field];
      }
    });

    return { updatedArray, updatedMapping };
  };

  useEffect(() => {
    let updatedMapping = { ...oms.user.excelColumnMapping };
    let updatedOrder = [...oms.user.excelHeaderKeys];
    ({ updatedArray: updatedOrder, updatedMapping } = completeRequiredFields(
      updatedOrder,
      updatedMapping
    ));

    setKoreanUserHeader(updatedOrder.map((key) => updatedMapping[key]));
    setUserColumnMapping(updatedMapping);
  }, [oms.user.excelHeaderKeys, oms.user.excelColumnMapping]);

  const origToNorMal = (key) => {
    let dict = {
      origName: 'productName',
      origOption: 'option',
      origQuantity: 'quantity',
    };
    return dict[key] || key;
  };

  const getUserExcelContent = (row, index) => {
    return (
      <span className="pl-[16px]">
        {concatDict[oms.user.excelHeaderKeys[index]]
          ? concatDict[oms.user.excelHeaderKeys[index]].map(
              (concatKey, concatIndex) => {
                return (
                  row?.[
                    partnerKoreanHeader.findIndex((korPartner) => {
                      return (
                        concatKey === findKeyByValue(columnMapping, korPartner)
                      );
                    })
                  ] ||
                  (fixedColumnSettings[concatKey] ? (
                    <span className="text-blue-500">
                      {fixedColumnSettings[concatKey]}
                    </span>
                  ) : (
                    ''
                  ))
                );
              }
            )
          : row?.[
              partnerKoreanHeader.findIndex((korPartner) => {
                return (
                  origToNorMal(oms.user.excelHeaderKeys[index]) ===
                  findKeyByValue(columnMapping, korPartner)
                );
              })
            ] || ' '}
      </span>
    );
  };

  const partnerSelect = (key, index) => {
    //isBasedOnPartnerExcel false
    return (
      <>
        {(concatDict[oms.user.excelHeaderKeys[index]] && (
          <div className="flex h-[40px] w-full items-center justify-center rounded-lg border border-gray-300 px-[16px]">
            {concatDict[oms.user.excelHeaderKeys[index]].map(
              (concatKey, concatIndex) => (
                <>
                  {(concatIndex !== 0 ? ' + ' : '') +
                    (columnMapping[concatKey] || '고정값')}
                </>
              )
            )}
          </div>
        )) ||
          (['origName', 'origOption', 'origQuantity'].includes(
            oms.user.excelHeaderKeys[index]
          ) && (
            <div className="flex h-[40px] w-full items-center justify-center rounded-lg border border-gray-300 px-[16px]">
              {'(원본) ' +
                columnMapping[
                  {
                    origName: 'productName',
                    origOption: 'option',
                    origQuantity: 'quantity',
                  }[oms.user.excelHeaderKeys[index]]
                ]}
            </div>
          )) || (
            <select
              value={partnerKoreanHeader.findIndex((korPartner) => {
                return (
                  findKeyByValue(userColumnMapping, key) ===
                  findKeyByValue(columnMapping, korPartner)
                );
              })}
              onChange={(e) => {
                return handleMappingChange(index, e.target.value);
              }}
              className={`h-[40px] w-full rounded-lg border border-gray-300 px-[16px] ${
                partnerKoreanHeader.findIndex((korPartner) => {
                  return (
                    findKeyByValue(userColumnMapping, key) ===
                    findKeyByValue(columnMapping, korPartner)
                  );
                }) === -1
                  ? 'text-gray-500'
                  : 'text-gray-900'
              }`}
            >
              <option value={-1}>선택되지 않음</option>

              {partnerKoreanHeader.map((column, index) => (
                <option key={index} value={index}>
                  {column}
                </option>
              ))}
            </select>
          )}
      </>
    );
  };

  const aggregationSelect = (key, index) => {
    //isBasedOnPartnerExcel true
    return (
      <>
        {concatDict[partnerKoreanHeader[index]] ? (
          <div className="flex h-[40px] w-full items-center justify-center rounded-lg border border-gray-300 px-[16px]">
            {'고정값'}
          </div>
        ) : (
          <select
            value={koreanUserHeader.findIndex((korUser) => {
              return (
                findKeyByValue(userColumnMapping, korUser) ===
                findKeyByValue(columnMapping, key)
              );
            })}
            onChange={(e) => {
              return handleMappingChange(e.target.value, index);
            }}
            className={`h-[40px] w-full rounded-lg border border-gray-300 px-[16px] ${
              koreanUserHeader.findIndex((korUser) => {
                return (
                  findKeyByValue(userColumnMapping, korUser) ===
                  findKeyByValue(columnMapping, key)
                );
              }) === -1
                ? 'text-gray-500'
                : 'text-gray-900'
            }`}
          >
            <option value={-1}>선택되지 않음</option>

            {koreanUserHeader.map((column, index) => (
              <option key={index} value={index}>
                {column}
              </option>
            ))}
          </select>
        )}
      </>
    );
  };

  const 해석을위한추가Select가필요한지 = (
    isBasedOnPartnerExcel,
    key,
    index
  ) => {
    if (isBasedOnPartnerExcel) {
      return false;
    }

    if (oms.user.excelHeaderKeys[index] !== 'option') {
      return false;
    }

    if (oms.user.excelHeaderKeys.includes('productName')) {
      return false;
    }

    return true;
  };

  const additionalSelectForProductName = () => (
    <select
      value={partnerKoreanHeader.findIndex((korPartner) => {
        return 'productName' === findKeyByValue(columnMapping, korPartner);
      })}
      onChange={(e) => {
        handleMappingChange(-2, e.target.value);
        console.log(columnMapping, partnerKoreanHeader);
      }}
      className={`mr-[20px] h-[40px] w-full rounded-lg border border-gray-300 px-[16px] ${
        columnMapping['productName'] ? 'text-gray-900' : 'text-gray-500'
      }`}
    >
      <option value={-1}>선택되지 않음</option>

      {partnerKoreanHeader.map((column, index) => (
        <option key={index} value={index}>
          {column}
        </option>
      ))}
    </select>
  );

  return (
    <>
      {(isBasedOnPartnerExcel ? partnerKoreanHeader : koreanUserHeader)?.map(
        (key, index) => (
          <div key={index} className="flex  flex-col whitespace-nowrap">
            <div
              className={`mb-[32px] flex min-w-[200px] items-center justify-between ${
                해석을위한추가Select가필요한지(
                  isBasedOnPartnerExcel,
                  key,
                  index
                )
                  ? 'min-w-[420px]'
                  : 'min-w-[200px]'
              }`}
            >
              {해석을위한추가Select가필요한지(
                isBasedOnPartnerExcel,
                key,
                index
              ) && additionalSelectForProductName()}
              <div className="flex w-full flex-col">
                {isBasedOnPartnerExcel
                  ? aggregationSelect(key, index)
                  : partnerSelect(key, index)}
              </div>
            </div>
            <div
              className={`mb-[8px] flex-1 truncate whitespace-nowrap bg-[#F5F7FA] px-[16px] py-[3px] text-left text-[14px] ${
                해석을위한추가Select가필요한지(
                  isBasedOnPartnerExcel,
                  key,
                  index
                )
                  ? 'bg-blue-100 '
                  : ' '
              }`}
            >
              {isBasedOnPartnerExcel ? key : koreanUserHeader[index]}
            </div>

            <div>
              {exampleContents?.map((row, rowIndex) => (
                <div
                  key={rowIndex}
                  className={`relative mb-[8px] flex-1 truncate whitespace-nowrap px-[12px] text-left text-[14px] leading-[21px] text-[#6B7683]`}
                >
                  <span className="absolute">•</span>
                  {isBasedOnPartnerExcel ? (
                    <span className="pl-[16px]">
                      {fixedColumnSettings[
                        concatDict[partnerKoreanHeader[index]]
                      ] ||
                        row?.[index] ||
                        ' '}
                    </span>
                  ) : (
                    getUserExcelContent(row, index)
                  )}
                </div>
              ))}
            </div>
          </div>
        )
      )}
    </>
  );
};

export default ColumnMappingHandle;
