import { EvaluateOperatorResult, Token } from '../interface';

export const INVALID_EXPRESSION: EvaluateOperatorResult = {
  success: false,
  message: 'Invalid expression',
};

export const CAN_NOT_CONVERT_TO_NUMBER = (
  operand: Token
): EvaluateOperatorResult => ({
  success: false,
  message: `${operand}는 숫자로 변환될 수 없습니다.`,
});

export const CAN_NOT_CONVERT_TO_BOOLEAN = (
  operand: Token
): EvaluateOperatorResult => ({
  success: false,
  message: `${operand}는 불리언으로 변환될 수 없습니다.`,
});
