import { tryCatch } from 'src/utils/function/tryCatch';
import api, { APIResponse } from '../api';

export type FulfillOrderResponse = APIResponse<FulfilledOrderResult[]>;

export interface FulfilledOrder {
  uniqueCode?: string;
  orderNumber?: string;
  shippingCompany: string;
  shippingNumber: string;
}

export type FulfilledOrderResult =
  | ({ success: true } & FulfilledOrder)
  | ({ success: false; failReason: string } & FulfilledOrder);

export async function fulfillOrder(orders: FulfilledOrder[]) {
  const url = '/fulfill-order/fulfill-orders';
  return tryCatch(async () => {
    const res = await api.post<FulfillOrderResponse>(url, {
      infoArray: orders,
    });

    return res.data;
  });
}
