export function formatDate(date: Date, format: string): string {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  const hour = date.getHours();
  const twelveHour = hour % 12 === 0 ? 12 : hour % 12;
  const minute = date.getMinutes();
  const second = date.getSeconds();

  const ampm = hour < 12 ? '오전' : '오후';

  return format
    .replace('yyyy', `${year}`)
    .replace('yy', `${year}`.slice(2))
    .replace('MM', applyPadding(month))
    .replace('M', `${month}`)
    .replace('dd', applyPadding(day))
    .replace('d', `${day}`)
    .replace('HH', applyPadding(hour))
    .replace('H', `${hour}`)
    .replace('hh', applyPadding(twelveHour))
    .replace('h', `${twelveHour}`)
    .replace('mm', applyPadding(minute))
    .replace('m', `${minute}`)
    .replace('ss', applyPadding(second))
    .replace('s', `${second}`)
    .replace('a', ampm);
}

function applyPadding(num: number): string {
  return num < 10 ? `0${num}` : `${num}`;
}
