import './App.css';

import { OverlayProvider } from 'overlay-kit';
import { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import PluginSettingScreen from 'screens/setting/plugin/PluginSettingScreen';
import LoadingProvider from 'stores/loading/LoadingProvider';
import { OMSContextProvider } from 'stores/OMSContext';
import { OMSStore } from 'stores/OMSStore';
import ErrorReporter from 'src/third-parties/ErrorReporter';
import UserExcelFormatRegistrationModal from './modals/IntegratedExcelFormatRegistrationModal';
import OMSServiceLayout from './routes/OMSServiceLayout';
import AddressValidScreen from './screens/AddressValidScreen';
import AdminUserSettingScreen from './screens/Admin/AdminUserSettingScreen';
import IntroduceBaljuScreen from './screens/BalJuGwanRi';
import OrderDispatchScreen from './screens/dispatch/OrderDispatchScreen';
import OrderDispatchSearchScreen from './screens/dispatch-search/OrderDispatchSearchScreen';
import IntroduceOrderScreen from './screens/JuMunSoOJip';
import LoginScreen from './screens/Login/LoginScreen';
import MyPageScreen from './screens/MyPage/MyPageScreen';
import FullPageScreen from './screens/NewLandingPagewithFullPager';
import OrderAggregationScreen from './screens/order-aggregation/OrderAggregationScreen';
import CompositionMatchingManagementScreen from './screens/product/CompositionMatchingManage/CompositionMatchingManageScreen';
import OrderCombinationSettingsScreen from './screens/product/OrderCombinationSettingsScreen';
import ProductManagementScreen from './screens/product/product-management/ProductManagementScreen';
import PreRegistrationScreen from './screens/Registration/PreRegistrationScreen';
import RegisterConsultingScreen from './screens/Registration/RegisterConsultingScreen';
import PartnerManagementScreen from './screens/setting/partnerManagement/PartnerManagementScreen';
import QaSettingScreen from './screens/setting/qa/QaSettingScreen';
import SupplierManagementScreen from './screens/setting/supplierManagement/SupplierManagementScreen';
import DailySettlementManagementScreen from './screens/Settlement/DailySettlementManagementScreen';
import UploadShippingInfoScreen from './screens/UploadShippingInfoScreen';
import { Toaster } from './third-parties/toast';

function App() {
  const [oms] = useState(() => new OMSStore());

  // TODO(@이지원): react-router-dom loader에 넣기
  useEffect(() => {
    if (oms.user.isLoggedIn) {
      oms.loading.batch(() => oms.init());

      // Add Sentry user tracking
      try {
        const userId = oms.user.userId.value!;
        const email = oms.user.email;
        ErrorReporter.setUser(userId, email);
      } catch (error) {
        ErrorReporter.reportError(error as Error, {
          message: 'Error setting Sentry user:',
        });

        console.error('Error setting Sentry user:', error);
      }
    }
  }, [oms]);

  useEffect(() => {
    ErrorReporter.leaveBreadcrumb({
      message: 'App mounted',
    });

    return () => {
      ErrorReporter.leaveBreadcrumb({
        message: 'App unmounted',
      });
    };
  }, []);

  return (
    <OMSContextProvider value={oms}>
      <LoadingProvider>
        <OverlayProvider>
          <Routes>
            <Route path="/address" element={<AddressValidScreen />} />
            <Route
              path="/pre-registration"
              element={<PreRegistrationScreen />}
            />
            <Route
              path="/register-consulting"
              element={<RegisterConsultingScreen />}
            />
            <Route path="/sign-in" element={<LoginScreen />} />

            <Route path="pre-register" element={<FullPageScreen />} />

            <Route
              path="/introduce-order-dispatch"
              element={<IntroduceOrderScreen />}
            />
            <Route
              path="/introduce-order-manage"
              element={<IntroduceBaljuScreen />}
            />

            <Route path="/" element={<FullPageScreen />} />
            <Route path="/" element={<OMSServiceLayout />}>
              <Route path="my-page/*" element={<MyPageScreen />} />
              <Route
                path="order-aggregation"
                element={<OrderAggregationScreen />}
              />
              <Route path="order-dispatch" element={<OrderDispatchScreen />} />
              <Route
                path="order-dispatch-search"
                element={<OrderDispatchSearchScreen />}
              />
              <Route
                path="/upload-shipping-info"
                element={<UploadShippingInfoScreen />}
              />
              <Route path="product">
                <Route index element={<ProductManagementScreen />} />
                <Route
                  path="combination"
                  element={<OrderCombinationSettingsScreen />}
                />
                <Route
                  path="composition-matching"
                  element={<CompositionMatchingManagementScreen />}
                />
              </Route>

              <Route
                path="settlement/daily-management"
                element={
                  <DailySettlementManagementScreen userType="supplier" />
                }
              />
              <Route path="setting">
                <Route path="partner" element={<PartnerManagementScreen />} />
                <Route path="plugin" element={<PluginSettingScreen />} />
                <Route path="qa" element={<QaSettingScreen />} />
                <Route
                  path="integrated-excel-format"
                  element={<UserExcelFormatRegistrationModal />}
                />
              </Route>

              <Route path="retailer">
                <Route
                  path="upload-shipping-info"
                  element={<UploadShippingInfoScreen />}
                />
                <Route path="settlement">
                  <Route
                    path="daily-management"
                    element={
                      <DailySettlementManagementScreen userType="retailer" />
                    }
                  />
                </Route>
                <Route path="setting">
                  <Route path="partner" element={<PartnerManagementScreen />} />
                  <Route
                    path="supplier"
                    element={<SupplierManagementScreen />}
                  />
                </Route>
              </Route>

              <Route
                path="admin/user-setting"
                element={<AdminUserSettingScreen />}
              />
            </Route>
          </Routes>
          <Toaster />
        </OverlayProvider>
      </LoadingProvider>
    </OMSContextProvider>
  );
}

export default App;
