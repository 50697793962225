import readXlsxFile, { readSheetNames } from 'read-excel-file';
import * as XLSX from 'xlsx';
import backendApi from './backendApi';
import { translateHeaderRowInEnglish } from './headerColumnMapping';
import { convertToUTF8, detectEncoding } from './utils';

function _fillEmptyWithAbove(rows: any[]) {
  const specificExcelheader = [
    '발주일',
    '상품명',
    '수량',
    '수취인',
    '휴대전화',
    '주소',
    '배송 메모',
  ];

  const headerRow = rows[0];

  const headersCorrectOrder =
    headerRow.length === specificExcelheader.length &&
    specificExcelheader.every((header, index) => headerRow[index] === header);

  if (!headersCorrectOrder) {
    return;
  }

  const headersToCheck = ['발주일', '수취인', '휴대전화', '주소', '배송 메모'];

  const headerIndicesToCheck = headersToCheck.map((header) =>
    specificExcelheader.indexOf(header)
  );

  for (let rowIndex = 1; rowIndex < rows.length; rowIndex++) {
    const row = rows[rowIndex];
    const isEmptyRow = headerIndicesToCheck.every((index) => !row[index]);
    if (isEmptyRow) {
      const aboveIndex = rowIndex - 1;
      const aboveRow = rows[aboveIndex];

      const hasAboveRowValues = headerIndicesToCheck.every(
        (index) => aboveRow[index]
      );
      if (hasAboveRowValues) {
        _copyValuesFromAbove(rows, rowIndex, headerIndicesToCheck);
      }
    }
  }

  return rows;
}

function _copyValuesFromAbove(
  rows: any[],
  rowIndex: number,
  headerIndices: number[]
) {
  const aboveRow = rows[rowIndex - 1];
  headerIndices.forEach((index) => {
    if (!rows[rowIndex][index] && aboveRow[index]) {
      rows[rowIndex][index] = aboveRow[index];
    }
  });
}

//xlsx 파일을 읽어오는 함수
const readFile = async (
  file: File,
  settings: ReadExcelFileSettings = {
    isHeaderIndexNeeded: false,
    isOrderFile: true,
  }
): Promise<
  | {
      rows: any[];
      headerRowIndex?: number;
    }
  | any[]
> => {
  const { isHeaderIndexNeeded = false, isOrderFile = true } = settings;

  return new Promise(async (resolve, reject) => {
    let rows;
    // 비밀번호가 걸려있는 파일인지 체크하는 로직
    let isPasswordProtected = false;
    try {
      await readSheetNames(file);
    } catch (e) {
      isPasswordProtected = true;
    }
    if (isPasswordProtected) {
      let password: any = '';
      let data: any = { success: false };
      let already_tried = false;
      while (password === '' || !data.success) {
        password = window.prompt(
          `${already_tried ? '암호가 틀렸습니다. ' : ''}${file.name.normalize(
            'NFC'
          )}의 암호를 입력해주세요. 모른다면 취소를 눌러주세요.`
        );
        if (password === null) {
          reject(Error(`${file.name.normalize('NFC')} 비밀번호 입력안함`));
          return;
        }
        if (password === '') {
          continue;
        }
        try {
          data = await backendApi.unprotectXlsx(file, password);
        } catch (e) {
          reject(e);
          return;
        }
        if (!data.success) {
          already_tried = true;
        }
      }
      rows = await JSON.parse(data.data);
    } else {
      let data;
      try {
        data = await backendApi.readXlsx(file);
        if (!data.success) {
          throw new Error('failed to read file with backend');
        }
        rows = await JSON.parse(data.data);
        console.log('file read from backend', rows);
      } catch (e) {
        console.log('file read from backend failed', data);
        const sheets = await readSheetNames(file);
        rows = sheets.map(async (sheet) => {
          try {
            const rows = await readXlsxFile(file, { sheet: sheet });
            if (rows && rows.length > 0 && rows[0].length > 0) {
              return rows;
            }
          } catch (e) {
            console.warn(e);
          }
          return;
        });
        rows = (await Promise.all(rows)).filter((rows) => rows)[0];
        console.log('file read from read-excel-file', rows);
      }
    }
    try {
      const headersToFind = ['name', 'address'];
      // 헤더 행 찾기
      let headerRowIndex = isOrderFile
        ? rows.findIndex((row: any) => {
            return headersToFind.every((header) => {
              return translateHeaderRowInEnglish(row).includes(header);
            });
          })
        : 0;

      if (headerRowIndex === -1) {
        const optionalHeaders = [
          'productName',
          'option',
          'quantity',
          'name',
          'contactNumber',
          'postCode',
          'address',
          'deliveryMessage',
          'shippingCompany',
          'shippingNumber',
          'note',
          'uniqueCode',
          'originFile',
          'orderNumber',
          'productCode',
          'platformCode',
          'price',
          'platformName',
        ];
        //rows 중 조건을 만족하는 것의 갯수 세기
        headerRowIndex = rows.findIndex((row: any) => {
          return (
            optionalHeaders.filter((header) =>
              translateHeaderRowInEnglish(row).includes(header)
            ).length > 1
          );
        });
      }

      if (headerRowIndex !== -1) {
        const newRows = rows.slice(headerRowIndex);
        _fillEmptyWithAbove(newRows);
        if (isHeaderIndexNeeded) {
          resolve({ rows: newRows, headerRowIndex });
        }
        resolve(newRows);
      } else {
        reject(new Error('Header row not found.'));
      }
    } catch (e) {
      reject(e);
    }
  });
};

const readXlsFile = async (file: File): Promise<any[]> => {
  if (!file) {
    return [];
  }
  const htmlToJson = (html: string) => {
    return new Promise((resolve, reject) => {
      const parser = new DOMParser();
      const htmlDoc = parser.parseFromString(
        html.replace('&nbsp;', ' '),
        'text/html'
      );
      try {
        const temp_rows = Array.from(
          htmlDoc.getElementsByTagName('table')[0].rows
        );
        const parsed_data = temp_rows.map((row) =>
          Array.from(row.cells).map((cell) => cell.textContent)
        );
        const firstRow = parsed_data[0];
        console.log('raw first row from html', firstRow);
        const encoding = detectEncoding(firstRow);
        console.log('detected encoding from html', encoding);

        // if (encoding === null) {
        //   reject(new Error('Encoding not detected'));
        //   return;
        // }
        const result =
          encoding === 'ok'
            ? parsed_data
            : convertToUTF8(parsed_data, encoding || '');
        resolve(result);
      } catch (e) {
        window.alert(
          '파일이 열리지 않습니다. 엑셀 프로그램에서도 열리는지 확인해주시고, 만약 열린다면 다른이름으로 저장하여 그 파일을 업로드 해주시면 감사하겠습니다.'
        );
        reject(e);
      }
    });
  };

  const readHTMLfromFileasText = (file: File) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = async (event: any) => {
        const data: any = event.target.result;
        const result = await htmlToJson(data);
        resolve(result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsText(file);
    });
  };
  const readHTMLfromFileasBinarystring = (file: File) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = async (event: any) => {
        const data: any = event.target.result;
        let result;
        try {
          result = await htmlToJson(data);
        } catch (e) {
          reject(e);
        }
        console.log(result);
        resolve(result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsBinaryString(file);
    });
  };
  return new Promise<any>(async (resolve, reject) => {
    let is_html = false;
    const primary_reader = new FileReader();
    await new Promise((resolve, reject) => {
      primary_reader.onload = async function (e: any) {
        const text = e.target.result;
        if (text.includes('text/html')) {
          console.log('html file');
          is_html = true;
        }
        resolve(null);
      };
      const blob = file.slice(0, 1024);
      primary_reader.readAsText(blob, 'utf-8');
    });

    if (is_html) {
      try {
        resolve(await readHTMLfromFileasBinarystring(file));
        return;
      } catch (e) {
        reject(e);
        return;
      }
    }

    const data = await backendApi.readXlsx(file);
    if (data.success) {
      let rows = await JSON.parse(data.data);
      console.log('file read with backend pandas', rows);
      const encoding = detectEncoding(rows[0]);
      console.log('detected encoding from backend', encoding);
      if (encoding !== null) {
        if (encoding !== 'ok') {
          rows = convertToUTF8(rows, encoding);
        }
        resolve(rows);
        return;
      }
    } else {
      console.log('file read with backend pandas failed', data);
    }

    const reader = new FileReader();
    reader.onload = async (event: any) => {
      const data: any = event.target.result;
      // console.log(data);
      const workbook: any = XLSX.read(data, {
        type: 'binary',
        cellDates: false,
        raw: true,

        // bookType: 'xls',
      });
      const sheetName = workbook.SheetNames[0];
      let worksheet = workbook.Sheets[sheetName];

      const isEnglishOnly = /^[A-Za-z\s]+$/;
      let firstRow: any = XLSX.utils.sheet_to_json(worksheet, {
        header: 1,
        range: 'A1:Z1',
      })[0];

      if (!Array.isArray(firstRow)) {
        firstRow = Object.values(firstRow); // 객체의 값을 배열로 변환
      }
      console.log('raw first row from xls', firstRow);
      let encoding = detectEncoding(firstRow);
      console.log('detected encoding from xls ', encoding);
      if (encoding === null) {
        try {
          const result = await htmlToJson(data);
          resolve(result);
        } catch (e) {
          const confirmResult = window.confirm(
            `'${file.name.normalize('NFC')}'의 실제 파일형식과 확장명(${(
              file.name.normalize('NFC').split('.').pop() as string
            ).toLowerCase()})이 일치하지 않습니다. 그래도 강제로 파일을 여시겠습니까?`
          );
          if (!confirmResult) {
            reject(e); // Error('File format and extension do not match.'));
            return;
          } else {
            encoding = 'ok';
          }
        }
      }

      if (encoding !== 'ok') {
        firstRow = convertToUTF8(firstRow, encoding || '');
        console.log('converted first row', firstRow);
      }
      let originalJsonData = [];
      if (isEnglishOnly.test(firstRow.join(''))) {
        const range = XLSX.utils.decode_range(worksheet['!ref']); // 워크시트의 범위를 가져옵니다
        range.s.r = 1; // 시작 행을 1로 설정합니다 (0부터 시작하는 인덱스), 즉 두 번째 행이 됩니다

        originalJsonData = XLSX.utils.sheet_to_json(worksheet, {
          header: 1,
          range: range,
          defval: '',
        });
      } else {
        originalJsonData = XLSX.utils.sheet_to_json(worksheet, {
          header: 1,
          defval: '',
        });
      }

      if (!originalJsonData || originalJsonData.length === 0) {
        if (sheetName.length > 1) {
          worksheet = workbook.Sheets[workbook.SheetNames[1]];
          originalJsonData = XLSX.utils.sheet_to_json(worksheet, {
            header: 1,
            defval: '',
          });
        } else {
          reject(new Error('No data found'));
          return;
        }
      }
      const xls_data =
        encoding === 'ok'
          ? originalJsonData
          : convertToUTF8(originalJsonData, encoding || '');
      console.log('file data read with XLSX', xls_data);
      resolve(xls_data);
    };
    reader.onerror = (error) => {
      reject(error);
    };
    // reader.readAsText(file, 'utf-8');
    reader.readAsBinaryString(file);
  });
};

interface ReadExcelFileSettings {
  isHeaderIndexNeeded?: boolean;
  isOrderFile?: boolean;
}
export const readExcelFile = async (
  file: File,
  settings: ReadExcelFileSettings = {
    isHeaderIndexNeeded: false,
    isOrderFile: true,
  }
) => {
  const fileName = file?.name.normalize('NFC') || '';
  const ext = (fileName.split('.').pop() as string).toLowerCase();

  const { isHeaderIndexNeeded = false, isOrderFile = true } = settings;

  let rows: any;

  if (ext === 'xlsx') {
    try {
      rows = await readFile(file, settings);
    } catch (e) {
      console.error(e);
      throw e;
    }
    //Date 타입의 데이터를 string으로 변환 년.월.일 형식으로 변환

    if (isHeaderIndexNeeded) {
      rows.rows = rows.rows.map((row: any[]) => {
        return row.map((cell) => {
          if (cell instanceof Date) {
            return cell.toLocaleDateString('ko-KR');
          }
          return cell;
        });
      });
    } else {
      rows = rows.map((row: any[]) => {
        return row.map((cell) => {
          if (cell instanceof Date) {
            return cell.toLocaleDateString('ko-KR');
          }
          return cell;
        });
      });
    }
  } else {
    rows = await readXlsFile(file);
    const headersToFind = ['name', 'address'];

    // 헤더 행 찾기
    let headerRowIndex = rows.findIndex((row: any[]) =>
      headersToFind.every((header) => {
        return translateHeaderRowInEnglish(row).includes(header);
      })
    );

    if (headerRowIndex !== -1) {
      rows = rows.slice(headerRowIndex);

      _fillEmptyWithAbove(rows);
    } else {
      const optionalHeaders = [
        'productName',
        'option',
        'quantity',
        'name',
        'contactNumber',
        'postCode',
        'address',
        'deliveryMessage',
        'shippingCompany',
        'shippingNumber',
        'note',
        'uniqueCode',
        'originFile',
        'orderNumber',
        'productCode',
        'platformCode',
        'price',
        'platformName',
      ];
      //rows중 조건을 만족하는 것의 갯수 세기
      headerRowIndex = rows.findIndex((row: any[]) => {
        return (
          optionalHeaders.filter((header) =>
            translateHeaderRowInEnglish(row).includes(header)
          ).length > 1
        );
      });
    }
    if (isHeaderIndexNeeded) {
      return { rows, headerRowIndex };
    }
  }

  return rows;
};

export async function isFilePasswordProtected(file: File) {
  try {
    // Excel 파일을 읽어옵니다. 비밀번호로 보호된 파일은 읽을 수 없습니다.
    await readSheetNames(file);
    // 비밀번호 보호가 걸리지 않았으므로 false 반환
    return false;
  } catch (error) {
    console.warn('file password protected :', error);
    // 비밀번호 보호로 인해 오류가 발생하면 true 반환
    return true;
  }
}
