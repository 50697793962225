import { Order } from '@sweep/contract';
import { http, HttpResponse } from 'msw';
import { BACKEND_URL } from 'src/env';
import { LOCALSTORAGE_FASTAPI_PREFIX } from 'src/mocks/common/constants';
import {
  CheckedAddress,
  CheckOrdersAddressResponse,
} from 'src/network/fastapi';
import { isNotNil } from 'src/utils/function';
import { sleep } from 'src/utils/function/sleep';
import { LocalStorageValue } from 'src/utils/mobx';

const FASTAPI_URL = `${BACKEND_URL}/fastapi`;

export type CheckAddressType = 'fail-long-time' | 'fail' | 'none';
export const checkAddressTypeStorage = new LocalStorageValue<CheckAddressType>({
  key: `${LOCALSTORAGE_FASTAPI_PREFIX}/check-address`,
  defaultValue: 'none',
});

export function createCheckAddressHandler() {
  const checkAddressType = checkAddressTypeStorage.value;

  if (checkAddressType === 'none') {
    return null;
  }

  return http.post(`${FASTAPI_URL}/correct_orders`, async ({ request }) => {
    if (checkAddressType === 'fail-long-time') {
      await sleep(1000 * 10);
    }

    const orders = (await request.json()) as Order[];
    const orderAddressArray = orders
      .map((order) => order.address)
      .filter(isNotNil);

    const checkedAddressArray = orderAddressArray.map<CheckedAddress>(
      (address) => ({
        result: 'fail',
        data: address,
        orig_data: address,
        main_address: '',
        detail_address: '',
        tried_data: address,
        message: ['시도가 없습니다.'],
        tried_tried_data: [],
      })
    );

    return HttpResponse.json<CheckOrdersAddressResponse>({
      success: true,
      data: checkedAddressArray,
    });
  });
}
