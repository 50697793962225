import { MatchedProduct } from 'models/CompositionMatching';
import { OMSStore } from 'stores/OMSStore';
import { isNotNil } from 'utils/function';

export function transformToCMProducts(
  products: Partial<MatchedProduct>[],
  oms: OMSStore
) {
  return products
    .map((product) => transformToCMProductFromPartial(product, oms))
    .filter(isNotNil);
}

function transformToCMProductFromPartial(
  value: Partial<MatchedProduct>,
  oms: OMSStore
): MatchedProduct | null {
  if (value.productId == null) {
    return null;
  }

  const product = oms.product.getProduct(value.productId);
  if (product == null) {
    return null;
  }

  if (product.units == null || product.units.length === 0) {
    return {
      productId: value.productId,
      quantity: value.quantity ?? 1,
    };
  }

  if (product.units.every((unit) => unit.unit !== value.unit)) {
    return null;
  }

  return {
    productId: value.productId,
    quantity: value.quantity ?? 1,
    unit: value.unit,
  };
}
