export function randomName() {
  const names = [
    '윤현일',
    '김민수',
    '이승준',
    '박지훈',
    '김태훈',
    '김민재',
    '이승현',
    '김민석',
    '이승민',
    '이지원',
    '김민지',
    '김민주',
    '박지원',
    '박지민',
    '윤서영',
    '이지민',
    '박지윤',
    '여현수',
    '박지현',
    '김민현',
    '강예찬',
    '김은서',
    '김경한',
    '김경훈',
    '김경민',
  ];

  return names[Math.floor(Math.random() * names.length)];
}
