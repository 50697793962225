import { useState } from 'react';

const ScrollingImages = ({ teamImages }) => {
  // 애니메이션 제어 할 상태 변수
  const [animate, setAnimate] = useState(true);
  // 마우스가 슬라이더 진입 시 호출, false값으로 애니메이션 중지
  const onStop = () => setAnimate(false);
  // 마우스가 슬라이더 떠날 때 호출, true값으로 애니메이션 재시작
  const onRun = () => setAnimate(true);

  return (
    <div className="wrapper">
      <div className="slide_container">
        <ul
          className="slide_wrapper"
          onMouseEnter={onStop}
          onMouseLeave={onRun}
        >
          <div className={'slide original' + (animate ? '' : ' stop')}>
            {teamImages.map((images, i) => (
              <li key={i}>
                <div className="hover:animate-bounce item bg-white rounded-[22px] shadow-lg flex justify-center items-center">
                  <img src={images} alt={`Scrolling ${i + 1}`} />
                </div>
              </li>
            ))}
          </div>
          <div className={'slide clone' + (animate ? '' : ' stop')}>
            {teamImages.map((images, i) => (
              <li key={i}>
                <div className="item bg-white rounded-[22px] shadow-lg flex justify-center items-center">
                  <img src={images} alt={`Scrolling ${i + 1}`} />
                </div>
              </li>
            ))}
          </div>
        </ul>
      </div>
    </div>
  );
};
export default ScrollingImages;
