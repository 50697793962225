import { Breadcrumb } from '@sentry/node';
import * as Sentry from '@sentry/react';

// TODO(@형준): 추후에 타입 정의 변경 필요 ?
type SweepBreadcrumb = Breadcrumb;

export function reportError(error: Error, hint?: any) {
  // TODO(@형준): hint 타입 올바르게 동작하게 수정 필요
  Sentry.captureException(error, hint);
}

function reportMessage(message: string) {
  Sentry.captureMessage(message);
}

function leaveBreadcrumb(breadcrumb: SweepBreadcrumb) {
  Sentry.addBreadcrumb(breadcrumb);
}

function setUser(userId: string, email?: string) {
  Sentry.setUser({
    id: userId,
    email: email,
  });
}

const ErrorReporter = {
  reportError,
  reportMessage,
  leaveBreadcrumb,
  setUser,
};

export default ErrorReporter;
