import { useDebounceState } from '@sweep/utils';
import { useCallback } from 'react';
import { isNotEmptyString } from 'src/utils/string';

export function useSearchState<T extends Record<string, unknown>>(
  defaultValue: string = '',
  keys: string[]
) {
  const [search, setSearch, debouncedSearch] = useDebounceState(
    defaultValue,
    80
  );

  const handleSearchChange = (value: string) => {
    setSearch(value);
  };

  const filterOrders = useCallback(
    (items: T[]): T[] => {
      const searchItems = debouncedSearch
        .split(',')
        .map((item) => item.trim())
        .filter((item) => item !== '');
      if (searchItems.length === 0) {
        return items;
      }

      return items.filter((order) =>
        searchItems.some((search) => isContain(search, keys, order))
      );
    },
    [debouncedSearch, keys]
  );

  return [search, handleSearchChange, filterOrders] as const;
}

function isContain<T extends Record<string, unknown>>(
  search: string,
  keys: string[],
  order: T
) {
  return keys.some((key) => {
    const value = getStringValue(order[key]);

    return isNotEmptyString(value) && value.includes(search);
  });
}

function getStringValue(value: unknown): string | null {
  if (typeof value === 'string') {
    return value;
  }

  if (typeof value === 'number') {
    return value.toString();
  }

  return null;
}
