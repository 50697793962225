import { useState } from 'react';
import Button from 'src/components/buttons/Button';
import { preRegister } from 'src/network/user';
import sweepIcon from '../../images/Sweep.png';
import RegistrationInput from './components/RegistrationInput';
import RegistrationMemoTextarea from './components/RegistrationMemoTextarea';
import RegistrationMonthlyOrderCountSelect from './components/RegistrationMonthlyOrderCountSelect';

const PreRegistrationScreen = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const monthlyOrderCountOptions = [
    '5,000개 미만',
    '5,000개 이상 10,000개 미만',
    '10,000개 이상',
  ];

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [monthlyOrderCount, setMonthlyOrderCount] = useState(
    monthlyOrderCountOptions[0]
  );
  const [memo, setMemo] = useState('');

  const DEFAULT_ERROR_MESSAGE = '답변을 입력해 주세요.';

  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [companyNameError, setCompanyNameError] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState('');

  const validateValue = (
    value: string,
    setValueError: (value: string) => void
  ): boolean => {
    if (value.length === 0) {
      setValueError(DEFAULT_ERROR_MESSAGE);
      return false;
    }
    setValueError('');
    return true;
  };

  const validateName = (value: string): boolean =>
    validateValue(value, setNameError);
  const validateCompanyName = (value: string): boolean =>
    validateValue(value, setCompanyNameError);
  const validatePhoneNumber = (value: string): boolean =>
    validateValue(value, setPhoneNumberError);

  const validateEmail = (value: string): boolean => {
    if (value.length === 0) {
      setEmailError(DEFAULT_ERROR_MESSAGE);
      return false;
    }
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailPattern.test(value)) {
      setEmailError('올바른 이메일을 입력해 주세요.');
      return false;
    }
    setEmailError('');
    return true;
  };

  const handleName = (value: string) => {
    setName(value);
    validateName(value);
  };
  const handleEmail = (value: string) => {
    setEmail(value);
    validateEmail(value);
  };
  const handleCompanyName = (value: string) => {
    setCompanyName(value);
    validateCompanyName(value);
  };
  const handlePhoneNumber = (value: string) => {
    const digitsAndHypens: string = filterDigitsAndHypens(value);
    setPhoneNumber(digitsAndHypens);
    validatePhoneNumber(digitsAndHypens);
  };
  const handleMonthlyOrderCount = (value: string) => {
    setMonthlyOrderCount(value);
  };
  const handleMemo = (value: string) => {
    setMemo(value);
  };

  const submitPreRegistration = async () => {
    const isNameValid: boolean = validateName(name);
    const isEmailValid: boolean = validateEmail(email);
    const isCompanyNameValid: boolean = validateCompanyName(companyName);
    const isPhoneNumberValid: boolean = validatePhoneNumber(phoneNumber);

    if (
      isNameValid &&
      isEmailValid &&
      isCompanyNameValid &&
      isPhoneNumberValid
    ) {
      const response = await preRegister({
        name,
        email,
        companyName,
        phoneNumber,
        monthlyOrderCount,
        memo,
      });
      if (response.status === 200) {
        setIsSubmitted(true);
      }
    }
  };

  return (
    <>
      {isSubmitted ? (
        <div className="align-center flex h-screen flex-col items-center justify-center gap-[12px]">
          <img src={sweepIcon} alt="sweep icon" className="w-[144px]" />
          <span className="text-[28px]">도입문의가 접수되었습니다.</span>
        </div>
      ) : (
        <div className="flex h-full items-center justify-center">
          <div className="w-full max-w-[500px] space-y-[14px] bg-white px-[14px]">
            <div className="flex flex-col items-center justify-center">
              <span className="text-[24px] font-bold">
                스윕 (Sweep) 도입 문의
              </span>
              <span className="text-[16px] text-gray-600">
                스윕팀 매니저가 맞춤 설명을 드리고 발주 업무를 함께 고민합니다.
              </span>
            </div>
            <RegistrationInput
              label="성함"
              value={name}
              type="text"
              error={nameError}
              onChange={handleName}
            />
            <RegistrationInput
              label="업무 메일 주소"
              value={email}
              type="email"
              error={emailError}
              onChange={handleEmail}
            />
            <RegistrationInput
              label="회사명"
              value={companyName}
              type="text"
              error={companyNameError}
              onChange={handleCompanyName}
            />
            <RegistrationInput
              label="연락처"
              value={phoneNumber}
              type="text"
              error={phoneNumberError}
              onChange={handlePhoneNumber}
            />
            <RegistrationMonthlyOrderCountSelect
              options={monthlyOrderCountOptions}
              onChange={handleMonthlyOrderCount}
            />
            <RegistrationMemoTextarea value={memo} onChange={handleMemo} />
            <Button
              className="h-[50px] w-full"
              size="lg"
              name="도입 문의하기"
              onClick={submitPreRegistration}
            />
          </div>
        </div>
      )}
    </>
  );
};

const filterDigitsAndHypens = (value: string): string =>
  value.replace(/[^0-9-]/g, '');

export default PreRegistrationScreen;
