import { Order } from '@sweep/contract';
import { formatDate } from 'src/utils/date/formatDate';
import { AbstractPlugin } from '../../interface';

interface DateRule {
  target: string;
  format: string;
  excelFormat?: string;
}

interface DatePluginConfig {
  rules: DateRule[];
}

export class DatePlugin extends AbstractPlugin<DatePluginConfig> {
  transform = (orders: Order[]): Promise<Order[]> => {
    return Promise.resolve(orders.map((order) => this.transformOrder(order)));
  };

  transformOrder = (order: Order): Order => {
    const today = new Date();
    this.config.rules.forEach((rule) => {
      const { target, format, excelFormat } = rule;
      order[target] = formatDate(today, format);
      order.excelModel = {
        ...order.excelModel,
        formats: {
          ...order.excelModel?.formats,
          [target]: {
            type: 'date',
            numFmt: DEFAULT_NumFmt_RECORD[format] ?? excelFormat ?? format,
          },
        },
      };
    });

    return order;
  };
}

const DEFAULT_NumFmt_RECORD: Record<string, string | undefined> = {
  'yyyy.M.d': 'yyyy.m.d',
  'yyyy-MM-dd': 'yyyy-mm-dd;@',
  'yyyy. M. d': '[$-ko-Kore-KR,1]yyyy. m. d;@',
};
