import { useOMSStore } from 'hooks/useOMSStore';
import { observer } from 'mobx-react-lite';
import { Plugin } from 'stores/plugin/interface';
import PartnerItem from '../components/PartnerItem';

function PartnerPluginSection() {
  const oms = useOMSStore();
  const partners = oms.partner.partners;

  const handleCreatePlugin = (partnerId: string) => async () => {
    await oms.partner.createPlugin(partnerId, {
      pluginId: '',
    });
  };

  const handleDeletePlugin =
    (partnerId: string) => async (pluginId: string) => {
      await oms.partner.deletePlugin(partnerId, pluginId);
    };

  const handleChange = (pluginId: string, plugin: Partial<Plugin>) => {
    oms.plugin.update(pluginId, plugin);
  };

  return (
    <div className="flex flex-col gap-2">
      <p className="text-3xl">Partner</p>
      <div className="flex w-full flex-col gap-4">
        {partners.map((partner) => (
          <PartnerItem
            key={partner._id}
            partner={partner}
            onCreate={handleCreatePlugin(partner._id)}
            onChange={handleChange}
            onDelete={handleDeletePlugin(partner._id)}
          />
        ))}
      </div>
    </div>
  );
}

export default observer(PartnerPluginSection);
