import { DispatchedOrder } from '@sweep/contract';
import { useCallback, useState } from 'react';

export function useShoppingMallsState(defaultValue = []) {
  const [shoppingMalls, setShoppingMalls] = useState<string[]>(defaultValue);

  const handleShoppingMallsChange = (value: string[]) => {
    setShoppingMalls(value);
  };

  const filterOrders = useCallback(
    (orders: DispatchedOrder[]): DispatchedOrder[] => {
      if (shoppingMalls.length === 0) {
        return orders;
      }

      return orders.filter((order) =>
        shoppingMalls.includes(order.shoppingMall)
      );
    },
    [shoppingMalls]
  );

  return [shoppingMalls, handleShoppingMallsChange, filterOrders] as const;
}
