import { useOMSStore } from 'hooks/useOMSStore';
import { useEffect, useRef } from 'react';
import UserInfoButton from '../components/UserInfoButton';
import logoutIcon from '../icons/logout.png';

const UserInfoModal = ({ show, toggleModal, reference, userStore = {} }) => {
  const modalRef = useRef(null);
  const oms = useOMSStore();

  useEffect(() => {
    function updateModalPosition() {
      if (!modalRef.current || !reference.current) {
        return;
      }

      const { bottom, left } = reference.current.getBoundingClientRect();
      modalRef.current.style.top = `${bottom + window.scrollY + 10}px`;
      modalRef.current.style.left = `${left - 136}px`;
    }

    function handleEscapePress(e) {
      if (e.key === 'Escape') {
        toggleModal();
      }
    }
    function handleOutsideClick(e) {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        toggleModal();
      }
    }

    document.addEventListener('keydown', handleEscapePress);
    document.addEventListener('mousedown', handleOutsideClick);
    window.addEventListener('resize', updateModalPosition);
    updateModalPosition();
    return () => {
      document.removeEventListener('keydown', handleEscapePress);
      document.removeEventListener('mousedown', handleOutsideClick);
      window.removeEventListener('resize', updateModalPosition);
    };
  }, [toggleModal]);

  if (!show) {
    return null;
  }

  return (
    <div
      ref={modalRef}
      className="fixed w-[187px] min-h-[160px] bg-white border border-[#E0E7ED] rounded-[8px] z-50 flex flex-col"
    >
      <div className="border-b border-[#E0E7ED]">
        <div className="flex flex-col items-start px-[16px] py-[10px] w-full text-left font-medium text-[#343D4B] transition duration-300 ease-in-out">
          {userStore?.userInfo?.name && (
            <div className="flex items-center text-[14px]">
              <span className="font-bold">{userStore.userInfo.name}</span>
              &nbsp;님
            </div>
          )}
          {userStore?.billing?.price && (
            <div className="flex h-[28px] bg-[#057FF0] rounded-[4px] mt-[10px]">
              <div className="flex items-center justify-center text-white text-[12px] px-[8px] py-[2px]">
                구독 중
              </div>
            </div>
          )}
          {!userStore?.billing?.price && (
            <div className="flex h-[28px] bg-[#057FF0] rounded-[4px] mt-[10px]">
              <div className="flex items-center justify-center text-white text-[12px] px-[8px] py-[2px]">
                미구독
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col flex-1">
        <UserInfoButton
          to="my-page/payment-info"
          className="h-[41px] border-b border-[#E0E7ED]"
          onClick={toggleModal}
        >
          결제정보 관리
        </UserInfoButton>
        <UserInfoButton to="/sign-in" className="h-[40px]" isLast={true}>
          <div
            className="flex items-center"
            onClick={() => {
              localStorage.removeItem('@authToken');
              localStorage.removeItem('@userId');
              oms.user.logout();
              window.location.reload();
            }}
          >
            <span>로그아웃</span>
            <img
              src={logoutIcon}
              alt="logout icon"
              style={{ marginLeft: '4px' }} // Inline style for exact spacing
              className="w-[15px] h-[15px]"
            />
          </div>
        </UserInfoButton>
      </div>
    </div>
  );
};

export default UserInfoModal;
