import { Link, Navigate, Route, Routes, useParams } from 'react-router-dom';
import OMSContainer from '../../components/OMSContainer';
import PaymentInfoScreen from './PaymentInfoScreen';

const MyPageScreen = () => {
  const { '*': params } = useParams();

  const isActive = (link: string) => {
    return params === link
      ? 'border-b-[2px] border-[#343D4B] text-[#343D4B]'
      : 'text-[#9FB1C1]';
  };

  return (
    <OMSContainer>
      <nav className="flex h-[52px] w-full flex-row items-center">
        <Link
          to="payment-info"
          className={`flex h-[52px] flex-row items-center justify-center gap-[10px] px-[20px] py-[16px] ${isActive(
            'payment-info'
          )}`}
        >
          <span className="flex h-[26px] w-[98px] items-center text-[18px] font-bold leading-[26px]">
            결제정보 관리
          </span>
        </Link>
      </nav>
      <div className="h-px w-full">
        <div className="size-full bg-[#E0E7ED]"></div>
      </div>

      <Routes>
        <Route index element={<Navigate to="payment-info" />} />
        <Route path="payment-info" element={<PaymentInfoScreen />} />
      </Routes>
    </OMSContainer>
  );
};

export default MyPageScreen;
