import { Button } from 'design-system/components/Button';
import { useOMSStore } from 'hooks/useOMSStore';
import { observer } from 'mobx-react-lite';
import { Plugin } from 'stores/plugin/interface';
import { pluginIds } from 'stores/plugin/PluginExecutionService';
import { cva } from 'src/third-parties/tailwind';
import { InlineEdit } from './InlineEdit';

interface PluginItemProps {
  pluginId: string;
  onChange: (plugin: Partial<Plugin>) => void;
  onDelete: () => void;
}

function PluginItem({ pluginId, onChange, onDelete }: PluginItemProps) {
  const oms = useOMSStore();
  const plugin = oms.plugin.getPluginById(pluginId);

  if (plugin == null) {
    return <p>Wrong PluginId</p>;
  }

  return (
    <div>
      <div className="flex gap-2">
        <p className="text-medium-s">{plugin._id}</p>
        <Button color="gray" variant="line" onClick={() => onDelete()}>
          삭제
        </Button>
      </div>
      <div className={row()}>
        <span className={rowLabel()}>pluginId</span>
        <select
          className="rounded-md bg-gray-100 hover:bg-gray-200"
          value={plugin.pluginId}
          onChange={(e) => onChange({ pluginId: e.target.value })}
        >
          <option value=""></option>
          {pluginIds.map((pluginId) => (
            <option key={pluginId} value={pluginId}>
              {pluginId}
            </option>
          ))}
        </select>
      </div>
      <div className={row()}>
        <span className={rowLabel()}>config</span>
        <InlineEdit
          value={JSON.stringify(plugin.config)}
          onChange={(value) => onChange({ config: JSON.parse(value) })}
        />
      </div>
      <hr className="my-4" />
    </div>
  );
}

const row = cva('flex gap-2');
const rowLabel = cva('w-[80px] shrink-0 text-gray-500');

export default observer(PluginItem);
