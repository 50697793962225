import copy from 'fast-copy';
import { useEffect, useRef, useState } from 'react';
import { useOMSStore } from 'src/hooks/useOMSStore';
import Button from '../components/buttons/Button';
import { isValid } from '../utils/utils';
import PartnerDetailHeaderMatchingComponent from './partnerForm/PartnerDetailHeaderMatchingComponent';
import PartnerExcelUpload from './partnerForm/PartnerExcelUpload';
import PartnerHeaderComponents from './partnerForm/PartnerHeaderComponents';

const PartnerForm = ({
  type = 'register',
  partnerType = 'retailer',
  partnerInfo = undefined,
  onFormSubmit,
  initialRegisterFile = { target: { files: [] } },
}) => {
  const oms = useOMSStore();
  const partnerInfoRef = useRef(null);

  const [newPartnerInfo, setNewPartnerInfo] = useState(
    { ...copy(partnerInfo) } || {}
  );

  useEffect(() => {
    setNewPartnerInfo({
      file: null,
      name: '',
      email: '',
      phone: '',
      productIds: [],
      header: [],
      headerForShipping: [],
      columnMapping: {},
      exampleContents: [],
      customizedSettings: {},
      indexes: {},
      ...copy(partnerInfo),
    });
  }, [partnerInfo]);

  const handlePartnerInfoChange = (e) => {
    const { name, value } = e.target;
    setNewPartnerInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (!initialRegisterFile) {
      return;
    }
    if (initialRegisterFile?.target?.files?.length > 0) {
      partnerInfoRef.current?.handleExcelFileUpload(initialRegisterFile);
    } else if (typeof initialRegisterFile?.[0]?.[0] === 'string') {
      partnerInfoRef.current?.handleExcelFileUploadRows(initialRegisterFile);
    }
  }, [initialRegisterFile, partnerInfoRef]);

  // isChecked 상태가 변경될 때마다 실행되는 useEffect

  const validateNewPartnerName = (name) => {
    const isSupplier = partnerType === 'supplier';
    const partnerInfos = isSupplier
      ? oms.supplier.suppliers
      : oms.partner.partners;
    const partnerTypeName = isSupplier ? '공급사' : '파트너사';

    if (!name) {
      alert(`${partnerTypeName} 이름을 입력해주세요.`);
      return false;
    }

    const hasNameAlreadyExisted = partnerInfos.some((partner) => {
      if (type === 'update') {
        return newPartnerInfo._id !== partner._id && partner.name === name;
      } else {
        return partner.name === name;
      }
    });
    if (hasNameAlreadyExisted) {
      alert(`이미 등록된 ${partnerTypeName}에요.`);
      return false;
    }

    const existingPartnerIncludingNewName = partnerInfos.find((partner) => {
      if (type === 'update') {
        return (
          newPartnerInfo._id !== partner._id && partner.name.includes(name)
        );
      } else {
        return partner.name && partner.name.includes(name);
      }
    });
    if (existingPartnerIncludingNewName) {
      const existingPartnerName = existingPartnerIncludingNewName.name;
      const confirmToRegister = window.confirm(
        `이미 '${name}'라는 ${partnerTypeName} 이름을 포함하는 '${existingPartnerName}'이라는 이름의 ${partnerTypeName}가 존재해요. 그래도 새 ${partnerTypeName}공급사를 추가하시겠어요?`
      );
      if (!confirmToRegister) {
        return false;
      }
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // 방어로직 시작 - 필수 입력값이 없을 경우

    const isValidPartnerName = validateNewPartnerName(newPartnerInfo?.name);

    if (!isValidPartnerName) {
      return;
    }

    const formData = {
      ...newPartnerInfo,
    };
    if (partnerType === 'supplier') {
      formData.productIds = newPartnerInfo.productIds;
      if (newPartnerInfo.file) {
        let templateFile = new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = function (e) {
            const arrayBuffer = e.target.result;
            resolve(new Uint8Array(arrayBuffer));
          };
          reader.onerror = function (e) {
            reject(e);
          };
          reader.readAsArrayBuffer(newPartnerInfo.file);
        });
        templateFile = await templateFile;
        templateFile = JSON.stringify(Array.from(templateFile));
        // let templateFile = reader.readAsArrayBuffer(file);
        //
        formData.customizedSettings.xlsxTemplateSetting = {
          enabled: newPartnerInfo?.file?.name?.endsWith('.xlsx'),
          name: newPartnerInfo.file.name,
          headerRowIndex: newPartnerInfo.indexes.headerRowIndex,
          minRowIndex: newPartnerInfo.indexes.minRowIndex,
          templateFile: templateFile,
        };
      }
    }

    if (partnerType === 'retailer') {
      if (isValid(newPartnerInfo.headerForShipping)) {
        formData.headerForShipping = newPartnerInfo.headerForShipping;
      }
    }

    delete formData._id;
    delete formData.file;
    delete formData.indexes;

    onFormSubmit(formData);
  };

  const Divider = (margin) => (
    <div
      className={`my-[ 
      h-px bg-[#DDE4EB]${margin || 40}px] self-stretch`}
    ></div>
  );

  return (
    <form onSubmit={handleSubmit} className="mb-8 w-full px-[40px]">
      <PartnerHeaderComponents
        newPartnerInfo={newPartnerInfo}
        handlePartnerInfoChange={handlePartnerInfoChange}
      />
      {Divider()}

      <PartnerExcelUpload
        partnerInfo={newPartnerInfo}
        handlePartnerInfoChange={handlePartnerInfoChange}
        ref={partnerInfoRef}
      />
      {Divider()}
      <PartnerDetailHeaderMatchingComponent
        newPartnerInfo={newPartnerInfo}
        handlePartnerInfoChange={handlePartnerInfoChange}
        partnerType={partnerType}
      />
      <div className="flex justify-end">
        <Button
          name={type === 'update' ? '수정하기' : '등록하기'}
          type="submit"
        />
      </div>
    </form>
  );
};

export default PartnerForm;
