import { Order } from '@sweep/contract';
import { isEqualArray } from '@sweep/utils';
import { FileMatching } from 'forms/partner/matching/interface';
import { createUniqeCode } from 'utils/utils';
import { findHeaderKeys } from './findHeaderKeys';
import { getShoppingMall } from './getShoppingMall';

export function transformToOrders(partnerMatching: FileMatching): Order[] {
  const file = partnerMatching.file;
  const header = file.data.at(0);
  const rows = file.data.slice(1);

  if (header == null) {
    return [];
  }

  const shoppingMall = getShoppingMall(header);

  const matching = partnerMatching.partner.matchings?.find((matching) =>
    isEqualArray(matching.header, header)
  );
  if (matching == null) {
    return [];
  }

  const matchingId = matching.id;
  const columnMapping = matching.columnMapping;

  const headerKeys = findHeaderKeys(header, columnMapping);
  return rows.map<Order>((row, index) => {
    const order = row.reduce<Record<string, string | number>>(
      (acc, value, index) => {
        const key = headerKeys.at(index);
        if (key == null) {
          return acc;
        }
        const trimmedValue = value.trim();

        if (trimmedValue === '') {
          return acc;
        }

        acc[key] = trimmedValue;
        return acc;
      },
      {}
    );

    return {
      uniqueCode: createUniqeCode(),
      partnerId: partnerMatching.partner._id,
      originFile: file.name,
      originFilIndex: index,
      matchingId,
      shoppingMall: shoppingMall ?? undefined,
      ...order,
    } as Order;
  });
}
