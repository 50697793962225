import { Order } from '@sweep/contract';
import { http, HttpResponse } from 'msw';
import { BACKEND_URL } from 'src/env';
import {
  AcceptCancelResponse,
  CanceledOrder,
} from 'src/network/order/cancelOrder';
import { isNotNil } from 'src/utils/function';
import { sleep } from 'src/utils/function/sleep';
import { LocalStorageValue } from 'src/utils/mobx';
import { LOCALSTORAGE_DISPATCH_PREFIX } from '../../../common/constants';

const CANCEL_ORDER_URL = `${BACKEND_URL}/cancel-order`;

export const createOrderHandlers = () => {
  return [createAcceptCancelHandler(), createRejectCancelHandler()].filter(
    isNotNil
  );
};

export type AcceptCancelType = 'success' | 'partial-fail' | 'fail' | 'none';
export const acceptCancelTypeStorage = new LocalStorageValue<AcceptCancelType>({
  key: `${LOCALSTORAGE_DISPATCH_PREFIX}/cancel-order-type`,
  defaultValue: 'none',
});

function createAcceptCancelHandler() {
  const acceptCancelType = acceptCancelTypeStorage.value;

  if (acceptCancelType === 'none') {
    return null;
  }

  return http.post(`${CANCEL_ORDER_URL}/accept-cancel`, async ({ request }) => {
    await sleep(1000);
    const { infoArray: orders } = (await request.json()) as {
      infoArray: Order[];
    };

    switch (acceptCancelType) {
      case 'success':
        return HttpResponse.json<AcceptCancelResponse>({
          success: true,
          data: mapSuccessCanceledOrders(orders),
        });
      case 'partial-fail':
        return HttpResponse.json<AcceptCancelResponse>({
          success: true,
          data: mapPartialFailCanceledOrders(orders),
        });
      case 'fail':
        return HttpResponse.json<AcceptCancelResponse>({
          success: false,
          error: 'msw-fail',
        });
    }
  });
}

export type RejectCancelType = 'success' | 'partial-fail' | 'fail' | 'none';
export const rejectCancelTypeStorage = new LocalStorageValue<RejectCancelType>({
  key: `${LOCALSTORAGE_DISPATCH_PREFIX}/reject-cancel-type`,
  defaultValue: 'none',
});

function createRejectCancelHandler() {
  const rejectCancelType = rejectCancelTypeStorage.value;

  if (rejectCancelType === 'none') {
    return null;
  }

  return http.post(`${CANCEL_ORDER_URL}/reject-cancel`, async ({ request }) => {
    await sleep(1000);
    const { infoArray: orders } = (await request.json()) as {
      infoArray: Order[];
      rejectReason: string;
    };

    switch (rejectCancelType) {
      case 'success':
        return HttpResponse.json<AcceptCancelResponse>({
          success: true,
          data: mapSuccessCanceledOrders(orders),
        });
      case 'partial-fail':
        return HttpResponse.json<AcceptCancelResponse>({
          success: true,
          data: mapPartialFailCanceledOrders(orders),
        });
      case 'fail':
        return HttpResponse.json<AcceptCancelResponse>({
          success: false,
          error: 'msw-fail',
        });
    }
  });
}

function mapSuccessCanceledOrders(orders: Order[]) {
  return orders.map<CanceledOrder>((order) => ({
    success: true,
    uniqueCode: order.uniqueCode,
    shippingCompany: order.shippingCompany ?? 'CJ대한통운',
    shippingNumber:
      order.shippingNumber ?? String(Math.floor(Math.random() * 1000000000)),
  }));
}

function mapPartialFailCanceledOrders(orders: Order[]) {
  return orders.map<CanceledOrder>((order, index) =>
    index % 2 === 0
      ? { success: true, uniqueCode: order.uniqueCode }
      : {
          success: false,
          failReason: 'msw-order-error',
          uniqueCode: order.uniqueCode,
        }
  );
}
