import { imageSearch } from 'images/index';

const SearchNoData = () => {
  return (
    <>
      <div className="mt-[160px] flex items-center justify-center">
        <div className="flex-col">
          <div>
            <img src={imageSearch} alt="img-search" className="w-[180px]" />
          </div>
          <div className="mt-[20px]" />
          <div>
            <p className="text-[20px] font-medium text-gray-400">
              검색 결과가 없습니다.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchNoData;
