import { useOMSContext } from 'stores/OMSContext';

export function useOMSStore() {
  const oms = useOMSContext();

  if (oms == null) {
    throw new Error('OMS store is not initialized');
  }

  return oms;
}
