import { ExcelFormat, ExcelStyle,Order  } from '@sweep/contract';
import { AbstractPlugin } from '../../interface';

interface ExcelModelPluginConfig {
  styles: Record<string, ExcelStyle>;
  formats: Record<string, ExcelFormat>;
}

export class ExcelModelPlugin extends AbstractPlugin<
  ExcelModelPluginConfig | undefined
> {
  transform = (orders: Order[]): Promise<Order[]> => {
    return Promise.resolve(orders.map((order) => this.transformOrder(order)));
  };

  transformOrder = (order: Order) => {
    const excelModel = this.config;
    if (excelModel == null) {
      return order;
    }

    const { formats, styles } = excelModel;
    const { formats: prevFormats, styles: prevStyles } = order.excelModel ?? {
      formats: {},
      styles: {},
    };

    const transformedOrder = {
      ...order,
      excelModel: {
        formats: {
          ...prevFormats,
          ...formats,
        },
        styles: {
          ...prevStyles,
          ...styles,
        },
      },
    };

    return transformedOrder;
  };
}
