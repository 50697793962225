import { observer } from 'mobx-react-lite';
import { PropsWithChildren } from 'react';
import { useOMSStore } from 'src/hooks/useOMSStore';
import { Product, Unit } from 'src/models/Product';
import { Separated } from 'src/utils/react/components/Separated';
import { getOptionSupplierMatchings } from '../../services/getOptionSupplierMatchings';
import Divider from './Divider';

interface OptionSupplierCellsProps {
  product: Product;
}

function OptionSupplierCells({ product }: OptionSupplierCellsProps) {
  const oms = useOMSStore();

  const getSupplierName = (supplierId: string | undefined | null) => {
    if (supplierId == null) {
      return null;
    }

    const supplier = oms.supplier.getSupplier(supplierId);

    return supplier?.name ?? null;
  };

  const optionSupplierMatchings = getOptionSupplierMatchings(product)?.sort(
    (ma, mb) => mb.options.length - ma.options.length
  );

  if (optionSupplierMatchings == null) {
    return (
      <EmptyOptionSupplierCells
        supplierName={getSupplierName(product.supplierId)}
      />
    );
  }

  return (
    <div className="flex h-full flex-col">
      <Separated with={<Divider type="horizontal" />}>
        {optionSupplierMatchings.map((matching, index) => (
          <OptionSupplierRow
            key={index}
            options={matching.options}
            supplier={getSupplierName(matching.supplierId)}
          />
        ))}
      </Separated>
    </div>
  );
}

function OptionSupplierRow({
  options,
  supplier,
}: {
  options: Unit[];
  supplier?: string | null;
}) {
  const optionNames = options
    .map((option) => option.unit)
    .sort((a, b) => a.localeCompare(b));

  return (
    <div className="flex">
      <div className="w-3/5">
        <div className="gap-8px p-8px flex min-w-0 flex-wrap">
          {optionNames.map((option) => (
            <Option key={option}>{option}</Option>
          ))}
        </div>
      </div>
      <Divider type="vertical" />
      <div className="w-2/5">
        <SupplierCell supplier={supplier} />
      </div>
    </div>
  );
}

function Option({ children }: PropsWithChildren) {
  return (
    <p className="rounded-8px text-medium-s bg-gray-100 px-[12px] py-[4px] text-blue-700">
      {children}
    </p>
  );
}

function EmptyOptionSupplierCells({
  supplierName,
}: {
  supplierName: string | null;
}) {
  return (
    <div className="flex h-full">
      <div className="w-3/5"></div>
      <Divider type="vertical" />
      <div className="w-2/5">
        <SupplierCell supplier={supplierName} />
      </div>
    </div>
  );
}

function SupplierCell({ supplier }: { supplier?: string | null }) {
  return (
    <div className="pl-16px flex h-full items-center">
      <p className="text-medium-s text-gray-700">{supplier}</p>
    </div>
  );
}

export default observer(OptionSupplierCells);
