import { action, makeObservable, observable } from 'mobx';
import backendApi from 'utils/backendApi';
import { isValid } from 'utils/utils';
import AuthStore from './AuthStore';
import LoadingStore from './LoadingStore';

class UserStore {
  priceInfos: any[] = [];
  combinationSettings: any[] = [];
  billing: { [key: string]: any } = {};

  constructor() {
    makeObservable(this, {
      priceInfos: observable,
      combinationSettings: observable,
      billing: observable,

      setPriceInfos: action,
      setCombinationSettings: action,
      setBilling: action,

      loginUser: action,
      initializeUserData: action,
    });
  }

  setPriceInfos(infos: any[]) {
    this.priceInfos = infos;
  }

  setCombinationSettings(settings: any[]) {
    this.combinationSettings = settings;
  }

  setBilling(billing: { [key: string]: any }) {
    this.billing = billing;
  }

  async initializeUserData() {
    try {
      LoadingStore.setIsLoading(true);
      const result = await backendApi.initializeUserData();

      if (isValid(result)) {
        const billing = result?.billing || {};
        const combinationSettings = result?.orderCombinationSettings;

        if (isValid(combinationSettings)) {
          this.setCombinationSettings(combinationSettings);
        }
        if (isValid(billing)) {
          this.setBilling(billing);
        }
      }
    } catch (err) {
      console.error('Error initializing user data :', err);
      throw err;
    } finally {
      LoadingStore.setIsLoading(false);
    }
  }

  async loginUser(loginData: { email: string; password: string }) {
    try {
      const result: { [key: string]: any } =
        await backendApi.loginUser(loginData);
      if (result?.token && result?.userId) {
        AuthStore.setToken(result?.token);

        localStorage.setItem('@userId', result?.userId);

        await this.initializeUserData();

        return true;
      }
      return false;
    } catch (error) {
      console.error('Error occurred while login', error);
      return false;
    }
  }
}

export default new UserStore();
