import { observer } from 'mobx-react-lite';
import { useId } from 'react';
import { cva } from 'src/third-parties/tailwind';

interface MockSettingSelectProps<T> {
  value: T;
  onChange: (value: T) => void;
  label: string;
  options: { value: T; label: string }[];
}

function MockSettingSelect<T extends string>({
  value,
  onChange,
  label,
  options,
}: MockSettingSelectProps<T>) {
  const id = useId();

  return (
    <div className={container()}>
      <label htmlFor={id} className={labelClass()}>
        {label}
      </label>
      <select
        id={id}
        value={value}
        onChange={(e) => onChange(e.target.value as T)}
        className={selectClass()}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
}

const container = cva('flex items-center gap-4');
const labelClass = cva('text-bold-m');
const selectClass = cva('w-40 rounded border border-gray-200 p-2');

export default observer(MockSettingSelect);
