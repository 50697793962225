import axios from 'axios';
import configs from 'utils/configs';

const BACKEND_URL = configs.backendUrl;

const token = localStorage.getItem('@authToken');

const api = axios.create({
  baseURL: BACKEND_URL,
  headers: token ? { Authorization: token } : {},
});

api.interceptors.request.use(function (config) {
  const token =
    localStorage.getItem('@authToken') ??
    localStorage.getItem('LocalStorageValue#User#authToken');
  if (token) {
    config.headers.Authorization = token;
  }
  return config;
});

let isConnectionErrorAlert = false;

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.code === 'ERR_NETWORK' && !isConnectionErrorAlert) {
      alert('네트워크 연결이 끊겼어요. 새로고침 후 다시 시도해주세요.');
      isConnectionErrorAlert = true;
    }

    return Promise.reject(error);
  }
);

export default api;

export type APIResponse<T> =
  | { success: true; data: T }
  | { success: false; error: string };
