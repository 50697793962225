import { Order } from '@sweep/contract';
import { MatchedOrder } from '../interface';

export function separateOrdersByMatched(orders: Order[]): {
  matched: MatchedOrder[];
  unmatched: Order[];
} {
  const matched = orders.filter(
    (order) => order.data != null && order.data.length > 0
  );
  const unmatched = orders.filter(
    (order) => order.data == null || order.data.length === 0
  );

  return {
    matched,
    unmatched,
  };
}
