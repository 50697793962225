import { action, makeObservable, observable } from 'mobx';

interface LocalStorageValueOptions<T> {
  key: string;
  defaultValue: T;
  parse?: (value: string) => T;
  stringify?: (value: T) => string;
}

export class LocalStorageValue<T> {
  key: string;
  parse: (value: string) => T;
  stringify: (value: T) => string;

  value: T;

  constructor({
    key,
    defaultValue,
    parse = JSON.parse,
    stringify = JSON.stringify,
  }: LocalStorageValueOptions<T>) {
    makeObservable(this, {
      value: observable,
      setValue: action.bound,
    });

    this.key = `LocalStorageValue#${key}`;
    this.parse = parse;
    this.stringify = stringify;

    const cachedValue = localStorage.getItem(this.key);

    try {
      this.value = cachedValue != null ? parse(cachedValue) : defaultValue;
    } catch {
      this.value = defaultValue;
      localStorage.setItem(this.key, this.stringify(defaultValue));
    }
  }

  setValue = (value: T) => {
    this.value = value;
    localStorage.setItem(this.key, this.stringify(value));
  };
}

export class LocalStorageNumberValue extends LocalStorageValue<number> {
  constructor(options: { key: string; defaultValue: number }) {
    super({
      ...options,
      parse: parseFloat,
      stringify: (value) => value.toString(),
    });
  }
}

const TRUE = 'TRUE';
const FALSE = 'FALSE';
export class LocalStorageBooleanValue extends LocalStorageValue<boolean> {
  constructor(options: { key: string; defaultValue: boolean }) {
    super({
      ...options,
      parse: (value) => value === TRUE,
      stringify: (value) => (value ? TRUE : FALSE),
    });
  }
}

export class LocalStorageStringValue extends LocalStorageValue<string> {
  constructor(options: { key: string; defaultValue: string }) {
    super({
      ...options,
      parse: (value) => value,
      stringify: (value) => value,
    });
  }
}

const NULL = 'STRING_NULL';
export class LocalStorageNullableStringValue extends LocalStorageValue<
  string | null
> {
  constructor(options: { key: string; defaultValue: string | null }) {
    super({
      ...options,
      parse: (value) => (value === NULL ? null : value),
      stringify: (value) => value ?? NULL,
    });
  }
}
