import { Order } from '@sweep/contract';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { createExcelFromTemplate } from 'stores/order/divide/createExcelFromTemplate';
import * as XLSX from 'xlsx';
import { applyExcelModel } from 'src/services/file/excel/create/applyExcelModel';
import { colorDict } from 'src/utils/colorDict';
import { mapKeysByColumnMapping } from '../utils/headerColumnMapping';
import { isValid } from '../utils/utils';
import { useOMSStore } from './useOMSStore';

export const SPECIFIC_COLUMN_WIDTHS: { [key: string]: number } = {
  productName: 30,
  quantity: 10,
  name: 15,
  postCode: 10,
  address: 50,
  shippingCompany: 15,
  shippingNumber: 25,
  uniqueCode: 18,
  failReason: 30,
};

const EXCEL_MIME_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

const EXCEL_XLS_MIME_TYPE = 'application/vnd.ms-excel';

const useCreateExcel = () => {
  const oms = useOMSStore();
  const createOrderExcelBuffer = async (
    orders: Order[],
    fileName: string,
    headers: string[],
    columnMapping: {
      [key: string]: string;
    },
    customizedSettings: any = {}
  ) => {
    if (!isValid(orders)) {
      return;
    }
    let englishHeaderRow = mapKeysByColumnMapping(headers, columnMapping, {
      returnNullIfNotInColumnMapping:
        customizedSettings?.returnNullIfNotInColumnMapping !== undefined
          ? customizedSettings?.returnNullIfNotInColumnMapping
          : true,
    });

    if (headers.length === 0) {
      englishHeaderRow = oms.user.excelHeaderKeys;
      columnMapping = oms.user.excelColumnMapping;
    }
    if (isValid(customizedSettings?.usesXlsFile)) {
      const workbook = XLSX.utils.book_new();

      const rows = orders.map((order) => {
        return headers.map((header) => {
          const key = Object.keys(columnMapping).find(
            (key) => columnMapping[key] === header
          );
          return (key && order[key]) || '';
        });
      });

      const worksheet = XLSX.utils.aoa_to_sheet([headers, ...rows]);
      XLSX.utils.book_append_sheet(workbook, worksheet, fileName);

      return XLSX.write(workbook, { bookType: 'xls', type: 'buffer' });
    }

    if (customizedSettings?.xlsxTemplateSetting?.enabled === true) {
      const buffer = await createExcelFromTemplate(
        headers,
        columnMapping,
        orders as Order[],
        customizedSettings?.xlsxTemplateSetting
      );
      if (buffer != null) {
        return buffer;
      }
    }

    const workbook = addWorkSheetByOrders(new Workbook(), orders, fileName, {
      englishHeaderNames: englishHeaderRow as string[],
      columnMapping,
    });
    return workbook.xlsx.writeBuffer();
  };

  const createOrderExcel = async (
    orders: Order[],
    fileName: string,
    headers: string[],
    columnMapping: {
      [key: string]: string;
    },
    customizedSettings: any = {}
  ) => {
    if (!isValid(orders)) {
      return;
    }
    const buffer = await createOrderExcelBuffer(
      orders,
      fileName,
      headers,
      columnMapping,
      customizedSettings
    );
    if (!isValid(buffer)) {
      return;
    }
    // excelJS, sheetJS로만 해도 되는거 아님? -> 불가능
    // 조건부서식
    if (isValid(customizedSettings?.usesXlsFile)) {
      const blob = new Blob([buffer], { type: EXCEL_XLS_MIME_TYPE });

      saveAs(blob, `${fileName}.xls`);
    } else if (customizedSettings?.xlsxTemplateSetting?.enabled === true) {
      buffer.then((buf: any) => {
        // const blob = new Blob([buf], { type: EXCEL_MIME_TYPE });
        saveAs(buf, `${fileName}.xlsx`);
      });
    } else {
      await buffer;
      const blob = new Blob([buffer], {
        type: EXCEL_MIME_TYPE,
      });
      // 다운로드
      saveAs(blob, `${fileName}.xlsx`);
    }
  };

  const addWorkSheetByOrders = (
    workbook: Workbook,
    orders: Order[],
    tsheetName: string,
    settings: {
      englishHeaderNames: string[];
      columnMapping: Record<string, string>;
    }
  ): Workbook => {
    const sheetName = tsheetName.replace(/[*?:\\/\[\]]/g, ' ');

    orders = orders.map((order) => {
      Object.keys(order).forEach((key) => {
        const value = order[key];
        if (typeof value === 'number' && value.toString().length > 9) {
          order[key] = value.toString();
        }
      });

      return order;
    });

    const { columnMapping, englishHeaderNames } = settings;

    const koreanHeaderNames = englishHeaderNames.map(
      (col: string) => columnMapping[col]
    );
    if (oms.user.setting?.preprocessSettings?.separateAddress?.enabled) {
      englishHeaderNames.push('mainAddress', 'detailAddress');
      koreanHeaderNames.push('메인주소', '상세주소');
    }
    const worksheet = workbook.addWorksheet(`${sheetName}`);
    const headerRow = worksheet.addRow(koreanHeaderNames);

    const addressIndex =
      englishHeaderNames.findIndex((col) => col === 'address') + 1;
    //INDEX -> AA 식으로
    if (addressIndex !== 0) {
      let addressIndexString = String.fromCharCode(64 + addressIndex);
      if (addressIndex > 26) {
        addressIndexString =
          String.fromCharCode(64 + Math.floor(addressIndex / 26)) +
          String.fromCharCode(64 + (addressIndex % 26));
      }
      if (!oms.user.setting?.disableMultipleAddressHighlighting?.enabled) {
        worksheet.addConditionalFormatting({
          ref: `${addressIndexString}2:${addressIndexString}${
            orders.length + 50
          }`,
          rules: [
            {
              priority: 1,
              type: 'expression',
              formulae: [
                `COUNTIF($${addressIndexString}$2:$${addressIndexString}$${
                  orders.length + 50
                },$${addressIndexString}2)>1`,
              ],
              style: {
                fill: {
                  type: 'pattern',
                  pattern: 'solid',
                  bgColor: { argb: 'FFFF00' },
                },
              },
            },
          ],
        });
      }
    }

    orders.forEach((row) => {
      const newRow = worksheet.addRow(
        englishHeaderNames.map((col: string) => {
          let value = row[col];

          // name의 길이가 1이면, value에 '님'을 추가
          if (
            col === 'name' &&
            row[col] != null &&
            (row[col] as string).length === 1
          ) {
            value += '님';
          }

          if (col === 'contactNumber' && !isValid(row[col])) {
            value = row['telephoneNumber'];
          }

          if (
            col === 'price' ||
            col === 'quantity' ||
            col === 'shippingPrice'
          ) {
            value = Number(value) !== Number(value) ? value : Number(value);
          } //NaN 이면 그대로, 아니면 숫자로 변환

          return typeof value === 'string' ? value.normalize('NFC') : value;
        })
      );

      // 'isCombinedOrder'가 true일 경우, 배경색을 붉은색으로 설정
      if (row.isCombinedOrder) {
        const addressIndex =
          englishHeaderNames.findIndex((col: string) => col === 'address') + 1;

        newRow.getCell(addressIndex).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFC7CD' }, // 붉은색
        };
      }

      if (row.isReflectedQuantitytoOption) {
        //TODO option으로 키고끄게 할 수 있게 하기
        const optionIndex =
          englishHeaderNames.findIndex((col: string) => col === 'option') + 1;
        if (optionIndex !== 0) {
          newRow.getCell(optionIndex).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFADD8E6' }, // 파란색
          };
        }
      }

      if (row.isIncludedSpecialCharacterInName) {
        const nameIndex =
          englishHeaderNames.findIndex((col: string) => col === 'name') + 1;

        if (nameIndex !== 0) {
          newRow.getCell(nameIndex).font = {
            color: { argb: 'FF0000' },
          };
        }
      }

      if (isValid(row.isPostCodeOutdated)) {
        const postCodeIndex =
          englishHeaderNames.findIndex((col: string) => col === 'postCode') + 1;
        if (postCodeIndex !== 0) {
          newRow.getCell(postCodeIndex).font = {
            color: { argb: 'FF0000' }, // 빨간색
          };
        }
      }

      if (isValid(row.postCodeColor)) {
        const postCodeIndex =
          englishHeaderNames.findIndex((col: string) => col === 'postCode') + 1;
        if (postCodeIndex !== 0) {
          newRow.getCell(postCodeIndex).font = {
            color: { argb: row.postCodeColor ?? '' }, // 빨간색
          };
        }
      }

      if (isValid(row.isQuantityMoreThanOne)) {
        const quantityIndex =
          englishHeaderNames.findIndex((col: string) => col === 'quantity') + 1;
        if (quantityIndex !== 0) {
          newRow.getCell(quantityIndex).font = {
            color: { argb: 'FF0000' }, // 빨간색
            bold: true, // 볼드 처리 추가
          };
        }
      }

      const contactNumberIndex = englishHeaderNames.findIndex(
        (col: string) => col === 'contactNumber'
      );
      if (contactNumberIndex !== -1) {
        let contactNumber = newRow.getCell(contactNumberIndex + 1).value;
        if (typeof contactNumber === 'string') {
          contactNumber = contactNumber.replace(/-/g, '');
          if (
            contactNumber.slice(0, 4) !== '0502' &&
            (contactNumber.slice(0, 3) !== '010' ||
              (contactNumber.length !== 11 && contactNumber.length !== 10))
          ) {
            newRow.getCell(contactNumberIndex + 1).font = {
              color: { argb: 'FF0000' },
            };
          }
        }
      }

      const addressIndex = englishHeaderNames.findIndex(
        (col: string) => col === 'address'
      );
      if (addressIndex !== -1) {
        const isAddressValid = row.isAddressValid;
        if (isAddressValid !== 'okay' && isAddressValid != null) {
          if (colorDict[isAddressValid]) {
            newRow.getCell(addressIndex + 1).font = {
              // type: 'pattern',
              // pattern: 'solid',
              bold: true,
              color: { argb: colorDict[isAddressValid][0] }, // 연한 초록색, fixed 된것들
            };
          }
        }
        if (row.memo) {
          newRow.getCell(addressIndex + 1).note = {
            texts: row.memo,
            // visibility: 'visible', // 메모의 초기 가시성 (visible | hidden)
          };
        }
      }

      const textColumns = [
        '주문번호',
        '상품코드',
        '연락처',
        '우편번호',
        '옵션',
        '개수',
        '운송장번호',
      ];
      textColumns.forEach((textCol) => {
        const colNum = koreanHeaderNames.indexOf(textCol) + 1;
        if (colNum > 0) {
          newRow.getCell(colNum).numFmt = '@'; // '@' indicates text format
        }
      });

      const excelModel = row.excelModel;
      if (excelModel != null) {
        applyExcelModel(newRow, excelModel, englishHeaderNames);
      }
    });

    // 엑셀 beautify
    headerRow.eachCell((cell: any) => {
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
      cell.font = { bold: true };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D9D9D9' },
      };
    });

    worksheet.views = [{ state: 'frozen', ySplit: 1 }];

    worksheet.autoFilter = {
      from: 'A1',
      to: `${String.fromCharCode(64 + englishHeaderNames.length)}1`,
    };

    // 열 너비 설정
    worksheet.columns = englishHeaderNames.map((col: string) => {
      const columnObject = {
        key: col,
        width: SPECIFIC_COLUMN_WIDTHS[col] || 20,
      };

      return columnObject;
    });

    return workbook;
  };

  return { createOrderExcel, createOrderExcelBuffer, addWorkSheetByOrders };
};
export default useCreateExcel;
