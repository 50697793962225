import { overlay } from 'overlay-kit';
import { Dialog } from 'src/design-system/components/Dialog';

export function openConfirmOrderDialog(orderCount: number) {
  return new Promise<boolean>((resolve) =>
    overlay.open(({ isOpen, close }) => (
      <Dialog
        open={isOpen}
        onClose={close}
        className="w-[452px] px-[28px] pb-[20px] pt-[24px]"
      >
        <Dialog.Title className="mb-[28px]">
          선택한 <span className="text-blue-500">{orderCount}건</span>의 주문을
          확인할까요?
        </Dialog.Title>
        <Dialog.Footer>
          <Dialog.Close onClick={() => resolve(false)} color="lightGray">
            취소
          </Dialog.Close>
          <Dialog.Close onClick={() => resolve(true)}>확인</Dialog.Close>
        </Dialog.Footer>
      </Dialog>
    ))
  );
}
