import { offset, size, useDismiss, useFloating } from '@floating-ui/react';

interface SelectFloatingOptions {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  options?: {
    maxHeight?: number;
  };
}

export function useSelectFloating({
  open,
  onOpenChange,
  options,
}: SelectFloatingOptions) {
  const { refs, floatingStyles, context } = useFloating({
    placement: 'bottom',
    open,
    onOpenChange,
    middleware: [
      offset(6),
      size({
        apply({ availableHeight: givenAvailableHeight, rects, elements }) {
          const optionMaxHeight = options?.maxHeight ?? Infinity;
          const availableHeight = Math.max(0, givenAvailableHeight);
          const maxHeight = Math.min(optionMaxHeight, availableHeight);

          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
            maxHeight: `${maxHeight}px`,
          });
        },
      }),
    ],
  });

  useDismiss(context, {
    ancestorScroll: true,
    outsidePress: true,
    escapeKey: false,
  });

  return { refs, floatingStyles };
}
