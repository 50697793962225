import { Token } from '../interface';

export function parseNumber(token: Token): number | null {
  if (typeof token === 'number') {
    return token;
  }

  if (typeof token === 'boolean') {
    return token ? 1 : 0;
  }

  if (token === '') {
    return 0;
  }

  const parsed = parseFloat(token);
  if (isNaN(parsed)) {
    return null;
  }

  return parsed;
}
