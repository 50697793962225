import { overlay } from 'overlay-kit';
import ShippingUploadFormModal, {
  ShippingInvoice,
} from './ShippingUploadFormModal';

export function openShippingUploadFormModal(): Promise<ShippingInvoice | null> {
  return new Promise((resolve) =>
    overlay.open(({ isOpen, close }) => (
      <ShippingUploadFormModal
        open={isOpen}
        onSubmit={(shippingInvoice) => {
          close();
          resolve(shippingInvoice);
        }}
      />
    ))
  );
}
