import { Tooltip } from 'design-system/index';
import { IconEdit, IconTrashcan } from 'icons/index';
import { observer } from 'mobx-react-lite';
import {
  CompositionMatching,
  CompositionMatchingXX,
} from 'models/CompositionMatching';
import { useEffect, useState } from 'react';
import { useOMSStore } from 'src/hooks/useOMSStore';
import Button from '../../../components/buttons/Button';
import LoadingIndicator from '../../../components/LoadingIndicator';
import OMSContainer from '../../../components/OMSContainer';
import SearchInput from '../../../components/SearchInput';
import SearchNoData from '../../../components/SearchNoData';
import Modal from '../../../modals/Modal';
import LoadingStore from '../../../stores/LoadingStore';
import backendApi from '../../../utils/backendApi';
import CompositionEditionForm from './components/CompositionEditionForm';

const CompositionMatchingManagementScreen = observer(() => {
  const oms = useOMSStore();
  const [query, setQuery] = useState('');

  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [compositionMatchingList, setCompositionMatchingList] = useState<
    CompositionMatchingXX[]
  >([]);

  useEffect(() => {
    setCompositionMatchingList(oms.cm.legacyCompositionMatchings);
  }, [oms.cm.legacyCompositionMatchings]);

  const [show100Matching, setShow100Matching] = useState(true);

  const [isCompositionEditionModalOpen, setIsCompositionEditionModalOpen] =
    useState(false);

  const getUnitList = (productName: string) => {
    const productInfo = oms.product.products.find((productInfo) => {
      return productInfo.productName === productName;
    });
    return productInfo?.units || [];
  };

  useEffect(() => {
    if (query.length > 1) {
      const productList = oms.product.products;

      let compositionMatchings = [...oms.cm.legacyCompositionMatchings];

      compositionMatchings = compositionMatchings.map((item) => {
        const data = item.data.map((dataItem) => {
          const productInfo = productList.find(
            (product) => product._id === dataItem.productId
          );

          return {
            ...dataItem,
            composition: `${productInfo?.productName}${
              dataItem.unit ? ' ' + dataItem.unit : ''
            } x${dataItem.quantity}`,
          };
        });

        return { ...item, data };
      });

      const newCompositionMatchingList = compositionMatchings.filter((item) => {
        if (
          (item.productNameOption && item.productNameOption.includes(query)) ||
          (item.productName && item.productName.includes(query)) ||
          (item.optionCode && item.optionCode.includes(query)) ||
          item.data.some(
            (dataItem) =>
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              dataItem.composition && dataItem.composition.includes(query)
          )
        ) {
          return true;
        }
      });

      setCompositionMatchingList(newCompositionMatchingList);
    } else {
      setCompositionMatchingList(oms.cm.legacyCompositionMatchings);
    }
  }, [query]);

  const handleEditCompositionMatching = async (
    updatedComp: CompositionMatchingXX
  ) => {
    const res = await backendApi.updateCompositionMatching(updatedComp);
    if (res.success) {
      const updatedCompositionMatching = oms.cm.legacyCompositionMatchings.map(
        (item) => {
          if (item._id === updatedComp._id) {
            return updatedComp;
          }
          return item;
        }
      );

      oms.cm.setCompositionMatchings(
        updatedCompositionMatching as unknown as CompositionMatching[]
      );

      const updatedCompositionMatchingList = compositionMatchingList.map(
        (item) => {
          if (item._id === updatedComp._id) {
            return updatedComp;
          }
          return item;
        }
      );

      setCompositionMatchingList(updatedCompositionMatchingList);

      alert('상품 구성 수정이 완료되었어요.');
      setIsCompositionEditionModalOpen(false);
    }
  };

  const handleDeleteComposition = async (compositionIndex: number) => {
    const isconfirmed = window.confirm(
      '정말로 해당 상품 구성을 삭제하시겠습니까?'
    );

    if (!isconfirmed) {
      return;
    }

    const composition = compositionMatchingList[compositionIndex];

    const res = await backendApi.deleteCompositionMatching(composition._id);

    if (res.success) {
      const updatedCompositionMatching =
        oms.cm.legacyCompositionMatchings.filter(
          (item) => item._id !== composition._id
        );
      oms.cm.setCompositionMatchings(
        updatedCompositionMatching as unknown as CompositionMatching[]
      );

      const updatedCompositionMatchingList = compositionMatchingList.filter(
        (item) => item._id !== composition._id
      );
      setCompositionMatchingList(updatedCompositionMatchingList);

      alert('상품 구성 삭제가 완료되었어요.');
    } else {
      alert(
        '상품 구성 삭제에 실패했어요. 자세한 사항은 관리자에게 문의해주세요.'
      );
    }
  };

  const handleCompositionEditionModalOpen = (index: number) => {
    setSelectedIndex(index);
    setIsCompositionEditionModalOpen(true);
  };

  return (
    <OMSContainer className="gap-[20px]">
      <h1 className="text-extrabold-l">상품 구성 매칭 관리</h1>

      <SearchInput
        type="common"
        searchQuery={query}
        setSearchQuery={setQuery}
        placeholder="상품명, 옵션명 또는 매칭된 구성으로 검색하세요."
      />

      {compositionMatchingList.length > 0 && (
        <div>
          <div className="mt-[20px]" />
          <div className="flex h-12 items-center">
            <div className="flex h-full w-[300px] items-center border-b-2 bg-gray-100 px-3">
              <p className="text-bold-m">상품명</p>
              <p className="text-medium-s mx-1">/</p>
              <p className="text-bold-m text-gray-500">옵션</p>
            </div>
            <div className="w-10" />
            <div
              className="flex h-full items-center border-b-2 bg-gray-100 px-3"
              style={{ width: 'calc(100% - 340px)' }}
            >
              <p className="font-bold">상품 구성</p>
            </div>
          </div>

          {compositionMatchingList &&
            compositionMatchingList
              .map((item, compositionIndex) => (
                <>
                  <div key={compositionIndex} className="flex items-center">
                    <div className="w-[300px] items-center px-[16px] py-[24px]">
                      <p className="text-medium-m text-gray-600">
                        {item.productName ?? ''}
                      </p>
                      <p className="text-medium-s text-gray-500">
                        {item.option ?? ''}
                      </p>
                    </div>
                    <div className="w-[40px]"></div>
                    <div
                      className="flex justify-between gap-4 px-[16px] py-[24px]"
                      style={{ width: 'calc(100% - 340px)' }}
                    >
                      <div>
                        {item.data.map((dataItem, dataIndex) => (
                          <div
                            key={dataIndex}
                            className="flex flex-row items-center gap-2"
                          >
                            <div>{dataItem.productName}</div>
                            <div
                              className={`whitespace-nowrap ${
                                dataItem.productName &&
                                getUnitList(dataItem.productName).length > 0
                                  ? ''
                                  : 'hidden'
                              }`}
                            >
                              {dataItem.unit}
                            </div>
                            <div className="whitespace-nowrap">
                              x{dataItem.quantity}
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="text-md flex items-center gap-2 whitespace-nowrap font-semibold text-gray-500">
                        <Tooltip>
                          <Tooltip.Trigger>
                            <button
                              className="flex size-[30px] items-center justify-center rounded-lg bg-[#EBF0F5] hover:bg-gray-300"
                              onClick={() =>
                                handleCompositionEditionModalOpen(
                                  compositionIndex
                                )
                              }
                            >
                              <IconEdit className="text-gray-500" />
                            </button>
                          </Tooltip.Trigger>
                          <Tooltip.Content>수정</Tooltip.Content>
                        </Tooltip>
                        <Tooltip>
                          <Tooltip.Trigger>
                            <button
                              className="flex size-[30px] items-center justify-center rounded-lg bg-[#EBF0F5] hover:bg-gray-300"
                              onClick={() =>
                                handleDeleteComposition(compositionIndex)
                              }
                            >
                              <IconTrashcan className="text-gray-500" />
                            </button>
                          </Tooltip.Trigger>
                          <Tooltip.Content>삭제</Tooltip.Content>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                  <hr className="border-gray" />
                </>
              ))
              .slice(0, show100Matching ? 100 : compositionMatchingList.length)}

          {compositionMatchingList.length > 100 && (
            <Button
              name={
                show100Matching
                  ? `전체 ${compositionMatchingList.length}개 보기`
                  : '100개만 보기'
              }
              className="my-4"
              onClick={() => setShow100Matching(!show100Matching)}
            />
          )}
        </div>
      )}

      {compositionMatchingList.length === 0 && <SearchNoData />}

      {isCompositionEditionModalOpen && (
        <Modal
          header="상품 구성 수정하기"
          onClose={() => setIsCompositionEditionModalOpen(false)}
        >
          <CompositionEditionForm
            option={compositionMatchingList[selectedIndex]}
            onFormSubmit={(updatedComp: CompositionMatchingXX) =>
              handleEditCompositionMatching(updatedComp)
            }
          />
        </Modal>
      )}
      {LoadingStore?.isLoading && <LoadingIndicator />}
    </OMSContainer>
  );
});

export default CompositionMatchingManagementScreen;
