import { Number } from 'design-system/components/Number';
import { IconControlNext } from 'icons/index';
import { PropsWithChildren, ReactNode } from 'react';
import { cva } from 'src/third-parties/tailwind';

interface CMProgressProps {
  stage: 'partnerMatching' | 'compositionMatching' | 'complete';
  children?: ReactNode;
  rightAccesory?: ReactNode;
}

function CMProgress({ stage, children, rightAccesory }: CMProgressProps) {
  return (
    <div className="flex w-full justify-between rounded-[12px] bg-white p-4 shadow-sm">
      <div className="flex items-center gap-[6px]">
        <StageTitle index={1}>파트너사 매칭</StageTitle>
        <IconControlNext className="text-gray-400" />
        <StageTitle index={2} active={stage === 'compositionMatching'}>
          상품 구성 매칭
        </StageTitle>
        {children}
        <IconControlNext className="text-gray-400" />
        <StageTitle index={3}>발주서 제작 완료</StageTitle>
      </div>
      {rightAccesory}
    </div>
  );
}

CMProgress.Item = CMProgressItem;

interface StageTitleProps {
  active?: boolean;
  index: number;
  children: ReactNode;
}

function StageTitle({ active = false, index, children }: StageTitleProps) {
  return (
    <div className="flex gap-[6px]">
      <Number active={active}>{index}</Number>
      <p className={stageTitle({ active })}>{children}</p>
    </div>
  );
}

const stageTitle = cva('text-bold-m', {
  variants: {
    active: {
      true: 'text-gray-600',
      false: 'text-gray-400',
    },
  },
});

function CMProgressItem({ children }: PropsWithChildren) {
  return (
    <div className="text-bold-m rounded-[4px] bg-gray-100 px-[20px] py-[10px] text-gray-600">
      {children}
    </div>
  );
}

export default CMProgress;
