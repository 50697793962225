import { User } from '@sweep/contract';
import { Plugin } from 'stores/plugin/interface';
import api, { APIResponse } from './api';

export async function getPlugins() {
  const url = '/plugin';
  const response = await api.get<{ plugins: Plugin[] }>(url);

  return response.data.plugins;
}

export interface CreatePluginDTO {
  pluginId: string;
  config?: unknown;
}

export async function createPlugin(plugin: CreatePluginDTO) {
  const url = '/plugin';
  const response = await api.post<APIResponse<Plugin>>(url, plugin);

  return response.data;
}

export interface UpdatePluginDTO {
  pluginId?: string;
  config?: unknown;
}

export async function updatePlugin(pluginId: string, plugin: UpdatePluginDTO) {
  const url = `/plugin/${pluginId}`;
  const response = await api.put(url, plugin);

  return response.data;
}

export async function deletePlugin(id: string) {
  const url = `/plugin/${id}`;
  const response = await api.delete(url);

  return response.data;
}

interface CreateUserPluginResponseData {
  user: User;
  plugin: Plugin;
}

export async function createUserPlugin(plugin: CreatePluginDTO) {
  const response = await api.post<APIResponse<CreateUserPluginResponseData>>(
    '/user/plugin',
    plugin
  );

  return response.data;
}

export async function deleteUserPlugin(pluginId: string) {
  const response = await api.delete(`/user/plugin/${pluginId}`);

  return response.data;
}

type CreateDispatchPluginResponse = APIResponse<{
  user: User;
  plugin: Plugin;
}>;

export async function createDispatchPlugin(plugin: CreatePluginDTO) {
  const response = await api.post<CreateDispatchPluginResponse>(
    '/user/dispatch-plugin',
    plugin
  );

  return response.data;
}

export async function deleteDispatchPlugin(pluginId: string) {
  const response = await api.delete(`/user/dispatch-plugin/${pluginId}`);

  return response.data;
}

type CreateDispatchMergePluginResponse = APIResponse<{
  user: User;
  plugin: Plugin;
}>;

export async function createMergeDispatchPlugin(plugin: CreatePluginDTO) {
  const response = await api.post<CreateDispatchMergePluginResponse>(
    '/user/merge-dispatch-plugin',
    plugin
  );

  return response.data;
}

export async function deleteMergeDispatchPlugin(pluginId: string) {
  const response = await api.delete(`/user/merge-dispatch-plugin/${pluginId}`);

  return response.data;
}
