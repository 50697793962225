import { DispatchedOrder } from '@sweep/contract';
import { overlay } from 'overlay-kit';
import { Modal } from 'src/design-system/components/Modal';
import RejectCanceledOrderModal from '../components/RejectCanceledOrderModal';

export function openRejectCanceledOrderDialog(
  orders: DispatchedOrder[],
  rejectCanceledOrders: (
    rejectReason: string,
    orders: DispatchedOrder[]
  ) => void
) {
  overlay.open(({ isOpen, close }) => (
    <Modal
      open={isOpen}
      onClose={close}
      className="max-h-[80vh] w-[700px] overflow-scroll"
    >
      <Modal.Title>주문취소 거부</Modal.Title>
      <RejectCanceledOrderModal
        onFormSubmit={(rejectReason, orders) => {
          rejectCanceledOrders(rejectReason, orders);
          close();
        }}
        orders={orders}
      />
    </Modal>
  ));
}
