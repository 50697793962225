import { http, HttpResponse } from 'msw';
import { sleep } from 'utils/function/sleep';
import { BACKEND_URL } from 'src/env';
import {
  DispatchOrderPollingResponse,
  GetFetchOrdersRes,
  GetValidShoppingMallListRes,
} from 'src/network/order/dispatchOrder';
import { LocalStorageValue } from 'src/utils/mobx';
import { LOCALSTORAGE_DISPATCH_PREFIX } from '../../../common/constants';
import { dispatchStore } from '../DispatchStore';

const DISPATCH_ORDER_URL = `${BACKEND_URL}/dispatch-order`;

export type DispatchOrderType = 'success' | 'fail-five' | 'fail' | 'none';
export const dispatchOrderTypeStorage =
  new LocalStorageValue<DispatchOrderType>({
    key: `${LOCALSTORAGE_DISPATCH_PREFIX}/dispatch-order-type`,
    defaultValue: 'none',
  });

export const createDispatchOrderHandlers = () => {
  const type = dispatchOrderTypeStorage.value;
  if (type === 'none') {
    return [];
  }

  return [
    createValidShoppingMallListHandler(),
    createFetchOrdersHandler(),
    createFetchOrdersPollingHandler(),
  ];
};

function createValidShoppingMallListHandler() {
  return http.get(
    `${DISPATCH_ORDER_URL}/valid-shopping-mall-list`,
    async () => {
      return HttpResponse.json<GetValidShoppingMallListRes>({
        message: 'Success',
        result: dispatchStore.shoppingMalls,
      });
    }
  );
}

function createFetchOrdersHandler() {
  return http.get(`${DISPATCH_ORDER_URL}/fetch-orders`, async ({ request }) => {
    const url = new URL(request.url);
    const shoppingMall = url.searchParams.get('shoppingMall') as string;
    await sleep(1000);

    return HttpResponse.json<GetFetchOrdersRes>({
      message: 'Success',
      result: {
        requestId: shoppingMall,
      },
    });
  });
}

function createFetchOrdersPollingHandler() {
  const type = dispatchOrderTypeStorage.value;
  const pollingCounts: Record<string, number> = {};

  return http.get(
    `${DISPATCH_ORDER_URL}/fetch-orders-polling`,
    async ({ request }) => {
      await sleep(1000);
      const url = new URL(request.url);
      const shoppingMall = url.searchParams.get('requestId');
      if (shoppingMall == null) {
        return failPollingResponse();
      }

      if (type === 'success') {
        return successPollingResponse(shoppingMall);
      }

      if (type === 'fail-five') {
        const count = pollingCounts[shoppingMall] ?? 1;
        pollingCounts[shoppingMall] = count + 1;
        if (shoppingMall === 'NAVER-1' || count % 5 === 0) {
          return successPollingResponse(shoppingMall);
        }

        return failPollingResponse();
      }

      if (type === 'fail') {
        return failPollingResponse();
      }
    }
  );
}

const successPollingResponse = (shoppingMall: string) => {
  return HttpResponse.json<DispatchOrderPollingResponse>({
    success: true,
    data: {
      status: 'FINISH',
      data: dispatchStore.getOrdersByShoppingMall(shoppingMall),
    },
  });
};

const failPollingResponse = () => {
  return HttpResponse.json<DispatchOrderPollingResponse>({
    success: false,
    error: 'Failed',
  });
};
