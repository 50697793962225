import React, { useEffect, useState } from 'react';

interface LoadingBarProps {
  isFinish: boolean;
  rowNum: number;
}

const LoadingBar: React.FC<LoadingBarProps> = ({ isFinish, rowNum }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let interval: NodeJS.Timeout;

    const updateProgress = () => {
      setProgress((prev) => {
        const randomIncrement = Math.random() * Math.min(20000 / rowNum, 10);
        const newProgress = prev + randomIncrement;
        return newProgress >= 100 ? 100 : newProgress;
      });
    };

    if (!isFinish) {
      interval = setInterval(updateProgress, Math.random() * 500);
    } else {
      setProgress(100);
    }

    return () => clearInterval(interval);
  }, [isFinish, rowNum]);

  return (
    <div className="h-2 w-full rounded-lg bg-gray-200">
      <div
        className="h-2 rounded-lg bg-blue-500"
        style={{
          width: `${progress}%`,
          transition: 'width 0.5s ease-in-out',
        }}
      ></div>
    </div>
  );
};

export default LoadingBar;
