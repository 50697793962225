import copy from 'fast-copy';
import { IconTrashcan } from 'icons/index';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useOMSStore } from 'src/hooks/useOMSStore';
import AutocompleteByArray from '../../../../components/AutocompleteByArray';
import Button from '../../../../components/buttons/Button';
import { isValid } from '../../../../utils/utils';

// 옵션 데이터를 받아서 수정하고, 수정된 데이터를 호출한 곳으로 전달합니다.
const CompositionEditionForm = ({ option, onFormSubmit }) => {
  const oms = useOMSStore();
  const [modifiedOption, setModifiedOption] = useState(option || []);
  const [productInfos, setProductInfos] = useState([]);

  useEffect(() => {
    setProductInfos(oms.product.products || []);
  }, [oms.product.products]);

  const getProductList = () => {
    return productInfos.map((productInfo) => productInfo.productName);
  };

  const getUnitList = (productName) => {
    const productInfo = productInfos.find((productInfo) => {
      return productInfo.productName === productName;
    });
    const onlyUnitname = (productInfo?.units || []).map((unit) => {
      return typeof unit === 'string' ? unit : unit.unit;
    });

    return onlyUnitname || [];
  };

  const handleSelectionChange = (dataIndex, field, value) => {
    if (value > 9999) {
      alert('너무 큰 숫자를 입력한 것은 아닌지 다시 한 번 확인해 주세요.');
      return;
    }

    const updatedData = modifiedOption.data.map((data, idx) => {
      if (idx === dataIndex) {
        if (field === 'productName') {
          return { ...data, [field]: value, unit: '', quantity: 1 };
        } else {
          return { ...data, [field]: value };
        }
      }
      return data;
    });

    updatedData.forEach((dataItem, dataIndex) => {
      let unitList = getUnitList(dataItem.productName);
      if (dataItem.productName !== '' && !unitList.includes(dataItem.unit)) {
        updatedData[dataIndex].unit = unitList[0] || '';
      } else if (dataItem.productName === '' || unitList.length === 0) {
        updatedData[dataIndex].unit = '';
      }
    });

    setModifiedOption({ ...modifiedOption, data: [...updatedData] });
  };

  function getWidthClass(length) {
    if (length <= 2) {
      return 'w-10'; // 2자리 숫자에 대한 너비
    } else if (length === 3) {
      return 'w-12'; // 3자리 숫자에 대한 너비
    } else {
      return 'w-14';
    }
  }

  return (
    <>
      <div className="mt-4 flex flex-col items-center justify-center">
        <div>
          <p className="text-left">{option.productName}</p>
          <p className="text-left text-base text-gray-500">{option.option}</p>
        </div>
        <div className="my-4">
          <div className="size-4 rotate-45 border-b-2 border-r-2 border-gray-500" />
        </div>
        <div className="w-max">
          {modifiedOption?.data.map((dataItem, dataIndex) => {
            const isNaN = dataItem.quantity !== dataItem.quantity;

            const inputWidthClass = getWidthClass(
              !isNaN ? dataItem.quantity.toString().length : 0
            );

            return (
              <div key={dataIndex} className={`mt-3 flex w-max items-center`}>
                <span className="mr-2">
                  <AutocompleteByArray
                    dataArray={getProductList()}
                    selectedValue={dataItem.productName}
                    onChange={(value) =>
                      handleSelectionChange(dataIndex, 'productName', value)
                    }
                    className="w-[220px]"
                  />
                </span>
                <span
                  className={`mr-2 ${
                    getUnitList(dataItem.productName).length > 0 ? '' : 'hidden'
                  }`}
                >
                  <AutocompleteByArray
                    dataArray={getUnitList(dataItem.productName)}
                    selectedValue={copy(dataItem.unit)}
                    onChange={(value) =>
                      handleSelectionChange(dataIndex, 'unit', value)
                    }
                    className="w-[140px]"
                  />
                </span>
                <span>
                  <div className="inline-block w-[104px] rounded-lg border border-gray-300 px-[16px] py-2">
                    <label>x</label>
                    <input
                      className={`outline-none ${inputWidthClass}`}
                      type="number"
                      value={dataItem.quantity}
                      min={1}
                      max={9999}
                      onChange={(e) =>
                        handleSelectionChange(
                          dataIndex,
                          'quantity',
                          parseInt(e.target.value)
                        )
                      }
                      onClick={(e) => e.target.select()}
                    />
                  </div>
                </span>

                {/* 삭제 버튼 */}
                <button
                  className="ml-[16px] flex size-[34px] items-center justify-center rounded-lg bg-gray-200 hover:bg-gray-300"
                  type="button"
                  onClick={() => {
                    const updateOption = {
                      ...modifiedOption,
                      data: [
                        ...modifiedOption.data.slice(0, dataIndex),
                        ...modifiedOption.data.slice(dataIndex + 1),
                      ],
                    };
                    setModifiedOption(updateOption);
                  }}
                >
                  <IconTrashcan className="text-gray-500" />
                </button>
              </div>
            );
          })}

          {/* 추가 버튼 */}
          <button
            type="button"
            onClick={() => {
              const updatedOption = {
                ...modifiedOption,
                data: [
                  ...modifiedOption.data,
                  { productName: '', unit: '', quantity: 1 },
                ],
              };
              setModifiedOption(updatedOption);
            }}
          >
            <div className="mb-4 mt-[10px] font-semibold text-gray-500 hover:text-gray-700">
              + 구성 추가하기
            </div>
          </button>
        </div>
      </div>
      <div className="flex justify-end">
        <Button
          name="수정완료"
          onClick={() => {
            if (
              modifiedOption.data.some(
                (product) => product.productName === ''
              ) ||
              !isValid(modifiedOption?.data)
            ) {
              alert(
                `구성 설정이 완료되지 않은 상품명과 옵션이 있습니다.\n 상품 구성 설정을 완료해 주세요.`
              );
              return;
            }
            modifiedOption.data.map((data) => {
              data.productId = productInfos.find(
                (productInfo) => productInfo.productName === data.productName
              )?._id;
              return data;
            });
            onFormSubmit(modifiedOption);
          }}
          className="my-4"
        />
      </div>
    </>
  );
};

export default observer(CompositionEditionForm);
