import { NormalizedOrder } from '@sweep/contract';
import { useEffect, useState } from 'react';
import Selector from 'src/components/Selector';
import { IconPlusSmall } from 'src/icons';
import { toast } from 'src/third-parties/toast';

export type FieldFilters = Record<string, Set<string>>;

export const FieldFilterList = ({
  value,
  onChange,
  orders,
  headerKeys,
  columnMapping,
}: {
  value: FieldFilters;
  onChange: (value: FieldFilters) => void;
  orders: NormalizedOrder[];
  headerKeys: string[];
  columnMapping: Record<string, string>;
}) => {
  const fieldOptions = [
    '필터 추가',
    ...headerKeys.map((header) => {
      return {
        label: columnMapping[header] || header,
        value: header,
      };
    }),
  ];

  return (
    <div>
      <div className="h-40px flex flex-row gap-[10px]">
        <Selector
          placeholder="상세 필터"
          options={fieldOptions}
          value="상세 필터"
          onChange={(selectedField: string) => {
            if (selectedField === '') {
              return;
            }

            const selectedFilterFields: FieldFilters = {
              ...value,
              [selectedField]: new Set<string>(),
            };

            if (Object.keys(value).includes(selectedField)) {
              toast.error('이미 선택된 필터입니다.');
              return;
            }

            if (Object.keys(selectedFilterFields).length > 5) {
              toast.error('필터는 최대 5개까지 추가 가능합니다.');
              return;
            }

            onChange(selectedFilterFields);
          }}
          iconComponent={
            <div className="flex h-full items-center">
              <IconPlusSmall className="text-gray-700" />
            </div>
          }
          className="min-w-[160px]"
        />

        {value &&
          Object.keys(value).map((filterField) => (
            <FilterCheckSelector
              key={filterField}
              allOrders={orders}
              columnMapping={columnMapping}
              filterField={filterField}
              setFilter={(
                filterField: string,
                selectedFieldValues: Set<string>
              ) => {
                onChange({
                  ...value,
                  [filterField]: selectedFieldValues,
                });
              }}
              deleteFilter={() => {
                const newFilterFields = { ...value };
                delete newFilterFields[filterField];
                onChange(newFilterFields);
              }}
            />
          ))}
      </div>
    </div>
  );
};

const FilterCheckSelector = ({
  allOrders,
  columnMapping,
  filterField,
  setFilter,
  deleteFilter,
}: {
  allOrders: NormalizedOrder[];
  columnMapping: Record<string, string>;
  filterField: string;
  setFilter: any;
  deleteFilter: any;
}) => {
  const [selectedFieldValues, setSelectedFieldValues] = useState<Set<string>>(
    new Set()
  );

  useEffect(() => {
    setFilter(filterField, selectedFieldValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFieldValues]);

  const getfilterFieldOptions = (filterField: string) => {
    const fieldToUniqueCodeDict: { [key: string]: Set<string> } = {};

    allOrders.forEach((order) => {
      if (!fieldToUniqueCodeDict[filterField]) {
        fieldToUniqueCodeDict[filterField] = new Set();
      }
      const value = order[filterField]?.toString();
      if (value == null) {
        return;
      }

      fieldToUniqueCodeDict[filterField].add(value);
    });

    const orderOptions = Array.from(fieldToUniqueCodeDict[filterField].keys());

    return ['전체', ...orderOptions];
  };

  const allOptions = getfilterFieldOptions(filterField);

  useEffect(() => {
    const allFieldValues = new Set<string>();

    allOptions.forEach((option: any) => {
      allFieldValues.add(option);
    });

    setSelectedFieldValues(allFieldValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Selector
      key={filterField}
      placeholder={filterField}
      options={allOptions}
      value={columnMapping[filterField] || filterField}
      className="max-w[200px] group min-w-[180px]"
      isDeletable={true}
      onDelete={deleteFilter}
      isCheckable={true}
      isEllipsis={true}
      isScrollable={true}
      checkedValues={selectedFieldValues}
      setCheckedValues={setSelectedFieldValues}
      emptyText="(빈 데이터)"
    />
  );
};
