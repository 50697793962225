import React, { useEffect } from 'react';
import closeIcon from '../icons/close.png';

interface NewModalProps {
  children: React.ReactNode;
  onClose: () => void;
  header?: string;
  subtitle?: string;
  className?: string;
  backdropClick?: boolean;
  tailwindWidth?: string;
  footerButton?: () => React.ReactNode;
}

const NewModal: React.FC<NewModalProps> = ({
  children,
  onClose,
  header = '',
  subtitle = '',
  className = '',
  backdropClick = true,
  tailwindWidth = 'w-11/12 md:w-2/3 lg:w-1/2',
  footerButton,
}) => {
  // 모달 바깥쪽을 클릭하면 모달을 닫음
  const handleBackdropClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (!backdropClick) {
      return;
    }
    const target = e.target as HTMLElement;
    if (target.id === 'modal-backdrop') {
      onClose();
    }
  };

  // 키보드에서 esc키를 누르면 모달을 닫음
  const handleEscapePress = (e: any) => {
    if (e.key === 'Escape') {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleEscapePress);
    return () => {
      document.removeEventListener('keydown', handleEscapePress);
    };
  }, []);

  return (
    <div
      id="modal-backdrop"
      onClick={handleBackdropClick}
      className={`fixed inset-0 z-40 flex size-full items-center justify-center overflow-visible bg-gray-700 bg-opacity-50`}
    >
      <div
        className={`relative border pb-[20px] pt-[40px] ${tailwindWidth} rounded-3xl bg-white shadow-lg ${className}`}
      >
        <div className="flex items-center justify-between px-[40px] pb-[40px]">
          <div>
            <p className="text-[24px] font-bold">{header}</p>
            {subtitle && (
              <p className="mt-[8px] text-[16px] font-normal">{subtitle}</p>
            )}
          </div>
          <button className="modal-close z-40 cursor-pointer" onClick={onClose}>
            <img src={closeIcon} alt="close" className="h-[32px]" />
          </button>
        </div>
        <div className="modal-content max-h-[70vh] overflow-y-auto">
          {children}
        </div>
        {footerButton && (
          <div className="mt-[20px] flex items-center justify-center">
            {footerButton()}
          </div>
        )}
      </div>
    </div>
  );
};

export default NewModal;
