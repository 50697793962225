import { useLocation, useNavigate } from 'react-router-dom';

const UserInfoButton = ({ to, children, className, isLast, onClick }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const active = location.pathname === `/${to}`;
  const activeStyles = 'text-[#343D4B] bg-[#F2F6FA]';

  // Include border-radius on the bottom if it is the last button
  const lastButtonStyles = isLast ? 'rounded-b-[8px]' : '';

  // Adjust padding or remove border-bottom if necessary on hover for the last button
  const hoverStyles = `hover:bg-[#F2F6FA] ${isLast ? 'hover:border-b-0' : ''}`;

  const buttonStyles = `flex items-center justify-between px-[16px] py-[10px] w-full text-left font-medium text-[#343D4B] transition duration-300 ease-in-out ${
    active ? activeStyles : ''
  } ${className || ''} ${lastButtonStyles} ${hoverStyles}`;

  // Combine navigation and additional onClick action if provided
  const handleClick = () => {
    if (onClick) {
      onClick();
    } // This checks if an onClick prop was provided and calls it
    navigate(to);
  };

  return (
    <button onClick={handleClick} className={buttonStyles}>
      {children}
    </button>
  );
};

export default UserInfoButton;
