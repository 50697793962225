import { CreateMatching } from 'models/CompositionMatching';
import { Control } from 'stores/order/NewOrderStore';
import UnmatchedCMForm from './components/UnmatchedCMForm/UnmatchedCMForm';
import { CMOrder } from './interface';

export function openUnmatchedCMForm(
  render: (control: Control) => void,
  cmOrders: CMOrder[]
): Promise<CreateMatching[]> {
  return new Promise((resolve) => {
    render(({ close }) => (
      <UnmatchedCMForm
        cmOrders={cmOrders}
        onSubmit={(value) => {
          resolve(value);
          close();
        }}
      />
    ));
  });
}
