import { CJ_Doseosangan, LOTTE_Doseosangan } from './constants';
import { DoseosanganStandard } from './interface';

export function isDoseosangan(
  postCode: string,
  standard: DoseosanganStandard = 'CJ'
): boolean {
  if (postCode.length !== 5) {
    return false;
  }

  const doseosangans = () => {
    switch (standard) {
      case 'CJ':
        return CJ_Doseosangan;
      case 'LOTTE':
        return LOTTE_Doseosangan;
    }
  };

  return doseosangans().some(([start, end]) => {
    return postCode >= start && postCode <= end;
  });
}
