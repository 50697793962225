import { Token } from '../interface';
import {
  getOperatorPriority,
  isOperator,
  isVariableArgumentOperatorToken,
  Operator,
} from '../operator';
import { getArgumentLength } from './getArgumentLength';

export function infixToPostfix(infix: Token[]) {
  const operators: Operator[] = [];
  const postfix: Token[] = [];

  infix.forEach((token, index) => {
    if (isOperator(token)) {
      if (token === '(') {
        operators.push(token);
        return;
      }

      if (token === ')') {
        while (operators.at(-1) !== '(' && operators.length > 0) {
          postfix.push(operators.pop()!);
        }

        operators.pop();
        return;
      }

      if (isVariableArgumentOperatorToken(token)) {
        const argumentLength = getArgumentLength(infix.slice(index + 1));
        operators.push(`${token}-${argumentLength}`);
        return;
      }

      if (operators.length === 0) {
        operators.push(token);
        return;
      }

      while (
        operators.length > 0 &&
        operators.at(-1) !== '(' &&
        getOperatorPriority(operators.at(-1)!) >= getOperatorPriority(token)
      ) {
        postfix.push(operators.pop()!);
      }

      if (token === ',') {
        return;
      }

      operators.push(token);
      return;
    }

    postfix.push(token);
  });

  while (operators.length !== 0) {
    postfix.push(operators.pop()!);
  }

  return postfix;
}
