import {
  DISPATCH_ORDER_POllING_URL,
  DISPATCH_ORDER_SEARCH_URL,
  DispatchOrderPollingData,
  DispatchOrderPollingQuery,
  DispatchOrderSearchData,
  DispatchOrderSearchQuery,
} from '@sweep/contract';
import { amplitude } from 'src/utils';
import { tryCatch } from 'src/utils/function/tryCatch';
import api, { APIResponse } from '../api';

export type GetValidShoppingMallListRes =
  | { message: 'Success'; result: string[] }
  | { message: 'Failed' };

export async function getShoppingMallNames() {
  const url = '/dispatch-order/valid-shopping-mall-list';
  const response = await api.get<GetValidShoppingMallListRes>(url);

  return response.data;
}

export type GetFetchOrdersRes =
  | {
      message: 'Success';
      result: {
        requestId: string;
      };
    }
  | {
      message: 'Failed';
      result: { err: string };
    };

export async function requestDispatchOrders(
  startTime: number,
  endTime: number,
  shoppingMall: string,
  modified = false
) {
  const url = '/dispatch-order/fetch-orders';
  const response = await tryCatch(async () => {
    const response = await api.get<GetFetchOrdersRes>(url, {
      params: {
        startTime,
        endTime,
        shoppingMall,
        modified,
      },
    });

    return response.data;
  });

  if (response?.message === 'Success') {
    amplitude.track('Dispatch Order (Server)', { 'Store Name': shoppingMall });
  }

  return response;
}

export type DispatchOrderPollingResponse =
  APIResponse<DispatchOrderPollingData>;

export async function getDispatchedOrdersPolling(
  query: DispatchOrderPollingQuery
) {
  return tryCatch(async () => {
    const response = await api.get<DispatchOrderPollingResponse>(
      DISPATCH_ORDER_POllING_URL,
      { params: query }
    );

    return response.data;
  });
}

type DispatchOrderSearchResponse = APIResponse<DispatchOrderSearchData>;

export async function searchDispatchedOrders(query: DispatchOrderSearchQuery) {
  const response = await tryCatch(async () => {
    const response = await api.get<DispatchOrderSearchResponse>(
      DISPATCH_ORDER_SEARCH_URL,
      { params: query }
    );

    return response.data;
  });

  if (response?.success === true) {
    amplitude.track('Fetch Order (Server)', {
      Store: query.shoppingMall ?? 'all',
      'Order Number': query.orderNumber,
      'Phone Number': query.contactNumber,
      Name: query.name,
      'Order Count': response.data.length,
    });
  }

  return response;
}
