import { ShoppingMall } from '@sweep/contract';
import { isEqualArray } from '@sweep/utils';

export function getShoppingMall(header: string[]): ShoppingMall | null {
  if (isEqualArray(header, 돌쇠네_HEADER)) {
    return '돌쇠네';
  }

  if (isEqualArray(header, ELEVEN_HEADER)) {
    return 'eleven';
  }

  if (isEqualArray(header, EBAY_HEADER)) {
    return 'ebay';
  }

  return null;
}

const 돌쇠네_HEADER: string[] = [
  '상품명',
  '주문수량',
  '수령인명',
  '우편번호',
  '주소',
  '수령인연락처',
  '배송시요청사항',
  '택배사**',
  '배송번호',
  '(공백)',
  '주문번호',
  '상품코드',
  '공급사배송비',
  '공급가',
  '판매사',
  '주문자명',
  '주문자연락처',
];

const ELEVEN_HEADER: string[] = [
  '번호',
  '주문상태',
  '주문번호',
  '주문순번',
  '상품명',
  '옵션',
  '수량',
  '주문금액',
  '수취인',
  '배송방법',
  '송장번호',
  '배송비',
  '도서산간 배송비',
  '휴대폰번호',
  '전화번호',
  '우편번호',
  '주소',
  '배송메시지',
  '판매자 상품코드',
  '판매단가',
  '옵션가',
];

const EBAY_HEADER: string[] = [
  '주문옵션',
  '상품명',
  '수량',
  '수령인명',
  '수령인 휴대폰',
  '수령인 전화번호',
  '주소',
  '우편번호',
  '배송시 요구사항',
  '주문번호',
  '정산예정금액',
  '배송비 금액',
  '아이디',
  '상품번호',
  '택배사명(발송방법)',
  '송장번호',
  '주문일자(결제확인전)',
  '결제완료일',
  '주문확인일자',
  '구매자명',
  '구매자ID',
  '구매자 휴대폰',
  '판매방식',
  '주문종류',
  '발송정책',
  '발송마감일',
  '선물주문여부',
  'SKU번호 및 수량',
  '장바구니번호(결제번호)',
  '판매금액',
  '판매자 상세관리코드',
];
