import { useCallback, useEffect, useRef, useState } from 'react';
import { useWindowEvent } from 'utils/react';

export function useHorizontalScroll<T extends HTMLElement>() {
  const ref = useRef<T>(null);
  const [hasHorizontalScroll, setHasHorizontalScroll] = useState(false);

  const checkForScroll = useCallback(() => {
    if (ref.current) {
      setHasHorizontalScroll(ref.current.scrollWidth > ref.current.clientWidth);
    }
  }, [ref]);

  useEffect(() => {
    checkForScroll();
  }, [checkForScroll]);

  useWindowEvent('resize', checkForScroll);

  return { hasHorizontalScroll, ref };
}
