import { useEffect, useRef, useState } from 'react';
import { isNumeric } from '../../utils/utils';

interface EditableCellProps {
  value: string;
  onValueChange: (value: string) => void;
  onFocused: (isFocused: boolean) => void;
  className?: string;
}

const EditableCell = ({
  value,
  onValueChange,
  onFocused,
  className = '',
}: EditableCellProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [currentValue, setCurrentValue] = useState<string>(value);
  const inputRef = useRef<any>(null);

  const handleBlur = () => {
    setIsEditing(false);
    onValueChange(currentValue);
    onFocused(false);
  };

  const handleFocus = () => {
    onFocused(true);
  };

  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      handleBlur();
    }
  };

  useEffect(() => {
    if (isEditing) {
      inputRef.current && inputRef.current.focus();
    }
  }, [isEditing]);

  return isEditing ? (
    <div className={`editable-cell-container ${className}`}>
      <input
        ref={inputRef}
        type="text"
        value={currentValue}
        onChange={(e) => setCurrentValue(e.target.value)}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onKeyPress={handleKeyPress}
        autoFocus
        className={`w-full border-none outline-none ${
          isNumeric(value) ? 'text-right' : 'text-left'
        } editable-input`}
        style={{ boxSizing: 'border-box' }}
      />
    </div>
  ) : (
    <div className={`editable-cell-container ${className}`}>
      <span onClick={() => setIsEditing(true)}>{value}</span>
    </div>
  );
};

export default EditableCell;
