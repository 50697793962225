import { DispatchedOrder, isHiddenStatus } from '@sweep/contract';
import { observer } from 'mobx-react-lite';
import { overlay } from 'overlay-kit';
import { useMemo, useState } from 'react';
import { Button } from 'src/design-system/components/Button';
import SearchInput from 'src/design-system/components/SearchInput/SearchInput';
import useCreateExcel from 'src/hooks/useCreateExcel';
import { useOMSStore } from 'src/hooks/useOMSStore';
import { useSearchState } from 'src/hooks/useSearchState';
import useShippingUpload from 'src/hooks/useShippingUpload';
import {
  IconAddAll,
  IconAddExcel,
  IconArrowRight,
  IconCheckSmall,
  IconConfirmOrders,
  IconControlDownload,
  IconTimeSmall,
} from 'src/icons';
import { amplitude, SwitchCase } from 'src/utils';
import { formatDate } from 'src/utils/date/formatDate';
import DispatchSelectModal from './components/DispatchSelectModal/DispatchSelectModal';
import MultiSelect from './components/MultiSelect/MultiSelect';
import OrderDispatchTable from './components/OrderDispatchTable';
import OrderStatusRadioGroup from './components/OrderStatusRadioGroup/OrderStatusRadioGroup';
import {
  DISPATCHED_ORDER_COLUMN_MAPPING,
  DISPATCHED_ORDER_HEADER,
  DISPATCHED_ORDER_HEADER_KEYS,
} from './constants';
import { useOrderStatusState } from './hooks/useSelectedOrderStatus';
import { useShoppingMallsState } from './hooks/useShoppingMallsState';
import { OrderStatusType } from './interface';
import { openRejectCanceledOrderDialog } from './services/openRejectCanceledOrderDialog';
import { warningNotLinkedShoppingMall } from './services/warningNotLinkedShoppingMall';
import { OrderDispatchScreenStore } from './store/OrderDispatchScreenStore';

function OrderDispatchScreen() {
  const oms = useOMSStore();
  const selectedShoppingMalls = oms.order.dispatch.selectedShoppingMallNames;
  const { createOrderExcel } = useCreateExcel();
  const { mallUploadShippingInfo } = useShippingUpload();

  const [store] = useState(
    () => new OrderDispatchScreenStore(oms, createOrderExcel)
  );

  const lastestDispatchedAt = oms.order.dispatch.lastestDispatchedAt;

  const shoppingMalls = oms.order.dispatch.shoppingMallNames;
  const isNotLinkedShoppingMall = shoppingMalls.length === 0;

  const [
    selectedOrderStatus,
    setSelectedOrderStatus,
    filterSelectedOrderStatus,
  ] = useOrderStatusState('PaymentComplete');
  const dispatchedOrders = oms.order.dispatch.dispatchedOrders;
  const statusFilteredDispatchedOrders = useMemo(() => {
    const visibleOrders = dispatchedOrders.filter(
      (order) => !isHiddenStatus(order.orderStatus)
    );

    return filterSelectedOrderStatus(visibleOrders);
  }, [dispatchedOrders, filterSelectedOrderStatus]);

  const [search, setSearch, filterSearch] = useSearchState<DispatchedOrder>(
    '',
    DISPATCHED_ORDER_HEADER_KEYS
  );
  const [
    selectedShoppingMallFilters,
    setSelectedShoppingMallFilters,
    filterSelectedShoppingMallFilters,
  ] = useShoppingMallsState([]);
  const filteredOrders = useMemo(
    () =>
      filterSearch(
        filterSelectedShoppingMallFilters(statusFilteredDispatchedOrders)
      ),
    [
      filterSearch,
      filterSelectedShoppingMallFilters,
      statusFilteredDispatchedOrders,
    ]
  );

  const [selectedOrders, setSelectedOrders] = useState<DispatchedOrder[]>([]);

  const handleSelectedOrderStatusChange = (
    selectedOrderStatus: OrderStatusType
  ) => {
    setSelectedOrderStatus(selectedOrderStatus);
    setSelectedOrders([]);
  };

  const handleSearchChange = (search: string) => {
    setSearch(search);
  };

  const handleSelectedShoppingmallChange = (
    selectedShoppingMalls: string[]
  ) => {
    oms.order.dispatch.setSelectedShoppingMallNames(selectedShoppingMalls);
    setSelectedShoppingMallFilters([]);
  };

  const dispatchSelectedShoppingMalls = () => {
    if (isNotLinkedShoppingMall) {
      warningNotLinkedShoppingMall();
      return;
    }
    dispatchOrder(selectedShoppingMalls);
  };

  const dispatchOrder = async (shoppingMalls: string[]) => {
    const orders = await store.dispatchOrder(shoppingMalls);
    if (orders == null) {
      return;
    }

    setSelectedOrders([]);
  };

  const openDispatchSelectModal = () => {
    if (isNotLinkedShoppingMall) {
      warningNotLinkedShoppingMall();
      return;
    }

    overlay.open(({ isOpen, close }) => (
      <DispatchSelectModal
        open={isOpen}
        onClose={close}
        onSubmit={(shoppingMalls) => {
          handleSelectedShoppingmallChange(shoppingMalls);
          dispatchOrder(shoppingMalls);
        }}
        selectedShoppingMalls={selectedShoppingMalls}
      />
    ));
  };

  const confirmSelectedOrders = async () => {
    const result = await store.confirmSelectedOrders(selectedOrders);
    if (result) {
      setSelectedOrders([]);
    }
  };

  const acceptCanceledSelectedOrders = async () => {
    const result = await store.acceptCanceledSelectedOrders(selectedOrders);
    if (result) {
      setSelectedOrders([]);
    }
  };

  const openRejectCanceledSelectedOrdersDialog = () => {
    if (selectedOrders.length === 0) {
      alert('거부할 취소 건을 선택해주세요.');
      return;
    }

    openRejectCanceledOrderDialog(selectedOrders, rejectCanceledOrders);
  };

  const rejectCanceledOrders = async (
    rejectReason: string,
    orders: DispatchedOrder[]
  ) => {
    const result = await store.rejectCanceledSelectedOrders(
      rejectReason,
      orders
    );
    if (result) {
      setSelectedOrders([]);
    }
  };

  const handleShippingFilesChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    if (files == null) {
      return;
    }

    amplitude.track('Upload Shipping-info', {
      From: 'order-dispatch',
    });

    oms.loading.batch(async () => {
      // NOTE(@이지원): 운송장 업로드 리팩토링 후, try-catch 제거
      try {
        await mallUploadShippingInfo(Array.from(files));
      } catch (event) {
        alert('운송장 업로드에 실패했습니다. 잠시 후 다시 시도해주세요.');
      } finally {
        event.target.value = '';
        setSelectedOrders([]);
      }
    });
  };

  const mergedSelectedDispatchedOrders = async () => {
    await store.mergeDispatchedOrders(selectedOrders);
  };

  const downloadOrdersToExcel = async () => {
    if (selectedOrders.length === 0 && filteredOrders.length === 0) {
      alert('주문을 불러온 후 다시 시도해주세요.');
      return;
    }

    const downloadedOrders =
      selectedOrders.length === 0 ? filteredOrders : selectedOrders;

    oms.loading.batch(async () => {
      await createOrderExcel(
        downloadedOrders,
        `${formatDate(new Date(), 'yy.MM.dd')} 주문수집`,
        DISPATCHED_ORDER_HEADER,
        DISPATCHED_ORDER_COLUMN_MAPPING
      );
    });
  };

  return (
    <div className="flex h-full flex-col gap-[32px] bg-gray-100 px-[40px] py-[20px]">
      <div className="h-42px flex items-center">
        <h1 className="text-extrabold-l text-gray-700">주문 수집하기</h1>
      </div>
      <div className="flex flex-col gap-[20px] rounded-[8px] bg-white p-[20px] shadow-[0_1px_4px_0_rgba(0,0,0,0.1)]">
        <div className="flex items-center gap-[20px]">
          <p className="text-medium-s text-gray-500">쇼핑몰</p>
          <button
            className="flex h-[40px] min-w-0 max-w-[400px] flex-1 items-center rounded-[8px] border border-gray-300 px-[16px] hover:bg-gray-100"
            onClick={openDispatchSelectModal}
          >
            <p className="text-ellipsis-nowrap text-medium-s min-w-0 flex-1 text-left text-gray-700">
              {selectedShoppingMalls.length === shoppingMalls.length
                ? '전체'
                : selectedShoppingMalls.join(', ')}
            </p>
            <IconArrowRight className="text-gray-700" />
          </button>
          <Button onClick={dispatchSelectedShoppingMalls}>주문 불러오기</Button>
          {lastestDispatchedAt && (
            <div className="flex items-center gap-1">
              <IconTimeSmall className="text-gray-500" />
              <p className="text-regular-s text-gray-500">
                마지막 수집:{' '}
                {formatDate(
                  new Date(lastestDispatchedAt),
                  'yyyy. M. d. a h:mm'
                )}
              </p>
            </div>
          )}
        </div>
        <OrderStatusRadioGroup
          orders={dispatchedOrders}
          value={selectedOrderStatus}
          onChange={handleSelectedOrderStatusChange}
        />
      </div>
      <div className="flex flex-col gap-[16px] rounded-[8px] bg-white p-[20px] shadow-[0_1px_4px_0_rgba(0,0,0,0.1)]">
        <div className="flex items-center gap-[20px]">
          <p className="text-medium-s whitespace-nowrap text-gray-500">필터</p>
          <SearchInput
            value={search}
            onChange={handleSearchChange}
            placeholder="검색어를 입력하세요."
            className="max-w-[500px]"
          />
          <MultiSelect
            placeholder="쇼핑몰 필터"
            value={selectedShoppingMallFilters}
            onChange={setSelectedShoppingMallFilters}
            className="w-[220px] hover:bg-gray-100"
          >
            <MultiSelect.MultiOption value={selectedShoppingMalls}>
              전체
            </MultiSelect.MultiOption>
            {selectedShoppingMalls.map((mall) => (
              <MultiSelect.Option key={mall} value={mall}>
                <p className="text-ellipsis-nowrap">{mall}</p>
              </MultiSelect.Option>
            ))}
          </MultiSelect>
        </div>
        <hr />
        <div className="flex flex-col gap-[12px]">
          <div>
            <div className="flex items-center gap-[4px]">
              <p className="text-medium-s text-gray-500">목록</p>
              <p className="text-medium-s text-gray-500">
                (<span className="text-blue-500">{filteredOrders.length}</span>
                건)
              </p>
            </div>
            <div className="flex h-[40px] items-end justify-between">
              <div className="flex h-fit items-center gap-[6px]">
                <IconCheckSmall className="text-gray-400" />
                <span className="text-semibold-s text-blue-500">
                  {selectedOrders.length}
                </span>
                <span className="text-medium-s text-gray-500">선택됨</span>
              </div>
              <div className="flex gap-[10px]">
                <Button
                  color="gray"
                  rightAccessory={<IconControlDownload />}
                  onClick={downloadOrdersToExcel}
                >
                  엑셀 파일
                </Button>
                <SwitchCase
                  value={selectedOrderStatus}
                  caseBy={{
                    PaymentComplete: [
                      <Button
                        key={1}
                        leftAccessory={<IconAddExcel />}
                        onClick={mergedSelectedDispatchedOrders}
                      >
                        통합 엑셀에 추가
                      </Button>,
                      <Button
                        key={2}
                        leftAccessory={<IconConfirmOrders />}
                        onClick={confirmSelectedOrders}
                      >
                        주문 확인하기
                      </Button>,
                    ],
                    ProductPreparing: [
                      <Button
                        key="combine"
                        leftAccessory={<IconAddExcel />}
                        onClick={mergedSelectedDispatchedOrders}
                      >
                        통합 엑셀에 추가
                      </Button>,
                      <Button
                        key="upload"
                        variant="line"
                        leftAccessory={<IconAddAll />}
                        asChild
                      >
                        <label>
                          운송장 일괄 등록
                          <input
                            className="hidden"
                            type="file"
                            accept=".xlsx, .xls, .csv"
                            multiple
                            onChange={handleShippingFilesChange}
                          />
                        </label>
                      </Button>,
                    ],
                    CancelRequest: [
                      <Button
                        key="confirm"
                        onClick={acceptCanceledSelectedOrders}
                      >
                        취소 승인
                      </Button>,
                      <Button
                        key="reject"
                        color="gray"
                        onClick={openRejectCanceledSelectedOrdersDialog}
                      >
                        취소 거부
                      </Button>,
                    ],
                  }}
                />
              </div>
            </div>
          </div>
          <OrderDispatchTable
            orders={filteredOrders}
            selectedOrders={selectedOrders}
            onSelectedOrdersChange={setSelectedOrders}
            orderStatusType={selectedOrderStatus}
            className="h-[calc(100vh-300px)] overflow-auto"
          />
        </div>
      </div>
    </div>
  );
}

export default observer(OrderDispatchScreen);
