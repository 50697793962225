import { useRef } from 'react';
import useUpload from '../hooks/useUpload';
import LoadingStore from '../stores/LoadingStore';
import { isValid } from '../utils/utils';
import Button from './buttons/Button';

const FileDragAndDrop = ({ onUpload, text = '' }: any) => {
  const dropRef = useRef(null);
  const { validateFiles } = useUpload();
  const handleUpload = async (e: any) => {
    const validFiles = await validateFiles(e);
    LoadingStore?.setIsLoading(true);
    if (validFiles.length > 0) {
      await onUpload({
        target: {
          files: validFiles,
        },
      });
    }
    e.target.value = '';
    LoadingStore?.setIsLoading(false);
    // // To do: 오류 발생 시 로딩 상태를 비활성화하는 것을 보장
  };

  const handleDragOver = (e: any) => {
    e.preventDefault();
  };

  return (
    <div
      className="flex flex-col items-center rounded-xl border border-[#D3DFEB] bg-[#F2F6FA] transition duration-300 ease-in-out"
      ref={dropRef}
      onDrop={handleUpload}
      onDragOver={handleDragOver}
    >
      <div className="mt-[56px]"></div>
      <div className="flex items-center">
        <span className="text-[16px] font-semibold text-[#343D4B]">
          {isValid(text) ? (
            text
          ) : (
            <>
              통합하려는 판매처의&nbsp;
              <p className="inline text-[#057FF0]">주문 파일들</p>을 여기에
              끌어다 놓으세요.
            </>
          )}
        </span>
      </div>
      <div className="mt-[28px] flex items-center">
        <span className="text-[16px] font-semibold text-[#A7B3C2]">또는</span>
      </div>

      <div className="mb-[80px] mt-[12px]">
        <Button
          name="파일 업로드 하기"
          color="black"
          type="input"
          multiple
          onClick={handleUpload}
        />
      </div>
    </div>
  );
};

export default FileDragAndDrop;
