import { action, makeObservable, observable, runInAction } from 'mobx';
import { Supplier } from 'models/Partner';
import { CreatePluginDTO } from 'network/plugin';
import { getSuppliers } from 'network/supplier';
import { OMSStore } from './OMSStore';

export class SupplierStore {
  suppliers: Supplier[] = [];
  supplierMap: Map<string, Supplier> = new Map();

  constructor(private oms: OMSStore) {
    makeObservable(this, {
      suppliers: observable,

      init: action.bound,
      loadSuppliers: action.bound,

      _update: action.bound,
      createPlugin: action.bound,
      deletePlugin: action.bound,
      setSuppliers: action.bound,
    });
  }

  getSupplier(supplierId: string) {
    return this.supplierMap.get(supplierId);
  }

  async init() {
    await this.loadSuppliers();
  }

  async loadSuppliers() {
    const response = await getSuppliers();
    if (!response.success) {
      return;
    }

    runInAction(() => {
      this.suppliers = response.data;
      this.supplierMap = new Map(
        response.data.map((supplier) => [supplier._id, supplier])
      );
    });
  }

  _update(supplierId: string, supplier: Partial<Supplier>) {
    const prevSupplier = this.supplierMap.get(supplierId);
    if (prevSupplier == null) {
      return;
    }

    const updatedSupplier = { ...prevSupplier, ...supplier };

    this.supplierMap.set(supplierId, updatedSupplier);
    this.suppliers = this.suppliers.map((supplier) =>
      supplier._id === supplierId ? updatedSupplier : supplier
    );

    return updatedSupplier;
  }

  async createPlugin(supplierId: string, createPluginDTO: CreatePluginDTO) {
    const supplier = await this.oms.plugin.createSupplierPlugin(
      supplierId,
      createPluginDTO
    );
    if (supplier == null) {
      return;
    }

    this._update(supplierId, { plugins: supplier.plugins });
  }

  async deletePlugin(supplierId: string, pluginId: string) {
    const prevSupplier = this.supplierMap.get(supplierId);
    if (prevSupplier == null) {
      return;
    }

    this._update(supplierId, {
      plugins: prevSupplier.plugins?.filter((p) => p !== pluginId),
    });

    await this.oms.plugin.deleteSupplierPlugin(supplierId, pluginId);
  }

  /**
   * @deprecated
   */
  setSuppliers(suppliers: Supplier[]) {
    this.suppliers = suppliers;
    this.supplierMap = new Map(
      suppliers.map((supplier) => [supplier._id, supplier])
    );
  }
}
