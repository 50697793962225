import { useEffect, useState } from 'react';

const KEY_ENTER = 13;
const KEY_UP = 38;
const KEY_DOWN = 40;
const KEY_TAP = 9;

const AutocompleteByArray = ({
  dataArray,
  selectedValue,
  onChange,
  onClickAddProduct,
  contentName = '',
  className = '',
}) => {
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [selected, setSelected] = useState(selectedValue);
  const [inputValue, setInputValue] = useState(selectedValue || '');
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);

  useEffect(() => {
    const filtered = dataArray.filter(
      (info) =>
        !inputValue || info.toLowerCase().includes(inputValue.toLowerCase())
    );

    // 글자 수에 따라 정렬
    const sortedFiltered = filtered.sort((a, b) => a.length - b.length);

    setFilteredSuggestions(sortedFiltered);
    setActiveSuggestionIndex(0);
  }, [inputValue, dataArray]);

  useEffect(() => {
    if (selectedValue) {
      setInputValue(selectedValue);
      setSelected(selectedValue);
    }
  }, [selectedValue]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setShowSuggestions(true);
  };

  const setSelectedValue = (value) => {
    setSelected(value);
    onChange(value);
  };

  const handleKeyDown = (e) => {
    switch (e.keyCode) {
      case KEY_ENTER:
        e.preventDefault();
        const selectedValue = filteredSuggestions[activeSuggestionIndex];

        if (selectedValue) {
          setInputValue(selectedValue);
          setSelectedValue(selectedValue);
        } else {
          setInputValue(selected);
        }
        setShowSuggestions(false);
        break;
      case KEY_TAP:
        const selectedValueForKeyTaP =
          filteredSuggestions[activeSuggestionIndex];

        if (selectedValueForKeyTaP) {
          setInputValue(selectedValueForKeyTaP);
          setSelectedValue(selectedValueForKeyTaP);
        } else {
          setInputValue(selected);
        }
        setShowSuggestions(false);
        break;
      case KEY_UP:
        e.preventDefault();
        if (activeSuggestionIndex > 0) {
          setActiveSuggestionIndex(activeSuggestionIndex - 1);
        }
        break;
      case KEY_DOWN:
        e.preventDefault();
        if (activeSuggestionIndex < filteredSuggestions.length - 1) {
          setActiveSuggestionIndex(activeSuggestionIndex + 1);
        }
        break;
      default:
    }
  };

  const handleSuggestionClick = (selected) => {
    setShowSuggestions(false);
    // 선택한 selected을 가진 dataArray의 객체 찾기
    if (selected) {
      // selecteds에 선택된 객체를 추가
      setInputValue(selected);
      setSelectedValue(selected);
    } else {
      setInputValue('');
    }
  };

  const handleInputBlur = () => {
    // 포커스가 벗어나면 제안 목록을 숨기기
    // setTimeout을 사용하여 클릭 이벤트가 제안 선택보다 먼저 발생하지 않도록 함

    if (filteredSuggestions.includes(inputValue)) {
      setSelectedValue(inputValue);
    } else if (inputValue === '') {
      setSelectedValue('');
    } else if (selectedValue) {
      setInputValue(selectedValue);
      setSelectedValue(selectedValue);
    } else {
      setSelectedValue('');
      setInputValue('');
    }
    setShowSuggestions(false);
  };

  return (
    <div className="relative">
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        onBlur={handleInputBlur}
        onFocus={(e) => {
          e.target.select();
          setShowSuggestions(true);
        }}
        className={`py-2 px-[16px] w-[160px] border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-blue-400 focus:border-blue-400 placeholder-gray-500 text-black ${className}`}
        placeholder={
          showSuggestions
            ? contentName
              ? `${contentName} 검색`
              : '검색'
            : contentName
              ? `${contentName} 선택`
              : '선택'
        }
      />
      {showSuggestions && (
        <ul
          className="absolute rounded-lg z-50 left-0 right-0 top-full bg-white border mt-1 scrollable-list"
          onMouseDown={(e) => e.preventDefault()}
        >
          {filteredSuggestions.length > 0 &&
            filteredSuggestions.map((suggestion, index) => (
              <li
                key={suggestion}
                className={`p-2 rounded-lg hover:bg-gray-100 cursor-pointer ${
                  index === activeSuggestionIndex ? 'bg-gray-100' : ''
                }`}
                onMouseDown={() => handleSuggestionClick(suggestion)}
              >
                {suggestion}
              </li>
            ))}
          {contentName && (
            <li
              key=""
              className={`p-2 rounded-lg hover:bg-gray-100 cursor-pointer text-blue-500 font-bold `}
              onMouseDown={() => onClickAddProduct(inputValue)}
            >
              {contentName ? `+ ${contentName} 추가하기` : '+ 추가하기'}
            </li>
          )}
        </ul>
      )}
    </div>
  );
};

export default AutocompleteByArray;
