import { useState } from 'react';
import { useWindowEvent } from './useWindowEvent';

export function useComposition() {
  const [isComposing, setIsComposing] = useState(false);

  useWindowEvent('compositionstart', () => {
    setIsComposing(true);
  });

  useWindowEvent('compositionend', () => {
    setIsComposing(false);
  });

  return { isComposing };
}
