import * as XLSX from 'xlsx';

export async function isProtected(file: File) {
  return new Promise<boolean>((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const data = event.target?.result;

      try {
        XLSX.read(data, { type: 'binary' });
        resolve(false);
      } catch (error) {
        resolve(true);
      }
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsBinaryString(file);
  });
}
