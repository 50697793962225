import { Order } from '@sweep/contract';
import { OMSStore } from 'stores/OMSStore';
import { CompositionMatchingPlugin } from './features/composition-matching';
import { AbstractPlugin, Plugin, PluginImpl } from './interface';
import { AddZeroToContactNumberPlugin } from './plugins/add-zero-to-contact-number';
import { AliDispatchPlugin } from './plugins/ali-dispatch/AliDispatchPlugin';
import { AliSplitPlugin } from './plugins/ali-split';
import { CheckAddressPlugin } from './plugins/check-address';
import { ConditionalValuePlugin } from './plugins/conditional-value';
import { DatePlugin } from './plugins/date';
import { DispatchOrderFieldPlugin } from './plugins/dispatch-order-field/DispatchOrderFieldPlugin';
import { ExcelFunctionPlugin } from './plugins/excel-function';
import { ExcelModelPlugin } from './plugins/excel-model';
import { NormalizeAddressPlugin } from './plugins/normalize-address';
import { NormalizePhoneNumberPlugin } from './plugins/normalize-phone-number';
import { NumberingPlugin } from './plugins/numbering';
import { RemoveDuplicatedQuantityPlugin } from './plugins/remove-duplicated-quantity';
import { RemoveEmptyPlugin } from './plugins/remove-empty';
import { RemoveSubtotalPlugin } from './plugins/remove-subtotal';
import { RemoveZeroQuantityPlugin } from './plugins/remove-zero-quantity';
import { StoreOriginValuePlugin } from './plugins/store-origin-value';

export const pluginRecord: Record<string, PluginImpl<any>> = {
  'add-zero-to-contact-number': AddZeroToContactNumberPlugin,
  'check-address': CheckAddressPlugin,
  'conditional-value': ConditionalValuePlugin,
  'composition-matching': CompositionMatchingPlugin,
  date: DatePlugin,
  'excel-function': ExcelFunctionPlugin,
  'excel-model': ExcelModelPlugin,
  numbering: NumberingPlugin,
  'normalize-phone-number': NormalizePhoneNumberPlugin,
  'remove-empty': RemoveEmptyPlugin,
  'remove-subtotal': RemoveSubtotalPlugin,
  'remove-zero-quantity': RemoveZeroQuantityPlugin,
  'remove-duplicated-quantity': RemoveDuplicatedQuantityPlugin,
};

export const pluginIds = Object.keys(pluginRecord) as PluginId[];

export type PluginId = keyof typeof pluginRecord;

type OrderStage =
  | 'dispatch'
  | 'merge-dispatched'
  | 'merge'
  | 'normalize'
  | 'divide';

const defaultPluginsRecord: Record<OrderStage, PluginImpl<undefined>[]> = {
  dispatch: [
    AliDispatchPlugin,
    AddZeroToContactNumberPlugin,
    DispatchOrderFieldPlugin,
    NormalizeAddressPlugin,
  ],
  'merge-dispatched': [StoreOriginValuePlugin, RemoveDuplicatedQuantityPlugin],
  merge: [RemoveEmptyPlugin, AliSplitPlugin, RemoveDuplicatedQuantityPlugin],
  normalize: [NormalizeAddressPlugin],
  divide: [],
};

export class PluginExecutionService {
  plugins: AbstractPlugin[] = [];

  constructor(oms: OMSStore, pluginDatas: Plugin[], orderStage?: OrderStage) {
    const defaultPlugins = createDefaultPlugins(oms, orderStage);

    const plugins = pluginDatas.map(
      (plugin) => new pluginRecord[plugin.pluginId](oms, plugin.config)
    );

    this.plugins = [...defaultPlugins, ...plugins];
  }

  execute = (orders: Order[]): Promise<Order[]> => {
    return this.plugins.reduce(
      (promise, plugin) => promise.then(plugin.transform),
      Promise.resolve(orders)
    );
  };
}

function createDefaultPlugins(oms: OMSStore, orderStage?: OrderStage) {
  if (orderStage == null) {
    return [];
  }

  return defaultPluginsRecord[orderStage].map(
    (Plugin) => new Plugin(oms, undefined)
  );
}
