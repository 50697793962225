import { splitOptiosIntoParts } from './divideOrder';
import { extractMultiplier } from './multiplierUtils';
import { isValid } from './utils';

const sortSummaryByProductId = (summary) => {
  return Object.values(summary).sort((a, b) => {
    // partnerId가 없는 경우를 맨 뒤로 정렬
    const idA = a.partnerId || Infinity;
    const idB = b.partnerId || Infinity;

    return idA < idB ? -1 : idA > idB ? 1 : 0;
  });
};

export const createDailySettlementSummary = (orders, settings = {}) => {
  const { userType = 'retailer' } = settings;

  let summary = {};
  let orderSummary = {};
  orders?.forEach((order) => {
    let data = [];
    if (isValid(order?.data)) {
      if (userType === 'retailer') {
        const productName = order.option ? order.option : order.productName;
        const unit = '';
        const quantity = order.quantity || 1;
        const partnerId = isValid(order?.partnerId) ? order.partnerId : '';

        data = order.data.map((item) => ({
          productId: item.productId || '',
          unit: item.unit || '',
          quantity: item.quantity || 1,
        }));

        const key = `${productName} ${unit} ${partnerId}`;
        if (!orderSummary[key]) {
          orderSummary[key] = {
            productName,
            unit,
            quantity: 0,
            partnerId,
            data: data,
          };
        }

        orderSummary[key].quantity += quantity;
      } else {
        order.data.forEach((data) => {
          const productName = data.productName;
          const unit = data.unit;
          const quantity = (data.quantity || 1) * (order.quantity || 1);
          const partnerId = isValid(order?.partnerId) ? order.partnerId : '';
          const newData = [
            {
              productId: data.productId || '',
              unit: data.unit || '',
              quantity: data.quantity || 1,
            },
          ];

          const key = `${productName} ${unit} ${partnerId}`;
          if (!orderSummary[key]) {
            orderSummary[key] = {
              productName,
              unit,
              quantity: 0,
              partnerId,
              data: newData,
            };
          }

          orderSummary[key].quantity += quantity;
        });
      }
    } else {
      const productName = order.productName || '';
      const unit = order.option || '';
      const quantity = order.quantity || 1;
      // data 해석이 안 된 경우 partnerId가 없음

      const key = `${productName} ${unit}`;
      if (!orderSummary[key]) {
        orderSummary[key] = {
          productName,
          unit,
          quantity: 0,
          data: [
            {
              productName,
              unit,
              quantity: 0,
            },
          ],
        };
      }

      orderSummary[key].quantity += quantity;
    }
  });

  // // 택배 개수 로직 만들기
  // const shippingSummary = _calculateShipping(orders, partners);

  summary = {
    ...orderSummary,
    // ...shippingSummary,
  };

  //   const totalSum = result.reduce((acc, item) => acc + item.total, 0);

  return sortSummaryByProductId(summary);
};

const _calculateShipping = (orders, partners) => {
  const shippingSummary = {};

  if (localStorage.getItem('@userId') === '652cea92b6e9eead0c3382a3') {
    orders.forEach((order) => {
      let shippingCost = 2650; // Default shipping cost

      let partnerId = '';
      if (isValid(order?.partnerId)) {
        partnerId = order.partnerId;
      }

      // Check the base unit
      const baseUnitMatch = order.option.match(/\d+(구|개입|개)/);

      if (baseUnitMatch) {
        const baseUnit = parseInt(
          baseUnitMatch[0].replace(/(구|개입|개)/, ''),
          10
        );

        let totalUnits = 0;

        const parts = splitOptiosIntoParts(order.option);

        parts.forEach((part) => {
          const unitMatch = part.match(/(\d+)(구|개입|개)/);
          if (unitMatch) {
            const units = parseInt(unitMatch[1], 10);
            const multiplier = extractMultiplier(part);
            if (units % baseUnit === 0) {
              const partUnits = units * multiplier;

              totalUnits += partUnits;
            }
          }
        });

        // Apply the 3100 won shipping cost if conditions are met
        if (baseUnit === 30 && totalUnits === 120) {
          shippingCost = 3100;
        }
      }

      const key = `택배비 ${shippingCost}원 ${partnerId}`;

      // Update the companyShippingCount
      if (!shippingSummary[key]) {
        shippingSummary[key] = {
          productName: '택배비',
          unit: `${shippingCost}원`,
          quantity: 0,
          partnerId,
        };
      }

      shippingSummary[key].quantity += 1;

      // 도서산간비 로직 추가
      if (isValid(order.additionalShippingFee)) {
        const additionalShippingFee = order.additionalShippingFee;
        const key = `도서산간비 ${additionalShippingFee}원 ${partnerId}`;

        if (!shippingSummary[key]) {
          shippingSummary[key] = {
            productName: '도서산간비',
            unit: `${additionalShippingFee}원`,
            quantity: 0,
            partnerId,
          };
        }

        shippingSummary[key].quantity += 1;
      }
    });
  }

  return shippingSummary;
};
