import {
  CreateMatching,
  CreateOptionCodeMatching,
} from 'models/CompositionMatching';

export function transformToCreateOptionCodeMatching(
  matching: CreateMatching
): CreateOptionCodeMatching {
  return {
    optionCode: matching.optionCode!,
    products: matching.products,
  };
}
