import { Token } from './interface';

export type Operand = number | boolean | string;

export const OPERATORS = [
  '&',
  '+',
  '-',
  '*',
  '/',
  '(',
  ')',
  ',',
  '=',
  '<',
  '>',
  '<=',
  '>=',
] as const;

type FixedArgumentOperatorToken = (typeof OPERATORS)[number];

export const VARIABLE_ARGUMENT_OPERATOR_TOKENS = [
  'IF',
  'AND',
  'OR',
  'ROUND',
  'ROUNDUP',
  'ROUNDDOWN',
] as const;

type VariableArgumentOperatorToken =
  (typeof VARIABLE_ARGUMENT_OPERATOR_TOKENS)[number];
type VariableArgumentOperator = `${VariableArgumentOperatorToken}-${number}`;

export type Operator = FixedArgumentOperatorToken | VariableArgumentOperator;

export function isOperator(token: Token): token is Operator {
  if (typeof token === 'number' || typeof token === 'boolean') {
    return false;
  }

  if (isVariableArgumentOperator(token)) {
    return true;
  }

  return OPERATORS.includes(token as any);
}

export function isVariableArgumentOperatorToken(
  value: string
): value is VariableArgumentOperatorToken {
  return VARIABLE_ARGUMENT_OPERATOR_TOKENS.includes(value as any);
}

export function isVariableArgumentOperator(
  value: string
): value is VariableArgumentOperator {
  const token = value.split('-').at(0);

  return token == null ? false : isVariableArgumentOperatorToken(token);
}

export function parseVariableArgumentOperator(
  operator: VariableArgumentOperator
): null | [VariableArgumentOperatorToken, number] {
  const token = operator.split('-').at(0);
  const length = parseInt(operator.split('-')[1], 10);

  const isNotVariableArgumentOperatorToken =
    token == null || !isVariableArgumentOperatorToken(token);

  if (isNotVariableArgumentOperatorToken || isNaN(length)) {
    return null;
  }

  return [token, length];
}

export function getOperatorPriority(operator: Operator): number {
  switch (operator) {
    case ',':
      return -1;
    case '(':
      return 0;
    case ')':
      return 0;

    case '>':
      return 10;
    case '<':
      return 10;
    case '>=':
      return 10;
    case '<=':
      return 10;
    case '=':
      return 10;

    case '&':
      return 20;
    case '+':
      return 21;
    case '-':
      return 21;
    case '*':
      return 22;
    case '/':
      return 22;
  }

  if (isVariableArgumentOperator(operator)) {
    return 100;
  }

  return -1;
}

export function isOperand(token: Token): token is Operand {
  return (
    typeof token === 'number' ||
    typeof token === 'boolean' ||
    !isOperator(token)
  );
}
