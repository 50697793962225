import { DispatchedOrder, NormalizedOrder, Order } from '@sweep/contract';
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import { ReactElement } from 'react';
import { OMSStore } from 'stores/OMSStore';
import backendApi from 'src/utils/backendApi';
import { isNotNil } from 'src/utils/function';
import { DispatchOrderStore } from './DispatchOrderStore';
import { DivideOrderStore } from './DivideOrderStore';
import { MergeOrderStore } from './MergeOrderStore';
import { NormalizeOrderStore } from './NormalizeOrderStore';

export type Control = ({ close }: { close: () => void }) => ReactElement;

export class NewOrderStore {
  dispatch: DispatchOrderStore;
  _merge: MergeOrderStore;
  _normalize: NormalizeOrderStore;
  _divide: DivideOrderStore;

  component: ReactElement | null = null;

  get uploadedFileNames() {
    const filenameSet = new Set<string>();
    this._normalize.normalizedOrders.forEach((order) => {
      order.originFile != null && filenameSet.add(order.originFile);
    });

    return Array.from(filenameSet);
  }

  get dispatchedOrders() {
    return this.dispatch.dispatchedOrders;
  }

  get mergedOrders() {
    return this._merge.mergedOrders;
  }

  get normalizedOrders() {
    return this._normalize.normalizedOrders;
  }

  get pendingOrders() {
    const isNotInitialized =
      this._merge.initialized === false ||
      this._normalize.initialized === false;

    if (isNotInitialized) {
      return [];
    }

    const normalizedOrders = this._normalize.normalizedOrders;
    const normalizedOrderFiles = normalizedOrders
      .map((order) => order.originFile)
      .filter(isNotNil);
    const normalizedOrderFileSet = new Set(normalizedOrderFiles);

    const mergedOrders = this._merge.mergedOrders;

    return mergedOrders.filter(
      (order) =>
        order.originFile != null &&
        !normalizedOrderFileSet.has(order.originFile)
    );
  }

  constructor(private oms: OMSStore) {
    makeObservable(this, {
      component: observable,

      uploadedFileNames: computed,
      dispatchedOrders: computed,
      mergedOrders: computed,
      normalizedOrders: computed,
      pendingOrders: computed,

      setMergedOrders: action.bound,
      setNormalizedOrders: action.bound,
      render: action.bound,
      init: action.bound,

      mergeDispatchedOrders: action.bound,
      merge: action.bound,
      normalize: action.bound,
      removeOrderByFilename: action.bound,
      removeOrders: action.bound,
    });

    this.dispatch = new DispatchOrderStore(oms);
    this._merge = new MergeOrderStore(oms);
    this._normalize = new NormalizeOrderStore(oms);
    this._divide = new DivideOrderStore(oms);
  }

  /**
   * @deprecated OrderStore를 마이그레이션 하기 위한 임시 메서드
   */
  setMergedOrders(orders: Order[]) {
    this._merge.setMergedOrders(orders);
  }

  /**
   * @deprecated OrderStore를 마이그레이션 하기 위한 임시 메서드
   */
  setNormalizedOrders(orders: NormalizedOrder[]) {
    this._normalize.setNormalizedOrders(orders);
  }

  render(control: Control) {
    const isLoading = this.oms.loading.isLoading;
    if (isLoading) {
      this.oms.loading.end();
    }
    this.component = control({
      close: () => {
        runInAction(() => {
          this.component = null;
          if (isLoading) {
            this.oms.loading.start();
          }
        });
      },
    });
  }

  async init() {
    await Promise.all([
      this.dispatch.init(),
      this._merge.init(),
      this._normalize.init(),
    ]);
  }

  async mergeDispatchedOrders(orders: DispatchedOrder[]) {
    return await this._merge.mergeDispatchedOrders(orders);
  }

  async merge(files: File[]): Promise<Order[] | null> {
    return await this._merge.mergeOrders(files);
  }

  async normalize(orders: Order[]): Promise<Order[]> {
    const normalizedOrders = await this._normalize.normalizeOrders(orders);
    return normalizedOrders;
  }

  removeOrderByFilename(filename: string) {
    this._merge.removeOrderByFilename(filename);
    this._normalize.removeOrderByFilename(filename);
  }

  async removeOrders(orders: NormalizedOrder[]) {
    const uniqueCodeSet = new Set(
      orders.map((order) => order.uniqueCodeAfterCustomization).filter(isNotNil)
    );

    const filteredNormalizedOrders = this.normalizedOrders.filter(
      (order) => !uniqueCodeSet.has(order.uniqueCodeAfterCustomization)
    );
    const removedNormalizedOrders = this.normalizedOrders.filter((order) =>
      uniqueCodeSet.has(order.uniqueCodeAfterCustomization)
    );
    const removedUniqueCodes = removedNormalizedOrders
      .map((order) => order.uniqueCode)
      .filter((uniqueCode) =>
        filteredNormalizedOrders.every(
          (order) => order.uniqueCode !== uniqueCode
        )
      );
    const removedUniqueCodeSet = new Set(removedUniqueCodes);

    const filteredOrders = this.mergedOrders.filter(
      (order) => !removedUniqueCodeSet.has(order.uniqueCode)
    );

    this.setMergedOrders(filteredOrders);
    this.setNormalizedOrders(filteredNormalizedOrders);

    await backendApi.saveOrderData(filteredOrders, filteredNormalizedOrders);
  }
}
