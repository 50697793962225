import { IconPlusXSmall } from 'icons/index';
import { observer } from 'mobx-react-lite';
import { MatchedProduct } from 'models/CompositionMatching';
import CMProductInput from './CMProductInput';

interface CMInputProps {
  value: Partial<MatchedProduct>[];
  onChange: (value: Partial<MatchedProduct>[]) => void;
  required?: boolean;
}

function CMInput({ value, onChange, required }: CMInputProps) {
  const handleCMProductChange = (
    index: number,
    product: Partial<MatchedProduct> | null
  ) => {
    const newProducts = [...value];
    if (value.length === 1 && product == null) {
      onChange([{ quantity: 1 }]);
      return;
    }

    if (product == null) {
      newProducts.splice(index, 1);
      onChange(newProducts);
      return;
    }

    newProducts[index] = product;
    onChange(newProducts);
  };

  return (
    <div className="flex w-full flex-col gap-[10px]">
      {value.map((product, index) => (
        <CMProductInput
          key={`${product.productId}-${index}`}
          value={product}
          onChange={(product) => handleCMProductChange(index, product)}
          required={required}
        />
      ))}
      <button
        className="text-medium-s flex w-fit items-center gap-1 text-blue-500"
        onClick={() => {
          onChange([...value, { quantity: 1 }]);
        }}
      >
        <IconPlusXSmall />
        구성 추가하기
      </button>
    </div>
  );
}

export default observer(CMInput);
