import { cva } from 'src/third-parties/tailwind';

interface DividerProps {
  type: 'horizontal' | 'vertical';
}

function Divider({ type }: DividerProps) {
  return <div className={divider({ type })} />;
}

const divider = cva('shrink-0 bg-gray-200', {
  variants: {
    type: {
      vertical: 'w-px',
      horizontal: 'h-px',
    },
  },
});

export default Divider;
