import { ExcelFile } from 'services/file/interface';
import { findHeaderIndex } from './findHeaderIndex';

export function sliceUntilHeader(excelFile: ExcelFile): ExcelFile | null {
  const headerIndex = findHeaderIndex(excelFile.data);
  if (headerIndex === -1) {
    // TODO(@이지원): header가 매칭이 안 되었다고 알려주기
    return null;
  }

  const slicedData = excelFile.data.slice(headerIndex);

  return {
    ...excelFile,
    data: slicedData,
  };
}
