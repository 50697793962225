import { cva } from 'src/third-parties/tailwind';

interface TableCellProps extends React.HTMLAttributes<HTMLTableCellElement> {
  lastColumn?: boolean;
  lastRow?: boolean;
  className?: string;
  children: React.ReactNode;
}

function TableCell({
  lastColumn = false,
  lastRow = false,
  className,
  children,
  ...rest
}: TableCellProps) {
  return (
    <td className={cell({ lastColumn, lastRow, className })} {...rest}>
      {children}
    </td>
  );
}

const cell = cva(
  'text-medium-s px-16px whitespace-nowrap bg-white py-0 text-left text-gray-700',
  {
    variants: {
      lastColumn: {
        true: '',
        false: 'border-r border-gray-200',
      },
      lastRow: {
        true: '',
        false: 'border-b border-gray-200',
      },
    },
  }
);

export default TableCell;
