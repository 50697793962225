import Button from 'components/buttons/Button';
import { IconArrowModal } from 'icons/index';
import { observer } from 'mobx-react-lite';
import { Partner } from 'models/Partner';
import { Fragment, useState } from 'react';
import { isNotNil } from 'utils/function';
import { FileMatching, FileMatchingCandidate } from '../interface';
import WarningBanner from './WarningBanner';

interface PartnerDuplicatedMatchingFormProps {
  fileMatchingCandidates: FileMatchingCandidate[];
  onSubmit: (matchings: FileMatching[]) => void;
}

function PartnerDuplicatedMatchingForm({
  fileMatchingCandidates,
  onSubmit,
}: PartnerDuplicatedMatchingFormProps) {
  const [matchingRecord, setMatchingRecord] = useState<
    Record<string, Partner | undefined>
  >({});

  const isMatchingComplete =
    Object.keys(matchingRecord).length === fileMatchingCandidates.length &&
    Object.values(matchingRecord).every(isNotNil);

  const handleMatchingRecord = (filename: string, partnerName: string) => {
    const partner = fileMatchingCandidates
      .find((candidate) => candidate.file.name === filename)
      ?.partners.find((partner) => partner.name === partnerName);

    setMatchingRecord({
      ...matchingRecord,
      [filename]: partner,
    });
  };

  const handleSubmit = () => {
    const fileMatchings = fileMatchingCandidates.map(({ file }) => ({
      file,
      partner: matchingRecord[file.name]!,
    }));

    onSubmit(fileMatchings);
  };

  return (
    <div className="px-[40px]">
      <WarningBanner>
        동일한 엑셀 양식을 가진 파일이 있어요.
        <br />각 파일에 맞는 파트너사를 선택해주세요
      </WarningBanner>

      <div className="mt-[36px] flex text-[14px]">
        <div className="flex w-full items-center bg-[#F5F7FA] px-[20px] py-[10px]">
          <p className="text-[14px] font-bold">파일명</p>
        </div>
        <div className="flex w-[60px] items-center justify-center">
          <IconArrowModal />
        </div>
        <div className="flex w-full items-center bg-[#F5F7FA] px-[20px] py-[10px]">
          <p className="text-[14px] font-bold">파트너사</p>
        </div>
      </div>

      <div className="mt-[20px]">
        {fileMatchingCandidates.map(({ file, partners }, index) => (
          <Fragment key={file.name}>
            <div key={index} className="flex">
              <div className="flex w-full items-center px-5 py-3">
                <span className="text-[14px] font-medium">
                  {file.name ?? ''}
                </span>
              </div>
              <div className="w-[40px]" />
              <div className="flex w-full">
                <div className="flex w-full rounded-[8px] border border-[#CED7E0] px-[16px] py-[10px]">
                  <select
                    value={matchingRecord[file.name]?.name ?? ''}
                    onChange={(event) =>
                      handleMatchingRecord(file.name, event.target.value)
                    }
                    className="w-full border-white leading-none"
                  >
                    <option value="" hidden disabled>
                      파트너사를 선택하세요
                    </option>
                    {partners.map((partner) => (
                      <option key={partner._id} value={partner.name}>
                        {partner.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <hr className="my-[12px] border-[#EBF0F5]" />
          </Fragment>
        ))}
      </div>

      <div className="flex justify-end">
        <Button
          name="저장"
          disabled={!isMatchingComplete}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
}

export default observer(PartnerDuplicatedMatchingForm);
