// src/hooks/useGoogleTag.js
import { useEffect } from 'react';

const useGoogleTag = (trackingId: string) => {
  useEffect(() => {
    if (!trackingId) {
      return;
    }

    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
    document.head.appendChild(script);

    const settingScript = document.createElement('script');
    settingScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${trackingId}');
    `;
    document.head.appendChild(settingScript);

    return () => {
      document.head.removeChild(script);
      document.head.removeChild(settingScript);
    };
  }, [trackingId]);
};

export default useGoogleTag;
