import { useId } from 'react';
import TextInput from 'src/design-system/components/TextInput/TextInput';

interface ProductNameInputProps {
  value?: string;
  onChange: (value: string) => void;
  isDuplicated?: boolean;
}

function ProductNameInput({
  value,
  onChange: givenOnChange,
  isDuplicated,
}: ProductNameInputProps) {
  const id = useId();

  const onChange = (value: string) => {
    givenOnChange(value.trim());
  };

  return (
    <div className="flex flex-col gap-[8px]">
      <label htmlFor={id} className="text-medium-m w-fit text-gray-700">
        상품명
      </label>
      <TextInput
        id={id}
        value={value}
        onChange={onChange}
        status={isDuplicated ? 'error' : 'default'}
        helperText={isDuplicated ? '이미 존재하는 상품명입니다.' : ''}
        saveOnBlur
      />
    </div>
  );
}

export default ProductNameInput;
