import { FloatingPortal } from '@floating-ui/react';
import { ForwardedRef, forwardRef, HTMLProps } from 'react';
import { useRefs } from 'utils/react/hooks/useRefs';
import { cva } from 'src/third-parties/tailwind';
import { useTooltipContext } from './TooltipContext';

function _TooltipContent(
  { className, ...rest }: HTMLProps<HTMLDivElement>,
  ref: ForwardedRef<HTMLDivElement>
) {
  const { open, refs, getFloatingProps, floatingStyles } = useTooltipContext();
  const innerRef = useRefs([refs.setFloating, ref]);

  if (!open) {
    return null;
  }

  return (
    <FloatingPortal>
      <div
        ref={innerRef}
        className={content({ className })}
        style={floatingStyles}
        {...getFloatingProps?.(rest)}
      />
    </FloatingPortal>
  );
}

const content = cva([
  'flex flex-row items-center justify-center gap-[8px]',
  'rounded-md px-[8px] py-[4px]',
  'z-50 bg-gray-900 text-xs text-white',
]);

export const TooltipContent = forwardRef(_TooltipContent);
