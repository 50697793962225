import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useOMSStore } from 'src/hooks/useOMSStore';
import Button from '../components/buttons/Button';
import UserExcelFormatForm from '../forms/UserExcelFormatForm';
import backendApi from '../utils/backendApi';
import { mapKeysByColumnMapping } from '../utils/headerColumnMapping';
import { isValid } from '../utils/utils';
import NewModal from './NewModal';

function UserExcelFormatRegistrationModal() {
  const navigate = useNavigate();
  const oms = useOMSStore();

  const [columnMapping, setColumnMapping] = useState({});
  const [koreanHeaderRow, setKoreanHeaderRow] = useState([]);

  const type =
    isValid(oms.user.excelHeaderKeys) && isValid(oms.user.excelColumnMapping)
      ? 'update'
      : 'register';

  const handleRegisterExcelFormat = async () => {
    const englishHeaderRow = mapKeysByColumnMapping(
      koreanHeaderRow,
      columnMapping
    );

    const res = await backendApi.updateCustomExcelSettings(
      englishHeaderRow,
      columnMapping
    );

    if (res.success) {
      if (type === 'register') {
        alert('통합 엑셀 양식이 등록되었습니다.');
      } else {
        alert('통합 엑셀 양식이 수정되었습니다.');
      }

      navigate(-1);
    } else {
      alert('문제가 발생했습니다. 다시 시도해주세요');
    }
  };

  return (
    <NewModal
      header={`통합 엑셀 양식 ${type === 'update' ? '수정하기' : '등록하기'}`}
      onClose={() => {
        const isConfirmed = window.confirm(
          `통합 엑셀 양식을 ${
            type === 'update' ? '수정' : '등록'
          }하지 않고 닫으시겠습니까?`
        );

        if (!isConfirmed) {
          return; // 사용자가 취소를 선택한 경우, 작업 중단
        }

        navigate(-1);
      }}
      backdropClick={false}
      footerButton={() => {
        return (
          <Button
            name={type === 'update' ? '수정하기' : '등록하기'}
            onClick={handleRegisterExcelFormat}
            className="h-[45px] bg-blue-500 px-[16px]"
            disabled={!isValid(columnMapping) || !isValid(koreanHeaderRow)}
          />
        );
      }}
    >
      <UserExcelFormatForm
        type={type}
        columnMapping={columnMapping}
        setColumnMapping={setColumnMapping}
        koreanHeaderRow={koreanHeaderRow}
        setKoreanHeaderRow={setKoreanHeaderRow}
      />
    </NewModal>
  );
}

export default observer(UserExcelFormatRegistrationModal);
