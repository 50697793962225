import { FloatingPortal } from '@floating-ui/react';
import React, { Fragment, useRef, useState } from 'react';
import { IconControlArrowDown } from 'src/icons';
import { cva } from 'src/third-parties/tailwind';
import { sleep } from 'src/utils/function/sleep';
import { useIsOpenOptions } from './hooks/useIsOpenOptions';
import { useSearchSelectFloating } from './hooks/useSearchSelectFloating';
import { useSearchSelectKeyDown } from './hooks/useSearchSelectKeyDown';
import { useSearchSelectLabel } from './hooks/useSearchSelectLabel';
import SearchSelectOption from './SearchSelectOption';

interface SearchSelectProps {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  options: SearchSelectOption[];
  className?: string;
}

export interface SearchSelectOption {
  value: string;
  label: string;
  className?: string;
}

function SearchSelect({
  value,
  onChange,
  options: givenOptions,
  placeholder: givenPlaceholder,
  className,
}: SearchSelectProps) {
  const options = givenOptions.sort(compareOption);
  const inputRef = useRef<HTMLInputElement>(null);
  const optionRefs = useRef<(HTMLLIElement | null)[]>([]);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const { getLabel, getSearchLabel } = useSearchSelectLabel(options);
  const [searchValue, setSearchValue] = useState(getSearchLabel(value) ?? '');
  const filteredOptions = options.filter(filterBySearchValue(searchValue));

  const { isOpenOptions, openOptions, closeOptions } = useIsOpenOptions({
    inputRef,
    onSelectedIndexChange: setSelectedIndex,
  });

  const { refs, floatingStyles } = useSearchSelectFloating({
    open: isOpenOptions,
    onOpenChange: (open) => {
      if (open === false) {
        closeOptions();
        cancelSearch();
        return;
      }

      openOptions();
    },
  });

  const handleValue = (value: string) => {
    setSearchValue(getSearchLabel(value) ?? '');
    onChange(value);
  };

  const cancelSearch = () => {
    const selectedOption = filteredOptions.at(selectedIndex);
    if (selectedOption != null) {
      handleValue(selectedOption.value);
      return;
    }

    handleValue('');
    setSearchValue(getSearchLabel('') ?? '');
  };

  const { onKeyDown } = useSearchSelectKeyDown({
    cancelSearch,
    closeOptions,
    options: filteredOptions,
    selectedIndex,
    onSelectedIndexChange: setSelectedIndex,
    onChange: handleValue,
    optionRefs,
  });

  const handleFocus = async (event: React.FocusEvent<HTMLInputElement>) => {
    event.preventDefault();

    const currentIndex = options.findIndex((option) => option.value === value);
    const index = Math.max(currentIndex, 0);

    inputRef.current?.scrollIntoView({ block: 'nearest' });
    // NOTE(@이지원): scrollIntoView가 되면서 options가 바로 닫히는 현상을 방지하기 위함
    await sleep(10);

    setSelectedIndex(index);
    setSearchValue('');
    openOptions();
    await sleep(0);
    optionRefs.current[index]?.scrollIntoView();
  };

  const handleSearchValueChange = (serachValue: string) => {
    setSearchValue(serachValue ?? '');
    setSelectedIndex(0);
  };

  const handleOptionClick = (value: string) => {
    handleValue(value);
    closeOptions();
  };

  const focusInput = () => inputRef.current?.focus({ preventScroll: true });

  const placeholder =
    !isOpenOptions && value === '' ? getLabel('') : givenPlaceholder;

  return (
    <div className={containerClass({ className })}>
      <div ref={refs.setReference} className={inputContainer()}>
        <input
          type="text"
          ref={inputRef}
          value={searchValue}
          onChange={(event) => handleSearchValueChange(event.target.value)}
          onKeyDown={onKeyDown}
          onFocus={handleFocus}
          className={input()}
          placeholder={placeholder}
        />
        <IconControlArrowDown className={icon()} onMouseDown={focusInput} />
      </div>
      <FloatingPortal>
        {isOpenOptions && (
          <ul
            ref={refs.setFloating}
            className={optionContainerClass()}
            style={floatingStyles}
          >
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option, index) => (
                <Fragment key={option.value}>
                  <SearchSelectOption
                    ref={(element) => (optionRefs.current[index] = element)}
                    active={selectedIndex === index}
                    className={option.className}
                    onHover={() => setSelectedIndex(index)}
                    onMouseDown={() => handleOptionClick(option.value)}
                  >
                    {option.label}
                  </SearchSelectOption>
                  {index !== filteredOptions.length - 1 && (
                    <hr className="h-1px border-0 bg-gray-200" />
                  )}
                </Fragment>
              ))
            ) : (
              <li className="flex h-[36px] items-center px-[16px]">
                <span className="text-medium-s select-none text-gray-400">
                  검색 결과 없음
                </span>
              </li>
            )}
          </ul>
        )}
      </FloatingPortal>
    </div>
  );
}

function filterBySearchValue(searchValue: string) {
  return (option: SearchSelectOption) =>
    option.label.toLowerCase().includes(searchValue.toLowerCase()) &&
    option.label !== '';
}

function compareOption(a: SearchSelectOption, b: SearchSelectOption) {
  if (a.value === '') {
    return -1;
  }

  if (b.value === '') {
    return 1;
  }

  return a.label.localeCompare(b.label);
}

const containerClass = cva('relative');

const inputContainer = cva([
  'h-40px rounded-8px flex w-full items-center overflow-hidden border border-gray-300',
  'focus-within:border-blue-400',
]);

const input = cva(
  'pl-16px pr-40px text-medium-s h-full flex-1 text-gray-700 outline-none placeholder:text-gray-400'
);

const icon = cva('right-16px absolute text-gray-700');

const optionContainerClass = cva([
  'max-h-204px z-dialogDropdown w-full overflow-y-auto rounded-lg border bg-white',
  'shadow-[0_4px_12px_rgb(0,0,0,0.12)]',
]);

export default SearchSelect;
