import { evaluatePostfix } from './evaluate-postfix/evaluatePostfix';
import { infixToPostfix } from './infix-to-postfix/infixToPostfix';
import { EvaluateResult } from './interface';
import { parseExpression } from './parseExpression';
import { replaceVariable } from './replaceVariable';

export function evaluate(
  expression: string,
  object: Record<string, unknown>
): EvaluateResult {
  const replacedExpression = replaceVariable(expression, object);

  const infixExpression = parseExpression(replacedExpression);
  if (infixExpression == null) {
    return {
      success: false,
      message: 'Invalid expression',
    };
  }

  const postfixExpression = infixToPostfix(infixExpression);
  const evaluatedResult = evaluatePostfix(postfixExpression);

  return evaluatedResult;
}
