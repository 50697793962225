import { DispatchedOrder , OrderStatus } from '@sweep/contract';
import { useCallback, useState } from 'react';
import { OrderStatusType } from '../interface';
import {
  isCancelRequest,
  isEtc,
  isInDelivery,
  isPaymentComplete,
  isProductPreparing,
} from '../services/filterOrderStatus';

const STATUS_PREDICATES: Record<
  OrderStatusType,
  (status: OrderStatus) => boolean
> = {
  Total: () => true,
  PaymentComplete: isPaymentComplete,
  ProductPreparing: isProductPreparing,
  InDelivery: isInDelivery,
  CancelRequest: isCancelRequest,
  Etc: isEtc,
};

export function useOrderStatusState(defaultValue: OrderStatusType = 'Total') {
  const [orderStatus, setOrderStatus] = useState<OrderStatusType>(defaultValue);

  const filterOrders = useCallback(
    (orders: DispatchedOrder[]) => {
      if (orderStatus === 'Total') {
        return orders;
      }

      return orders.filter((order) =>
        STATUS_PREDICATES[orderStatus](order.orderStatus)
      );
    },
    [orderStatus]
  );

  return [orderStatus, setOrderStatus, filterOrders] as const;
}
