import { areArraysEqual } from './utils';

export const isSameComposition = (comp1, comp2) => {
  let data1 = comp1.map((data) => ({
    productId: data.productId,
    unit: data.unit,
    quantity: data.quantity,
  }));
  let data2 = comp2.map((data) => ({
    productId: data.productId,
    unit: data.unit,
    quantity: data.quantity,
  }));

  return areArraysEqual(data1, data2);
};
