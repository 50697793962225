import { Order } from '@sweep/contract';
import { AbstractPlugin } from '../../interface';

interface ConditionValueRule {
  condition: Record<string, string>;
  value: Record<string, string>;
}

interface ConditionalValueConfig {
  rules: ConditionValueRule[];
}

export class ConditionalValuePlugin extends AbstractPlugin<ConditionalValueConfig> {
  transform = (orders: Order[]): Promise<Order[]> => {
    return Promise.resolve(orders.map(this.applyRules));
  };

  applyRules = (order: Order): Order => {
    return this.config.rules.reduce(this.applyRule, order);
  };

  applyRule = (order: Order, rule: ConditionValueRule): Order => {
    if (this.isConditionMet(rule.condition, order)) {
      return { ...order, ...rule.value };
    }

    return order;
  };

  isConditionMet = (
    condition: Record<string, string>,
    order: Order
  ): boolean => {
    return Object.entries(condition).every(
      ([key, value]) => order[key] === value
    );
  };
}
