import { Order } from '@sweep/contract';
import { AbstractPlugin } from '../../interface';

interface NumberingPluginConfig {
  target: string;
}

export class NumberingPlugin extends AbstractPlugin<NumberingPluginConfig> {
  transform = (orders: Order[]): Promise<Order[]> => {
    return Promise.resolve(
      orders.map((order, index) => this.numbering(order, index + 1))
    );
  };

  numbering = (order: Order, number: number) => {
    const target = this.config.target;
    order[target] = number;
    return order;
  };
}
