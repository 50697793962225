import { useOMSStore } from 'src/hooks/useOMSStore';

export function useDuplicatedProductName(
  productName?: string,
  initialProductName?: string
) {
  const oms = useOMSStore();
  const productNames = oms.product.productNames;

  if (productName == null) {
    return false;
  }

  if (initialProductName === productName) {
    return false;
  }

  return productNames.includes(productName);
}
