import { BaseProduct, Product } from 'models/Product';
import { tryCatch } from 'src/utils/function/tryCatch';
import api, { APIResponse } from './api';

type GetProductsResponse = {
  success: true;
  products: Product[] | null;
};

export async function getProducts() {
  const response = await api.get<GetProductsResponse>('/product');

  return response.data;
}

export type CreateProduct = BaseProduct;
type CreateProductRes = APIResponse<Product>;

export async function createProduct(product: BaseProduct) {
  const url = '/product/register';
  const response = await api.post<CreateProductRes>(url, {
    productInfo: product,
  });

  return response.data;
}

export type UpdateProduct = Partial<BaseProduct>;
type UpdateProductRes = APIResponse<Product>;

export async function updateProduct(productId: string, product: UpdateProduct) {
  const url = '/product/update';
  return tryCatch(async () => {
    const response = await api.put<UpdateProductRes>(url, {
      productId,
      productInfo: product,
    });

    return response.data;
  });
}

export type UpdateProductMany = { _id: string } & Partial<BaseProduct>;
type UpdateProductManyRes = APIResponse<Product[]>;

export function updateProductMany(products: UpdateProductMany[]) {
  const url = '/product/many';
  return tryCatch(async () => {
    const response = await api.put<UpdateProductManyRes>(url, products);

    return response.data;
  });
}

export async function deleteProduct(productId: string) {
  const url = `/product/${productId}`;
  return tryCatch(async () => {
    const response = await api.delete<APIResponse<{}>>(url);

    return response.data;
  });
}
