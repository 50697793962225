const NODE_ENV = import.meta.env.VITE_NODE_ENV;
const BACKEND_URL_DEVELOPMENT = import.meta.env.VITE_BACKEND_URL_DEVELOPMENT;
const BACKEND_URL_PRODUCTION = import.meta.env.VITE_BACKEND_URL_PRODUCTION;

const configs = {
  backendUrl: 'https://api.sweepingoms.com',
};

if (NODE_ENV === 'development') {
  configs.backendUrl = BACKEND_URL_DEVELOPMENT;
} else if (NODE_ENV === 'production') {
  configs.backendUrl = BACKEND_URL_PRODUCTION;
}

export default configs;
