import {
  columnMappingArray,
  inclusiveMappingArray,
  priorityInclusiveMappingArray,
  shippingHeaderTranslationMap,
} from './mappingArrays';
import { isValid, removeSpaces } from './utils';

export function removeEndNulls(arr: any[]) {
  let lastNonNull = arr.length - 1;

  // 뒤에서부터 검사하여 처음으로 null이 아닌 인덱스를 찾기
  for (let i = arr.length - 1; i >= 0; i--) {
    if (arr[i] !== null) {
      lastNonNull = i;
      break;
    }
  }
  //

  // lastNonNull 인덱스 기반으로 새 배열 생성, 만약 모든 요소가 null이면 빈 배열 반환
  return arr.slice(0, lastNonNull + 1);
}

export function extractEnglishShippingHeaders(koreanHeaderRow: string[]) {
  const processedKeys: string[] = [];

  const englishShippingHeaders = koreanHeaderRow.map((key) => {
    const cleanedKey = removeSpaces(key);

    for (const [mappedKey, possibleKeys] of Object.entries(
      shippingHeaderTranslationMap
    )) {
      // Check if the key is already processed to avoid duplicates
      if (processedKeys.includes(mappedKey)) {
        continue;
      }

      if (possibleKeys.includes(cleanedKey)) {
        processedKeys.push(mappedKey);
        return mappedKey;
      }
    }

    return key;
  });

  return englishShippingHeaders;
}

export function translateHeaderRowInEnglish(
  koreanHeaderRow: string[],
  settings: {
    columnTranslation: { [key: string]: string };
  } = { columnTranslation: {} }
) {
  const processedKeys: string[] = [];
  let translatedHeaderRow = koreanHeaderRow;
  const { columnTranslation = {} } = settings;

  // user의 columnTranslation과 columnOrder를 사용하여 한국어 헤더를 영어로 변환
  if (isValid(columnTranslation)) {
    translatedHeaderRow = koreanHeaderRow.map((key) => {
      const cleanedKey = removeSpaces(key);

      for (const [mappedKey, value] of Object.entries(columnTranslation)) {
        // Check if the key is already processed to avoid duplicates
        if (processedKeys.includes(mappedKey)) {
          continue;
        }

        if (removeSpaces(value) === cleanedKey) {
          processedKeys.push(mappedKey);

          return mappedKey;
        }
      }

      return key;
    });
  }

  translatedHeaderRow = translatedHeaderRow.map((key) => {
    const cleanedKey = removeSpaces(key);

    for (const [mappedKey, possibleKeys] of Object.entries(
      columnMappingArray
    )) {
      // Check if the key is already processed to avoid duplicates
      if (processedKeys.includes(mappedKey)) {
        continue;
      }

      if (possibleKeys.includes(cleanedKey)) {
        processedKeys.push(mappedKey);
        return mappedKey;
      }
    }
    return key;
  });

  translatedHeaderRow = translatedHeaderRow.map((key) => {
    const cleanedKey = removeSpaces(key);
    for (const [mappedKey, possibleKeys] of Object.entries(
      priorityInclusiveMappingArray
    )) {
      if (processedKeys.includes(mappedKey)) {
        continue;
      }

      for (const possibleKey of possibleKeys) {
        if (cleanedKey.includes(possibleKey)) {
          processedKeys.push(mappedKey);
          return mappedKey;
        }
      }
    }
    return key;
  });

  translatedHeaderRow = translatedHeaderRow.map((key) => {
    const cleanedKey = removeSpaces(key);
    for (const [mappedKey, possibleKeys] of Object.entries(
      inclusiveMappingArray
    )) {
      if (processedKeys.includes(mappedKey)) {
        continue;
      }

      for (const possibleKey of possibleKeys) {
        if (cleanedKey.includes(possibleKey)) {
          processedKeys.push(mappedKey);
          return mappedKey;
        }
      }
    }
    return key;
  });

  return translatedHeaderRow;
}

// koreanHeader to englishHeader
export function mapKeysByColumnMapping(
  header: string[],
  columnMapping: { [key: string]: string },
  settings: {
    returnNullIfNotInColumnMapping?: boolean;
  } = {}
) {
  let keys: (string | null)[] = header;
  keys = header.map((key, index) => {
    const cleanedKey = removeSpaces(key);
    for (const [key, value] of Object.entries(columnMapping)) {
      //value가 0-9의 텍스트만 이뤄져있는지 알아보는 정규표현식
      if (
        parseInt(removeSpaces(value)) === index + 1 &&
        /^\d+$/.test(removeSpaces(value))
      ) {
        return key;
      }
      if (removeSpaces(value) === cleanedKey && isValid(cleanedKey)) {
        return key;
      }
    }
    //columnMapping에 있는 값이 아닌경우는 null을 리턴
    if (settings?.returnNullIfNotInColumnMapping) {
      return null;
    }
    return key;
  });
  return keys;
}

export function createColumnMapping(
  koreanHeaders: string[],
  englishHeaders: string[]
) {
  const columnMapping: { [key: string]: string | number } = {};
  if (koreanHeaders.length !== englishHeaders.length) {
    throw new Error('The arrays must be of the same length');
  }

  for (let i = 0; i < koreanHeaders.length; i++) {
    if (
      koreanHeaders.filter((header) => header === koreanHeaders[i]).length > 1
    ) {
      columnMapping[englishHeaders[i]] = i;
    }
    columnMapping[englishHeaders[i]] = koreanHeaders[i];
  }

  return columnMapping;
}

export function mapColumnKey(originalKey: string, processedKeys: string[]) {
  // key: 한국어, value = 영어

  const cleanedKey = removeSpaces(originalKey);

  // Check if the key is already processed to avoid duplicates
  if (processedKeys.includes(cleanedKey)) {
    return originalKey;
  }

  for (const [mappedKey, possibleKeys] of Object.entries(columnMappingArray)) {
    if (possibleKeys.includes(cleanedKey)) {
      return mappedKey;
    }
  }

  for (const [mappedKey, possibleKeys] of Object.entries(
    priorityInclusiveMappingArray
  )) {
    for (const possibleKey of possibleKeys) {
      if (cleanedKey.includes(possibleKey)) {
        return mappedKey;
      }
    }
  }

  for (const [mappedKey, possibleKeys] of Object.entries(
    inclusiveMappingArray
  )) {
    for (const possibleKey of possibleKeys) {
      if (cleanedKey.includes(possibleKey)) {
        return mappedKey;
      }
    }
  }

  return cleanedKey; // Return the cleaned key if no match is found
}

export function convertArrayValuesToKeys(
  arr: string[],
  map: { [key: string]: string }
) {
  // key: 영어, value = 한국어
  return arr.map((value) => Object.keys(map).find((key) => map[key] === value));
}

export function convertArrayKeysToValues(
  arr: string[],
  map: { [key: string]: string }
) {
  // key: 영어, value = 한국어
  return arr.map((key) => map[key]);
}
