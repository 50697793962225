export const gcd = (array) => {
  let ret = array[0];
  for (let i = 1; i < array.length; i++) {
    ret = gcd2(ret, array[i]);
  }
  return ret;
};

export const gcd2 = (a, b) => {
  if (!b) {
    return a;
  }

  return gcd2(b, a % b);
};

// Path: src/utils/handleString/lcs.js
