import { If } from '@sweep/utils';
import { createRef, useRef, useState } from 'react';
import { IconArrowRightSmall, IconCheck, IconControlPlus } from 'src/icons';
import { Unit } from 'src/models/Product';
import { cva } from 'src/third-parties/tailwind';
import { isNotNil } from 'src/utils/function';
import OptionInput from './OptionInput';

export interface OptionsWithSupplier {
  options: Unit[];
  supplierId?: string | null;
  useSupplierByOption: boolean;
}

interface OptionListInputProps {
  value: OptionsWithSupplier;
  onChange: (value: OptionsWithSupplier) => void;
}

function OptionListInput({ value, onChange: onChange }: OptionListInputProps) {
  const inputRefs = useRef(
    value.options.map(() => createRef<HTMLInputElement>())
  );

  const handleUseSupplierChange = (useSupplier: boolean) => {
    if (useSupplier) {
      onChange({
        options: value.options?.map((option) => ({
          ...option,
          supplierId: value.supplierId,
        })),
        supplierId: null,
        useSupplierByOption: useSupplier,
      });
      return;
    }

    onChange({
      options: value.options?.map((option) => ({
        ...option,
        supplierId: null,
      })),
      supplierId: null,
      useSupplierByOption: useSupplier,
    });
  };

  const handleOptionChange = (index: number) => (option: Unit | null) => {
    if (option == null) {
      inputRefs.current = [
        ...inputRefs.current.slice(0, index),
        ...inputRefs.current.slice(index + 1),
      ];
    }

    const newOptions = [
      ...value.options.slice(0, index),
      option == null ? null : { ...option, unit: option.unit.trim() },
      ...value.options.slice(index + 1),
    ].filter(isNotNil);
    onChange({ ...value, options: newOptions });
  };

  const handleAddOption = () => {
    inputRefs.current.push(createRef<HTMLInputElement>());
    onChange({
      ...value,
      options: [...value.options, { unit: '' }],
    });

    setTimeout(() => {
      inputRefs.current[inputRefs.current.length - 1].current?.focus();
    });
  };

  const isDuplicated = (option: string) =>
    option !== '' && value.options.filter((o) => o.unit === option).length > 1;

  return (
    <div className="flex flex-col gap-[8px]">
      <TitleWithSetting
        useSupplier={value.useSupplierByOption}
        onUseSupplierChange={handleUseSupplierChange}
      />
      {value.options.map((option, index) => (
        <OptionInput
          ref={inputRefs.current[index]}
          key={index}
          value={option}
          onChange={handleOptionChange(index)}
          onAddOption={handleAddOption}
          isDuplicated={isDuplicated(option.unit)}
          useSupplierByOption={value.useSupplierByOption}
        />
      ))}
      <button className={addButton()} onClick={handleAddOption}>
        <IconControlPlus className="text-gray-400" />
      </button>
    </div>
  );
}

const addButton = cva(
  'flex-center flex w-full rounded-[8px] border border-gray-300 bg-gray-50 py-[9px]'
);

function TitleWithSetting({
  useSupplier,
  onUseSupplierChange,
}: {
  useSupplier: boolean;
  onUseSupplierChange: (value: boolean) => void;
}) {
  const [openSetting, setOpenSetting] = useState(useSupplier);

  const handleOpenSettingClick = () => {
    setOpenSetting(!openSetting);
  };

  const handleUseSupplierClick = () => {
    onUseSupplierChange(!useSupplier);
  };

  return (
    <div className="flex h-[24px] items-center gap-[4px]">
      <p className="tex-gray-700 text-medium-m">옵션</p>
      <button
        className="flex items-center pl-[8px] pr-[4px]"
        onClick={handleOpenSettingClick}
      >
        <p className="text-semibold-xs text-gray-400">세부설정</p>
        <IconArrowRightSmall className="text-gray-400" />
      </button>
      <If is={openSetting}>
        <button
          className={useSupplierButton({ active: useSupplier })}
          onClick={handleUseSupplierClick}
        >
          <IconCheck />
          <p className="text-semibold-xs">옵션별 공급사 지정</p>
        </button>
      </If>
    </div>
  );
}

const useSupplierButton = cva(
  'flex h-[24px] items-center gap-[4px] rounded-[6px] border px-[8px]',
  {
    variants: {
      active: {
        true: 'border-blue-500 text-blue-500',
        false: 'border-gray-400 text-gray-400',
      },
    },
  }
);

export default OptionListInput;
