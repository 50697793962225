import { InputHTMLAttributes, useId, useState } from 'react';
import { mergeProps, useFocus } from 'react-aria';
import { useUncontrollableState } from 'src/design-system/hooks/useUncontrollableState';
import { IconDeleteAll, IconSearch } from 'src/icons';
import { cva } from 'src/third-parties/tailwind';
import { isNotEmptyString } from 'src/utils/string';

type HTMLInputProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'>;

interface SearchInputProps extends HTMLInputProps {
  value?: string;
  onChange?: (value: string) => void;
}

function SearchInput({
  value: givenValue,
  onChange: givenOnChange,
  className,
  id: givenId,
  ...rest
}: SearchInputProps) {
  const randomId = useId();
  const id = givenId ?? randomId;
  const [focused, setFocused] = useState(false);
  const { focusProps } = useFocus({ onFocusChange: setFocused });

  const [value, onChange] = useUncontrollableState({
    value: givenValue,
    onChange: givenOnChange,
    defaultValue: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <div className={container({ focused, className })}>
      <label htmlFor={id}>
        <IconSearch className="text-gray-400" />
      </label>
      <input
        id={id}
        type="text"
        value={value ?? ''}
        onChange={handleChange}
        className={input()}
        autoComplete="off"
        placeholder=""
        {...mergeProps(focusProps, rest)}
      />
      {isNotEmptyString(value) && (
        <button onClick={() => onChange('')}>
          <IconDeleteAll />
        </button>
      )}
    </div>
  );
}

const container = cva(
  [
    'flex h-[40px] w-full items-center gap-[8px] rounded-[8px] pl-[12px] pr-[16px]',
    'border bg-white',
  ],
  {
    variants: {
      focused: {
        true: 'border-blue-500',
        false: 'border-gray-300',
      },
    },
  }
);

const input = cva('text-medium-s h-full flex-1 text-gray-700 outline-none');

export default SearchInput;
