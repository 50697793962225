import readXlsxFile, { readSheetNames } from 'read-excel-file';
import { ExcelData } from '../../interface';

export async function readExcelOnBrowser(
  file: File
): Promise<ExcelData | null> {
  const sheets = await readSheetNames(file);
  const sheetName = sheets.at(0);
  if (sheetName == null) {
    return null;
  }

  const rows = await (async () => {
    try {
      return await readXlsxFile(file, { sheet: sheetName });
    } catch {
      return null;
    }
  })();

  if (rows == null || rows.length === 0) {
    return null;
  }

  const strRows = rows.map((row) => {
    return row.map((cell) => (cell != null ? cell.toString() : ''));
  });

  const maxLength = Math.max(...strRows.map((row) => row.length));

  const filledRows = strRows.map((row) => {
    const diff = maxLength - row.length;
    return diff > 0 ? row.concat(Array(diff).fill('')) : row;
  });

  return filledRows;
}
