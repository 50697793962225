import { ReactNode } from 'react';
import { cva } from 'src/third-parties/tailwind';

interface LabelProps extends React.HTMLAttributes<HTMLSpanElement> {
  color: 'gray';
  children: ReactNode;
}

export function Label({ color, children, className, ...rest }: LabelProps) {
  return (
    <span className={label({ color, className })} {...rest}>
      {children}
    </span>
  );
}

const label = cva('flex h-[21px] w-fit rounded-[4px] px-[6px]', {
  variants: {
    color: {
      gray: 'text-medium-s bg-gray-100 text-gray-500',
    },
  },
});
