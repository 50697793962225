import { Order } from '@sweep/contract';

/**
 * @returns uniqueCode가 같은 경우, 앞에 있는 order를 유지
 */
export function uniqueByUniqueCode<T extends Order>(orders: T[]): T[] {
  const uniqueOrders: T[] = [];
  const uniqueCodes: Set<string> = new Set();

  orders.forEach((order) => {
    const uniqueCode = order.uniqueCode;

    if (!uniqueCodes.has(uniqueCode)) {
      uniqueCodes.add(uniqueCode);
      uniqueOrders.push(order);
    }
  });

  return uniqueOrders;
}
