import { Order } from '@sweep/contract';
import { makeObservable } from 'mobx';
import { OMSStore } from 'stores/OMSStore';
import { PluginExecutionService } from 'stores/plugin/PluginExecutionService';

export class DivideOrderStore {
  constructor(private oms: OMSStore) {
    makeObservable(this, {});
  }

  transform(orders: Order[], supplierId: string): Promise<Order[]> {
    const supplier = this.oms.supplier.getSupplier(supplierId);
    if (supplier == null || supplier.plugins == null) {
      return Promise.resolve(orders);
    }

    const plugins = this.oms.plugin.getPlugins(supplier.plugins);
    const pluginExecutionService = new PluginExecutionService(
      this.oms,
      plugins
    );

    return pluginExecutionService.execute(orders);
  }
}
