import { MatchedProduct } from 'models/CompositionMatching';
import { OMSStore } from 'stores/OMSStore';
import { lccsWithoutSpace } from 'src/utils/string';
import { CMOrder } from '../interface';

export function getInitialProducts(
  cmOrder: CMOrder,
  oms: OMSStore
): Partial<MatchedProduct>[] {
  const products = oms.product.products;

  let product = products.at(0);

  if (product == null) {
    return [{ quantity: 1 }];
  }

  let maxProductLcs = 0;
  products.forEach((p) => {
    const productLccs = lccsWithoutSpace(stringify(cmOrder), p.productName);
    if (productLccs > maxProductLcs) {
      maxProductLcs = productLccs;
      product = p;
    }

    if (productLccs === maxProductLcs) {
      const prevProductnameLength = product?.productName.length ?? 0;
      if (p.productName.length < prevProductnameLength) {
        product = p;
      }
    }
  });

  if (maxProductLcs <= 1) {
    return [{ quantity: 1 }];
  }

  let unit = product.units?.at(0);
  if (product.units == null || unit == null) {
    return [{ productId: product._id, quantity: 1 }];
  }

  let maxUnitLccs = 0;
  product.units.forEach((u) => {
    const unitLcs = lccsWithoutSpace(stringify(cmOrder), u.unit);
    if (unitLcs > maxUnitLccs) {
      maxUnitLccs = unitLcs;
      unit = u;
    }
  });

  return [{ productId: product._id, unit: unit.unit, quantity: 1 }];
}

function stringify(cmOrder: CMOrder) {
  return (cmOrder.productName ?? '') + (cmOrder.option ?? '');
}
