import { cva } from 'src/third-parties/tailwind';
import { Button, ButtonProps } from '../Button';
import { useDialogContext } from './context/DialogContext';

export interface DialogCloseProps extends ButtonProps {}

export function DialogClose({
  onClick,
  className,
  children,
  ...rest
}: DialogCloseProps) {
  const { onClose } = useDialogContext();

  return (
    <Button
      className={button({ className })}
      onClick={(event) => {
        onClose();
        onClick?.(event);
      }}
      {...rest}
    >
      {children}
    </Button>
  );
}

const button = cva('flex-1');
