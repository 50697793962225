import { Fragment, useState } from 'react';
import { Button } from 'src/design-system/components/Button';
import { Modal } from 'src/design-system/components/Modal';
import { useOMSStore } from 'src/hooks/useOMSStore';
import ShoppingMallSelect from './ShoppingMallSelect';

interface DispatchSelectModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (shoppingMalls: string[]) => void;
  selectedShoppingMalls: string[];
}

function DispatchSelectModal({
  open,
  onClose,
  onSubmit,
  selectedShoppingMalls: givenSelectedShoppingMalls,
}: DispatchSelectModalProps) {
  const oms = useOMSStore();
  const dispatchOrderStore = oms.order.dispatch;
  const shoppingMalls = dispatchOrderStore.shoppingMallNames;

  const [selectedShoppingMalls, setSelectedShoppingMalls] = useState(
    givenSelectedShoppingMalls
  );

  const handleDispatchClick = () => {
    onClose();
    onSubmit(selectedShoppingMalls);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Title>쇼핑몰 선택</Modal.Title>
      <Modal.Description className="mb-[24px]">
        주문 목록을 불러올 쇼핑몰을 선택하세요.
      </Modal.Description>
      <ShoppingMallSelect
        value={selectedShoppingMalls}
        onChange={setSelectedShoppingMalls}
      >
        <ShoppingMallSelect.MultiOption value={shoppingMalls}>
          <p className="text-semibold-s ml-[4px] text-gray-700">전체</p>
        </ShoppingMallSelect.MultiOption>
        {shoppingMalls.map((shoppingMall) => (
          <Fragment key={shoppingMall}>
            <hr />
            <ShoppingMallSelect.Option
              shoppingMall={shoppingMall}
              lastDispatchedAt={
                dispatchOrderStore.lastDispatchedAts[shoppingMall]
              }
              rejected={dispatchOrderStore.rejectedShoppingMallNames.includes(
                shoppingMall
              )}
            />
          </Fragment>
        ))}
      </ShoppingMallSelect>
      <div className="flex justify-center gap-[12px] pt-[20px]">
        <Button onClick={onClose} color="gray" className="w-[200px]">
          취소하기
        </Button>
        <Button onClick={handleDispatchClick} className="w-[200px]">
          주문 불러오기
        </Button>
      </div>
    </Modal>
  );
}

export default DispatchSelectModal;
