import { EvaluateOperatorResult, Token } from '../../interface';
import { CAN_NOT_CONVERT_TO_NUMBER, INVALID_EXPRESSION } from '../error';
import { parseNumber } from '../parseNumber';

export function evaluateOperatorRound(
  stack: Token[],
  argumentLength: number
): EvaluateOperatorResult {
  if (argumentLength === 1) {
    const operand = stack.pop();
    if (operand == null) {
      return INVALID_EXPRESSION;
    }

    const parsedOperand = parseNumber(operand);
    if (parsedOperand == null) {
      return CAN_NOT_CONVERT_TO_NUMBER(operand);
    }

    return {
      success: true,
      stack: [...stack, Math.round(parsedOperand)],
    };
  }

  if (argumentLength === 2) {
    const operand2 = stack.pop();
    const operand1 = stack.pop();

    if (operand1 == null || operand2 == null) {
      return INVALID_EXPRESSION;
    }

    const parsedOperand1 = parseNumber(operand1);
    const parsedOperand2 = parseNumber(operand2);
    if (parsedOperand1 == null) {
      return CAN_NOT_CONVERT_TO_NUMBER(operand1);
    }

    if (parsedOperand2 == null) {
      return CAN_NOT_CONVERT_TO_NUMBER(operand2);
    }

    const h = +'1'.padEnd(parsedOperand2 + 1, '0');
    return {
      success: true,
      stack: [...stack, Math.round(parsedOperand1 * h) / h],
    };
  }

  return INVALID_EXPRESSION;
}
