import { Button } from 'design-system/components/Button';
import { observer } from 'mobx-react-lite';
import { DispatchModalStore } from '../../store/DispatchModalStore';
import DispatchResultContent from './DispatchResultContent';

interface DispatchSuccessResultProps {
  store: DispatchModalStore;
  onClose: () => void;
}

function DispatchSuccessResult({ store, onClose }: DispatchSuccessResultProps) {
  return (
    <div className="flex flex-col">
      <p className="text-medium-m text-gray-700">
        주문 수집이 정상적으로 완료되었습니다.
      </p>
      <div className="h-[28px]" />
      <DispatchResultContent
        successNames={store.successedShoppingMallNames}
        failedNames={store.failedShoppingMallNames}
        initialSelectedTab="success"
      />
      <div className="h-[20px]" />
      <Button onClick={onClose} className="w-[200px] self-center">
        확인
      </Button>
    </div>
  );
}

export default observer(DispatchSuccessResult);
