import React from 'react';

interface Props {
  fill?: string;
}

const fileCode: React.FC<Props> = ({ fill = 'white' }) => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.33333 2.10703C4.13383 2.10703 3.15 3.09087 3.15 4.29036V14.707C3.15 15.9065 4.13383 16.8904 5.33333 16.8904H10.5417C10.7063 16.8904 10.8642 16.825 10.9807 16.7085C11.0971 16.5921 11.1625 16.4342 11.1625 16.2695C11.1625 16.1049 11.0971 15.947 10.9807 15.8305C10.8642 15.7141 10.7063 15.6487 10.5417 15.6487H5.33333C4.80731 15.6487 4.39167 15.2331 4.39167 14.707V4.29036C4.39167 3.76434 4.80731 3.3487 5.33333 3.3487H10.9625V5.33203C10.9625 6.24403 11.713 6.99453 12.625 6.99453H14.6083V14.707C14.6083 15.2331 14.1927 15.6487 13.6667 15.6487H12.625C12.4603 15.6487 12.3024 15.7141 12.186 15.8305C12.0696 15.947 12.0042 16.1049 12.0042 16.2695C12.0042 16.4342 12.0696 16.5921 12.186 16.7085C12.3024 16.825 12.4603 16.8904 12.625 16.8904H13.6667C14.8662 16.8904 15.85 15.9065 15.85 14.707V6.3737C15.85 5.87582 15.652 5.39778 15.2999 5.0457L12.9113 2.65715C12.5593 2.30507 12.0812 2.10703 11.5833 2.10703H5.33333ZM12.2042 5.33203V3.70575L14.2513 5.75286H12.625C12.3828 5.75286 12.2042 5.5742 12.2042 5.33203Z"
        fill={fill}
        stroke={fill}
        strokeWidth="0.2"
      />
      <path
        d="M7.04664 9.10946L5.99606 10.1857C5.90289 10.2814 5.85059 10.4108 5.85059 10.5456C5.85059 10.6805 5.90289 10.8099 5.99606 10.9056L7.04664 11.9813C7.14086 12.0745 7.26716 12.1261 7.39834 12.1252C7.52952 12.1242 7.65507 12.0707 7.74796 11.9761C7.84085 11.8816 7.89364 11.7536 7.89496 11.6198C7.89628 11.4859 7.84603 11.3568 7.75503 11.2604L7.05714 10.5456L7.75503 9.83087C7.84868 9.73499 7.90119 9.60508 7.901 9.4697C7.90081 9.33432 7.84795 9.20456 7.75403 9.10895C7.53841 8.9129 7.25725 8.89452 7.04664 9.10946ZM11.2459 9.10844C11.1994 9.15579 11.1624 9.21204 11.1371 9.27399C11.1119 9.33593 11.0988 9.40235 11.0987 9.46944C11.0986 9.53654 11.1115 9.60299 11.1366 9.66501C11.1617 9.72703 11.1985 9.78339 11.2449 9.83087L11.9428 10.5456L11.2449 11.2604C11.1539 11.3568 11.1037 11.4859 11.105 11.6198C11.1063 11.7536 11.1591 11.8816 11.252 11.9761C11.3449 12.0707 11.4704 12.1242 11.6016 12.1252C11.7328 12.1261 11.8591 12.0745 11.9533 11.9813L13.0039 10.9056C13.0971 10.8099 13.1494 10.6805 13.1494 10.5456C13.1494 10.4108 13.0971 10.2814 13.0039 10.1857L11.9533 9.10946C11.7197 8.88175 11.4465 8.93893 11.2459 9.10844ZM9.51149 8.33597L8.51594 12.5138C8.48456 12.6454 8.5057 12.7844 8.57471 12.9001C8.64371 13.0158 8.75493 13.0988 8.88389 13.1308C9.01286 13.1629 9.14901 13.1413 9.26239 13.0709C9.37577 13.0004 9.4571 12.8869 9.48848 12.7553L10.484 8.57695C10.5152 8.44548 10.494 8.30674 10.4251 8.19119C10.3562 8.07565 10.2451 7.99276 10.1163 7.96071C9.81315 7.89536 9.56001 8.12052 9.51149 8.33597Z"
        fill={fill}
        stroke={fill}
        strokeWidth="0.2"
      />
    </svg>
  );
};

export default fileCode;
