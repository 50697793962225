import { overlay } from 'overlay-kit';
import { Product } from 'src/models/Product';
import ProductUpdateFormModal from './ProductUpdateFormModal';

export function openProductUpdateFormModal(product: Product) {
  return new Promise<Product | null>((resolve) =>
    overlay.open(({ isOpen, close, unmount }) => (
      <ProductUpdateFormModal
        open={isOpen}
        product={product}
        onSubmit={(product) => {
          resolve(product);
          // TODO(@이지원): close만 하면 안 되고, unmount로 해야 함.
          // 닫을 때, 확인 Dialog 뜨는 것과 연관이 있어 보이는데, 정확한 이유는 확인 필요.
          close();
          unmount();
        }}
      />
    ))
  );
}
