import {
  autoUpdate,
  ReferenceType,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import { useLayoutEffect, useMemo } from 'react';
import { OptionalRect, UseDialogOptions, UseDialogReturn } from './interface';

const optionalRectReferenceCacheMap = new Map<string, ReferenceType>();

export function useDialog({
  open,
  onOpenChange,
  placement,
  middleware,
  reference,
  dismiss: dismissProps = {
    outsidePress: true,
    escapeKey: true,
  },
}: UseDialogOptions): UseDialogReturn {
  const { refs, floatingStyles, context } = useFloating({
    open,
    onOpenChange,
    middleware: [...(middleware ?? [])],
    placement: placement ?? 'left-start',
    strategy: 'fixed',
    whileElementsMounted: autoUpdate,
  });

  const role = useRole(context, { role: 'dialog' });
  const dismiss = useDismiss(context, {
    bubbles: {
      escapeKey: true,
      outsidePress: false,
    },
    ...dismissProps,
  });

  const { getFloatingProps } = useInteractions([role, dismiss]);

  const positionReference = useMemo((): ReferenceType | null => {
    // HTMLElement | SVGElement
    if (reference instanceof HTMLElement || reference instanceof SVGElement) {
      return {
        getBoundingClientRect() {
          return reference.getBoundingClientRect();
        },
      };
    }

    if (typeof reference === 'object') {
      // OptionalRect
      if ('x' in reference && 'y' in reference) {
        const key = JSON.stringify(reference);
        if (optionalRectReferenceCacheMap.has(key)) {
          const cached = optionalRectReferenceCacheMap.get(key);
          if (cached != null) {
            return cached;
          }
        }

        const value: ReferenceType = {
          getBoundingClientRect() {
            return toRect(reference);
          },
        };
        optionalRectReferenceCacheMap.set(key, value);
        return value;
      }

      // RefObject
      if ('current' in reference) {
        return {
          getBoundingClientRect() {
            if (reference.current == null) {
              throw new Error('Reference is not mounted');
            }
            return reference.current.getBoundingClientRect();
          },
        };
      }
    }
    return null;
  }, [reference]);

  useLayoutEffect(() => {
    if (reference == null) {
      return;
    }

    refs.setPositionReference(positionReference);

    // HTMLElement | SVGElement
    if (reference instanceof HTMLElement || reference instanceof SVGElement) {
      refs.setReference(reference);
      return;
    }

    if (typeof reference === 'object') {
      // RefObject
      if ('current' in reference) {
        refs.setReference(reference.current);
        return;
      }
    }
  }, [positionReference, reference, refs]);

  return {
    refs,
    floatingStyles,
    getFloatingProps,
    context,
  };
}

function toRect(optionalRect: OptionalRect): Omit<DOMRect, 'toJSON'> {
  const {
    x,
    y,
    width = 0,
    height = 0,
    top,
    right,
    bottom,
    left,
  } = optionalRect;

  return {
    x,
    y,
    width,
    height,
    top: top ?? y,
    right: right ?? window.innerWidth - x - width,
    bottom: bottom ?? window.innerHeight - y - height,
    left: left ?? x,
  };
}
