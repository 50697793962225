import { observer } from 'mobx-react-lite';

interface LoginInputProps {
  label: string;
  type: 'email' | 'password';
  value: string;
  onChange: (value: string) => void;
  onKeyDown: (key: string) => void;
  error?: string;
}

function LoginInput({
  label,
  type,
  value,
  onChange,
  onKeyDown,
  error,
}: LoginInputProps) {
  return (
    <div className="mb-8">
      <label className="text-md text-semibold mb-2 block text-neutral-900">
        {label}
      </label>
      <input
        value={value}
        onChange={(event) => onChange(event.target.value)}
        className={`appearance-none border ${
          error
            ? 'ring-2 ring-red-500'
            : 'focus:shadow-outline transition-all duration-200 ease-in-out hover:ring-2 hover:ring-blue-400 focus:ring-2 focus:ring-blue-500'
        } w-full rounded-lg border-gray-300 p-4 leading-tight text-neutral-900 focus:outline-none`}
        type={type}
        onKeyDown={(event) => onKeyDown(event.key)}
      />
      {error && (
        <div className="text-md text-semibold mt-1 text-red-600">{error}</div>
      )}
    </div>
  );
}

export default observer(LoginInput);
