import { forwardRef } from 'react';
import { mergeProps, useHover } from 'react-aria';
import { cva } from 'src/third-parties/tailwind';

export interface SearchSelectOptionProps {
  active: boolean;
  onMouseDown: () => void;
  onHover: () => void;
  className?: string;
  children: string;
}

function SearchSelectOption(
  {
    active,
    onMouseDown,
    onHover,
    className,
    children,
  }: SearchSelectOptionProps,
  ref: React.Ref<HTMLLIElement>
) {
  const { hoverProps } = useHover({ onHoverStart: onHover });

  return (
    <li
      ref={ref}
      className={optionClass({ active, className })}
      {...mergeProps(hoverProps, { onMouseDown })}
    >
      <span className={textClass()}>{children}</span>
    </li>
  );
}

const optionClass = cva(
  'flex h-[36px] flex-1 cursor-pointer items-center px-[16px]',
  {
    variants: {
      active: {
        true: 'bg-gray-100',
      },
    },
  }
);

const textClass = cva('text-medium-s text-ellipsis-nowrap');

export default forwardRef(SearchSelectOption);
