import { convertToAscii, findEncoding } from 'src/utils/string';
import { ExcelData } from '../../interface';

export function readHTMLFile(file: File): Promise<ExcelData | null> {
  return new Promise((resolve) => {
    const reader = new FileReader();

    reader.onload = function (event) {
      const html = event.target?.result;
      if (html == null) {
        resolve(null);
        return;
      }

      const htmlStr = html.toString();
      const encoding = findEncoding(htmlStr);
      const encodedContent = convertToAscii(htmlStr, encoding);

      const parser = new DOMParser();
      const htmlDoc = parser.parseFromString(
        encodedContent.replace('&nbsp;', ' '),
        'text/html'
      );

      const tableRows = Array.from(
        htmlDoc.getElementsByTagName('table')[0].rows
      );
      const parsed = tableRows.map((row) =>
        Array.from(row.cells).map((cell) => cell.textContent ?? '')
      );
      resolve(parsed);
    };

    reader.onerror = (error) => {
      console.error(error);
      resolve(null);
    };

    reader.readAsBinaryString(file);
  });
}
