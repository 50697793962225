import { observer } from 'mobx-react-lite';
import { Button } from 'src/design-system/components/Button';
import { useOMSStore } from 'src/hooks/useOMSStore';
import { IconDragFilesNoshadow } from 'src/icons';

interface OrderAggregationEmptyScreenProps {
  onUpload: (files: File[]) => void;
}

function OrderAggregationEmptyScreen({
  onUpload,
}: OrderAggregationEmptyScreenProps) {
  const oms = useOMSStore();
  const companyType = oms.user.companyType;

  const handleDragOver = (event: React.DragEvent) => {
    event.preventDefault();
  };

  const handleDrop = (event: React.DragEvent) => {
    event.preventDefault();
    onUpload(Array.from(event.dataTransfer.files));
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files == null) {
      return;
    }

    onUpload(Array.from(files));
  };

  return (
    <div className="py-20px px-40px gap-40px flex h-full flex-col bg-gray-100">
      <div className="h-40px flex items-center">
        <p className="text-extrabold-l font-bold text-gray-700">
          {companyType === 'supplier' ? '발주 통합하기' : '발주서 만들기'}
        </p>
      </div>
      <div
        className="rounded-8px flex-center py-40px flex flex-col bg-white shadow-[0_1px_4px_0_rgba(0,0,0,0.1)]"
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <IconDragFilesNoshadow className="mb-16px" />
        <p className="text-semibold-l mb-20px text-gray-700">
          통합하려는 판매처의 <span className="text-blue-500">주문 파일</span>
          들을 여기에 끌어다 놓으세요.
        </p>
        <p className="text-semibold-s mb-12px text-gray-400">또는</p>
        <Button className="w-200px" asChild>
          <label>
            파일 올리기
            <input
              className="hidden"
              type="file"
              accept=".xlsx, .xls, .csv"
              multiple
              onChange={handleFileChange}
            />
          </label>
        </Button>
      </div>
    </div>
  );
}

export default observer(OrderAggregationEmptyScreen);
