import { Control } from 'stores/order/NewOrderStore';
import MatchedCMForm from './components/MatchedCMForm/MatchedCMForm';
import { ProductMatching } from './interface';

export function openMatchedCMForm(
  render: (control: Control) => void,
  matchings: ProductMatching[]
): Promise<ProductMatching[]> {
  return new Promise((resolve) => {
    render(({ close }) => (
      <MatchedCMForm
        matchings={matchings}
        onSubmit={(value) => {
          resolve(value);
          close();
        }}
      />
    ));
  });
}
