import { Button } from 'design-system/components/Button';
import { observer } from 'mobx-react-lite';
import { Partner } from 'models/Partner';
import { Plugin } from 'stores/plugin/interface';
import PluginItem from './PluginItem';

interface PartnerItemProps {
  partner: Partner;
  onCreate: () => void;
  onChange: (pluginId: string, plugin: Partial<Plugin>) => void;
  onDelete: (pluginId: string) => void;
}

function PartnerItem({
  partner,
  onCreate,
  onChange,
  onDelete,
}: PartnerItemProps) {
  const isEmptyPlugin =
    partner.pluginIds == null || partner.pluginIds.length === 0;

  return (
    <div className="flex w-full flex-col gap-2 rounded-[12px] bg-gray-100 px-4 py-2">
      <div className="flex gap-3">
        <span className="text-bold-s">{partner.name}</span>
        <Button color="gray" variant="line" onClick={onCreate}>
          추가
        </Button>
      </div>
      {isEmptyPlugin && (
        <span className="text-sm text-gray-600">No plugin</span>
      )}
      {partner.pluginIds?.map((pluginId) => (
        <PluginItem
          key={pluginId}
          pluginId={pluginId}
          onChange={(plugin) => onChange(pluginId, plugin)}
          onDelete={() => onDelete(pluginId)}
        />
      ))}
    </div>
  );
}

export default observer(PartnerItem);
