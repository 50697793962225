import { IconTrashcan } from 'icons/index';
import { Partner } from 'models/Partner';

interface PartnerListProps {
  partners: Partner[];
  onClick: (id: string) => void;
  onDelete: (id: string) => void;
}

const PartnerList = ({ partners, onClick, onDelete }: PartnerListProps) => {
  return (
    <div className="3xl:grid-cols-5 grid grid-cols-4 gap-4">
      {partners.map((partner) => (
        <div
          key={partner._id}
          className="relative flex cursor-pointer flex-col items-start gap-[20px] rounded-xl border-2 border-gray-200 bg-white p-5 transition-all duration-300 ease-in-out hover:bg-gray-100"
          onClick={() => onClick(partner._id)}
        >
          <h3 className="3xl:text-2xl text-xl  font-bold">{partner.name}</h3>
          <div className="relative inline-flex flex-[0_0_auto] flex-col gap-[13px]">
            <div className="relative inline-flex flex-col items-start gap-[2px]">
              <div className="text-md relative whitespace-nowrap leading-[19px] tracking-normal text-gray-500">
                {partner.email || '메일 정보가 없어요.'}
              </div>
              <div className="relative inline-flex flex-[0_0_auto] items-start gap-[8px]">
                <div className="text-md relative whitespace-nowrap leading-[19px] tracking-normal text-gray-500">
                  {partner.phone || '연락처 정보가 없어요.'}
                </div>
              </div>
            </div>
          </div>
          <div className="mt-auto flex w-full justify-end">
            <div
              className="flex size-[30px] items-center justify-center rounded-lg bg-gray-200 hover:bg-gray-300"
              onClick={(e) => {
                e.stopPropagation();
                onDelete(partner._id);
              }}
            >
              <IconTrashcan className="text-gray-500" />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PartnerList;
