import { Order, ShoppingMall } from '@sweep/contract';
import { AbstractPlugin } from '../../interface';

export class RemoveDuplicatedQuantityPlugin extends AbstractPlugin {
  transform = (orders: Order[]): Promise<Order[]> => {
    return Promise.resolve(orders.map(this.removeDuplicatedQuantity));
  };

  removeDuplicatedQuantity = (order: Order): Order => {
    const shoppingMall = this.getShoppingMall(order);
    if (shoppingMall == null) {
      return order;
    }

    const regexMap: Record<ShoppingMall, RegExp | undefined> = {
      돌쇠네: /\(\d+개\)$/,
      eleven: /-\d+개(?: \([\-\+]\d+원\))?$/,
      ebay: /\d+개$/,
    };

    const regex = regexMap[shoppingMall];
    if (regex == null) {
      return order;
    }

    if (shoppingMall === '돌쇠네') {
      return {
        ...order,
        productName: order.productName?.replace(regex, '').trim(),
        option: order.option?.replace(regex, '').trim(),
      };
    }

    return {
      ...order,
      option: order.option?.replace(regex, '').trim(),
    };
  };

  getShoppingMall = (order: Order): ShoppingMall | null => {
    const shoppingMall = order.shoppingMall;
    if (shoppingMall == null) {
      return null;
    }

    if (shoppingMall.startsWith('돌쇠네')) {
      return '돌쇠네';
    }

    if (
      shoppingMall.startsWith('eleven') ||
      shoppingMall.startsWith('11번가')
    ) {
      return 'eleven';
    }

    if (shoppingMall.startsWith('ebay') || shoppingMall.startsWith('이베이')) {
      return 'ebay';
    }

    return null;
  };
}
