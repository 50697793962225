import { useOMSStore } from 'hooks/useOMSStore';
import { observer } from 'mobx-react-lite';
import NewModal from 'modals/NewModal';
import { useState } from 'react';
import { ExcelFile } from 'services/file/interface';
import { SwitchCase } from 'utils/react';
import PartnerDuplicatedMatchingForm from './components/PartnerDuplicatedMatchingForm';
import PartnerNotMatchingForm from './components/PartnerNotMatchingForm';
import PartnerUpdateHeaderForm from './components/PartnerUpdateHeaderForm';
import { FileMatching } from './interface';
import { PartnerMatchingStore } from './PartnerMatchingStore';

interface PartnerMatchingFormProps {
  open: boolean;
  files: ExcelFile[];
  onSubmit: (fileMatchings: FileMatching[]) => void;
}

function PartnerMatchingForm({
  open,
  files,
  onSubmit,
}: PartnerMatchingFormProps) {
  const oms = useOMSStore();
  const [store] = useState(
    () => new PartnerMatchingStore(files, onSubmit, oms)
  );

  const handleClose = () => {
    const CONFIRM_MESSAGE = [
      '주문파일의 판매처를 여기까지만 매칭하고 닫으시겠습니까?',
      '현재 페이지에서 세팅중인 것들은 저장하지않으면 반영되지 않으니 주의해주세요.',
    ].join('\n');
    const isConfirmed = window.confirm(CONFIRM_MESSAGE);
    if (!isConfirmed) {
      return;
    }

    onSubmit(store.fileMatchings);
  };

  if (!open || store.stage === 'END') {
    return null;
  }

  return (
    <NewModal
      header="엑셀 양식-판매처 매칭하기"
      onClose={handleClose}
      backdropClick={false}
      className="overflow-y-auto"
      tailwindWidth="w-11/12"
    >
      <SwitchCase
        value={store.stage}
        caseBy={{
          DUPLICAT_MACHED: (
            <PartnerDuplicatedMatchingForm
              fileMatchingCandidates={store.duplicatedMathingCandidates}
              onSubmit={store.submitDuplicatedMatchings}
            />
          ),
          NOT_MATCHED: (
            <PartnerNotMatchingForm
              files={store.notMatchingFiles}
              onSubmit={store.submitNotMatchings}
            />
          ),
          MATCHING_HEADER: (
            <PartnerUpdateHeaderForm
              fileMatchings={store.fileMatchingsToUpdateHeader}
              onSubmit={store.submitUpdateHeader}
            />
          ),
        }}
      />
    </NewModal>
  );
}

export default observer(PartnerMatchingForm);
