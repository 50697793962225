import { DoseosanganStandard, UserSetting } from '@sweep/contract';
import { observer } from 'mobx-react-lite';
import { Checkbox } from 'src/design-system/components/Checkbox';

interface UserSettingProps {
  value: UserSetting;
  onChange: (setting: UserSetting) => void;
}

function UserSettingInput({ value, onChange }: UserSettingProps) {
  const isDoseosanganEnabled = value?.doseosangan?.enabled ?? false;

  const handleDoseosanganEnabledChange = (enabled: boolean) => {
    onChange({
      ...(value ?? {}),
      doseosangan: {
        enabled,
        standard: value?.doseosangan?.standard ?? 'CJ',
      },
    });
  };

  const handleDoseosanganStandardChange = (standard: DoseosanganStandard) => {
    onChange({
      ...(value ?? {}),
      doseosangan: {
        enabled: isDoseosanganEnabled,
        standard,
      },
    });
  };

  return (
    <div>
      <p className="text-semibold-2xl">User Setting</p>
      <p>도서산간</p>
      <div className="gap-20px flex">
        <Checkbox
          checked={isDoseosanganEnabled}
          onCheckedChange={handleDoseosanganEnabledChange}
        />
        <select
          value={value.doseosangan?.standard ?? 'CJ'}
          onChange={(event) =>
            handleDoseosanganStandardChange(
              event.target.value as DoseosanganStandard
            )
          }
        >
          <option value="CJ">CJ</option>
          <option value="LOTTE">LOTTE</option>
        </select>
      </div>
    </div>
  );
}

export default observer(UserSettingInput);
