import { useCallback, useRef, useState } from 'react';
import { useDebounce } from './useDebounce';

export function useDebounceState<T>(
  initialValue: T,
  delay: number
): [T, React.Dispatch<React.SetStateAction<T>>, T] {
  const [value, setValueState] = useState<T>(initialValue);
  const [debouncedValue, setDebouncedValue] = useState<T>(initialValue);

  const latestValueRef = useRef<T>(initialValue);

  const debouncedSetDebouncedValue = useDebounce(() => {
    setDebouncedValue(latestValueRef.current);
  }, delay);

  const setValue = useCallback(
    (newValue: React.SetStateAction<T>) => {
      setValueState((prevValue) => {
        const valueToSet =
          typeof newValue === 'function'
            ? (newValue as (prevValue: T) => T)(prevValue)
            : newValue;

        latestValueRef.current = valueToSet;

        return valueToSet;
      });

      debouncedSetDebouncedValue();
    },
    [debouncedSetDebouncedValue]
  );

  return [value, setValue, debouncedValue];
}
