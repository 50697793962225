import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { Tooltip } from 'src/design-system';
import deleteTextIcon from '../icons/deleteText.png';

interface Option {
  label: string;
  value: string;
}

interface SelectorProps {
  placeholder: string;
  options: (string | Option)[];
  value: string;
  onChange?: (value: any) => void;
  onDelete?: () => void;
  checkedValues?: Set<string>;
  setCheckedValues?: React.Dispatch<React.SetStateAction<Set<string>>>;
  isDeletable?: boolean;
  isEllipsis?: boolean;
  isCheckable?: boolean;
  isScrollable?: boolean;
  className?: string;
  iconComponent?: React.ReactNode;
  emptyText?: string;
}

const OptionLabel = ({
  option,
  emptyText,
}: {
  option: string | Option | undefined;
  emptyText: string;
}) => {
  return (
    <>
      {typeof option === 'object' ? (
        <span>{option?.label}</span>
      ) : typeof option !== 'undefined' && option !== '' ? (
        <span>{option}</span>
      ) : (
        <span className="text-[#9FB1C1]">{emptyText}</span>
      )}
    </>
  );
};

const Selector: React.FC<SelectorProps> = ({
  placeholder = '',
  options,
  value,
  onChange,
  onDelete,
  checkedValues,
  setCheckedValues,
  isDeletable = false, // 셀렉터 버튼 삭제
  isEllipsis = false, // 옵션에 말줄임 표현
  isCheckable = false, // 옵션에 체크박스 사용
  isScrollable = false, // 옵션 목록에 스크롤 허용
  className = '',
  iconComponent = undefined, // 셀렉터 우측 아이콘 커스텀
  emptyText = '', // 옵션 데이터 undefined or ''인 경우 표현할 텍스트
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (!(event.target as HTMLElement).closest('.selector')) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  const handleOptionChange = (option: string | Option) => {
    const value = typeof option === 'object' ? option?.value : option;
    onChange?.(value);
    setIsOpen(false);
  };

  const getOptionName = () => {
    const option = options.find((option): any => {
      if (typeof option === 'object') {
        return option?.value === value;
      } else {
        return option === value;
      }
    });

    return typeof option === 'object' ? option?.label : value;
  };

  const isSelected = (option: any) => {
    const selectedValue = typeof option === 'object' ? option?.value : value;

    if (isCheckable) {
      const isChecked = checkedValues?.has(
        typeof option === 'object' ? option?.label : option
      );
      return isChecked;
    }

    return selectedValue === value;
  };

  const handleCheckboxChange = (option: any, isChecked: boolean) => {
    if (isChecked) {
      setCheckedValues?.(
        (prev: Set<string>) => new Set<string>([...prev, option])
      );
    } else {
      setCheckedValues?.((prev) => {
        prev?.delete('전체');
        return prev?.delete(option) ? new Set(prev) : prev;
      });
    }
  };

  const handleSelectAll = () => {
    if (!options.every((option: any) => checkedValues?.has(option))) {
      setCheckedValues?.((prev: Set<string>) => {
        const newSet = new Set<string>([...prev]);
        options.forEach((option: any) => {
          newSet.add(option);
        });
        return newSet;
      });
    } else {
      setCheckedValues?.((prev: Set<string>) => {
        const newSet = new Set<string>([...prev]);
        options.forEach((option: any) => {
          newSet.delete(option);
        });
        return newSet;
      });
    }
  };

  return (
    <div className={classNames('selector relative text-left', className)}>
      <button
        type="button"
        className="flex size-full justify-between rounded-lg border border-[#CED7E0] bg-white px-4 py-2 font-medium text-[#343D4B] focus:outline-none"
        id="menu-button"
        aria-expanded={isOpen}
        aria-haspopup="true"
        onClick={toggleDropdown}
      >
        <span className={`${!value && 'text-[#9FB1C1]'}`}>
          {getOptionName() || placeholder}
        </span>
        {iconComponent ? (
          iconComponent
        ) : (
          <div className="flex items-center justify-end py-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="8"
              viewBox="0 0 14 8"
              fill="none"
            >
              <path
                d="M1.79175 1.65625L7.00008 6.86458L12.2084 1.65625"
                stroke="#343D4B"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        )}
        {isDeletable && onDelete && (
          // Selector hover 시 제거 버튼 표현
          <div
            onClick={onDelete}
            className="z-100 absolute right-0 top-1 hidden -translate-y-1 translate-x-1/2 group-hover:block"
          >
            <img src={deleteTextIcon} alt="Close" className="bg-red size-5" />
          </div>
        )}
      </button>

      {isOpen && (
        <div
          className={classNames(
            't-0 absolute z-[100] mt-1 w-full origin-center rounded-lg border border-[#CED7E0] bg-white ring-opacity-5 focus:outline-none',
            isScrollable && 'max-h-[50vh] overflow-y-auto'
          )}
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex={-1}
          ref={ref}
        >
          <div className="py-1" role="none">
            {options.map((option, index) => (
              <div
                key={index}
                onClick={() => handleOptionChange(option)}
                className={classNames(
                  'flex cursor-pointer flex-row gap-[15px] px-4 py-2 text-[#343D4B] hover:bg-[#F2F6FA]',
                  isSelected(option) && 'bg-[#F2F6FA]'
                )}
              >
                {isCheckable &&
                  (option === '전체' ||
                  (typeof option === 'object' && option?.label === '전체') ? (
                    <input
                      type="checkbox"
                      className="scale-150 cursor-pointer"
                      checked={options.every((item) =>
                        checkedValues?.has(
                          typeof option === 'object' ? option?.label : option
                        )
                      )}
                      onClick={(e) => e.stopPropagation()}
                      onChange={handleSelectAll}
                    />
                  ) : (
                    <input
                      type="checkbox"
                      className="scale-150 cursor-pointer"
                      checked={checkedValues?.has(
                        typeof option === 'object' ? option?.label : option
                      )}
                      onClick={(e) => e.stopPropagation()}
                      onChange={(e) => {
                        handleCheckboxChange?.(option as any, e.target.checked);
                      }}
                    />
                  ))}
                {isEllipsis ? (
                  <Tooltip>
                    <Tooltip.Content>
                      {typeof option === 'object' ? option?.label : option}
                    </Tooltip.Content>
                    <Tooltip.Trigger>
                      <div className="overflow-hidden truncate whitespace-nowrap">
                        <OptionLabel option={option} emptyText={emptyText} />
                      </div>
                    </Tooltip.Trigger>
                  </Tooltip>
                ) : (
                  <div>
                    <OptionLabel option={option} emptyText={emptyText} />
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Selector;
