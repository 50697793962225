import { DispatchedOrder } from '@sweep/contract';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { Button } from 'src/design-system/components/Button';
import { ColumnDef } from 'src/design-system/components/Table';
import { SelectableTable } from 'src/design-system/components/Table/SelectableTable';
import { useOMSStore } from 'src/hooks/useOMSStore';
import useShippingUpload from 'src/hooks/useShippingUpload';
import { formatPhoneNumber, isEmptyString } from 'src/utils/string';
import { OrderStatusType } from '../interface';
import { openShippingUploadFormModal } from './ShippingUploadFormModal/openShippingUploadFormModal';

interface OrderDispatchTableProps {
  orders: DispatchedOrder[];
  selectedOrders: DispatchedOrder[];
  onSelectedOrdersChange: (value: DispatchedOrder[]) => void;
  orderStatusType: OrderStatusType;
  className?: string;
}

function OrderDispatchTable({
  orders,
  selectedOrders,
  onSelectedOrdersChange,
  orderStatusType,
  className,
}: OrderDispatchTableProps) {
  const [showMore, setShowMore] = useState(false);

  return (
    <>
      <SelectableTable
        items={orders}
        columns={getColumns(orderStatusType)}
        range={showMore ? undefined : [0, 100]}
        selectedItems={selectedOrders}
        onSelect={onSelectedOrdersChange}
        getKey={(order) => order.uniqueCode}
        className={className}
      />
      {orders.length > 100 && (
        <Button
          color="gray"
          onClick={() => setShowMore(!showMore)}
          className="w-fit"
        >
          {showMore ? '100개 주문만 보기' : `${orders.length}개 주문 전부 보기`}
        </Button>
      )}
    </>
  );
}

const getColumns = (
  orderStatusType: OrderStatusType
): ColumnDef<DispatchedOrder>[] => [
  {
    header: '주문시간',
    accessorFn: (order) => order.orderDate,
  },
  {
    header: '결제시간',
    accessorFn: (order) => order.paymentDate,
  },
  {
    header: '주문상태',
    accessorFn: (order) => order.orderStatus,
  },
  {
    header: '상품명',
    accessorFn: (order) => order.productName,
  },
  {
    header: '상품옵션',
    accessorFn: (order) => order.option,
  },
  {
    header: '상품관리코드',
    accessorFn: (order) => order.optionCode,
  },
  {
    header: '수량',
    accessorFn: (order) => order.quantity?.toString(),
  },
  {
    header: '수령인',
    accessorFn: (order) => order.name,
  },
  {
    header: '휴대폰번호',
    accessorFn: (order) => formatPhoneNumber(order.contactNumber ?? ''),
  },
  {
    header: '전화번호',
    accessorFn: (order) => formatPhoneNumber(order.telephoneNumber ?? ''),
  },
  {
    header: '주소',
    accessorFn: (order) => order.address,
  },
  {
    header: '우편번호',
    accessorFn: (order) => order.postCode,
  },
  {
    header: '배송요청사항',
    accessorFn: (order) => order.deliveryMessage,
  },
  {
    header: '주문번호',
    accessorFn: (order) => order.orderNumber,
  },
  {
    header: '상세주문번호',
    accessorFn: (order) => order.childOrderNumber,
  },
  {
    header: '정산예정금액',
    accessorFn: (order) => order.price?.toString(),
  },
  {
    header: '배송비',
    accessorFn: (order) => order.shippingPrice?.toString(),
  },
  {
    header: '배송업체',
    accessorFn: (order) => order.shippingCompany,
  },
  {
    header: '상품코드(쇼핑몰)',
    accessorFn: (order) => order.productCode,
  },
  {
    header: '쇼핑몰',
    accessorFn: (order) => order.marketName,
  },
  {
    header: '주문자id',
    accessorFn: (order) => order.buyerId,
  },
  {
    header: '운송장번호',
    accessorFn: (order) => order.shippingNumber,
    cell: ({ row }) => {
      const shippingNumber = row.shippingNumber;
      const isEmptyShippingNumber = isEmptyString(shippingNumber);
      const isAbleToUpload =
        orderStatusType === 'ProductPreparing' ||
        orderStatusType === 'InDelivery';

      if (isEmptyShippingNumber && isAbleToUpload) {
        return (
          <SelectableTable.Cell className="px-11px">
            <UploadShippingInvoiceButton order={row} />
          </SelectableTable.Cell>
        );
      }
    },
  },
  {
    header: '스윕고유번호',
    accessorFn: (order) => order.uniqueCode,
  },
];

function UploadShippingInvoiceButton({ order }: { order: DispatchedOrder }) {
  const { mallUploadShippingInfoManually } = useShippingUpload();
  const oms = useOMSStore();

  const handleClick = async () => {
    const shippingInvoice = await openShippingUploadFormModal();
    if (shippingInvoice == null) {
      return;
    }

    oms.loading.batch(() =>
      mallUploadShippingInfoManually([
        {
          ...order,
          ...shippingInvoice,
        },
      ])
    );
  };

  return (
    <Button color="gray" size="xsmall" onClick={handleClick}>
      운송장 등록
    </Button>
  );
}

export default observer(OrderDispatchTable);
