import { z } from 'zod';

const ZUserDeploy = z.object({
  productManagement: z.boolean().optional(),
});

export type UserDeploy = z.infer<typeof ZUserDeploy>;

const ZUserCompanyType = z.enum(['retailer', 'supplier']);

export type UserCompanyType = z.infer<typeof ZUserCompanyType>;

export const ZUser = z.object({
  _id: z.string(),
  email: z.string(),
  name: z.string(),
  companyType: ZUserCompanyType,

  dispatchPlugins: z.array(z.string()).optional(),
  mergeDispatchPlugin: z.array(z.string()).optional(),
  plugins: z.array(z.string()).optional(),

  trialEndsAt: z.string().optional(),

  deploy: ZUserDeploy.optional(),
  admin: z.boolean().optional(),
});

export type User = z.infer<typeof ZUser>;
