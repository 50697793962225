import Lottie from 'react-lottie';
import { LottieLoading } from 'src/assets/lotties';

export function LoadingLottie() {
  return (
    <Lottie
      options={{
        animationData: LottieLoading,
        loop: true,
        autoplay: true,
        rendererSettings: {
          className: 'cursor-default',
        },
      }}
      width={48}
      height={48}
      style={{ margin: 0, flexShrink: 0 }}
    />
  );
}
