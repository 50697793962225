import { NormalizedOrder, Order } from '@sweep/contract';
import { If } from '@sweep/utils';
import { useOMSStore } from 'hooks/useOMSStore';
import { observer } from 'mobx-react-lite';
import { OrderXX } from 'models/OrderXX';
import { useEffect, useMemo, useState } from 'react';
import { Button } from 'src/design-system/components/Button';
import SearchInput from 'src/design-system/components/SearchInput/SearchInput';
import Select from 'src/design-system/components/Select/Select';
import useCreateExcel from 'src/hooks/useCreateExcel';
import { useSearchState } from 'src/hooks/useSearchState';
import { IconCheckSmall, IconControlDownload, IconTrashcan } from 'src/icons';
import { isDoseosangan } from 'src/services/doseosangan/isDoseosangan';
import { toast } from 'src/third-parties/toast';
import { amplitude, SwitchCase } from 'src/utils';
import useFileHandling from '../../hooks/useFileHandling';
import { FieldFilterList, FieldFilters } from './components/FieldFilterList';
import OrderAggregationTable from './components/OrderAggregationTable';
import UploadedFileList from './components/UploadedFileList';
import OrderAggregationEmptyScreen from './OrderAggregationEmptyScreen';

function OrderAggregationScreen() {
  const oms = useOMSStore();
  const companyType = oms.user.companyType;
  const orders = oms.order.normalizedOrders;
  const headers = oms.user.excelHeaders;
  const headerKeys = oms.user.excelHeaderKeys;
  const columnMapping = oms.user.excelColumnMapping;
  const uploadedFilenames = oms.order.uploadedFileNames;
  const doseosanganSetting = oms.user.setting?.doseosangan;

  const {
    exportToExcel,
    separateOrdersIntoOrderFiles,
    removeFile,
    customizeOrders,
  } = useFileHandling();

  const { createOrderExcel } = useCreateExcel();

  const [filedFilters, setFieldFilters] = useState<FieldFilters>({});
  const [search, setSearch, filterSearch] = useSearchState<NormalizedOrder>(
    '',
    headerKeys
  );
  const [doseosangan, setDoseosangan] = useState('');

  const fieldFilteredOrders = useMemo(() => {
    const filterKeys = Object.keys(filedFilters);
    if (filterKeys.length === 0) {
      return orders;
    }

    return orders.filter((order) =>
      filterKeys.every((key) => {
        const value = order[key]?.toString();
        return value != null && filedFilters[key].has(value);
      })
    );
  }, [filedFilters, orders]);
  const searchFilteredOrders = useMemo(
    () => filterSearch(fieldFilteredOrders),
    [filterSearch, fieldFilteredOrders]
  );
  const filteredOrders = useMemo(() => {
    if (doseosanganSetting?.enabled !== true) {
      return searchFilteredOrders;
    }

    if (doseosangan === '') {
      return searchFilteredOrders;
    }
    return searchFilteredOrders.filter(
      (order) =>
        order.postCode != null &&
        isDoseosangan(order.postCode, doseosanganSetting.standard)
    );
  }, [doseosangan, doseosanganSetting, searchFilteredOrders]);

  const [selectedOrders, setSelectedOrders] = useState<NormalizedOrder[]>([]);

  const customize = async (orders: Order[]) => {
    // NOTE(@이지원): batch가 아니라 start, end를 사용 https://github.com/sweepoms/sweep-mono/pull/748
    oms.loading.start();
    const normalizedOrders = await customizeOrders(
      orders as unknown as OrderXX[]
    );

    // TODO(@이지원): customize가 현재는 기존 combinedOrder를 포함해서 전체를 반환함.
    // 나중에 추가한 order에 대해서만 반환하면 그때가서 pushNormalizeOrders로 변경
    oms.order.setMergedOrders(normalizedOrders as unknown as Order[]);
    oms.loading.end();
  };

  // TODO(@이지원): customize가 없어지면 OrderStore 내부에 메소드로 이동될 로직
  useEffect(() => {
    const pendingOrders = oms.order.pendingOrders;
    if (pendingOrders.length > 0) {
      customize(pendingOrders);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oms.order.pendingOrders]);

  const handleDeleteFile = async (index: number) => {
    const filename = uploadedFilenames[index];
    oms.order.removeOrderByFilename(filename);
    removeFile(filename);
    setSelectedOrders([]);
  };

  const handleSplitOrderClick = async () => {
    amplitude.track('Download Purchase-order');
    await separateOrdersIntoOrderFiles();
  };

  const handleUpload = async (files: File[]) => {
    if (files.length > 0) {
      amplitude.track('Upload Order', { 'File Count': files.length });
    }
    const orders = await oms.order.merge(Array.from(files));

    if (orders == null) {
      return;
    }

    await customize(orders);
  };

  const handleDownloadExcelClick = async () => {
    amplitude.track('Download Merged-excel');
    exportToExcel();
  };

  const handleDeleteOrders = async () => {
    await oms.loading.batch(() => oms.order.removeOrders(selectedOrders));
    setSelectedOrders([]);
    toast.success('주문이 삭제되었습니다.');
  };

  const handleDownloadSelectedOrders = () => {
    createOrderExcel(selectedOrders, 'download', headers, columnMapping);
  };

  if (oms.order.component) {
    return oms.order.component;
  }

  if (uploadedFilenames.length === 0) {
    return <OrderAggregationEmptyScreen onUpload={handleUpload} />;
  }

  return (
    <div className="px-40px py-20px gap-32px flex h-full flex-col bg-gray-100">
      <div className="flex items-center justify-between">
        <h1 className="text-extrabold-l font-bold text-gray-700">
          {companyType === 'supplier' ? '발주 통합하기' : '발주서 만들기'}
        </h1>
        <div className="gap-10px flex">
          <SwitchCase
            value={companyType}
            caseBy={{
              supplier: (
                <Button
                  rightAccessory={<IconControlDownload />}
                  onClick={handleDownloadExcelClick}
                >
                  통합 엑셀
                </Button>
              ),
              retailer: (
                <>
                  <Button
                    rightAccessory={<IconControlDownload />}
                    onClick={handleSplitOrderClick}
                  >
                    발주서
                  </Button>
                  <Button
                    rightAccessory={<IconControlDownload />}
                    onClick={handleDownloadExcelClick}
                  >
                    통합 엑셀
                  </Button>
                </>
              ),
            }}
          />
        </div>
      </div>
      <div className="gap-20px flex flex-col">
        <UploadedFileList
          filenames={uploadedFilenames}
          onUpload={handleUpload}
          onDelete={handleDeleteFile}
        />
        <div className="gap-16px p-20px rounded-8px flex flex-col bg-white shadow-[0_1px_4px_0_rgba(0,0,0,0.1)]">
          <div className="gap-20px flex items-center">
            <p className="text-medium-s text-gray-500">필터</p>
            <SearchInput
              value={search}
              onChange={setSearch}
              placeholder="검색어를 입력하세요."
              className="w-400px"
            />
            <div className="w-1px h-full bg-gray-200" />
            <FieldFilterList
              value={filedFilters}
              onChange={setFieldFilters}
              orders={orders}
              headerKeys={headerKeys}
              columnMapping={columnMapping}
            />
            <If is={doseosanganSetting?.enabled === true}>
              <Select
                value={doseosangan}
                onChange={setDoseosangan}
                placeholder="도서산간 지역"
                className="w-160px shrink-0"
              >
                <Select.Option value="" className="text-gray-400">
                  선택 안 함
                </Select.Option>
                <Select.Option value="true">제주 및 도서산간</Select.Option>
              </Select>
            </If>
          </div>
          <hr />
          <div>
            <p className="text-medium-s text-gray-500">
              목록 (
              <span className="text-semibold-s text-blue-500">
                {filteredOrders.length}
              </span>
              건)
            </p>
            <div className="h-40px flex items-end justify-between">
              <div className="gap-6px flex items-center">
                <IconCheckSmall className="text-gray-400" />
                <p className="text-semibold-s text-blue-500">
                  {selectedOrders.length}
                </p>
                <p className="text-medium-s text-gray-500">선택됨</p>
              </div>
              <If is={selectedOrders.length > 0}>
                <div className="gap-10px flex">
                  <Button
                    color="lightGray"
                    variant="line"
                    leftAccessory={<IconTrashcan />}
                    onClick={handleDeleteOrders}
                  >
                    삭제
                  </Button>
                  <Button
                    color="gray"
                    rightAccessory={<IconControlDownload />}
                    onClick={handleDownloadSelectedOrders}
                  >
                    엑셀 파일
                  </Button>
                </div>
              </If>
            </div>
          </div>
          <OrderAggregationTable
            orders={filteredOrders}
            selectedOrders={selectedOrders}
            onSelect={setSelectedOrders}
          />
        </div>
      </div>
    </div>
  );
}

export default observer(OrderAggregationScreen);
