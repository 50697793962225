import { useState } from 'react';
import { useOMSStore } from 'src/hooks/useOMSStore';
import Button from '../components/buttons/Button';
import FileDragAndDrop from '../components/FileDragAndDrop';
import deleteTextGray from '../icons/deleteTextGray.png';
import fileIcon from '../icons/file.png';
import tagIcon from '../icons/tag.png';
import NewModal from '../modals/NewModal';
import {
  createColumnMapping,
  removeEndNulls,
  translateHeaderRowInEnglish,
} from '../utils/headerColumnMapping';
import { commonColumns, headerTranslationMap } from '../utils/mappingArrays';
import { readExcelFile } from '../utils/readExcelFile';
import { isValid } from '../utils/utils';

const keyFields = [
  'productName',
  'option',
  'quantity',
  'name',
  'contactNumber',
  'address',
];

const UserExcelFormatForm = ({
  type,
  columnMapping,
  setColumnMapping,
  koreanHeaderRow,
  setKoreanHeaderRow,
}) => {
  const oms = useOMSStore();
  const [isColumnMappingModalOpen, setIsColumnMappingModalOpen] =
    useState(false);
  const [exampleContents, setExampleContents] = useState([]);

  const [file, setFile] = useState(null);

  const handleExcelFileUpload = async (e) => {
    try {
      let { rows, _ } = await readExcelFile(e.target.files[0], {
        isHeaderIndexNeeded: true,
      });

      if (isValid(rows)) {
        let koreanHeaderRow = removeEndNulls(rows[0]);

        let settings = {
          columnTranslation: oms.user.excelColumnMapping || {},
        };

        let mappedHeaderRow = translateHeaderRowInEnglish(
          koreanHeaderRow,
          settings
        );

        let columnMapping = createColumnMapping(
          koreanHeaderRow,
          mappedHeaderRow
        );

        setFile(e.target.files[0]);
        setKoreanHeaderRow(koreanHeaderRow);
        setColumnMapping(columnMapping);
        setExampleContents(rows.slice(1, 7));
      }
    } catch (error) {
      console.error('Error reading excel file:', error);
    }
    e.target.value = '';
  };

  const handleMappingChange = (key, value) => {
    // key 영어, value 한글
    const existingKey = Object.keys(columnMapping).find(
      (key) => columnMapping[key] === value
    );

    let updatedMapping = { ...columnMapping };
    if (existingKey) {
      updatedMapping = { ...columnMapping, [existingKey]: undefined };
    }

    updatedMapping[key] = value;

    setColumnMapping(updatedMapping);
  };

  const findKeyByValue = (object, value) => {
    return Object.keys(object).find((key) => object[key] === value);
  };

  const MappingRow = ({
    keyField,
    koreanHeaderRow,
    selectedHeader,
    onChange,
    className = '',
  }) => {
    return (
      <div
        className={`mb-[20px] flex items-center justify-between ${className}`}
      >
        <label className="w-1/6 whitespace-normal break-words text-[16px]">
          {headerTranslationMap[keyField]}
        </label>

        <div className="w-4/5">
          <select
            value={selectedHeader}
            onChange={(e) => onChange(keyField, e.target.value)}
            className={`h-[45px] w-full rounded-lg border border-gray-300 px-[16px] ${
              selectedHeader === '' ? 'text-gray-500' : 'text-gray-900'
            }`}
          >
            <option value="">선택되지 않음</option>

            {koreanHeaderRow.map((column, index) => (
              <option key={index} value={column}>
                {column}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  };

  const DetailHeaderMatchingComponent = (
    <form className="mb-8 flex w-full flex-row px-[40px]">
      <div className="flex gap-[24px] overflow-x-scroll whitespace-nowrap">
        {koreanHeaderRow?.map((header, index) => (
          <div key={index} className="flex flex-col whitespace-nowrap">
            <div
              className={`mb-[32px] flex w-[200px] items-center justify-between`}
            >
              <div className="w-full">
                <select
                  value={findKeyByValue(columnMapping, header) || ''}
                  onChange={(e) => handleMappingChange(e.target.value, header)}
                  className={`h-[45px] w-full rounded-lg border border-gray-300 px-[16px]`}
                >
                  <option value="">선택되지 않음</option>

                  {commonColumns.map((header, index) => (
                    <option key={index} value={header}>
                      {headerTranslationMap[header]}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {/* 엑셀 컴포넌트 */}
            <div
              className={`mb-[8px] w-[200px] truncate whitespace-nowrap bg-[#F5F7FA] px-[16px] py-[3px] text-left text-[14px]`}
            >
              {header}
            </div>
            <div>
              {exampleContents?.map((row, rowIndex) => (
                <div
                  key={rowIndex}
                  className={`relative mb-[8px] w-[200px] truncate whitespace-nowrap px-[12px] text-left text-[14px] leading-[21px] text-[#6B7683]`}
                >
                  <span className="absolute">•</span>
                  <span className="pl-[16px]">{row?.[index] || ' '}</span>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </form>
  );

  return (
    <form
      // onSubmit={}
      className="mx-[40px]"
    >
      <div className="">
        {!file && (
          <div>
            <FileDragAndDrop
              onUpload={handleExcelFileUpload}
              text={
                <div className="flex">
                  사용하고 계신&nbsp;
                  <p className="font-bold text-[#057FF0]">통합 엑셀 양식</p>을
                  여기에 끌어다 놓으세요.
                </div>
              }
            />
          </div>
        )}

        {file && (
          <div>
            <label
              htmlFor="excelFile"
              className="block text-[16px] font-medium text-gray-700"
            >
              엑셀 양식 올리기
            </label>
            <div className="relative flex items-center justify-between">
              <div className="flex items-center">
                <div className="">
                  <img src={fileIcon} alt="File" className="size-5" />
                </div>
                <div className="ml-[2px]">
                  <span className="truncate text-sm font-medium text-gray-500">
                    {file.name}
                  </span>
                </div>
                <div
                  className="ml-[8px] flex cursor-pointer items-center"
                  onClick={() => {
                    setFile(null);
                    setKoreanHeaderRow([]);
                    setColumnMapping({});
                  }}
                >
                  <img src={deleteTextGray} alt="Close" className="size-4" />
                </div>
              </div>

              <Button
                name="다른 파일 업로드하기"
                color="black"
                type="input"
                onClick={(e) => {
                  handleExcelFileUpload(e);
                }}
              />
            </div>
            <hr className="mt-[40px]" />
          </div>
        )}
      </div>

      {isValid(koreanHeaderRow) && (
        <div>
          <div className="mt-[40px] flex items-center">
            <div className="flex w-full items-center justify-between">
              <div className="flex">
                <label
                  htmlFor="excelFile"
                  className="block text-[24px] font-bold text-black"
                >
                  주요 내용 매칭하기
                </label>

                <div className="w-[16px]" />
                <div
                  className="flex h-[34px] items-center rounded-lg border-2 border-blue-500 pl-[8px] pr-[12px]"
                  onClick={() => setIsColumnMappingModalOpen(true)}
                >
                  <div className="mr-[4px]">
                    <img src={tagIcon} alt="File" className="size-5" />
                  </div>
                  <div className="font-semibold text-blue-500">
                    매칭 세부설정
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-[40px] grid grid-cols-2 gap-x-[52px]">
            {keyFields.map((englishField, index) => (
              <MappingRow
                key={index}
                keyField={englishField}
                koreanHeaderRow={koreanHeaderRow}
                selectedHeader={columnMapping[englishField]}
                onChange={handleMappingChange}
              />
            ))}
          </div>
        </div>
      )}

      {isColumnMappingModalOpen && (
        <NewModal
          header="매칭 세부설정"
          subtitle="업로드한 파일의 열들에 해당하는 통합 파일의 제목을 선택해주세요."
          onClose={() => setIsColumnMappingModalOpen(false)}
          tailwindWidth="w-3/4"
          footerButton={() => {
            return (
              <Button
                name="세부설정 저장하기"
                onClick={() => setIsColumnMappingModalOpen(false)}
                className="h-[45px] bg-blue-500 px-[16px]"
              />
            );
          }}
        >
          {DetailHeaderMatchingComponent}
        </NewModal>
      )}
    </form>
  );
};

export default UserExcelFormatForm;
