import classNames from 'classnames';

const OMSContainer = ({ children, className = '', bgColor = 'bg-white' }) => {
  return (
    <div
      className={classNames(
        'flex flex-col py-[20px] px-[40px] h-full overflow-x-hidden',
        className,
        bgColor
      )}
    >
      {children}
    </div>
  );
};

export default OMSContainer;
