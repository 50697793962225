import { ReactNode, useEffect, useMemo } from 'react';
import { cva } from 'src/third-parties/tailwind';
import { SelectContextOption, useSelectContext } from './context/SelectContext';

interface SelectOptionProps {
  value: string;
  className?: string;
  children?: ReactNode;
}

function SelectOption({ value, className, children }: SelectOptionProps) {
  const { onChange, onOptionMount, onOptionUnmount, options } =
    useSelectContext();

  const option: SelectContextOption = useMemo(
    () => ({
      value,
      label: typeof children === 'string' ? children : value,
    }),
    [value, children]
  );

  useEffect(() => {
    onOptionMount(option);

    return () => {
      onOptionUnmount(option);
    };
  }, [onOptionMount, onOptionUnmount, option]);

  const isNotLastOption = options.at(-1)?.value !== value;

  const handleClick = () => {
    onChange(value);
  };

  return (
    <>
      <div onClick={handleClick} className={optionClass({ className })}>
        {children}
      </div>
      {isNotLastOption && <hr />}
    </>
  );
}

const optionClass = cva([
  'h-44px px-16px flex items-center bg-white hover:bg-gray-100',
  'text-medium-s text-gray-700',
  'cursor-pointer',
]);

export default SelectOption;
