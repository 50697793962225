import { useOMSStore } from 'hooks/useOMSStore';
import { observer } from 'mobx-react-lite';
import { Plugin } from 'stores/plugin/interface';
import SuppliertItem from '../components/SupplierItem';

function PartnerPluginSection() {
  const oms = useOMSStore();
  const suppliers = oms.supplier.suppliers;

  const handleCreatePlugin = (supplierId: string) => async () => {
    await oms.supplier.createPlugin(supplierId, {
      pluginId: '',
    });
  };

  const handleDeletePlugin =
    (supplierId: string) => async (pluginId: string) => {
      await oms.supplier.deletePlugin(supplierId, pluginId);
    };

  const handleChange = (pluginId: string, plugin: Partial<Plugin>) => {
    oms.plugin.update(pluginId, plugin);
  };

  return (
    <div className="flex flex-col gap-2">
      <p className="text-3xl">Supplier</p>
      <div className="flex w-full flex-col gap-4">
        {suppliers.map((supplier) => (
          <SuppliertItem
            key={supplier._id}
            supplier={supplier}
            onCreate={handleCreatePlugin(supplier._id)}
            onChange={handleChange}
            onDelete={handleDeletePlugin(supplier._id)}
          />
        ))}
      </div>
    </div>
  );
}

export default observer(PartnerPluginSection);
