import { Token } from '../interface';

export function getArgumentLength(remainInfix: Token[]) {
  let count = 0;
  let parenthesis = -1;

  while (remainInfix.length > 0) {
    const token = remainInfix.shift();

    if (token === '(') {
      parenthesis += 1;
    }

    if (token === ')') {
      if (parenthesis === 0) {
        return count + 1;
      }

      parenthesis -= 1;
    }

    if (token === ',' && parenthesis === 0) {
      count += 1;
    }
  }

  return count;
}
