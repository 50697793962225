import { EvaluateOperatorResult, Token } from '../../interface';
import { CAN_NOT_CONVERT_TO_BOOLEAN, INVALID_EXPRESSION } from '../error';
import { parseBoolean } from '../parseBoolean';

export function evaluateOperatorIf(
  stack: Token[],
  argumentLength: number
): EvaluateOperatorResult {
  if (argumentLength === 2) {
    const [condition, trueValue] = stack.splice(-2);
    const parsedCondition = parseBoolean(condition);
    if (parsedCondition === null) {
      return CAN_NOT_CONVERT_TO_BOOLEAN(condition);
    }

    return {
      success: true,
      stack: [...stack, condition ? trueValue : false],
    };
  }

  if (argumentLength === 3) {
    const [condition, trueValue, falseValue] = stack.splice(-3);
    const parsedCondition = parseBoolean(condition);
    if (parsedCondition === null) {
      return CAN_NOT_CONVERT_TO_BOOLEAN(condition);
    }

    return {
      success: true,
      stack: [...stack, condition ? trueValue : falseValue],
    };
  }

  return INVALID_EXPRESSION;
}
