interface RegistrationInputProps {
  label: string;
  value: string;
  type: 'text' | 'email';
  error: string;
  onChange: (value: string) => void;
}

function RegistrationInput({
  label,
  value,
  type,
  error,
  onChange,
}: RegistrationInputProps) {
  return (
    <div className="space-y-8px flex flex-col">
      <label className="text-[16px]">
        <span>{label}</span>
        <span className="text-red-500"> *</span>
      </label>
      <input
        className={`appearance-none border ${
          error
            ? 'ring-2 ring-red-500'
            : 'focus:shadow-outline transition-all duration-200 ease-in-out hover:ring-2 hover:ring-blue-400 focus:ring-2 focus:ring-blue-500'
        } w-full rounded-lg border-gray-300 px-4 py-3 leading-tight text-neutral-900 focus:outline-none`}
        value={value}
        type={type}
        onChange={(event) => onChange(event.target.value)}
      />
      {error && (
        <div className="text-md text-semibold mt-1 text-red-600">{error}</div>
      )}
    </div>
  );
}

export default RegistrationInput;
