import { NormalizedOrder, Order } from '@sweep/contract';
import { tryCatch } from 'src/utils/function/tryCatch';
import api from '../api';

export async function getMergedOrders() {
  const url = '/order';

  const response = await tryCatch(() => api.get<Order[]>(url));

  return response?.data;
}

interface OrderDataResponse {
  message: string;
}

export async function upsertOrders(orders: Order[]) {
  const url = '/order/upsert-orderData';
  const response = await api.post<OrderDataResponse>(url, {
    orderData: orders,
  });

  return response.data;
}

interface GetNormalizedOrdersResponse {
  data: NormalizedOrder[];
}

export async function getNormalizedOrders() {
  const url = '/order/fetch-customizedOrderByDate';

  const response = await api.get<GetNormalizedOrdersResponse>(url, {
    params: {
      date: 'all',
    },
  });

  return response.data.data;
}
