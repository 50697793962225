import copy from 'fast-copy';
import { Partner } from '../../../models/Partner';
import { mapKeysByColumnMapping } from '../../../utils/headerColumnMapping';
import {
  areArraysEqual,
  isSubsequenceArray,
  isValid,
} from '../../../utils/utils';

export function detectEnglishByKoreanHeader(
  koreanHeader: string[],
  partnerInfo: Partner,
  settings: {
    partnerCommonHeader: string[];
    customColumnTranslation: { [key: string]: string };
  } = {
    partnerCommonHeader: [],
    customColumnTranslation: {},
  }
) {
  const { partnerCommonHeader = [], customColumnTranslation = {} } = settings;

  for (const additionalHeader of getValidAdditionalHeader(partnerInfo)) {
    if (areArraysEqual(koreanHeader, copy(additionalHeader.koreanHeader))) {
      const mappedKoreanHeader = koreanHeader.map(
        (header) => additionalHeader.additionalHeaderMatching[header]
      );

      return mapKeysByColumnMapping(
        mappedKoreanHeader,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        partnerInfo.columnMapping
      );
    }
  }

  if (
    areArraysEqual(
      partnerInfo.header ?? partnerInfo?.matchings?.at(0)?.header ?? [],
      koreanHeader
    )
  ) {
    if (
      isValid(
        partnerInfo.columnMapping ??
          partnerInfo?.matchings?.at(0)?.columnMapping ??
          {}
      )
    ) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      return mapKeysByColumnMapping(
        koreanHeader,
        (partnerInfo.columnMapping ??
          partnerInfo?.matchings?.at(0)?.columnMapping ??
          {}) as Record<string, string>
      );
    }
  }

  if (
    isValid(partnerCommonHeader) &&
    isValid(customColumnTranslation) &&
    isSubsequenceArray(partnerCommonHeader, koreanHeader)
  ) {
    return mapKeysByColumnMapping(koreanHeader, customColumnTranslation);
  }

  return null;
}

export function getValidAdditionalHeader(partnerInfo: Partner) {
  if (!partnerInfo?.additionalHeaders) {
    return [];
  }
  return partnerInfo?.additionalHeaders?.filter((header) => {
    const mappedKoreanHeader = header.koreanHeader.map(
      (kHeader) => header.additionalHeaderMatching[kHeader]
    );

    return !mappedKoreanHeader.some(
      // null,undefined가 아닌 헤더중, partnerInfo.header에 없는 헤더가 존재할 경우-> valid하지 않음
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      (header) => header && !partnerInfo.header.includes(header)
    );
  });
}
