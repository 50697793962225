import { Encoding } from './models';

export function convertToAscii(
  content: string,
  encoding: Encoding = 'UTF-8'
): string {
  const eucKrDecoder = new TextDecoder(encoding);
  const binaryStr = content.toString();
  const uInt8Array = new Uint8Array(binaryStr.length);

  for (let i = 0; i < binaryStr.length; i++) {
    uInt8Array[i] = binaryStr.charCodeAt(i);
  }

  return eucKrDecoder.decode(uInt8Array);
}
