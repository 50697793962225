import { DispatchedOrder } from '@sweep/contract';
import { observer } from 'mobx-react-lite';
import { cva } from 'src/third-parties/tailwind';
import { OrderStatusType } from '../../interface';
import {
  isCancelRequest,
  isEtc,
  isInDelivery,
  isPaymentComplete,
  isProductPreparing,
} from '../../services/filterOrderStatus';
import OrderStatusRadio from './OrderStatusRadio';

interface OrderStatusTypeFilterProps {
  orders: DispatchedOrder[];
  value: OrderStatusType;
  onChange: (selectedOrderStatusType: OrderStatusType) => void;
}

function OrderStatusRadioGroup({
  orders,
  value,
  onChange,
}: OrderStatusTypeFilterProps) {
  const orderStatusTypeCounts = getOrderStatusTypeCounts(orders);
  const orderStatusTypes: OrderStatusType[] = [
    'Total',
    'PaymentComplete',
    'ProductPreparing',
    'InDelivery',
    'CancelRequest',
    'Etc',
  ];

  return (
    <div className={container()}>
      {orderStatusTypes.map((orderStatusType) => (
        <OrderStatusRadio
          key={orderStatusType}
          orderStatusType={orderStatusType}
          count={orderStatusTypeCounts[orderStatusType]}
          selected={value === orderStatusType}
          onChange={() => onChange(orderStatusType)}
        />
      ))}
    </div>
  );
}

const container = cva('flex min-w-0 flex-wrap justify-between gap-[14px]');

function getOrderStatusTypeCounts(
  orders: DispatchedOrder[]
): Record<OrderStatusType, number> {
  let paymentComplete = 0;
  let productPreparing = 0;
  let inDelivery = 0;
  let cancelRequest = 0;
  let etc = 0;

  orders.forEach((order) => {
    if (isPaymentComplete(order.orderStatus)) {
      paymentComplete += 1;
      return;
    }

    if (isProductPreparing(order.orderStatus)) {
      productPreparing += 1;
      return;
    }

    if (isInDelivery(order.orderStatus)) {
      inDelivery += 1;
      return;
    }

    if (isCancelRequest(order.orderStatus)) {
      cancelRequest += 1;
      return;
    }

    if (isEtc(order.orderStatus)) {
      etc += 1;
      return;
    }
  });

  return {
    Total: orders.length,
    PaymentComplete: paymentComplete,
    ProductPreparing: productPreparing,
    InDelivery: inDelivery,
    CancelRequest: cancelRequest,
    Etc: etc,
  };
}

export default observer(OrderStatusRadioGroup);
