import { Product } from 'src/models/Product';
import { OMSStore } from 'src/stores/OMSStore';
import { isNotNil } from 'src/utils/function';

export function filterProduct(search: string, product: Product, oms: OMSStore) {
  if (search === '') {
    return true;
  }

  if (product.productName.includes(search)) {
    return true;
  }

  const optionNames = product.units?.map((unit) => unit.unit) ?? [];
  if (optionNames.some((name) => name.includes(search))) {
    return true;
  }

  if (product.useSupplierByOption === true) {
    const supplierIds =
      product.units?.map((unit) => unit.supplierId)?.filter(isNotNil) ?? [];
    const suppliers = supplierIds
      .map((id) => oms.supplier.getSupplier(id))
      .filter(isNotNil);

    if (suppliers.some((supplier) => supplier.name.includes(search))) {
      return true;
    }

    return false;
  }

  if (product.supplierId != null) {
    const supplier = oms.supplier.getSupplier(product.supplierId);

    if (supplier != null && supplier.name.includes(search)) {
      return true;
    }
  }

  return false;
}
