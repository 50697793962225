import { If } from '@sweep/utils';
import Button from 'components/buttons/Button';
import { useOMSStore } from 'hooks/useOMSStore';
import { IconCircleDeleteSmall, IconCodeFile } from 'icons/index';
import NewModal from 'modals/NewModal';
import {
  ColumnMapping,
  CreatePartnerDTO,
  Matching,
  Partner,
} from 'models/Partner';
import { ReactNode, useContext, useState } from 'react';
import { findEstimatedColumnMapping } from 'services/column-mapping/findEstimatedColumnMapping';
import { sliceUntilHeader } from 'services/column-mapping/sliceUntilHeader';
import { readExcel } from 'services/file/excel/readExcel';
import PartnerColumnMatchingInput from './common/PartnerColumnMatchingInput';
import PartnerInfoInput from './common/PartnerInfoInput';
import { PartnerFormModalContext } from './PartnerFormModalContext';

type PartnerFormType = 'register' | 'update';

interface PartnerFormModalProps {
  open: boolean;
  defaultPartner?: Partial<Partner>;
  onSubmit: (partner: Omit<Partner, '_id'> | null) => void;
  type: PartnerFormType;
  children?: ReactNode;
}

export function PartnerFormModal({
  open,
  onSubmit,
  defaultPartner,
  type,
  children,
}: PartnerFormModalProps) {
  const [partner, setPartner] = useState<CreatePartnerDTO>({
    name: '',
    ...defaultPartner,
  });

  const handleChange = (partner: Partial<Partner>) => {
    setPartner((prev) => ({ ...prev, ...partner }));
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const partnerName = partner.name?.trim();
    if (partnerName == null || partnerName === '') {
      alert('회사명을 입력해주세요.');
      return;
    }

    if (partnerName.includes('/')) {
      alert(`회사명에 / 는 포함될 수 없습니다.`);
      return;
    }

    onSubmit({
      ...partner,
      name: partnerName,
    });
  };

  const handleClose = () => {
    const message = getCloseConfirmMessage(type);
    const isConfirmed = window.confirm(message);
    if (!isConfirmed) {
      return;
    }

    onSubmit(null);
  };

  if (!open) {
    return null;
  }

  const modalTitle = getTitle(type);
  const submitButtonName = getSubmitButtonName(type);

  return (
    <PartnerFormModalContext.Provider
      value={{ partner, onChange: handleChange }}
    >
      <NewModal
        header={modalTitle}
        onClose={handleClose}
        backdropClick={false}
        tailwindWidth="w-10/12"
      >
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col gap-6 px-10">{children}</div>

          <Button
            type="submit"
            name={submitButtonName}
            className="float-end mr-8"
          />
        </form>
      </NewModal>
    </PartnerFormModalContext.Provider>
  );
}

PartnerFormModal.InfoInput = PartnerFormModalInfoInput;
PartnerFormModal.FileUpload = PartnerFormModalFileUpload;
PartnerFormModal.ColumnMatchingInput = PartnerFormModalColumnMatchingInput;

function PartnerFormModalInfoInput() {
  const { partner, onChange } = useContext(PartnerFormModalContext);

  return <PartnerInfoInput partner={partner} onChange={onChange} />;
}

function PartnerFormModalFileUpload() {
  const oms = useOMSStore();
  const { partner, onChange } = useContext(PartnerFormModalContext);

  const [matchingId, setMatchingId] = useState<string | null>(null);
  const [filename, setFilename] = useState<string | null>(null);

  const handleFile = async (files: FileList | null) => {
    const filesArray = Array.from(files ?? []);
    if (filesArray.length === 0) {
      return;
    }

    const file = filesArray[0];

    oms.loading.start();
    const excelFile = await readExcel(file);
    oms.loading.end();

    const slicedExcelFile = excelFile && sliceUntilHeader(excelFile);
    if (slicedExcelFile == null) {
      return;
    }

    const filename = slicedExcelFile.name;
    const header = slicedExcelFile.data.at(0);
    const exampleContents = slicedExcelFile.data.slice(1, 7);

    if (header == null) {
      return;
    }

    const estimatedColumnMapping = findEstimatedColumnMapping(header);

    const matching: Matching = {
      id: self.crypto.randomUUID(),
      header,
      columnMapping: estimatedColumnMapping,
      exampleContents,
    };

    onChange({
      header,
      exampleContents,
      matchings: [...(partner.matchings ?? []), matching],
    });
    setFilename(filename);
    setMatchingId(matching.id);
  };

  const handleRemoveFile = () => {
    setFilename(null);
    setMatchingId(null);
    const matchings = partner.matchings?.filter(
      (matching) => matching.id !== matchingId
    );

    onChange({ matchings });
  };

  return (
    <div className="flex justify-between">
      <div>
        <label
          htmlFor="excelFile"
          className="font-pretendard block self-stretch text-base font-medium not-italic leading-[21px_] text-[color:var(--Gray-600,#343D4B)]"
        >
          판매처의 엑셀 양식을 올려주세요
        </label>
        <div className="mb-4 mt-[16px] flex items-center space-x-4 rounded-md border-gray-300 bg-white ">
          <If is={filename == null}>
            <div className="">
              <div className="relative flex h-10 w-32 items-center justify-center rounded-lg bg-blue-500">
                <input
                  type="file"
                  accept=".xlsx, .xls, .csv"
                  onChange={(event) => handleFile(event.target.files)}
                  className="absolute inset-0 size-full cursor-pointer opacity-0"
                />
                <IconCodeFile color="white" />
                <span className="ml-[4px] text-sm font-bold not-italic leading-[19px_] text-[color:var(--Gray-100,#F2F6FA)]">
                  파일 업로드
                </span>
              </div>
            </div>
          </If>

          <If is={filename != null}>
            <div className="flex h-[45px] items-center justify-center gap-[8px] rounded-lg bg-[#F2F6FA] px-5 py-0">
              <div className="flex ">
                <IconCodeFile color="#9FB1C1" />
                <span className="ml-[4px] text-sm font-bold not-italic leading-[19px_] text-[#343D4B]">
                  업로드된 파일
                </span>
              </div>
              <span className="text-sm font-bold not-italic leading-[19px_] text-blue-500">
                {filename}
              </span>
              <div
                className="inset-y-0 right-[24px] flex cursor-pointer items-center"
                onClick={handleRemoveFile}
              >
                <IconCircleDeleteSmall />
              </div>
            </div>
          </If>
        </div>
      </div>
    </div>
  );
}

function PartnerFormModalColumnMatchingInput() {
  const { partner, onChange } = useContext(PartnerFormModalContext);
  const matching = partner.matchings?.at(-1);

  const handleColumnMappingChange = (columnMapping: ColumnMapping) => {
    if (matching == null) {
      return;
    }

    onChange({
      columnMapping,
      matchings: partner.matchings?.map((m) =>
        m.id === matching.id ? { ...m, columnMapping } : m
      ),
    });
  };

  if (
    matching == null ||
    matching.header == null ||
    matching.header.length === 0
  ) {
    return null;
  }

  return (
    <div className="flex flex-col gap-3">
      <div className="flex flex-col gap-2">
        <p className="block text-xl font-bold text-black">매칭 세부설정</p>
        <p className="text-md">
          엑셀 파일 정보에 가장 부합하는 주요 명칭을 선택해주세요.
        </p>
      </div>
      <PartnerColumnMatchingInput
        header={matching.header}
        columnMapping={matching.columnMapping}
        onChange={handleColumnMappingChange}
        exampleContents={matching.exampleContents}
      />
    </div>
  );
}

function getTitle(type: PartnerFormType) {
  switch (type) {
    case 'register':
      return '판매처 추가';
    case 'update':
      return '판매처 수정';
  }
}

function getCloseConfirmMessage(type: PartnerFormType) {
  switch (type) {
    case 'register':
      return '판매처를 추가하지 않고 화면을 닫으시겠습니까?';
    case 'update':
      return '판매처 수정을 취소하시겠습니까?';
  }
}

function getSubmitButtonName(type: PartnerFormType) {
  switch (type) {
    case 'register':
      return '추가';
    case 'update':
      return '수정';
  }
}
