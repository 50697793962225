import { ReactNode, useCallback, useState } from 'react';
import ReactDOM from 'react-dom';
import { useUncontrollableState } from 'src/design-system/hooks/useUncontrollableState';
import { IconControlArrowDown } from 'src/icons';
import { cva } from 'src/third-parties/tailwind';
import { isEmptyString } from 'src/utils/string';
import {
  SelectContextOption,
  SelectContextProvider,
} from './context/SelectContext';
import { useSelectFloating } from './hooks/useSelectFloating';
import SelectOption from './SelectOption';

interface SelectProps {
  value?: string;
  onChange?: (value: string) => void;
  status?: 'default' | 'error';
  helperText?: string;
  placeholder?: string;
  className?: string;
  children?: ReactNode;
}

function Select({
  value: givenValue,
  onChange: givenOnChange,
  status = 'default',
  helperText,
  placeholder,
  className,
  children,
}: SelectProps) {
  const [value, onChange] = useUncontrollableState({
    value: givenValue,
    onChange: givenOnChange,
  });

  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState<SelectContextOption[]>([]);
  const [fragment] = useState<DocumentFragment>(() => new DocumentFragment());

  const label =
    value === '' ? undefined : options.find((o) => o.value === value)?.label;

  const handleOptionMount = useCallback((option: SelectContextOption) => {
    setOptions((prev) => [...prev, option]);
  }, []);

  const handleOptionUnmount = useCallback((option: SelectContextOption) => {
    setOptions((prev) => prev.filter((o) => o.value !== option.value));
  }, []);

  const handleValue = (value: string) => {
    onChange(value);
    setIsOpen(false);
  };

  const { floatingStyles, refs } = useSelectFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    options: {
      maxHeight: 292,
    },
  });

  return (
    <SelectContextProvider
      value={{
        value: value ?? undefined,
        onChange: handleValue,
        onOptionMount: handleOptionMount,
        onOptionUnmount: handleOptionUnmount,
        options,
      }}
    >
      <div className={container({ className })}>
        <div
          ref={refs.setReference}
          onClick={() => setIsOpen(!isOpen)}
          className={selectContainer({
            status: isOpen ? 'focused' : status,
            placeholder: placeholder != null && isEmptyString(value),
          })}
        >
          {label ?? placeholder}
          <IconControlArrowDown className="text-gray-700" />
        </div>
        {helperText != null && (
          <p className={helperTextClass({ status })}>{helperText}</p>
        )}
      </div>
      {isOpen ? (
        <div
          ref={refs.setFloating}
          style={floatingStyles}
          className={optionContainer()}
        >
          {children}
        </div>
      ) : (
        ReactDOM.createPortal(children, fragment)
      )}
    </SelectContextProvider>
  );
}

const container = cva('w-300px flex flex-col');

const selectContainer = cva(
  [
    'h-40px px-16px flex cursor-pointer items-center justify-between',
    'rounded-8px border',
    'text-medium-s',
  ],
  {
    variants: {
      status: {
        default: 'border-gray-300',
        focused: 'border-blue-500',
        error: 'border-red-500',
      },
      placeholder: {
        true: 'text-gray-400',
        false: 'text-gray-700',
      },
    },
  }
);

const optionContainer = cva(
  'rounded-8px overflow-y-auto border border-gray-200 shadow-[0_4px_12px_0px_rgba(0,0,0,0.12)]'
);

const helperTextClass = cva('text-medium-xs h-[25px] px-[8px] py-[4px]', {
  variants: {
    status: {
      default: 'text-gray-700',
      error: 'text-red-500',
    },
  },
});

Select.Option = SelectOption;

export default Select;
