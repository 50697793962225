import { NormalizedOrder } from '@sweep/contract';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import { SelectableTable } from 'src/design-system/components/Table/SelectableTable';
import { useOMSStore } from 'src/hooks/useOMSStore';
import backendApi from 'src/utils/backendApi';

interface OrderAggregationTableCellProps {
  order: NormalizedOrder;
  orderKey: string;
  lastColumn: boolean;
  lastRow: boolean;
}

function OrderAggregationTableCell({
  order,
  orderKey,
  lastColumn,
  lastRow,
}: OrderAggregationTableCellProps) {
  const oms = useOMSStore();

  const [isEditing, setIsEditing] = useState(false);
  const [internalValue, setInternalValue] = useState(
    getOrderValue(order, orderKey)
  );

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setInternalValue(getOrderValue(order, orderKey));
  }, [orderKey, order]);

  const handleBlur = () => {
    setIsEditing(false);
    const updatedOrder = { ...order, [orderKey]: internalValue };
    const updatedOrders = oms.order.normalizedOrders.map((prevOrder) =>
      prevOrder.uniqueCodeAfterCustomization ===
      order.uniqueCodeAfterCustomization
        ? updatedOrder
        : prevOrder
    );
    oms.order.setNormalizedOrders(updatedOrders);
    backendApi.updateCustomizedOrders([updatedOrder]);
  };

  const handleDoubleClick = () => {
    setIsEditing(true);
    setTimeout(() => inputRef.current?.focus());
  };

  return (
    <SelectableTable.Cell
      lastRow={lastRow}
      lastColumn={lastColumn}
      onDoubleClick={handleDoubleClick}
    >
      {isEditing ? (
        <input
          className="w-full"
          ref={inputRef}
          value={internalValue}
          onChange={(e) => setInternalValue(e.target.value)}
          onBlur={handleBlur}
        />
      ) : (
        internalValue
      )}
    </SelectableTable.Cell>
  );
}

function getOrderValue(order: NormalizedOrder, key: string) {
  return order[key]?.toString() ?? '';
}

export default observer(OrderAggregationTableCell);
