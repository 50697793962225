import { Order } from '@sweep/contract';
import { checkAddress } from 'src/network/fastapi';
import { AbstractPlugin } from '../../interface';

interface CheckAddressPluginConfig {
  fixInvalidAddress?: boolean;
}

export class CheckAddressPlugin extends AbstractPlugin<
  CheckAddressPluginConfig | undefined
> {
  transform = async (orders: Order[]): Promise<Order[]> => {
    const response = await checkAddress(orders);
    if (
      response == null ||
      response.success === false ||
      response.data.length !== orders.length
    ) {
      return orders;
    }

    const order_addresses_corrected = response.data;
    const newOrders = orders.map((order, index) => {
      const newOrder = { ...order };
      const checkedAddress = order_addresses_corrected[index];
      const checkedAddressResult = checkedAddress.result;

      newOrder.isAddressValid = checkedAddress.result;
      newOrder.mainAddress = checkedAddress.main_address;
      newOrder.detailAddress = checkedAddress.detail_address;

      switch (checkedAddressResult) {
        case 'okay': {
          newOrder.address = checkedAddress.data;
          return newOrder;
        }
        case 'fixed': {
          if (this.config?.fixInvalidAddress === true) {
            newOrder.address = checkedAddress.tried_data;
            newOrder.memo = [];
            newOrder.memo.push({
              text: '기존주소:' + checkedAddress.data + '\n',
            });

            return newOrder;
          }

          newOrder.isAddressValid = 'fail';
          newOrder.address = checkedAddress.data;
          break;
        }
        case 'fail': {
          newOrder.address = this.config?.fixInvalidAddress
            ? checkedAddress.tried_data
            : checkedAddress.data;
        }
      }

      if (newOrder.isAddressValid !== 'fail') {
        return newOrder;
      }

      newOrder.memo = [];
      if (checkedAddress.message.includes('지번 주소입니다.')) {
        if (
          checkedAddress.message.length > 2 &&
          checkedAddress.message[2].includes('해당하는 도로명 주소가')
        ) {
          newOrder.isAddressValid = 'multiple';
          newOrder.memo.push({
            text: checkedAddress.message[2] + '\n',
          });
          newOrder.memo = newOrder.memo.concat(
            checkedAddress.tried_tried_data.map((item) => ({
              text: item + '\n',
              font: { bold: true },
            }))
          );

          return newOrder;
        }

        newOrder.memo = newOrder.memo.concat(
          checkedAddress.message.slice(2).map((item) => ({ text: item + '\n' }))
        );
      } else {
        const postCodeMessage = checkedAddress.message.find((message) =>
          message.includes('고객이 입력한 우편번호')
        );

        if (postCodeMessage != null) {
          newOrder.isAddressValid = 'postcode';
          newOrder.memo.push({
            text: postCodeMessage + '\n',
          });
          return newOrder;
        }
      }

      if (newOrder.isAddressValid !== 'fail') {
        return newOrder;
      }

      newOrder.memo = newOrder.memo.concat(
        checkedAddress.message.map((item) => ({
          text: item + '\n',
        }))
      );

      newOrder.memo = newOrder.memo.concat(
        checkedAddress.tried_tried_data.map((item) => ({
          text: item + '\n',
          font: { bold: true },
        }))
      );

      return newOrder;
    });

    return newOrders;
  };
}
