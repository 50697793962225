import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import {
  CompositionMatching,
  CompositionMatchingXX,
  CreateCompositionMatching,
  CreateOptionCodeMatching,
  MatchedProductXX,
  OptionCodeMatching,
  UpdateCompositionMatching,
  UpdateOptionCodeMatching,
} from 'models/CompositionMatching';
import {
  createCompositionMatching,
  createCompositionMatchings,
  getCompositionMatchings,
  updateCompositionMatching,
} from 'network/composition-matching';
import {
  createOptionCodeMatching,
  createOptionCodeMatchings,
  getOptionCodeMatchings,
  updateOptionCodeMatching,
} from 'network/option-code-matching';
import { OMSStore } from './OMSStore';

export class CMStore {
  compositionMatchings: CompositionMatching[] = [];
  optionCodeMatchings: OptionCodeMatching[] = [];

  /**
   * @deprecated
   */
  get legacyCompositionMatchings(): CompositionMatchingXX[] {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    return this.compositionMatchings.map<CompositionMatchingXX>((cm) => ({
      ...cm,
      data: cm.data.map<MatchedProductXX>((cmProduct) => {
        const product = this.oms.product.products.find(
          (product) => product?._id === cmProduct.productId
        );

        if (product != null) {
          return {
            ...cmProduct,
            productId: cmProduct.productId,
            productName: product.productName,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            supplierName: product.supplierName || '',
            supplierId: product.supplierId || '',
          };
        }

        return cmProduct;
      }),
    }));
  }

  constructor(private oms: OMSStore) {
    makeObservable(this, {
      compositionMatchings: observable,
      optionCodeMatchings: observable,

      legacyCompositionMatchings: computed,

      init: action.bound,
      loadCompositionMatchings: action.bound,
      loadOptionCodeMatchings: action.bound,

      createCompositionMatching: action.bound,
      createCompositionMatchings: action.bound,
      updateCompositionMatching: action.bound,
      createOptionCodeMatching: action.bound,
      createOptionCodeMatchings: action.bound,
      updateOptionCodeMatching: action.bound,
      setCompositionMatchings: action.bound,
    });
  }

  async init() {
    await Promise.all([
      this.loadCompositionMatchings(),
      this.loadOptionCodeMatchings(),
    ]);
  }

  async loadCompositionMatchings() {
    const response = await getCompositionMatchings();
    if (!response.success) {
      return;
    }

    runInAction(() => {
      this.compositionMatchings = response.data;
    });
  }

  async loadOptionCodeMatchings() {
    const response = await getOptionCodeMatchings();
    if (!response.success) {
      return;
    }

    runInAction(() => {
      this.optionCodeMatchings = response.data;
    });
  }

  async createCompositionMatching(
    compositionMatching: CreateCompositionMatching
  ) {
    const response = await createCompositionMatching(compositionMatching);

    if (!response.success) {
      return;
    }

    runInAction(() => {
      this.compositionMatchings = [...this.compositionMatchings, response.data];
    });

    return response.data;
  }

  async createCompositionMatchings(
    compositionMatchings: CreateCompositionMatching[]
  ) {
    const response = await createCompositionMatchings(compositionMatchings);

    if (!response.success) {
      return;
    }

    runInAction(() => {
      this.compositionMatchings = [
        ...this.compositionMatchings,
        ...response.data,
      ];
    });

    return response.data;
  }

  async updateCompositionMatching(
    compositionMatching: UpdateCompositionMatching
  ) {
    const response = await updateCompositionMatching(compositionMatching);

    if (!response.success) {
      return;
    }

    runInAction(() => {
      this.compositionMatchings = this.compositionMatchings.map((cm) =>
        cm._id === compositionMatching._id
          ? {
              ...cm,
              ...compositionMatching,
            }
          : cm
      );
    });

    return response.data;
  }

  async createOptionCodeMatching(optionCodeMatching: CreateOptionCodeMatching) {
    const response = await createOptionCodeMatching(optionCodeMatching);

    if (!response.success) {
      return;
    }

    runInAction(() => {
      this.optionCodeMatchings = [...this.optionCodeMatchings, response.data];
    });

    return response.data;
  }

  async createOptionCodeMatchings(
    optionCodeMatchings: CreateOptionCodeMatching[]
  ) {
    const response = await createOptionCodeMatchings(optionCodeMatchings);

    if (!response.success) {
      return;
    }

    runInAction(() => {
      this.optionCodeMatchings = [
        ...this.optionCodeMatchings,
        ...response.data,
      ];
    });

    return response.data;
  }

  async updateOptionCodeMatching(optionCodeMatching: UpdateOptionCodeMatching) {
    const response = await updateOptionCodeMatching(optionCodeMatching);

    if (!response.success) {
      return;
    }

    runInAction(() => {
      this.optionCodeMatchings = this.optionCodeMatchings.map((ocm) =>
        ocm._id === optionCodeMatching._id
          ? {
              ...ocm,
              ...optionCodeMatching,
            }
          : ocm
      );
    });

    return response.data;
  }

  /**
   * @deprecated
   */
  setCompositionMatchings(compositionMatchings: CompositionMatching[]) {
    this.compositionMatchings = compositionMatchings;
  }
}
