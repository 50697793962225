import classNames from 'classnames';
import React from 'react';
import deleteTextIcon from '../icons/deleteTextGray.png';
import searchIcon from '../icons/search.png';
import searchArrowIcon from '../icons/searchArrow.png';

interface SearchInputProps {
  type?: 'common' | 'upload';
  searchQuery: string;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  placeholder?: string;
  width?: string;
  onClickDelete?: () => void;
}

const SearchInput: React.FC<SearchInputProps> = ({
  type = 'common',
  searchQuery = '',
  setSearchQuery,
  placeholder = '검색어를 입력하세요.',
  width = 'w-[800px]',
  onClickDelete = () => {
    setSearchQuery('');
  },
}) => {
  return (
    <>
      <div
        className={classNames(
          'flex items-center gap-[8px] rounded-xl border-[1.5px] bg-white p-2 pl-3 text-[16px] font-medium leading-[23px] shadow-sm',
          `${searchQuery ? 'border-[#3C82F6] text-[#343D4B]' : 'border-[#CED7E0] text-[#A7B3C2]'}`,
          width
        )}
      >
        <div className="flex size-[25px] items-center justify-center">
          <img src={searchIcon} alt="Search" className="size-[20px]" />
        </div>
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder={placeholder}
          className="bg-transparent focus:outline-none"
          style={{ width: 'calc(100% - 56px)' }}
          required
        />
        {/* 검색어 입력 시 우측에 회색 (X) 아이콘 발생 - 검색어 클리어 */}
        {type === 'common' && searchQuery && (
          <div
            className="flex h-auto w-[31px] cursor-pointer items-center justify-center"
            onClick={onClickDelete}
          >
            <img
              src={deleteTextIcon}
              alt="Close"
              className="size-[16px]"
            />
          </div>
        )}
        {/* 우측에 파란 배경 화살표 모양 아이콘 고정 표현 - 기능 없음 */}
        {type === 'upload' && (
          <div className="size-[31px] cursor-pointer">
            <img src={searchArrowIcon} alt="searchArrow icon" />
          </div>
        )}
      </div>
    </>
  );
};

export default SearchInput;
