import { ColumnMapping } from 'models/Partner';

export function findHeaderKeys(
  header: string[],
  columnMapping: ColumnMapping
): (string | null)[] {
  return header.map((value) => {
    return (
      Object.entries(columnMapping).find(([_, name]) => {
        return name === value;
      })?.[0] ?? null
    );
  });
}
