import { Order } from '@sweep/contract';
import api from './api';

export type CheckedAddress =
  | {
      result: 'okay';
      data: string;
      main_address: string;
      detail_address: string;
    }
  | {
      result: 'fixed';
      data: string;
      orig_data: string;
      main_address: string;
      detail_address: string;
      tried_data: string;
      message: string[];
      tried_tried_data: unknown[];
    }
  | {
      result: 'fail';
      data: string;
      orig_data: string;
      main_address: string;
      detail_address: string;
      tried_data: string;
      message: string[];
      tried_tried_data: unknown[];
    };

export type CheckOrdersAddressResponse =
  | {
      success: true;
      data: CheckedAddress[];
    }
  | { success: false };

export async function checkAddress(orders: Order[]) {
  try {
    const url = '/fastapi/correct_orders';
    const response = await api.post<CheckOrdersAddressResponse>(url, orders);

    return response.data;
  } catch (e) {
    return null;
  }
}
