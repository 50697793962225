import '../config/sentry-init';
import './index.css';

import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import ReactDOM from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { AMPLITUDE_API_KEY } from './env';
import { startMockingOnlyDev } from './mocks/browser';
import { amplitude } from './utils';

amplitude.init(AMPLITUDE_API_KEY, {
  autocapture: true,
});
const sessionReplayTracking = sessionReplayPlugin({
  sampleRate: 1,
});
amplitude.add(sessionReplayTracking);

const GTM_ID = 'GTM-T6KPK6W5';

TagManager.initialize({
  gtmId: GTM_ID,
});

startMockingOnlyDev().then(() => {
  window.global ||= window;

  const container = document.getElementById('root');
  const root = ReactDOM.createRoot(container!);

  root.render(
    <Router>
      <App />
    </Router>
  );
});
