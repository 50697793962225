import React from 'react';

interface ButtonProps {
  name: string;
  onClick?: (...args: any[]) => void;
  type?: 'button' | 'submit' | 'reset' | 'input';
  color?: 'gray' | 'blue' | 'skyblue' | 'black';
  className?: string;
  size?: 'sm' | 'md' | 'lg';
  multiple?: boolean;
  disabled?: boolean;
  innerComponent?: React.ReactNode;
}

const Button = ({
  name,
  onClick,
  type = 'button',
  color = 'blue',
  className = '',
  size = 'md',
  multiple = false,
  disabled = false,
  innerComponent = undefined,
}: ButtonProps) => {
  const colorStyles = {
    gray: 'bg-neutral-200 text-neutral-600 hover:bg-neutral-300 font-semibold',
    blue: 'bg-blue-500 text-white hover:bg-blue-600 font-bold',
    skyblue: 'bg-blue-100 text-blue-500 hover:bg-blue-200 font-semibold',
    black: 'bg-gray-700 text-white hover:bg-gray-900 font-bold',
  };

  const disabledStyles = 'opacity-50 cursor-not-allowed';

  const colorClasses = colorStyles[color] || colorStyles.blue;

  const sizeStyles = {
    sm: 'px-2 py-1 text-sm',
    md: 'px-4 py-2 text-md',
    lg: 'px-6 py-3 text-lg',
  };

  const sizeClasses = sizeStyles[size] || sizeStyles.md;

  const buttonClasses = `${sizeClasses} ${colorClasses} rounded-lg ${
    disabled ? disabledStyles : ''
  }`;

  if (type === 'input') {
    return (
      <label
        className={`cursor-pointer ${buttonClasses} rounded-lg transition duration-300 ease-in-out ${className}`}
      >
        <input
          type="file"
          className="hidden"
          onChange={onClick}
          accept=".xlsx, .xls, .csv"
          multiple={multiple}
          disabled={disabled}
        />
        {innerComponent ? innerComponent : name}
      </label>
    );
  }

  return (
    <button
      type={type}
      onClick={onClick}
      className={`${buttonClasses} rounded-lg ${className}`}
      disabled={disabled}
    >
      {innerComponent ? innerComponent : name}
    </button>
  );
};

export default Button;
