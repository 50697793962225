import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useOMSStore } from 'src/hooks/useOMSStore';
import Button from '../../components/buttons/Button';
import LoadingIndicator from '../../components/LoadingIndicator';
import OMSContainer from '../../components/OMSContainer';
import OrderCombinationForm from '../../forms/OrderCombinationForm';
import toggleOff from '../../icons/toggleOff.png';
import toggleOn from '../../icons/toggleOn.png';
import Modal from '../../modals/Modal';
import LoadingStore from '../../stores/LoadingStore';
import UserStore from '../../stores/UserStore';
import backendApi from '../../utils/backendApi';
import { isValid } from '../../utils/utils';

const OrderCombinationSettingsScreen = observer(() => {
  const oms = useOMSStore();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedSettingId, setSelectedSettingId] = useState(null);
  const [isRegistrationModalOpen, setIsRegistrationModalOpen] = useState(false);
  const isCombinationSettingEnabled = isValid(
    oms.user.setting?.combinationCustomSetting?.enabled
  )
    ? oms.user.setting?.combinationCustomSetting.enabled
    : false;
  const [toggleToastOn, setToggleToastOn] = useState(false);

  const handleRegisterCombinationSetting = async (data) => {
    try {
      if (data.condition === null) {
        data.condition = [];
      }
      const res = await backendApi.registerCombinationSetting(data);

      if (res.success) {
        const newCombinationSetting = res.data;
        const updatedCombinationSettings = [
          ...UserStore?.combinationSettings,
          newCombinationSetting,
        ];
        UserStore?.setCombinationSettings(updatedCombinationSettings);

        alert('합배송 설정이 완료되었어요.');

        setIsRegistrationModalOpen(false);
      } else {
        alert('합배송 설정에 실패했어요. 다시 시도해주세요.');
      }
    } catch (err) {
      console.error('Error registering combination setting :', err);
      throw err;
    }
  };

  // 파트너 추가 또는 수정 폼 제출
  const handleEditCombinationSetting = async (data) => {
    const res = await backendApi.updateCombinationSetting(
      selectedSettingId,
      data
    );

    if (res.success) {
      const updatedCombinationSettings = UserStore?.combinationSettings.map(
        (setting) => {
          if (setting._id === selectedSettingId) {
            return { ...setting, ...data };
          }
          return setting;
        }
      );

      UserStore?.setCombinationSettings(updatedCombinationSettings);

      alert('합배송 설정이 수정되었어요.');

      setIsEditModalOpen(false);
      setSelectedSettingId(null);
    }
  };

  const handleDeleteCombinationSetting = async (settingId) => {
    const isConfirmed = window.confirm('정말로 해당 설정을 삭제하시겠습니까?');

    if (!isConfirmed) {
      return; // 사용자가 취소를 선택한 경우, 작업 중단
    }

    try {
      const res = await backendApi.deleteCombinationSetting(settingId);

      if (res.success) {
        const updatedCombinationSettings =
          UserStore?.combinationSettings.filter((setting) => {
            return setting._id !== settingId;
          });

        UserStore?.setCombinationSettings(updatedCombinationSettings);

        alert('합배송 설정이 삭제되었어요.');
      }
    } catch (err) {
      console.error('Error deleting combine setting:', err);
      throw err;
    }
  };

  const handleEditModalOpen = (settingId) => {
    setSelectedSettingId(settingId);
    setIsEditModalOpen(true);
  };

  const handleToggleChanged = async () => {
    let updatedCombinationCustomSetting = {};
    if (isCombinationSettingEnabled) {
      updatedCombinationCustomSetting = {
        combinationCustomSetting: {
          ...oms.user.setting?.combinationCustomSetting,
          enabled: false,
        },
      };

      const updatedCustomExcelSettings = {
        ...(oms.user.setting ?? {}),
        ...updatedCombinationCustomSetting,
      };
      if (updatedCustomExcelSettings._id) {
        delete updatedCustomExcelSettings._id;
      }

      await backendApi.updateCustomSetting(updatedCustomExcelSettings);
      oms.user.setCombinedExcelSetting(updatedCustomExcelSettings);
    } else {
      updatedCombinationCustomSetting = {
        combinationCustomSetting: {
          ...(oms.user.setting?.combinationCustomSetting ?? {}),
          enabled: true,
        },
      };

      const updatedCustomExcelSettings = {
        ...(oms.user.setting ?? {}),
        ...updatedCombinationCustomSetting,
      };
      if (updatedCustomExcelSettings._id) {
        delete updatedCustomExcelSettings._id;
      }

      await backendApi.updateCustomSetting(updatedCustomExcelSettings);
      oms.user.setCombinedExcelSetting(updatedCustomExcelSettings);
    }

    setToggleToastOn(true);

    setTimeout(() => {
      setToggleToastOn(false);
    }, 3000); // 3000ms = 3초
  };

  return (
    <OMSContainer>
      <div className="flex items-center">
        <h1 className="font-pretendard text-[24px] font-bold leading-[30px] text-[color:var(--Gray-600,#343D4B)]">
          합배송 설정
        </h1>
        <div
          className="ml-[12px] cursor-pointer"
          onClick={() => {
            handleToggleChanged();
          }}
        >
          <img
            src={isCombinationSettingEnabled ? toggleOn : toggleOff}
            alt="toggle"
            className="h-[28px] w-[42px]"
          />
        </div>
        <div
          className={`ml-[12px] rounded-2xl ${
            toggleToastOn ? 'bg-[#343D4B]' : 'bg-[#CED7E0]'
          } duration-800 px-[12px] py-[4px] text-[12px] font-medium text-white transition-colors ease-in-out`}
        >
          {isCombinationSettingEnabled
            ? '합배송 설정이 켜졌어요'
            : '합배송 설정이 꺼졌어요'}
        </div>
      </div>

      <div className="mt-[20px]">
        <Button
          name="설정 만들기"
          onClick={() => {
            setIsRegistrationModalOpen(true);
          }}
          className="mb-4"
        />
      </div>

      <div className="grid grid-cols-2 gap-4">
        {UserStore?.combinationSettings.map((setting, index) => (
          <div key={index} className="rounded-2xl border-2 px-6 py-4 shadow-md">
            {/* <div>
              <label
                htmlFor='unit'
                className='block mt-2 text-lg font-semibold text-gray-700'
              >
                합배송 기준
              </label>
              <div className='mt-2 block w-full px-3 py-2'>
                {translateCombinationType(setting.combinationType)}
              </div>
            </div> */}

            <div>
              <label
                htmlFor="unit"
                className="mt-3 block text-xl font-semibold text-gray-700"
              >
                아래 상품들을 합쳐서 배송해요
                {/* 합배송 적용 상품 */}
              </label>
              <div className="mt-3">
                <ul className="flex flex-row flex-wrap">
                  {setting.productIds.map((productId, index) => (
                    <li
                      key={productId}
                      className="mb-2 mr-2 flex items-center justify-between rounded-lg border-2 px-3.5 py-2.5"
                    >
                      {
                        oms.product.products.find(
                          (info) => info._id === productId
                        )?.productName
                      }
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div>
              <label
                htmlFor="unit"
                className="mt-6 block text-xl font-semibold text-gray-700"
              >
                합칠 수 있는 기준은 아래와 같아요
              </label>
              <div className="mt-3">
                {typeof setting.condition === 'string' ? (
                  <div className="flex shrink grow-0 items-center rounded-lg border-2 px-3.5 py-2.5">
                    {`최대 ${setting.condition}`}
                  </div>
                ) : (
                  <ul className="flex flex-row flex-wrap">
                    {Object.entries(setting.condition).map(([key, value]) => (
                      <li
                        key={`${key}-${value}`}
                        className="mr-2 flex items-center justify-between rounded-lg border-2 px-3.5 py-2.5"
                      >
                        {`${key} ${value}개`}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>

            {/* 다른 제품 정보 표시 */}
            <div className="mt-6 flex justify-end space-x-4">
              <button
                onClick={() => handleEditModalOpen(setting._id)}
                className="text-md font-semibold text-gray-500"
              >
                수정하기
              </button>
              <button
                onClick={() => handleDeleteCombinationSetting(setting._id)}
                className="text-md font-semibold text-gray-500"
              >
                삭제하기
              </button>
            </div>
          </div>
        ))}
      </div>

      {isRegistrationModalOpen && (
        <Modal
          header="합배송 설정 만들기"
          onClose={() => {
            const isConfirmed = window.confirm(
              `합배송 설정을 만들지 않고 화면을 닫으시겠습니까?`
            );

            if (!isConfirmed) {
              return; // 사용자가 취소를 선택한 경우, 작업 중단
            }

            setIsRegistrationModalOpen(false);
          }}
          backdropClick={false}
          // className='w-1/3 md:w-1/2 lg:w-1/4'
        >
          <OrderCombinationForm
            type="register"
            onFormSubmit={handleRegisterCombinationSetting}
          />
        </Modal>
      )}
      {isEditModalOpen && (
        <Modal
          header="설정 수정하기"
          onClose={() => {
            const isConfirmed = window.confirm(
              `합배송 설정을 수정하지 않고 화면을 닫으시겠습니까?`
            );

            if (!isConfirmed) {
              return; // 사용자가 취소를 선택한 경우, 작업 중단
            }

            setIsEditModalOpen(false);
          }}
          backdropClick={false}
        >
          <OrderCombinationForm
            type="update"
            combinationInfo={UserStore?.combinationSettings.find(
              (info) => info._id === selectedSettingId
            )}
            onFormSubmit={handleEditCombinationSetting}
          />
        </Modal>
      )}

      {LoadingStore?.isLoading && <LoadingIndicator />}
    </OMSContainer>
  );
});

export default OrderCombinationSettingsScreen;
