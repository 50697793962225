import deleteTextGray from '../../icons/deleteTextGray.png';

// components
const PartnerHeaderComponents = ({
  newPartnerInfo,
  handlePartnerInfoChange,
}) => {
  const labelComponent = ({ children, htmlFor }) => (
    <label
      htmlFor={htmlFor}
      className="font-pretendard text-[color:var(--Gray-600,#343D4B)] text-base not-italic font-medium leading-[21px_]"
    >
      {children}
    </label>
  );

  const mainInputComponent = ({
    id,
    type,
    name,
    value,
    onChange,
    required = false,
  }) => (
    <div className="relative">
      <input
        type={type}
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        className="mt-[8px] block w-full px-[16px] h-[40px] bg-white border border-[color:var(--Gray-300,#CED7E0)] border-[1px] rounded-lg shadow-sm
        focus:outline-none focus:ring-blue-500 focus:border-blue-500
        text-[color:var(--Gray-600,#343D4B)] text-sm not-italic font-medium"
        required={required}
      />
      {value && (
        <div
          className="absolute inset-y-0 right-0 pr-[16px] flex items-center cursor-pointer"
          onClick={() =>
            handlePartnerInfoChange({
              target: { name: name, value: '' },
            })
          }
        >
          <img src={deleteTextGray} alt="Close" className="h-[17px] w-[17px]" />
        </div>
      )}
    </div>
  );

  return (
    <div className="grid grid-cols-3 gap-6">
      <div>
        {labelComponent({ children: '회사명', htmlFor: 'name' })}
        {mainInputComponent({
          id: 'name',
          type: 'text',
          name: 'name',
          value: newPartnerInfo.name,
          onChange: handlePartnerInfoChange,
        })}
      </div>
      <div>
        {labelComponent({ children: '메일 주소', htmlFor: 'email' })}
        {mainInputComponent({
          id: 'email',
          type: 'email',
          name: 'email',
          value: newPartnerInfo.email,
          onChange: handlePartnerInfoChange,
        })}
      </div>

      <div>
        {labelComponent({ children: '전화번호', htmlFor: 'phone' })}
        {mainInputComponent({
          id: 'phone',
          type: 'tel',
          name: 'phone',
          value: newPartnerInfo.phone,
          onChange: handlePartnerInfoChange,
        })}
      </div>
    </div>
  );
};

export default PartnerHeaderComponents;
