import { EvaluateOperatorResult, Token } from '../../interface';
import { INVALID_EXPRESSION } from '../error';

export function evaluateOperatorEqual(stack: Token[]): EvaluateOperatorResult {
  const operand2 = stack.pop();
  const operand1 = stack.pop();

  if (operand1 == null || operand2 == null) {
    return INVALID_EXPRESSION;
  }

  return {
    success: true,
    stack: [...stack, operand1 === operand2],
  };
}
