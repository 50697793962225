import { extractMultiplier, updateOptionMultiplier } from './multiplierUtils';

const _extractSetMultiplier = (option) => {
  const setPattern = /,(\d+)세트/;
  const setMatch = option.match(setPattern);
  return setMatch ? parseInt(setMatch[1], 10) : 1;
};

const _removeSetFromOption = (option) => {
  return option.replace(/,(\d+)세트/, '');
};

export const splitOptiosIntoParts = (options) => {
  if (!Array.isArray(options)) {
    options = [options];
  }

  let result = [];
  options.forEach((option) => {
    const setMultiplier = _extractSetMultiplier(option);
    option = _removeSetFromOption(option);

    let parts = option
      .split(/(?<!,\d)\+|,(?!\d세트)/)
      .map((part) => part.trim());

    parts.forEach((part) => {
      const multiplier = extractMultiplier(part);

      const totalMultiplier = multiplier * setMultiplier;

      const newPart = updateOptionMultiplier(part, totalMultiplier);

      result.push(newPart);
    });
  });

  return result;
};
