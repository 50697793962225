import { useOMSStore } from 'hooks/useOMSStore';
import { observer } from 'mobx-react-lite';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconProfile } from 'src/icons';
import UserInfoModal from '../modals/UserInfoModal';

interface OMSHeaderProps {
  userStore?: any;
}

function OMSHeader({ userStore = {} }: OMSHeaderProps) {
  const oms = useOMSStore();

  const navigate = useNavigate();
  const iconRef = useRef<HTMLDivElement>(null);
  const [showModal, setShowModal] = useState(false);

  const handleIconClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowModal(!showModal);
  };

  return (
    <header className="flex w-full flex-row items-center justify-center gap-[10px] border-b border-[#E0E7ED] px-[40px] py-[20px]">
      <div className="flex w-full items-center justify-end">
        <div className="flex items-center gap-[20px]">
          <div className="flex items-center gap-[8px]">
            {oms.user.name && (
              <div className="font-pretendard text-[16px] leading-[21px] text-[color:var(--Gray-600,#343D4B)]">
                <span className="font-bold">{oms.user.name}</span>
                &nbsp;님
              </div>
            )}
            <div
              className="shrink-0 cursor-pointer"
              ref={iconRef}
              onMouseDown={handleIconClick}
            >
              <IconProfile />
            </div>
          </div>
          <div
            className="font-pretendard flex cursor-pointer items-center justify-end gap-[8px] px-[12px] py-[4px] text-[14px] font-bold leading-[26px] text-[#5B606C]"
            style={{ borderRadius: '8px', border: '1px solid #DDE4EB' }}
            onClick={() => {
              localStorage.removeItem('@authToken');
              localStorage.removeItem('@userId');
              oms.user.logout();
              navigate('/sign-in');
              window.location.reload();
            }}
          >
            로그아웃
          </div>
        </div>
      </div>
      <UserInfoModal
        show={showModal}
        toggleModal={() => setShowModal(false)}
        reference={iconRef}
        userStore={userStore}
      />
    </header>
  );
}

export default observer(OMSHeader);
