import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useOMSStore } from 'src/hooks/useOMSStore';
import Button from '../../components/buttons/Button';
import blueCheckIcon from '../../icons/blueCheck.png';
import creditCardIcon from '../../icons/cardIcon.jpg';
import creditCardButtonIcon from '../../icons/creditCard.png';
import starIcon from '../../icons/star.png';
import whiteCheckIcon from '../../icons/whiteCheck.png';
import UserStore from '../../stores/UserStore';
import backendApi from '../../utils/backendApi';

const CreditCardButton = ({ name = '' }) => {
  return (
    <>
      <span className="flex items-center justify-center gap-[4px]">
        <img src={creditCardButtonIcon} alt="Icon" className="size-[20px]" />
        <span>{name}</span>
      </span>
    </>
  );
};

const PaymentInfoScreen = observer(({ type = '' }: any) => {
  const oms = useOMSStore();

  const navigate = useNavigate();
  const [dataLoaded, setDataLoaded] = useState(false);
  const location = useLocation();
  const [selectFreeModal, setSelectFreeModal] = useState(false);
  const [selectBasicModal, setSelectBasicModal] = useState(false);
  const [selectStandardModal, setSelectStandardModal] = useState(false);
  const [billing, setBilling] = useState({} as any);
  const [plan, setPlan] = useState('');
  const [price, setPrice] = useState('');

  const getQueryParam = (param: string) => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get(param);
  };

  const code = getQueryParam('code');
  const message = getQueryParam('message');
  const customerKey = getQueryParam('customerKey');
  const authKey = getQueryParam('authKey');
  const planType = getQueryParam('planType');

  useEffect(() => {
    if (customerKey && authKey && planType) {
      // Assuming you have a function to create an axiosInstance and backendApi is imported
      backendApi
        .getBillingResult(customerKey, authKey, planType)
        .then(async () => {
          if (planType === 'origin') {
            alert('카드 등록이 완료되었습니다.');
          } else {
            alert('카드 등록 및 플랜 변경이 완료되었습니다.');
          }
          await UserStore.initializeUserData();
          setBilling(UserStore?.billing);
          setPlan(UserStore?.billing?.planType);
          setPrice(UserStore?.billing?.price);
          navigate('/my-page/payment-info');
          // Handle the success response
        })
        .catch((error) => {
          alert(
            '카드 등록에 실패하였습니다. 카드 정보를 올바르게 입력해주세요.'
          );
          // Handle the error case
        });
    }
  }, [customerKey, authKey, planType]);

  useEffect(() => {
    if (code && message) {
      alert('카드 등록에 실패하였습니다. 카드 정보를 올바르게 입력해주세요.');
      navigate('/my-page/payment-info');
    }
  }, [code, message]);

  useEffect(() => {
    const fetchData = async () => {
      await UserStore.initializeUserData();
      setBilling(UserStore?.billing);
      setPlan(UserStore?.billing?.planType);
      setPrice(UserStore?.billing?.price);
      setDataLoaded(true);
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (dataLoaded) {
      if (!plan) {
        setSelectFreeModal(true);
      } else {
        setSelectFreeModal(false);
      }
      if (plan === 'basic') {
        setSelectBasicModal(true);
      } else {
        setSelectBasicModal(false);
      }
      if (plan === 'standard') {
        setSelectStandardModal(true);
      } else {
        setSelectStandardModal(false);
      }
    }
  }, [dataLoaded, plan]);

  const modifyBillingInfo = (planType: string) => {
    backendApi
      .modifyBillingInfo(planType)
      .then(async () => {
        await UserStore.initializeUserData();
        setBilling(UserStore?.billing);
        setPlan(UserStore?.billing?.planType);
        setPrice(UserStore?.billing?.price);
      })
      .then(() => {
        alert('플랜이 정상적으로 변경되었습니다.');
      })
      .catch(() => {
        alert('플랜 변경에 실패하였습니다. 관리자에게 문의해주세요.');
      });
  };

  const loadTossPayments = (planType = 'origin') => {
    const script = document.createElement('script');
    script.src = 'https://js.tosspayments.com/v1/payment';
    script.onload = () => initiateTossPayment(planType);
    document.body.appendChild(script);
  };

  const initiateTossPayment = (planType: string) => {
    const clientKey = 'live_ck_P9BRQmyarY2AGNg5x5n78J07KzLN';
    const tossPayments = (window as any).TossPayments(clientKey);

    tossPayments
      .requestBillingAuth('카드', {
        customerKey: 'USER_' + localStorage.getItem('@userId'), // A unique ID for the customer
        successUrl:
          'https://www.sweepingoms.com/my-page/payment-info' +
          '?planType=' +
          planType,
        failUrl: 'https://www.sweepingoms.com/my-page/payment-info',
      })
      .catch(function (error: any) {
        console.error('Payment error:', error);
        if (error?.code === 'USER_CANCEL') {
          // Handle the case where the user closes the payment window.
        }
      });
  };

  return (
    dataLoaded && (
      <>
        <div className="flex flex-col items-center px-[80px] py-[60px]">
          {/* <!-- Title --> */}
          <div className="flex h-[30px] w-full items-center">
            <span className="flex h-[30px] items-center text-[24px] font-extrabold text-[#343D4B]">
              현재{' '}
              <span className="text-[#1991FF]">
                &nbsp;
                {plan === 'standard'
                  ? '스탠다드 플랜'
                  : plan === 'basic'
                    ? '베이직 플랜'
                    : '무료 시작 플랜'}
              </span>
              을 구독중입니다.
            </span>
          </div>

          {/* <!-- Main Content --> */}
          <div className="flex h-[555px] w-full flex-row py-[32px]">
            <div
              className={
                selectFreeModal
                  ? 'flex h-full w-[370px] flex-col rounded-[12px] border border-[#1991FF] p-[24px]'
                  : 'flex h-full w-[370px] flex-col rounded-[12px] border border-[#E0E7ED] p-[24px]'
              }
            >
              <div
                className={
                  'flex flex-row items-start justify-between rounded-[4px] bg-[#F2F6FA] px-[20px] py-[16px]'
                }
              >
                <div className={'flex flex-col items-start'}>
                  <p className={'text-[24px] font-extrabold text-[#343D4B]'}>
                    Free Starter
                  </p>
                  <p className={'text-[14px] font-semibold text-[#9FB1C1]'}>
                    무료 시작 플랜
                  </p>
                </div>
                {selectFreeModal && (
                  <div className={'flex flex-col items-end justify-end'}>
                    <img
                      src={blueCheckIcon}
                      alt="blueCheck icon"
                      className="size-[31px]"
                    />
                  </div>
                )}
              </div>

              <div className="h-[20px]"></div>
              <div className="flex h-[78px] w-full flex-col items-center px-[20px]">
                <div className="flex h-[19px] w-full flex-row items-center justify-start text-[16px] font-semibold text-[#9FB1C1]">
                  {oms.user.trialEndsAt?.slice(0, 10).replace(/-/g, '.')}
                  &nbsp;종료
                </div>
                <div className="my-[4px] flex h-[32px] w-full flex-row items-center justify-start text-[26px] font-bold text-[#343D4B]">
                  3주간 무료
                </div>
                <div className="flex h-[19px] w-full flex-row items-center justify-start text-[14px] text-[#9FB1C1]">
                  기능 제한 없이 테스트 가능
                </div>
              </div>
              <div className="h-[16px]"></div>
              <div className="h-px w-full bg-[#9FB1C1]"></div>
              <div className="h-[20px]"></div>
              <div className="flex h-[19px] flex-row">
                <img src={starIcon} alt="star icon" className="size-[19px]" />
                <div className="w-[8px]"></div>
                <p className="text-[14px] font-medium text-[#343D4B]">
                  월 주문 건수 1만건 미만
                </p>
              </div>
              <div className="h-[8px]"></div>
              <div className="flex h-[19px] flex-row">
                <img src={starIcon} alt="star icon" className="size-[19px]" />
                <div className="w-[8px]"></div>
                <p className="text-[14px] font-medium text-[#343D4B]">
                  발주 파일 통합 및 분리
                </p>
              </div>
              <div className="h-[8px]"></div>
              <div className="flex h-[19px] flex-row">
                <img src={starIcon} alt="star icon" className="size-[19px]" />
                <div className="w-[8px]"></div>
                <p className="text-[14px] font-medium text-[#343D4B]">
                  상품정보 설정 및 자동 매칭
                </p>
              </div>
              <div className="h-[8px]"></div>
              <div className="flex h-[19px] flex-row">
                <img src={starIcon} alt="star icon" className="size-[19px]" />
                <div className="w-[8px]"></div>
                <p className="text-[14px] font-medium text-[#343D4B]">
                  일일, 기간별, 파트너사별 정산관리
                </p>
              </div>
              <div className="h-[8px]"></div>
              <div className="flex h-[19px] flex-row">
                <img src={starIcon} alt="star icon" className="size-[19px]" />
                <div className="w-[8px]"></div>
                <p className="text-[14px] font-medium text-[#343D4B]">
                  합/분리배송 처리 및 기타 엑셀업무
                </p>
              </div>
            </div>
            <div className="w-[25px]"></div>
            <div
              className={
                selectBasicModal
                  ? 'flex h-full w-[370px] cursor-pointer flex-col rounded-[12px] border border-[#1991FF] p-[24px]'
                  : 'flex h-full w-[370px] cursor-pointer flex-col rounded-[12px] border border-[#E0E7ED] p-[24px]'
              }
              onClick={() => {
                setSelectBasicModal(!selectBasicModal);
                setSelectStandardModal(false);
                setSelectFreeModal(false);
              }}
            >
              <div
                className={
                  'flex flex-row items-start justify-between rounded-[4px] bg-[#F2F6FA] px-[20px] py-[16px]'
                }
              >
                <div className={'flex flex-col items-start'}>
                  <p className={'text-[24px] font-extrabold text-[#343D4B]'}>
                    Basic Plan
                  </p>
                  <p className={'text-[14px] font-semibold text-[#9FB1C1]'}>
                    베이직 플랜
                  </p>
                </div>
                {selectBasicModal && (
                  <div className={'flex flex-col items-end justify-end'}>
                    <img
                      src={blueCheckIcon}
                      alt="blueCheck icon"
                      className="size-[31px]"
                    />
                  </div>
                )}
              </div>
              <div className="h-[20px]"></div>
              <div className="flex min-h-[78px] w-full flex-col items-center px-[20px]">
                <div className="flex h-[19px] w-full flex-row items-center justify-start text-[16px] font-semibold text-[#9FB1C1] line-through">
                  ₩199,000/월
                </div>
                <div className="my-[4px] flex min-h-[32px] w-full flex-row items-center justify-between">
                  <div className="flex h-full flex-row items-center justify-start text-[26px] font-bold text-[#343D4B]">
                    ₩99,000/월
                  </div>
                  <div className="flex h-[40px] min-w-[86px] flex-row items-center justify-center rounded-[8px] bg-blue-500 px-[10px]">
                    <span className="text-[16px] font-medium text-[white]">
                      50% 할인
                    </span>
                  </div>
                </div>
                <div className="flex h-[19px] w-full flex-row items-center justify-start text-[14px] text-[#9FB1C1]">
                  * 부가세(VAT) 별도
                </div>
              </div>
              <div className="h-[16px]"></div>
              <div className="h-px w-full bg-[#9FB1C1]"></div>
              <div className="h-[20px]"></div>
              <div className="flex h-[19px] flex-row">
                <img src={starIcon} alt="star icon" className="size-[19px]" />
                <div className="w-[8px]"></div>
                <p className="text-[14px] font-medium text-[#343D4B]">
                  월 주문 건수 1만건 미만
                </p>
              </div>
              <div className="h-[8px]"></div>
              <div className="flex h-[19px] flex-row">
                <img src={starIcon} alt="star icon" className="size-[19px]" />
                <div className="w-[8px]"></div>
                <p className="text-[14px] font-medium text-[#343D4B]">
                  발주 파일 통합 및 분리
                </p>
              </div>
              <div className="h-[8px]"></div>
              <div className="flex h-[19px] flex-row">
                <img src={starIcon} alt="star icon" className="size-[19px]" />
                <div className="w-[8px]"></div>
                <p className="text-[14px] font-medium text-[#343D4B]">
                  상품정보 설정 및 자동 매칭
                </p>
              </div>
              <div className="h-[8px]"></div>
              <div className="flex h-[19px] flex-row">
                <img src={starIcon} alt="star icon" className="size-[19px]" />
                <div className="w-[8px]"></div>
                <p className="text-[14px] font-medium text-[#343D4B]">
                  일일, 기간별, 파트너사별 정산관리
                </p>
              </div>
              <div className="h-[8px]"></div>
              <div className="flex h-[19px] flex-row">
                <img src={starIcon} alt="star icon" className="size-[19px]" />
                <div className="w-[8px]"></div>
                <p className="text-[14px] font-medium text-[#343D4B]">
                  합/분리배송 처리 및 기타 엑셀업무
                </p>
              </div>
            </div>
            <div className="w-[25px]"></div>
            <div
              className={
                selectStandardModal
                  ? 'flex h-full w-[370px] cursor-pointer flex-col rounded-[12px] border border-[#1991FF] p-[24px]'
                  : 'flex h-full w-[370px] cursor-pointer flex-col rounded-[12px] border border-[#E0E7ED] p-[24px]'
              }
              onClick={() => {
                setSelectStandardModal(!selectStandardModal);
                setSelectBasicModal(false);
                setSelectFreeModal(false);
              }}
            >
              <div className="flex flex-row items-start justify-between rounded-[4px] bg-gradient-to-r from-[#0075FF] to-[#1991FF] px-[20px] py-[16px]">
                <div className={'flex flex-col items-start'}>
                  <p className={'text-[24px] font-extrabold text-[#F2F6FA]'}>
                    Standard Plan
                  </p>
                  <p className={'text-[14px] font-semibold text-white'}>
                    스탠다드 플랜
                  </p>
                </div>
                {selectStandardModal && (
                  <div className={'flex flex-col items-end justify-end'}>
                    <img
                      src={whiteCheckIcon}
                      alt="whiteCheck icon"
                      className="size-[31px]"
                    />
                  </div>
                )}
              </div>
              <div className="h-[20px]"></div>
              <div className="flex min-h-[78px] w-full flex-col items-center px-[20px]">
                <div className="flex h-[19px] w-full flex-row items-center justify-start text-[16px] font-semibold text-[#9FB1C1] line-through">
                  ₩399,000/월
                </div>
                <div className="my-[4px] flex min-h-[32px] w-full flex-row items-center justify-between">
                  <div className="flex h-full flex-row items-center justify-start text-[26px] font-bold text-[#343D4B]">
                    ₩199,000/월
                  </div>
                  <div className="flex h-[40px] min-w-[86px] flex-row items-center justify-center rounded-[8px] bg-blue-500 px-[10px]">
                    <span className="text-[16px] font-medium text-[white]">
                      50% 할인
                    </span>
                  </div>
                </div>
                <div className="flex h-[19px] w-full flex-row items-center justify-start text-[14px] text-[#9FB1C1]">
                  * 부가세(VAT) 별도
                </div>
              </div>
              <div className="h-[16px]"></div>
              <div className="h-px w-full bg-[#9FB1C1]"></div>
              <div className="h-[20px]"></div>
              <div className="flex h-[19px] flex-row">
                <img src={starIcon} alt="star icon" className="size-[19px]" />
                <div className="w-[8px]"></div>
                <p className="text-[14px] font-medium text-[#343D4B]">
                  월 주문 건수 1만건 이상
                </p>
              </div>
              <div className="h-[8px]"></div>
              <div className="flex h-[19px] flex-row">
                <img src={starIcon} alt="star icon" className="size-[19px]" />
                <div className="w-[8px]"></div>
                <p className="text-[14px] font-medium text-[#343D4B]">
                  발주 파일 통합 및 분리
                </p>
              </div>
              <div className="h-[8px]"></div>
              <div className="flex h-[19px] flex-row">
                <img src={starIcon} alt="star icon" className="size-[19px]" />
                <div className="w-[8px]"></div>
                <p className="text-[14px] font-medium text-[#343D4B]">
                  상품정보 설정 및 자동 매칭
                </p>
              </div>
              <div className="h-[8px]"></div>
              <div className="flex h-[19px] flex-row">
                <img src={starIcon} alt="star icon" className="size-[19px]" />
                <div className="w-[8px]"></div>
                <p className="text-[14px] font-medium text-[#343D4B]">
                  일일, 기간별, 파트너사별 정산관리
                </p>
              </div>
              <div className="h-[8px]"></div>
              <div className="flex h-[19px] flex-row">
                <img src={starIcon} alt="star icon" className="size-[19px]" />
                <div className="w-[8px]"></div>
                <p className="text-[14px] font-medium text-[#343D4B]">
                  합/분리배송 처리 및 기타 엑셀업무
                </p>
              </div>
              <div className="h-[8px]"></div>
              <div className="flex h-[19px] flex-row">
                <img src={starIcon} alt="star icon" className="size-[19px]" />
                <div className="w-[8px]"></div>
                <p className="text-[14px] font-medium text-[#1991FF]">
                  오픈마켓, 폐쇄몰 주문통합
                </p>
              </div>
              <div className="h-[8px]"></div>
              <div className="flex h-[19px] flex-row">
                <img src={starIcon} alt="star icon" className="size-[19px]" />
                <div className="w-[8px]"></div>
                <p className="text-[14px] font-medium text-[#1991FF]">
                  발주 메일 송수신 자동화 (예정)
                </p>
              </div>
              <div className="h-[8px]"></div>
              <div className="flex h-[19px] flex-row">
                <img src={starIcon} alt="star icon" className="size-[19px]" />
                <div className="w-[8px]"></div>
                <p className="text-[14px] font-medium text-[#1991FF]">
                  세금계산서 발행 자동화 (예정)
                </p>
              </div>
            </div>
          </div>

          <div className="mt-[40px] w-full">
            <div className="flex items-center justify-between">
              <div className="whitespace-nowrap text-[24px] font-extrabold text-[#343D4B]">
                결제수단
              </div>
              <div className="flex h-[45px] w-full items-center justify-end">
                {billing.billingKey ? (
                  <Button
                    name="결제 수단 변경"
                    onClick={() => loadTossPayments()}
                    color="black"
                    innerComponent={<CreditCardButton name="결제 수단 변경" />}
                  />
                ) : (
                  <Button
                    name="카드 등록하기"
                    onClick={() => loadTossPayments()}
                    innerComponent={<CreditCardButton name="카드 등록하기" />}
                  />
                )}
              </div>
            </div>

            {billing.billingKey && (
              <>
                <div className="flex h-[187px] w-full py-[32px]">
                  <div className="flex size-full flex-row items-center rounded-[8px] border border-[#CED7E0] px-[24px] py-[20px]">
                    <img
                      src={creditCardIcon}
                      alt="card icon icon"
                      className="h-[75px] w-[90px]"
                    />
                    <div className="w-[40px]"></div>
                    <div className="flex size-full flex-col items-center">
                      <div className="flex h-[21px] w-full flex-row">
                        <div className="flex w-1/2 flex-row">
                          <p className="w-[60px] text-[16px] font-medium text-[#708AA2]">
                            청구 기간
                          </p>
                          <div className="w-[12px]"></div>
                          {billing.lastPayDate && (
                            <p className="text-[16px] font-bold text-[#343D4B]">
                              {billing.lastPayDate.slice(0, 4)}년{' '}
                              {billing.lastPayDate.slice(5, 7)}월{' '}
                              {billing.lastPayDate.slice(8, 10)}일 -{' '}
                              {billing.nextPayDate.slice(0, 4)}년{' '}
                              {billing.nextPayDate.slice(5, 7)}월{' '}
                              {billing.nextPayDate.slice(8, 10)}일
                            </p>
                          )}
                          {!billing.lastPayDate && (
                            <p className="text-[16px] font-bold text-[#343D4B]">
                              결제 내역 없음
                            </p>
                          )}
                        </div>
                        <div className="w-[20px]"></div>
                        <div className="flex flex-row">
                          <p className="w-[105px] text-[16px] font-medium text-[#708AA2]">
                            다음 결제 예정일
                          </p>
                          <div className="w-[12px]"></div>
                          <p className="text-[16px] font-bold text-[#343D4B]">
                            {billing.nextPayDate.slice(0, 4)}년{' '}
                            {billing.nextPayDate.slice(5, 7)}월{' '}
                            {billing.nextPayDate.slice(8, 10)}일
                          </p>
                        </div>
                      </div>
                      <div className="h-[20px] w-full"></div>
                      <div className="h-px w-full bg-[#E0E7ED]"></div>
                      <div className="h-[20px] w-full"></div>
                      <div className="flex h-[21px] w-full flex-row">
                        <div className="flex w-1/2 flex-row">
                          <p className="text-[16px] font-medium text-[#708AA2]">
                            결제 예정 금액
                          </p>
                          <div className="w-[12px]"></div>
                          <p className="text-[16px] font-bold text-[#343D4B]">
                            {price
                              ? `${Number(price).toLocaleString('ko-KR')}원`
                              : '플랜 미선택'}
                          </p>
                        </div>
                        <div className="w-[20px]"></div>
                        <div className="flex flex-row">
                          <p className="w-[60px] text-[16px] font-medium text-[#708AA2]">
                            결제 수단
                          </p>
                          <div className="w-[12px]"></div>
                          <p className="text-[16px] font-bold text-[#343D4B]">
                            {billing.cardCompany + ' ' + billing.cardNumber}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {!billing?.billingKey && (
              <>
                <div className="flex h-[125px] w-full py-[32px]">
                  <div className="flex size-full items-center rounded-[8px] border border-[#CED7E0] px-[24px] py-[20px]">
                    <p className="text-[16px] text-[#708AA2]">
                      등록된 결제 수단이 없습니다. 결제 수단을 등록해주세요.
                    </p>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="w-full py-[40px]">
            <div className="h-px bg-[#E0E7ED]"></div>
          </div>
        </div>

        <div
          className="fixed bottom-0 left-[280px] flex h-[85px] items-center justify-center bg-white"
          style={{
            width: 'calc(100% - 280px)',
            boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.12)',
          }}
        >
          <Button
            name={plan ? '플랜 변경하기' : '구독하기'}
            className="flex h-[45px] w-[200px] cursor-pointer items-center justify-center rounded-[8px] bg-[#1991FF]"
            onClick={() =>
              billing.billingKey
                ? selectStandardModal
                  ? plan !== 'standard'
                    ? modifyBillingInfo('standard')
                    : alert('이미 해당 플랜을 사용중입니다.')
                  : selectBasicModal
                    ? plan !== 'basic'
                      ? modifyBillingInfo('basic')
                      : alert('이미 해당 플랜을 사용중입니다.')
                    : alert('변경할 플랜을 선택해주세요.')
                : selectStandardModal
                  ? loadTossPayments('standard')
                  : selectBasicModal
                    ? loadTossPayments('basic')
                    : alert('변경할 플랜을 선택해주세요.')
            }
          />
        </div>
      </>
    )
  );
});

export default PaymentInfoScreen;
