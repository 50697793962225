import { Token } from '../interface';

export function parseBoolean(token: Token): boolean | null {
  if (typeof token === 'boolean') {
    return token;
  }

  if (typeof token === 'number') {
    return token !== 0;
  }

  if (token === '') {
    return false;
  }

  if (token.toLowerCase() === 'true') {
    return true;
  }

  if (token.toLowerCase() === 'false') {
    return false;
  }

  return null;
}
