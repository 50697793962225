import { RefObject, useCallback, useEffect } from 'react';

interface Options {
  ref: RefObject<HTMLInputElement | HTMLTextAreaElement | null>;
  disabled?: boolean;
}

export function useAutoSelect({ ref, disabled = false }: Options) {
  const handleFocus = useCallback(() => {
    ref.current?.select();
  }, [ref]);

  const handleMouseDown = useCallback(
    (event: Event) => {
      const isAlreadyFocused = document.activeElement === event.currentTarget;
      if (isAlreadyFocused) {
        return;
      }

      event.preventDefault();
      ref.current?.select();
    },
    [ref]
  );

  useEffect(() => {
    const input = ref.current;
    if (input == null || disabled) {
      return;
    }

    input.addEventListener('focus', handleFocus);
    input.addEventListener('mousedown', handleMouseDown);

    return () => {
      input.removeEventListener('focus', handleFocus);
      input.removeEventListener('mousedown', handleMouseDown);
    };
  }, [disabled, handleFocus, handleMouseDown, ref]);
}
