import { getLogoMallBrand } from './getLogoMallBrand';
import { LogoMall, LogoMallProps } from './LogoMall';

interface LogoMallNameProps extends Omit<LogoMallProps, 'brand'> {
  name: string;
}

export function LogoMallByName({ name, ...rest }: LogoMallNameProps) {
  return <LogoMall brand={getLogoMallBrand(name)} {...rest} />;
}
