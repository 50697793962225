import { Order } from '@sweep/contract';
import { isNumeric } from 'utils/utils';
import { AbstractPlugin } from '../../interface';

export class RemoveSubtotalPlugin extends AbstractPlugin {
  transform = (orders: Order[]): Promise<Order[]> => {
    return Promise.resolve(orders.filter((order) => !this.isSubtotal(order)));
  };

  isSubtotal(order: Order) {
    return this.isOnlyNumeric(order) || this.isSpecificSubtotal(order);
  }

  isOnlyNumeric(order: Order) {
    return Object.values(order).every((value) => {
      if (typeof value === 'string' || typeof value === 'number') {
        return isNumeric(value);
      }
      return true;
    });
  }

  // NOTE(@이지원): 더웰상사 - 현대그린푸드, 삼성웰스토리의 경우
  // TODO(@이지원): 넓은 범위를 포함할 수 있도록 수정
  isSpecificSubtotal(order: Order) {
    const keys = Object.keys(order);
    const is현대그린푸드 = keys.includes('사업장') && keys.includes('사업장명');
    if (is현대그린푸드) {
      return order['사업장'] === '소계' || order['사업장'] === '총계';
    }

    const is삼성웰스토리 = keys.includes('date') && keys.includes('구분');
    if (is삼성웰스토리) {
      return order['date'] === '소계' || order['date'] === '합계';
    }

    return false;
  }
}
