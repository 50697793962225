export const OrderStatus = {
  // 결제 완료 (PaymentComplete)
  paymentComplete: '결제 완료',
  paymentCompletePartialDelivery: '결제 완료(일부 배송 완료)',

  // 상품 중비 중 (ProductPreparing)
  productPreparing: '상품 준비 중',
  productPreparingPartialDelivery: '상품 준비 중(일부 배송 완료)',
  partialDelivery: '일부 배송 완료',

  // 배송 중 (InDelivery)
  inDelivery: '배송 중',

  // 취소 요청(CancelRequest)
  cancelRequest: '취소 요청',
  cancelRequestPaymentComplete: '취소 요청(결제 완료)',

  // 기타 (Etc)
  exchange: '교환',
  exchangeRequest: '교환 요청',
  exchangeInDelivery: '교환 반송 중',
  exchangeRedelivery: '교환 재배송 중',
  exchangeHold: '교환 보류',

  refundRequest: '환불 요청',
  refundExpected: '환불 예정',
  refundRequestWaiting: '환불 요청 대기',

  returnRequest: '반품 요청',
  returnInDelivery: '반품 반송 중',
  riskControl: 'RISK_CONTROL',

  // 안 보여줘야 함 (Hidden)
  collectionProcessing: '수거 처리 중',
  purchaseConfirmation: '구매 확정',
  teamRecruitment: '팀 모집 중',
  paymentWaiting: '결제 대기 중',
  unpaidCancel: '미결제 취소',
  complete: '완료',
  refundComplete: '환불 완료',
  deliveryComplete: '배송 완료',
  cancelComplete: '취소 완료',
  returnComplete: '반품 완료',
  returnPaymentCancelComplete: '반품 결제 취소 완료',
  returnRefundComplete: '반품 환불 완료',
  returnSendComplete: '반품 반송 완료',

  // 추가된 상태들
  returnApprove: '반품 승인',
  returnProcessing: '반품 처리 중',
  returnReject: '반품 반려',
  returnHold: '반품 보류',
  returnWithdraw: '반품 철회',

  cancelApprove: '취소 승인',
  cancelProcessing: '취소 처리 중',
  cancelReject: '취소 반려',
  cancelHold: '취소 보류',
  cancelWithdraw: '취소 철회',

  redeliveryRequest: '재배송 요청',
  redeliveryApprove: '재배송 승인',
  redeliveryProcessing: '재배송 처리 중',
  redeliveryComplete: '재배송 완료',
  redeliveryReject: '재배송 반려',
  redeliveryHold: '재배송 보류',
  redeliveryWithdraw: '재배송 철회',
} as const;

export type OrderStatus = (typeof OrderStatus)[keyof typeof OrderStatus];

export function isHiddenStatus(status: OrderStatus) {
  const hiddenStatusList: OrderStatus[] = [
    OrderStatus.collectionProcessing,
    OrderStatus.purchaseConfirmation,
    OrderStatus.teamRecruitment,
    OrderStatus.paymentWaiting,
    OrderStatus.unpaidCancel,
    OrderStatus.complete,
    OrderStatus.refundComplete,
    OrderStatus.deliveryComplete,
    OrderStatus.cancelComplete,
    OrderStatus.returnComplete,
    OrderStatus.returnPaymentCancelComplete,
    OrderStatus.returnRefundComplete,
    OrderStatus.returnSendComplete,
    //TODO (@경한) 카카오메이커스 용으로 HiddenStatus 추가, 지원님과 확인 필요
    OrderStatus.returnApprove,
    OrderStatus.returnProcessing,
    OrderStatus.returnReject,
    OrderStatus.returnHold,
    OrderStatus.returnWithdraw,
    OrderStatus.cancelApprove,
    OrderStatus.cancelProcessing,
    OrderStatus.cancelReject,
    OrderStatus.cancelHold,
    OrderStatus.cancelWithdraw,
    OrderStatus.redeliveryRequest,
    OrderStatus.redeliveryApprove,
    OrderStatus.redeliveryProcessing,
    OrderStatus.redeliveryComplete,
    OrderStatus.redeliveryReject,
    OrderStatus.redeliveryHold,
    OrderStatus.redeliveryWithdraw,
  ];

  return hiddenStatusList.includes(status);
}
