import { DispatchedOrder, OrderStatus } from '@sweep/contract';
import {
  acceptCanceledOrdes,
  rejectCanceledOrder,
} from 'src/network/order/cancelOrder';
import { confirmAndUpdateOrder } from 'src/network/order/confirmOrder';
import { OMSStore } from 'src/stores/OMSStore';
import { formatDate } from 'src/utils/date/formatDate';
import { isNotNil } from 'src/utils/function';
import {
  CANCEL_REQUESTED_ORDER_COLUMN_MAPPING,
  CANCEL_REQUESTED_ORDER_HEADER,
} from '../constants';
import {
  isPaymentComplete,
  isProductPreparing,
} from '../services/filterOrderStatus';
import { openAcceptCanceledOrderDialog } from '../services/openAcceptCanceledOrderDialog';
import { openConfirmOrderDialog } from '../services/openConfirmOrderDialog';
import { openDispatchDialog } from '../services/openDispatchDialog';
import { DispatchModalStore } from './DispatchModalStore';

type CreateOrderExcel = (
  orders: DispatchedOrder[],
  fileName: string,
  headers: string[],
  columnMapping: Record<string, string>,
  customizedSettings: any
) => Promise<void>;

export class OrderDispatchScreenStore {
  constructor(
    private oms: OMSStore,
    private createOrderExcel: CreateOrderExcel
  ) {}

  dispatchOrder = async (shoppingMalls: string[]) => {
    if (shoppingMalls.length === 0) {
      return null;
    }

    const store = new DispatchModalStore(
      this.oms.order.dispatch,
      shoppingMalls
    );
    const close = openDispatchDialog(store);

    const orders = await store.getDispatchedOrders(shoppingMalls);
    this.oms.order.dispatch.setDispatchedOrders(orders);

    if (store.isSuccessful) {
      close();
    }

    return orders;
  };

  confirmSelectedOrders = async (orders: DispatchedOrder[]) => {
    if (orders.length === 0) {
      alert('주문 확인 처리할 주문을 선택해주세요.');
      return false;
    }

    const isConfirmed = await openConfirmOrderDialog(orders.length);
    if (!isConfirmed) {
      return false;
    }

    return this.oms.loading.batch(async () => {
      const response = await confirmAndUpdateOrder(orders);
      if (response?.success !== true) {
        alert('주문 확인에 실패했습니다. 잠시 후 다시 시도해주세요.');
        return false;
      }

      const successedOrders = response.result.filter((order) => order.success);
      const failedOrders = response.result.filter((order) => !order.success);

      if (successedOrders.length > 0) {
        const transformedOrders =
          await this.oms.order.dispatch.transformOrders(successedOrders);
        this.oms.order.dispatch.pushDispatchedOrders(transformedOrders);
      }

      if (failedOrders.length > 0) {
        alert(
          '주문 확인에 실패한 건들이 있어요. 해당 실패 건의 사유를 확인해주세요.'
        );
        const formattedDate = formatDate(new Date(), 'yy.MM.dd');
        const combinedExcelSetting = this.oms.user.setting ?? {
          columnOrder: [],
          columnTranslation: {},
        };

        await this.createOrderExcel(
          failedOrders,
          `${formattedDate} 주문 확인 실패 건`,
          ['failReason', ...combinedExcelSetting.columnOrder],
          {
            failReason: '실패 사유',
            ...combinedExcelSetting.columnTranslation,
          },
          { returnNullIfNotInColumnMapping: false }
        );
      } else {
        alert('주문 확인이 완료되었습니다.');
      }

      return true;
    });
  };

  acceptCanceledSelectedOrders = async (
    orders: DispatchedOrder[]
  ): Promise<boolean> => {
    if (orders.length === 0) {
      alert('승인할 취소 건을 선택해주세요.');
      return false;
    }

    const isConfirmed = await openAcceptCanceledOrderDialog(orders.length);
    if (!isConfirmed) {
      return false;
    }

    return this.oms.loading.batch(async () => {
      const response = await acceptCanceledOrdes(orders);
      if (response?.success !== true) {
        alert('취소 승인에 실패했습니다. 잠시 후 다시 시도해주세요.');
        return false;
      }

      const ordersByUniqueCode = new Map(
        orders.map((order) => [order.uniqueCode, order])
      );

      const successedUniqueCodes = response.data
        .filter((order) => order.success)
        .map((order) => order.uniqueCode);

      if (successedUniqueCodes.length > 0) {
        this.oms.order.dispatch.removeDispatchedOrdersByUniqueCodes(
          successedUniqueCodes
        );
      }

      const failedOrders = response.data
        .filter((order) => !order.success)
        .map<DispatchedOrder | null>((order) => {
          const prevOrder = ordersByUniqueCode.get(order.uniqueCode);
          return prevOrder == null ? null : { ...prevOrder, ...order };
        })
        .filter(isNotNil);

      if (failedOrders.length === 0) {
        alert('취소 승인이 완료되었습니다.');
      }

      if (failedOrders.length > 0) {
        alert(
          '취소 승인에 실패한 건들이 있어요. 해당 실패 건의 사유를 확인해주세요.'
        );
        const formattedDate = formatDate(new Date(), 'yy.MM.dd');
        const combinedExcelSetting = this.oms.user.setting ?? {
          columnOrder: [],
          columnTranslation: {},
        };

        await this.createOrderExcel(
          failedOrders,
          `${formattedDate} 취소 승인 실패 건`,
          ['failReason', ...combinedExcelSetting.columnOrder],
          {
            failReason: '실패 사유',
            ...combinedExcelSetting.columnTranslation,
          },
          { returnNullIfNotInColumnMapping: false }
        );
      }

      return true;
    });
  };

  rejectCanceledSelectedOrders = async (
    rejectReason: string,
    orders: DispatchedOrder[]
  ): Promise<boolean> => {
    return this.oms.loading.batch(async () => {
      const response = await rejectCanceledOrder(orders, rejectReason);
      if (response?.success !== true) {
        alert('취소 거부에 실패했습니다. 잠시 후 다시 시도해주세요.');
        return false;
      }

      const ordersByUniqueCode = new Map(
        orders.map((order) => [order.uniqueCode, order])
      );

      const successedOrders = response.data
        .filter((order) => order.success)
        .map<DispatchedOrder | null>((order) => {
          const prevOrder = ordersByUniqueCode.get(order.uniqueCode);
          return prevOrder == null
            ? null
            : {
                ...prevOrder,
                orderStatus: OrderStatus.inDelivery,
                shippingCompany: order.shippingCompany,
                shippingNumber: order.shippingNumber,
              };
        })
        .filter(isNotNil);
      const failedOrders = response.data
        .filter((order) => !order.success)
        .map<DispatchedOrder | null>((order) => {
          const prevOrder = ordersByUniqueCode.get(order.uniqueCode);
          return prevOrder == null ? null : { ...prevOrder, ...order };
        })
        .filter(isNotNil);

      if (failedOrders.length > 0) {
        alert(
          '취소 거부에 실패한 건들이 있어요. 해당 실패 건의 사유를 확인해주세요.'
        );

        await this.createOrderExcel(
          failedOrders,
          `${formatDate(new Date(), 'yy.mm.dd')} 취소 거부 실패 건`,
          ['failReason', ...CANCEL_REQUESTED_ORDER_HEADER],
          {
            failReason: '실패 사유',
            ...CANCEL_REQUESTED_ORDER_COLUMN_MAPPING,
          },
          { returnNullIfNotInColumnMapping: false }
        );
      } else {
        alert('취소 거부가 완료되었습니다.');
      }

      if (successedOrders.length > 0) {
        this.oms.order.dispatch.pushDispatchedOrders(successedOrders);

        // NOTE(@이지원): 11번가 한정 로직. 한 장바구니에 담겨서 여러 주문이 한 번에 들어온 경우가 있음.
        // 해당 주문 중 하나를 "취소거부" 하면, 다른 주문들("결제 완료", "상품 준비 중"에 있는 주문들)도 "배송 중"으로 변경됨.
        const elevenOrdersByNumber = new Map(
          successedOrders
            .filter((order) => order.uniqueCode.startsWith('ELV'))
            .map((order) => [order.orderNumber, order])
        );

        if (elevenOrdersByNumber.values.length > 0) {
          const elevenOrders = this.oms.order.dispatch.dispatchedOrders
            .map<DispatchedOrder | null>((order) => {
              const rejectedOrder = elevenOrdersByNumber.get(order.orderNumber);
              if (rejectedOrder == null) {
                return null;
              }

              const isNotPaymentComplete = !isPaymentComplete(
                order.orderStatus
              );
              const isNotProductPreparing = !isProductPreparing(
                order.orderStatus
              );
              if (isNotPaymentComplete || isNotProductPreparing) {
                return null;
              }

              return {
                ...order,
                orderStatus: OrderStatus.inDelivery,
                shippingCompany: rejectedOrder.shippingCompany,
                shippingNumber: rejectedOrder.shippingNumber,
              };
            })
            .filter(isNotNil);

          if (elevenOrders.length > 0) {
            this.oms.order.dispatch.pushDispatchedOrders(elevenOrders);
          }
        }
      }

      return true;
    });
  };

  mergeDispatchedOrders = async (orders: DispatchedOrder[]) => {
    if (orders.length === 0) {
      alert('통합 엑셀에 추가할 주문 건을 선택해주세요.');
      return;
    }

    const mergedUniqueCodesSet = new Set(
      this.oms.order.mergedOrders.map((order) => order.uniqueCode)
    );
    const normalizedUniqueCodesSet = new Set(
      this.oms.order.normalizedOrders.map((order) => order.uniqueCode)
    );

    const filteredOrders = orders.filter(
      (order) =>
        !mergedUniqueCodesSet.has(order.uniqueCode) &&
        !normalizedUniqueCodesSet.has(order.uniqueCode)
    );

    if (filteredOrders.length === 0) {
      alert('이미 통합 엑셀에 추가된 주문 건입니다.');
      return;
    }

    const filename = this.getOrderDispatchFilename();
    const ordersWithFilename = filteredOrders.map((order) => ({
      ...order,
      originFile: filename,
    }));
    await this.oms.loading.batch(() =>
      this.oms.order.mergeDispatchedOrders(ordersWithFilename)
    );

    alert(
      `${filteredOrders.length}건의 주문을 통합 엑셀에 추가하였습니다. (중복 건 제외)`
    );
    return;
  };

  getOrderDispatchFilename = () => {
    const filename = `주문수집_${formatDate(new Date(), 'yyyy년 M월 d일')}`;
    let count = 0;

    const fileNames = new Set(
      this.oms.order.normalizedOrders.map((order) => order.originFile)
    );

    while (fileNames.has(`${filename}${count ? `(${count})` : ''}`)) {
      count++;
    }

    return `${filename}${count ? `(${count})` : ''}`;
  };
}
