import { HTMLAttributes, ReactNode } from 'react';
import { cva } from 'src/third-parties/tailwind';

export interface DialogDescriptionProps
  extends HTMLAttributes<HTMLParagraphElement> {
  children: ReactNode;
}

export function DialogDescription({
  children,
  className,
  ...rest
}: DialogDescriptionProps) {
  return (
    <p className={text({ className })} {...rest}>
      {children}
    </p>
  );
}

const text = cva('text-medium-s px-[8px] text-gray-600');
