import LoadingIndicator from 'components/LoadingIndicator';
import { useOMSStore } from 'hooks/useOMSStore';
import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';

function LoadingProvider({ children }: { children: ReactNode }) {
  const oms = useOMSStore();

  return (
    <>
      {children}
      {oms.loading.isLoading && <LoadingIndicator />}
    </>
  );
}

export default observer(LoadingProvider);
