import { CMStore } from './CMStore';
import { LoadingStore } from './loading/NewLoadingStore';
import { UserStore } from './NewUserStore';
import { NewOrderStore } from './order/NewOrderStore';
import { PartnerStore } from './PartnerStore';
import { PlguinStore } from './plugin/PluginStore';
import { ProductStore } from './ProductStore';
import { SupplierStore } from './SupplierStore';

export class OMSStore {
  stores: Record<string, any> = {};

  user: UserStore;
  partner: PartnerStore;
  supplier: SupplierStore;
  order: NewOrderStore;
  product: ProductStore;
  plugin: PlguinStore;
  cm: CMStore;
  loading: LoadingStore;

  constructor() {
    this.loading = new LoadingStore();

    this.plugin = new PlguinStore();
    this.user = new UserStore(this);
    this.partner = new PartnerStore(this);
    this.supplier = new SupplierStore(this);
    this.order = new NewOrderStore(this);
    this.product = new ProductStore(this);
    this.cm = new CMStore(this);
  }

  async init() {
    await Promise.all([
      this.user.init(),
      this.partner.init(),
      this.supplier.init(),
      this.product.init(),
      this.order.init(),
      this.plugin.init(),
      this.cm.init(),
    ]);
  }

  getStore<T>(storeName: string, store: new (omsStore: OMSStore) => T): T {
    if (!this.stores[storeName]) {
      this.stores[storeName] = new store(this);
    }

    return this.stores[storeName];
  }
}
