import { Order } from '@sweep/contract';
import { AbstractPlugin } from '../../interface';

export class RemoveEmptyPlugin extends AbstractPlugin {
  transform = async (orders: Order[]): Promise<Order[]> => {
    return Promise.resolve(orders.filter((order) => !this.isEmpty(order)));
  };

  isEmpty(order: Order) {
    const isProductNameEmpty =
      order.productName == null || order.productName === '';

    // TODO(@이지원): columnMapping에서 concat삭제하면 concatEmpty 부분 없애기
    const isLiteralOptionEmpty = order.option == null || order.option === '';
    const isConcatOptionEmpty =
      order['optionConcat1'] == null || order['optionConcat1'] === '';
    const isOptionEmpty = isLiteralOptionEmpty && isConcatOptionEmpty;

    const isLiternalNameEmpty = order.name == null || order.name === '';
    const isConcatNameEmpty =
      order['nameConcat1'] == null || order['nameConcat1'] === '';
    const isNameEmpty = isLiternalNameEmpty && isConcatNameEmpty;

    const isLiteralContactNumberEmpty =
      order.contactNumber == null || order.contactNumber === '';
    const isConcatContactNumberEmpty =
      order['contactNumberConcat1'] == null ||
      order['contactNumberConcat1'] === '';
    const isContacNumberEmpty =
      isLiteralContactNumberEmpty && isConcatContactNumberEmpty;

    return (
      isProductNameEmpty && isOptionEmpty && isNameEmpty && isContacNumberEmpty
    );
  }
}
