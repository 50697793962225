import { offset, size, useDismiss, useFloating } from '@floating-ui/react';

interface SearchSelectFloatingOptions {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export function useSearchSelectFloating({
  open,
  onOpenChange,
}: SearchSelectFloatingOptions) {
  const { refs, floatingStyles, context } = useFloating({
    placement: 'bottom',
    open,
    onOpenChange,
    middleware: [
      offset(6),
      size({
        apply({ availableHeight, rects, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
            maxHeight: `${Math.max(0, availableHeight)}px`,
          });
        },
      }),
    ],
  });

  useDismiss(context, {
    ancestorScroll: true,
    outsidePress: true,
    escapeKey: false,
  });

  return { refs, floatingStyles };
}
