import { makeAutoObservable } from 'mobx';

export class LoadingStore {
  isLoading = false;
  batchCount = 0;

  constructor() {
    makeAutoObservable(this);
  }

  async batch<T>(fn: () => Promise<T>): Promise<T> {
    if (this.batchCount === 0) {
      this.start();
    }
    this.batchCount++;

    const result = await fn();

    this.batchCount--;
    if (this.batchCount === 0) {
      this.end();
    }

    return result;
  }

  start() {
    this.isLoading = true;
  }

  end() {
    this.isLoading = false;
  }
}
