import {
  CreateCompositionMatching,
  CreateMatching,
} from 'models/CompositionMatching';

export function transformToCreateCompositionMatching(
  createMatching: CreateMatching
): CreateCompositionMatching {
  return {
    productName: createMatching.productName,
    option: createMatching.option,
    data: createMatching.products,
  };
}
