import { DispatchedOrder, isDispatchedOrder } from '@sweep/contract';
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import { uniqueByUniqueCode } from 'services/order/uniqueByUniqueCode';
import { OMSStore } from 'stores/OMSStore';
import { getShoppingMallNames } from 'src/network/order/dispatchOrder';
import { isNotNil } from 'src/utils/function';
import { PluginExecutionService } from '../plugin/PluginExecutionService';

export class DispatchOrderStore {
  shoppingMallNames: string[] = [];
  selectedShoppingMallNames: string[] = [];

  dispatchedOrders: DispatchedOrder[] = [];
  lastDispatchedAts: Record<string, number | undefined> = {};
  rejectedShoppingMallNames: string[] = [];

  get lastestDispatchedAt() {
    const lastDispatchedAts = Object.values(this.lastDispatchedAts).filter(
      isNotNil
    );

    if (lastDispatchedAts.length === 0) {
      return null;
    }

    return Math.max(...lastDispatchedAts);
  }

  constructor(private oms: OMSStore) {
    makeObservable(this, {
      shoppingMallNames: observable,
      selectedShoppingMallNames: observable,

      dispatchedOrders: observable,
      lastDispatchedAts: observable,
      rejectedShoppingMallNames: observable,

      lastestDispatchedAt: computed,

      init: action.bound,
      loadShoppingMallNames: action.bound,
      setSelectedShoppingMallNames: action.bound,
      pushRejectedShoppingMallName: action.bound,
      removeRejectedShoppingMallName: action.bound,
      updateLastDispatchedAt: action.bound,
      setDispatchedOrders: action.bound,
      pushDispatchedOrders: action.bound,
      removeDispatchedOrdersByUniqueCodes: action.bound,
    });
  }

  async init() {
    await this.loadShoppingMallNames();
  }

  async loadShoppingMallNames() {
    const response = await getShoppingMallNames();
    if (response.message === 'Failed') {
      return;
    }

    runInAction(() => {
      this.shoppingMallNames = response.result;
      this.selectedShoppingMallNames = response.result;
    });
  }

  pushRejectedShoppingMallName(shoppingMallName: string) {
    if (this.rejectedShoppingMallNames.includes(shoppingMallName)) {
      return;
    }

    this.rejectedShoppingMallNames = [
      ...this.rejectedShoppingMallNames,
      shoppingMallName,
    ];
  }

  removeRejectedShoppingMallName(shoppingMallNames: string[]) {
    const nameSet = new Set(this.rejectedShoppingMallNames);
    shoppingMallNames.forEach((name) => {
      nameSet.delete(name);
    });
    this.rejectedShoppingMallNames = Array.from(nameSet);
  }

  async updateLastDispatchedAt(shoppingMallName: string, date: number) {
    this.lastDispatchedAts = {
      ...this.lastDispatchedAts,
      [shoppingMallName]: date,
    };
  }

  setSelectedShoppingMallNames(names: string[]) {
    this.selectedShoppingMallNames = names;
  }

  setDispatchedOrders(orders: DispatchedOrder[]) {
    this.dispatchedOrders = orders;
  }

  pushDispatchedOrders(orders: DispatchedOrder[]) {
    this.dispatchedOrders = uniqueByUniqueCode([
      ...orders,
      ...this.dispatchedOrders,
    ]);
  }

  removeDispatchedOrdersByUniqueCodes(uniqueCodes: string[]) {
    const uniqueCodeSet = new Set(uniqueCodes);
    const filteredOrders = this.dispatchedOrders.filter(
      (order) => !uniqueCodeSet.has(order.uniqueCode)
    );
    this.dispatchedOrders = filteredOrders;
  }

  async transformOrders(orders: DispatchedOrder[]): Promise<DispatchedOrder[]> {
    const plugins = this.oms.plugin.getPlugins(this.oms.user.dispatchPlugins);
    const executionService = new PluginExecutionService(
      this.oms,
      plugins,
      'dispatch'
    );

    const transformedOrders = await executionService.execute(orders);
    const dispatchedOrders = transformedOrders.filter(isDispatchedOrder);
    const uniquedOrders = uniqueByUniqueCode(dispatchedOrders);

    return uniquedOrders;
  }
}
