import { useEffect, useRef } from 'react';
import { EventListener } from '../../dom';
import { noop } from '../../function';

export function useEventTarget<TEvent extends Event>(
  target: EventTarget,
  type: TEvent['type'],
  listener: EventListener<TEvent> = noop,
  options?: EventListenerOptions | boolean
) {
  const listenerRef = useRef(listener);
  const capture = typeof options === 'object' ? options.capture : options;

  if (listenerRef.current !== listener) {
    listenerRef.current = listener;
  }

  useEffect(() => {
    const handler = (event: Event) => {
      listenerRef.current?.(event as TEvent);
    };

    target.addEventListener(type, handler, capture);

    return () => {
      target.removeEventListener(type, handler, capture);
    };
  }, [target, type, capture]);
}

export function useWindowEvent<K extends keyof WindowEventMap>(
  type: K,
  listener?: EventListener<WindowEventMap[K]>,
  options?: EventListenerOptions | boolean
) {
  useEventTarget(window, type, listener, options);
}
