import axios from 'axios';
import configs from './configs';

const BACKEND_URL = configs.backendUrl;

const axiosInstance = () => {
  const token = localStorage.getItem('LocalStorageValue#User#authToken');

  const instance = axios.create({
    baseURL: BACKEND_URL,
    headers: token ? { Authorization: token } : {},
  });

  instance.interceptors.request.use(
    (config) => {
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return instance;
};

export default axiosInstance;
