import { isNotEmptyString } from 'src/utils/string';
import { CMOrder } from '../interface';

export function uniqueByOptionCode<T>(
  items: T[],
  getCMOrder: (item: T) => CMOrder
): T[] {
  const uniqueItems: T[] = [];
  const optionCodes: Set<string> = new Set();

  items.forEach((item) => {
    const cmOrder = getCMOrder(item);
    const optionCode = cmOrder.optionCode;

    if (!isNotEmptyString(optionCode)) {
      uniqueItems.push(item);
      return;
    }

    if (!optionCodes.has(optionCode)) {
      optionCodes.add(optionCode);
      uniqueItems.push(item);
    }
  });

  return uniqueItems;
}
