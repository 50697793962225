import { useCallback, useState } from 'react';

export function useUncontrollableState<
  T extends string | number | boolean,
>(params: {
  value?: T | null;
  defaultValue?: T | null;
  onChange?: (value: T) => void;
}) {
  const { value, defaultValue, onChange } = params;
  const [controlled] = useState(value !== undefined);
  const [uncontrolledState, setUncontrolledState] = useState(
    value ?? defaultValue
  );

  const controlledValue = controlled ? value : uncontrolledState;

  const handleChange = useCallback(
    (value: T | ((prev?: T | null) => T)) => {
      const newValue =
        typeof value === 'function' ? value(controlledValue) : value;

      onChange?.(newValue);

      if (!controlled) {
        setUncontrolledState(newValue);
      }
    },
    [controlled, controlledValue, onChange]
  );

  return [controlledValue, handleChange] as const;
}
