import { range } from '@sweep/utils/array';

export const TW_EXTEND_SPACING = [
  ...range(1, 15),
  ...range(16, 1023, 2),
  ...range(1024, 1600, 4),
].reduce<Record<string, string>>((acc, i) => {
  acc[`${i}px`] = `${i}px`;
  return acc;
}, {});
