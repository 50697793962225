import { useOMSStore } from 'hooks/useOMSStore';
import { isValid } from '../../utils/utils';
import useCombineOrder from './interpretOrder/useCombineOrder';
import usePreprocessOrder from './interpretOrder/usePreprocessOrder';

function useInterpretOrder() {
  const { preprocessOrders } = usePreprocessOrder();
  const oms = useOMSStore();

  const interpretOrder = async (origOrders) => {
    if (!isValid(oms.product.products)) {
      console.warn('No product info)');
    }
    const preprocessedOrders = await preprocessOrders(origOrders);

    const compositionMatchedOrders =
      await oms.order._normalize.compositionMatching(preprocessedOrders);
    return compositionMatchedOrders;
  };

  const { combineOrder3, hightlightCombinedOrders, separateOrders } =
    useCombineOrder();

  return {
    combineOrder3,
    hightlightCombinedOrders,
    separateOrders,
    interpretOrder,
  };
}

export default useInterpretOrder;
