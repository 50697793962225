import { useCallback } from 'react';
import { SearchSelectOption } from '../SearchSelect';

export function useSearchSelectLabel(options: SearchSelectOption[]) {
  const getSearchLabel = useCallback(
    (value: string) =>
      value !== ''
        ? options.find((option) => option.value === value)?.label
        : '',
    [options]
  );

  const getLabel = useCallback(
    (value: string) => options.find((option) => option.value === value)?.label,
    [options]
  );

  return { getSearchLabel, getLabel };
}
