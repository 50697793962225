import { observer } from 'mobx-react-lite';
import { DispatchModalStore } from '../../store/DispatchModalStore';
import MallLoading from './MallLoading';

interface DispatchLoadingProps {
  store: DispatchModalStore;
}

function DispatchLoading({ store }: DispatchLoadingProps) {
  return (
    <div className="flex flex-col gap-[28px] overflow-y-auto">
      <p className="text-medium-m">주문 내역을 불러오는 중입니다.</p>
      <div className="grid grid-cols-2 gap-x-[22px] gap-y-[20px] overflow-y-auto rounded-[8px] bg-gray-100 px-[32px] py-[24px]">
        {store.currentLoadingShoppingMallNames?.map((name) => (
          <MallLoading
            key={name}
            status={store.getPollingState(name) ?? 'loading'}
            shoppingMallName={name}
          />
        ))}
      </div>
    </div>
  );
}

export default observer(DispatchLoading);
