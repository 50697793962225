import { cva as _cva } from 'class-variance-authority';
import { extendTailwindMerge } from 'tailwind-merge';
import { TW_EXTEND_SPACING } from './twExtendSpacing';
import { TEXT_COLOR_STYLES } from './twExtendTextColor';
import { TEXT_STYLES } from './twTextUtility';

const customTwMerge = extendTailwindMerge<'text-style' | 'flex-center'>({
  override: {
    classGroups: {
      'text-color': TEXT_COLOR_STYLES,
    },
  },
  extend: {
    theme: {
      spacing: [...Object.keys(TW_EXTEND_SPACING), '0'],
    },
    classGroups: {
      'text-style': TEXT_STYLES,
    },
    conflictingClassGroups: {
      'flex-center': ['align-items', 'justify-content'],
    },
  },
});

export function cva<T>(
  ...args: Parameters<typeof _cva<T>>
): ReturnType<typeof _cva<T>> {
  return (...subArgs: Parameters<ReturnType<typeof _cva<T>>>) => {
    return customTwMerge(_cva(...args)(...subArgs));
  };
}
