import {
  CreateOptionCodeMatching,
  OptionCodeMatching,
  UpdateOptionCodeMatching,
} from 'models/CompositionMatching';
import api, { APIResponse } from './api';

export async function getOptionCodeMatchings() {
  const url = '/option-code-matching';
  const response = await api.get<APIResponse<OptionCodeMatching[]>>(url);

  return response.data;
}

export async function createOptionCodeMatching(
  optionCodeMatching: CreateOptionCodeMatching
) {
  const url = '/option-code-matching';
  const response = await api.post<APIResponse<OptionCodeMatching>>(
    url,
    optionCodeMatching
  );

  return response.data;
}

export async function createOptionCodeMatchings(
  optionCodeMatchings: CreateOptionCodeMatching[]
) {
  const url = '/option-code-matching/many';
  const response = await api.post<APIResponse<OptionCodeMatching[]>>(
    url,
    optionCodeMatchings
  );

  return response.data;
}

export async function updateOptionCodeMatching(
  optionCodeMatching: UpdateOptionCodeMatching
) {
  const url = `/option-code-matching/${optionCodeMatching._id}`;
  const response = await api.put<APIResponse<OptionCodeMatching>>(
    url,
    optionCodeMatching
  );

  return response.data;
}