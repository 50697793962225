import {
  autoUpdate,
  flip,
  offset,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import { useUncontrollableState } from 'design-system/index';
import { TooltipOptions } from './interface';
import { TooltipContextValue } from './TooltipContext';

export function useTooltip({
  open: givenOpen,
  initialOpen = false,
  onOpenChange,
  placement = 'bottom',
  delay,
}: TooltipOptions): TooltipContextValue {
  const [open, setOpen] = useUncontrollableState({
    value: givenOpen,
    defaultValue: initialOpen,
    onChange: onOpenChange,
  });

  const { refs, floatingStyles, context } = useFloating({
    placement,
    open: open ?? undefined,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [offset(4), flip({ padding: 10 }), shift({ padding: 10 })],
  });

  const interactions = useInteractions([
    useHover(context, {
      move: false,
      enabled: givenOpen == null,
      delay: { open: 100, close: 0, ...delay },
    }),
    useFocus(context, {
      enabled: givenOpen == null,
    }),
    useDismiss(context),
    useRole(context, { role: 'tooltip' }),
  ]);

  return {
    open: open ?? false,
    refs: {
      setFloating: refs.setFloating,
      setReference: refs.setReference,
    },
    floatingStyles,
    getFloatingProps: interactions.getFloatingProps,
    getReferenceProps: interactions.getReferenceProps,
  };
}
