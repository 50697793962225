import * as amplitude from '@amplitude/analytics-browser';

export function initializeAmplitudeUser(user: {
  id: string;
  email: string;
  name: string;
}) {
  const indentiryEvent = new amplitude.Identify()
    .set('Email', user.email)
    .set('Username', user.name);
  amplitude.identify(indentiryEvent);
  amplitude.setUserId(user.id);
}

export { amplitude };
