import { getFileExtension } from './getFileExtension';
import { getFilename } from './getFilename';

export function validateFiles(
  files: File[],
  options: {
    accepts?: string[];
    invalidatedFileNames?: string[];
  } = {}
) {
  const invalidatedFileNameSet = new Set(
    options.invalidatedFileNames?.map((filename) => filename.normalize())
  );

  const validatedFiles: File[] = [];
  const extensionRejectedFiles: File[] = [];
  const invalidedFiles: File[] = [];

  for (const file of files) {
    const filename = getFilename(file);
    const extension = getFileExtension(file);
    if (extension == null) {
      invalidedFiles.push(file);
      continue;
    }

    const isAccept =
      options.accepts == null || options.accepts.includes(extension);
    const isDisallow = invalidatedFileNameSet.has(filename);

    if (isAccept && !isDisallow) {
      validatedFiles.push(file);
    } else {
      invalidedFiles.push(file);
    }
  }

  return {
    validatedFiles,
    extensionRejectedFiles,
    invalidedFiles,
  };
}
