import { FloatingOverlay, offset } from '@floating-ui/react';
import { HTMLAttributes, ReactNode, useRef } from 'react';
import { mergeProps } from 'react-aria';
import { useRefs } from 'utils/react';
import { cva } from 'src/third-parties/tailwind';
import { useDialog } from '../../hooks/useDialog';
import { DialogContextProvider } from './context/DialogContext';
import { DialogClose } from './DialogClose';
import { DialogDescription } from './DialogDescription';
import { DialogFooter } from './DialogFooter';
import { DialogTitle } from './DialogTitle';

export interface DialogProps extends HTMLAttributes<HTMLDivElement> {
  open: boolean;
  onClose: () => void;
  children?: ReactNode;
  dismiss?: {
    outsidePress?: boolean;
    escapeKey?: boolean;
  };
}

export function Dialog({ open, ...rest }: DialogProps) {
  if (!open) {
    return null;
  }

  return (
    <>
      <FloatingOverlay lockScroll className="z-dialog bg-[rgb(0,0,0)]/40" />
      <InnerDialog open={open} {...rest} />
    </>
  );
}

function InnerDialog({
  open,
  onClose,
  className,
  style,
  dismiss,
  children,
  ...rest
}: DialogProps) {
  const { floatingStyles, getFloatingProps, refs } = useDialog({
    open,
    onOpenChange: () => open && onClose(),
    placement: 'top',
    middleware: [
      offset(({ rects }) => {
        return -rects.floating.height / 2;
      }),
    ],
    reference: {
      x: window.innerWidth / 2,
      y: window.innerHeight / 2,
    },
    dismiss: {
      outsidePress: dismiss?.outsidePress ?? true,
      escapeKey: dismiss?.escapeKey ?? true,
    },
  });

  const dialogRef = useRef<HTMLDivElement | null>(null);
  const innerRef = useRefs([refs.setFloating, dialogRef]);

  return (
    <DialogContextProvider value={{ open, onClose }}>
      <div
        ref={innerRef}
        className={container({ className })}
        style={{ ...floatingStyles, ...style }}
        {...mergeProps(getFloatingProps(), rest)}
      >
        {children}
      </div>
    </DialogContextProvider>
  );
}

Dialog.Title = DialogTitle;
Dialog.Description = DialogDescription;
Dialog.Footer = DialogFooter;
Dialog.Close = DialogClose;

const container = cva(
  'z-dialog flex w-[452px] flex-col rounded-[16px] bg-white px-[20px] pb-[20px] pt-[32px] shadow-md'
);
