import { observer } from 'mobx-react-lite';
import { ForwardedRef, forwardRef } from 'react';
import TextInput from 'src/design-system/components/TextInput/TextInput';
import { IconTrashcan } from 'src/icons';
import { Unit } from 'src/models/Product';
import { cva } from 'src/third-parties/tailwind';
import { useComposition } from 'src/utils/react/hooks/useComposition';
import { isNotEmptyString } from 'src/utils/string';
import SupplierInput from './SupplierInput';

interface OptionInputProps {
  value: Unit;
  onChange: (value: Unit | null) => void;
  onAddOption: () => void;
  isDuplicated: boolean;
  useSupplierByOption: boolean;
}

function OptionInput(
  {
    value,
    onChange,
    onAddOption,
    isDuplicated,
    useSupplierByOption,
  }: OptionInputProps,
  ref: ForwardedRef<HTMLInputElement>
) {
  const { isComposing } = useComposition();
  const handleUnitChange = (unit: string) => {
    onChange({ ...value, unit });
  };

  const handleSupplierChange = (supplierId: string) => {
    onChange({
      ...value,
      supplierId: isNotEmptyString(supplierId) ? supplierId : null,
    });
  };

  const handleDeleteClick = () => {
    onChange(null);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    // NOTE(@이지원): 한글을 입력 중일때, 두 번 입력되는 이슈를 해결하기 위한 방어코드
    if (isComposing) {
      return;
    }

    if (event.key === 'Enter') {
      onAddOption();
    }
  };

  return (
    <div className="flex gap-[8px]">
      <TextInput
        ref={ref}
        className="flex-1"
        placeholder="예) 30구, 1.8L"
        status={isDuplicated ? 'error' : 'default'}
        value={value.unit}
        onChange={handleUnitChange}
        onKeyDown={handleKeyDown}
        saveOnBlur
      />
      {useSupplierByOption && (
        <SupplierInput
          value={value.supplierId ?? ''}
          onChange={handleSupplierChange}
        />
      )}
      <button className={trashButton()} onClick={handleDeleteClick}>
        <IconTrashcan className="text-gray-500" />
      </button>
    </div>
  );
}

const trashButton = cva(
  'flex-center flex size-[36px] rounded-[8px] bg-gray-100'
);

export default observer(forwardRef(OptionInput));
