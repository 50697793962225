import { EvaluateOperatorResult, Token } from '../../interface';
import { CAN_NOT_CONVERT_TO_BOOLEAN, INVALID_EXPRESSION } from '../error';
import { parseBoolean } from '../parseBoolean';

export function evaluateOperatorOr(
  stack: Token[],
  argumentLength: number
): EvaluateOperatorResult {
  const operands = stack.splice(-argumentLength);
  if (operands.length !== argumentLength) {
    return INVALID_EXPRESSION;
  }

  const parsedOperands: boolean[] = [];
  for (const operand of operands) {
    const parsed = parseBoolean(operand);
    if (parsed === null) {
      return CAN_NOT_CONVERT_TO_BOOLEAN(operand);
    }
    parsedOperands.push(parsed);
  }

  return {
    success: true,
    stack: [...stack, parsedOperands.some((operand) => operand)],
  };
}
