import { useEffect } from 'react';

const Modal = ({
  children,
  onClose,
  header = '',
  className = '',
  backdropClick = true,
  tailwindWidth = 'w-11/12 md:w-2/3 lg:w-1/2',
}) => {
  // 모달 바깥쪽을 클릭하면 모달을 닫음
  const handleBackdropClick = (e) => {
    if (!backdropClick) {
      return;
    }
    if (e.target.id === 'modal-backdrop') {
      onClose();
    }
  };

  // 키보드에서 esc키를 누르면 모달을 닫음
  const handleEscapePress = (e) => {
    if (e.key === 'Escape') {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleEscapePress);
    return () => {
      document.removeEventListener('keydown', handleEscapePress);
    };
  }, []);

  return (
    <div
      id="modal-backdrop"
      onClick={handleBackdropClick}
      className={`fixed inset-0 bg-gray-700 bg-opacity-50 h-full w-full z-40 flex justify-center items-center overflow-visible`}
    >
      <div
        className={`relative py-5 border ${tailwindWidth} shadow-lg rounded-3xl bg-white ${className}`}
      >
        <div className="flex px-5 justify-between items-center pb-3">
          <p className="text-2xl font-bold">{header}</p>
          <button className="modal-close cursor-pointer z-40" onClick={onClose}>
            <svg
              className="fill-current text-black"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path d="M12.45 11.707l4.243-4.243L15.536 6l-4.243 4.243L7.05 6 6 7.05l4.243 4.243L6 15.536l1.05 1.05 4.243-4.243 4.243 4.243 1.05-1.05-4.243-4.243z" />
            </svg>
          </button>
        </div>
        <div className="px-5 modal-content max-h-[70vh] overflow-y-auto">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
