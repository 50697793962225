import { Order } from '@sweep/contract';
import { AbstractPlugin } from '../../interface';

export class StoreOriginValuePlugin extends AbstractPlugin {
  transform = (orders: Order[]): Promise<Order[]> => {
    return Promise.resolve(orders.map(this.storeOriginValue));
  };

  storeOriginValue = (order: Order): Order => {
    const {
      productName,
      option,
      optionCode,
      quantity,
      origName,
      origOption,
      origQuantity,
    } = order;

    const parsedQuantity = this.parseQuantity(quantity);

    return {
      ...order,
      productName: productName ?? '',
      option: option ?? '',
      optionCode: optionCode ?? '',
      quantity: parsedQuantity,
      origName: origName ?? productName ?? '',
      origOption: origOption ?? option ?? '',
      origQuantity: origQuantity ?? parsedQuantity,
    };
  };

  parseQuantity = (quantity: string | number | undefined | null) => {
    if (quantity == null) {
      return 1;
    }

    if (typeof quantity === 'number') {
      return quantity;
    }

    const trimmedQuantity = quantity.trim();
    if (trimmedQuantity === '') {
      return 1;
    }

    const parsedQuantity = parseFloat(trimmedQuantity);
    if (!isNaN(parsedQuantity)) {
      return parsedQuantity;
    }

    return trimmedQuantity;
  };
}
