import { Product, Unit } from 'src/models/Product';

interface OptionSupplierMatching {
  options: Unit[];
  supplierId?: string | null;
}

const NULL_STRING = 'NULL';

export function getOptionSupplierMatchings(
  product: Product
): OptionSupplierMatching[] | null {
  const options = product.units;
  if (options == null || options.length === 0) {
    return null;
  }

  if (product.useSupplierByOption !== true) {
    return [
      {
        options,
        supplierId: product.supplierId,
      },
    ];
  }

  const optionsBySupplier: Record<string, Unit[]> = {};
  options.forEach((option) => {
    if (option.supplierId == null) {
      if (optionsBySupplier[NULL_STRING] == null) {
        optionsBySupplier[NULL_STRING] = [];
      }

      optionsBySupplier[NULL_STRING].push(option);
      return;
    }

    if (optionsBySupplier[option.supplierId] == null) {
      optionsBySupplier[option.supplierId] = [];
    }

    optionsBySupplier[option.supplierId].push(option);
  });

  return Object.entries(optionsBySupplier).map(([supplierId, options]) => ({
    options,
    supplierId: supplierId === NULL_STRING ? null : supplierId,
  }));
}
