import { createContext, useContext } from 'react';
import { noop } from 'utils/function';

export interface ModalContextValue {
  open: boolean;
  onClose: () => void;
}

const modalContext = createContext<ModalContextValue>({
  open: false,
  onClose: noop,
});

export const ModalContextProvider = modalContext.Provider;

export const useModalContext = () => useContext(modalContext);
