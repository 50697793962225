import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import React, { createRef, useEffect, useRef, useState } from 'react';
import { mapKeysByColumnMapping } from '../../utils/headerColumnMapping';
import { isValid } from '../../utils/utils';

interface SelectableOrderTableByUniqueCodeSetProps {
  allOrders: any[];
  orders: any[];
  origEnglishHeader?: string[];
  koreanHeader: string[];
  columnMapping: { [key: string]: string };
  selectedUniqueCodeSet: Set<string>;
  setSelectedUniqueCodeSet: React.Dispatch<React.SetStateAction<Set<string>>>;
  uniqueCodeName?: string;
  isEditable?: boolean;
  isCheckable?: boolean;
  handleValueChange?: (
    uniqueCodeName: string,
    key: string,
    value: string
  ) => void;
}

const SelectableOrderTableByUniqueCodeSet: React.FC<SelectableOrderTableByUniqueCodeSetProps> =
  observer(
    ({
      allOrders,
      orders, //slice 100 같은 용도
      origEnglishHeader,
      koreanHeader,
      columnMapping,
      selectedUniqueCodeSet,
      setSelectedUniqueCodeSet,
      uniqueCodeName = 'uniqueCode',
      isEditable = false,
      isCheckable = false,
      handleValueChange = (
        uniqueCodeName: string,
        key: string,
        value: string
      ) => {},
    }) => {
      const [englishHeader, setEnglishHeader] = useState<string[]>([]);
      origEnglishHeader || mapKeysByColumnMapping(koreanHeader, columnMapping);

      const [items, setItems] = useState<
        {
          id: string;
          content: string;
        }[]
      >([]);
      const [columnWidths, setColumnWidths] = useState<{ [key: string]: any }>(
        {}
      );

      const [editState, setEditState] = useState<{
        uniqueCodeTemp: string | null;
        key: string | null;
      }>({
        uniqueCodeTemp: null,
        key: null,
      });
      const [editValue, setEditValue] = useState('');
      const inputRef = useRef(null);

      const handleDoubleClick = (
        uniqueCodeTemp: string,
        key: string,
        value: string
      ) => {
        if (key === 'uniqueCode') {
          return;
        }
        if (key === 'uniqueCodeAfterCustomization') {
          return;
        }
        if (!isEditable) {
          return;
        }
        setEditState({ uniqueCodeTemp, key });
        setEditValue(value);

        // 포커스 설정
        setTimeout(() => {
          if (inputRef.current) {
            (inputRef.current as HTMLInputElement)?.focus();
          }
        }, 100);
      };

      const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEditValue(e.target.value);
      };

      const handleBlur = () => {
        if (editState.uniqueCodeTemp !== null && editState.key !== null) {
          handleValueChange(editState.uniqueCodeTemp, editState.key, editValue);
        }
        setEditState({ uniqueCodeTemp: null, key: null }); // 편집 모드 해제
      };

      useEffect(() => {
        const eng =
          origEnglishHeader ??
          (mapKeysByColumnMapping(koreanHeader, columnMapping) as string[]);
        setEnglishHeader(eng);

        setItems(
          eng?.map((key, index) => ({
            id: `item-${index}`,
            content: key,
          })) || []
        );
      }, [origEnglishHeader, koreanHeader, columnMapping]);

      const headerRefs = englishHeader.reduce(
        (
          acc: { [key: string]: React.RefObject<HTMLTableCellElement> },
          key
        ) => {
          acc[key] = createRef<HTMLTableCellElement>();
          return acc;
        },
        {}
      );

      const bodyRefs = orders?.map((_, index) =>
        englishHeader.reduce(
          (
            acc: { [key: string]: React.RefObject<HTMLTableCellElement> },
            key
          ) => {
            acc[key] = createRef<HTMLTableCellElement>();
            return acc;
          },
          {}
        )
      );

      useEffect(() => {
        const newColumnWidths: { [key: string]: any } = {};

        if (!bodyRefs || !bodyRefs[0]) {
          return;
        }
        englishHeader.forEach((key) => {
          const headerWidth = headerRefs[key].current?.offsetWidth || 0;
          const bodyWidth = bodyRefs[0][key].current?.offsetWidth || 0;
          newColumnWidths[key] = Math.max(headerWidth, bodyWidth);
        });

        setColumnWidths(newColumnWidths);
      }, [orders, englishHeader]);

      // 체크박스 선택/해제 핸들러
      const handleCheckboxChange = (
        order: { [key: string]: any },
        isChecked: boolean
      ) => {
        if (isChecked) {
          setSelectedUniqueCodeSet(
            (prev: Set<string>) =>
              new Set<string>([...prev, order[uniqueCodeName]])
          );
        } else {
          setSelectedUniqueCodeSet((prev) =>
            prev?.delete(order[uniqueCodeName]) ? new Set(prev) : prev
          );
        }
      };

      const handleSelectAll = () => {
        if (
          !allOrders.every((orders) =>
            selectedUniqueCodeSet.has(orders[uniqueCodeName])
          )
        ) {
          setSelectedUniqueCodeSet((prev: Set<string>) => {
            const newSet = new Set<string>([...prev]);
            allOrders.forEach((order) => {
              newSet.add(order[uniqueCodeName]);
            });
            return newSet;
          });
        } else {
          setSelectedUniqueCodeSet((prev: Set<string>) => {
            const newSet = new Set<string>([...prev]);
            allOrders.forEach((order) => {
              newSet.delete(order[uniqueCodeName]);
            });
            return newSet;
          });
        }
      };

      return (
        <div>
          <div className="sticky top-0 z-10 flex p-0 font-semibold">
            {isCheckable && (
              <div
                className="z-11 sticky left-0 whitespace-nowrap border bg-gray-100 px-4 py-2 text-center"
                style={{ width: '61px' }}
              >
                <input
                  type="checkbox"
                  className="scale-150"
                  checked={allOrders.every((orders) =>
                    selectedUniqueCodeSet.has(orders[uniqueCodeName])
                  )}
                  onChange={handleSelectAll}
                />
              </div>
            )}
            {items?.map((item, index) => {
              return (
                <div
                  key={item.id}
                  ref={headerRefs[item.content]}
                  className="whitespace-nowrap border bg-gray-100 px-4 py-2 text-left"
                  style={{
                    width: `${columnWidths[item.content] || 'auto'}px`,
                  }}
                >
                  {columnMapping[item.content]}
                </div>
              );
            })}
          </div>

          <table className="table-fixed border-collapse">
            <colgroup>
              {englishHeader?.map((key, index) => (
                <col
                  key={index}
                  className={key === 'someColumn' ? 'w-24' : 'auto'}
                />
              ))}
            </colgroup>

            <tbody>
              {orders
                .filter((order) => order?.hasBeenTempStored)
                .concat(orders.filter((order) => !order?.hasBeenTempStored))
                ?.map((row, index) => (
                  <tr
                    key={index}
                    className={`border-b border-gray-200 ${
                      row?.hasBeenTempStored ? 'bg-yellow-100' : ''
                    }`}
                  >
                    {isCheckable && (
                      <td
                        className={classNames(
                          'sticky left-0 whitespace-nowrap border-r border-gray-200 px-4 py-2 text-center',
                          row?.hasBeenTempStored ? 'bg-yellow-100' : 'bg-white'
                        )}
                        style={{ width: '61px' }}
                      >
                        <input
                          type="checkbox"
                          className="scale-150"
                          checked={selectedUniqueCodeSet.has(
                            row[uniqueCodeName]
                          )}
                          onChange={(e) =>
                            handleCheckboxChange(row, e.target.checked)
                          }
                        />
                      </td>
                    )}

                    {englishHeader?.map((key, i) => {
                      const isLastElement = i === englishHeader?.length - 1;
                      if (columnMapping[key]) {
                        const displayValue = isValid(row[key]) ? row[key] : '';

                        return (
                          <td
                            key={i}
                            ref={bodyRefs[index][key]}
                            className={`whitespace-nowrap px-4 py-2 text-left ${
                              !isLastElement ? 'border-r border-gray-200' : ''
                            }`}
                            style={{
                              width: `${columnWidths[key] || 'auto'}px`,
                            }}
                            onDoubleClick={() => {
                              handleDoubleClick(
                                row[uniqueCodeName],
                                key,
                                displayValue
                              );
                            }}
                          >
                            {editState.uniqueCodeTemp === row[uniqueCodeName] &&
                            editState.key === key ? (
                              <input
                                ref={inputRef}
                                type="text"
                                value={editValue}
                                onChange={handleInputChange}
                                onBlur={handleBlur}
                                className="w-full"
                              />
                            ) : (
                              displayValue
                            )}
                          </td>
                        );
                      }

                      return (
                        <td
                          key={i}
                          ref={bodyRefs[index][key]}
                          className={`whitespace-nowrap px-4 py-2 text-left ${
                            !isLastElement ? 'border-r border-gray-200' : ''
                          }`}
                          style={{ width: `${columnWidths[key] || 'auto'}px` }}
                        />
                      );
                    })}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      );
    }
  );

export default SelectableOrderTableByUniqueCodeSet;
