interface DownloadArrowProps {
  fill?: string;
  width?: string;
  height?: string;
}

const DownloadArrow: React.FC<DownloadArrowProps> = ({
  fill = 'white',
  width = '19',
  height = '19',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 19 20"
      fill="none"
    >
      <path
        d="M5.5 8.66699L9.5 12.667M9.5 12.667L13.5 8.66699M9.5 12.667V4.66699M5.5 15.3337H13.5"
        stroke={fill}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DownloadArrow;
