import { IconArrowDown } from 'icons/index';
import { cva } from 'src/third-parties/tailwind';

interface ToggleIconProps {
  active: boolean;
}

export function ToggleIcon({ active }: ToggleIconProps) {
  return <IconArrowDown className={icon({ active })} />;
}

const icon = cva('text-gray-300 transition-transform', {
  variants: {
    active: {
      true: 'rotate-180',
    },
  },
});
