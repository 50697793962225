export function formatDateAgo(prevDate: Date): string {
  const now = new Date().getTime();
  const timeDiff = now - prevDate.getTime();

  const ONE_MINUTE = 1000 * 60;
  if (timeDiff < ONE_MINUTE) {
    return '방금 전';
  }

  const ONE_HOUR = ONE_MINUTE * 60;
  if (timeDiff < ONE_HOUR) {
    const minutesAgo = Math.floor(timeDiff / ONE_MINUTE);
    return `${minutesAgo}분 전`;
  }

  const ONE_DAY = ONE_HOUR * 24;
  if (timeDiff < ONE_DAY) {
    const hoursAgo = Math.floor(timeDiff / ONE_HOUR);
    return `${hoursAgo}시간 전`;
  }

  const ONE_WEEK = ONE_DAY * 7;
  if (timeDiff < ONE_WEEK) {
    const daysAgo = Math.floor(timeDiff / ONE_DAY);
    return `${daysAgo}일 전`;
  }

  const year = prevDate.getFullYear();
  const month = prevDate.getMonth() + 1;
  const day = prevDate.getDate();
  return `${year}. ${month}. ${day}`;
}
