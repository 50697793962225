import { LogoMallByName } from 'design-system/components/LogoMall';
import { IconSuccessLarge } from 'icons/index';
import { observer } from 'mobx-react-lite';

interface MallSuccessProps {
  name: string;
}

function MallSuccess({ name }: MallSuccessProps) {
  return (
    <div className="flex items-center justify-between gap-1 rounded-[8px] bg-white px-[12px] py-[4px]">
      <div className="flex items-center gap-[6px] px-[6px]">
        <LogoMallByName name={name} />
        <p className="text-medium-s text-gray-700">{name}</p>
      </div>
      <IconSuccessLarge width={48} height={48} />
    </div>
  );
}

export default observer(MallSuccess);
