import { ShippingCompany } from '@sweep/contract';

export function getShippingCompanyUrl(
  shippingNumber: string,
  shippingCompany: string
): string | null {
  switch (shippingCompany) {
    case ShippingCompany.CJ:
      return `https://trace.cjlogistics.com/next/tracking.html?wblNo=${shippingNumber}`;
    case ShippingCompany.HANJIN:
      return `https://www.hanjin.com/kor/CMS/DeliveryMgr/WaybillResult.do?mCode=MN038&wblnumText2=${shippingNumber}&schLang=KR`;
    case ShippingCompany.LOGEN:
      return `https://www.ilogen.com/web/personal/trace/${shippingNumber}`;
    case ShippingCompany.KYUNGDONG:
      return `https://b2c.goodsflow.com/zkm/V1/whereis/coupang/kdexp/${shippingNumber}`;
    case ShippingCompany.LOTTE:
      return `https://www.lotteglogis.com/open/tracking?invno=${shippingNumber}`;
    case ShippingCompany.POST_OFFICE:
      return `https://service.epost.go.kr/trace.RetrieveDomRigiTraceList.comm?sid1=${shippingNumber}&displayHeader=N`;
    default:
      return null;
  }
}
