import { DefuaultPlugin } from './DefaultPlugin';
import { DispatchedOrder } from './DispatchedOrder';
import { UploadedOrder } from './UploadedOrder';

export type Order<P extends DefuaultPlugin = DefuaultPlugin> =
  | DispatchedOrder<P>
  | UploadedOrder<P>;

export type NormalizedOrder<P extends DefuaultPlugin = DefuaultPlugin> =
  Order<P> & {
    uniqueCodeAfterCustomization: string;
  };

export function isDispatchedOrder(order: Order): order is DispatchedOrder {
  return order.orderStatus != null;
}

export function isNormalizedOrder(order: Order): order is NormalizedOrder {
  return order.uniqueCodeAfterCustomization != null;
}
