export const colorDict: Record<string, [string, string]> = {
  fail: ['FF0000', '주소가 틀렸습니다. 이유는 각 셀의 메모를 참조하세요.'],
  fixed: [
    '0000FF',
    '다양한 이유로 수정한 주소입니다. 이유는 각 셀의 메모를 참조하세요.',
  ],
  postcode: [
    'ff3399',
    '고객이 입력한 주소와 우편번호가 일치하지 않습니다. 메모에 상세 주소가 적혀있습니다.',
  ],
  jibun: ['009933', '지번 주소가 입력되었습니다.'],
  multiple: [
    '8A2BE2',
    '하나의 지번 주소에 해당되는 도로명 주소가 여러 개 입니다. 메모에 주소 목록이 적혀 있습니다.',
  ],
};
