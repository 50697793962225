import { OrderProduct } from '@sweep/contract';
import { MatchedProduct } from 'models/CompositionMatching';

export function transformToCMProduct(product: OrderProduct): MatchedProduct {
  return {
    productId: product.productId,
    quantity: product.quantity,
    unit: product.unit,
  };
}
