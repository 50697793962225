import { OrderStatus } from '@sweep/contract';

export function isPaymentComplete(status: OrderStatus | undefined | null) {
  if (status == null) {
    return false;
  }

  const paymentCompleteStatusList: OrderStatus[] = [
    OrderStatus.paymentComplete,
    OrderStatus.paymentCompletePartialDelivery,
  ];

  return paymentCompleteStatusList.includes(status);
}

export function isProductPreparing(status: OrderStatus) {
  const productPreparingStatusList: OrderStatus[] = [
    OrderStatus.productPreparing,
    OrderStatus.productPreparingPartialDelivery,
    OrderStatus.partialDelivery,
  ];

  return productPreparingStatusList.includes(status);
}

export function isInDelivery(status: OrderStatus) {
  return status === OrderStatus.inDelivery;
}

export function isCancelRequest(status: OrderStatus) {
  const cancelRequestStatusList: OrderStatus[] = [
    OrderStatus.cancelRequest,
    OrderStatus.cancelRequestPaymentComplete,
  ];

  return cancelRequestStatusList.includes(status);
}

export function isEtc(status: OrderStatus) {
  const etcStatusList: OrderStatus[] = [
    OrderStatus.exchange,
    OrderStatus.exchangeRequest,
    OrderStatus.exchangeInDelivery,
    OrderStatus.exchangeRedelivery,
    OrderStatus.exchangeHold,
    OrderStatus.refundRequest,
    OrderStatus.refundExpected,
    OrderStatus.refundRequestWaiting,
    OrderStatus.returnRequest,
    OrderStatus.returnInDelivery,
    OrderStatus.riskControl,
  ];

  return etcStatusList.includes(status);
}
