import { LogoMallByName } from 'design-system/components/LogoMall';
import { IconErrorLarge } from 'icons/index';
import { observer } from 'mobx-react-lite';

interface MallFailProps {
  name: string;
}

function MallFail({ name }: MallFailProps) {
  return (
    <div className="flex flex-col items-center gap-1 rounded-[8px] bg-white px-[12px] py-[4px]">
      <div className="flex w-full items-center justify-between">
        <div className="flex items-center gap-[6px] px-[9px]">
          <LogoMallByName name={name} />
          <p className="text-medium-s text-gray-700">{name}</p>
        </div>
        <IconErrorLarge width={48} height={48} />
      </div>
    </div>
  );
}

export default observer(MallFail);
