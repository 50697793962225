import api from './api';

type ReadExcelResponse =
  | { success: false; error: string }
  | {
      success: true;
      data: string;
    };

export async function readExcelOnServer(file: File, password?: string | null) {
  const url = '/fastapi/unprotect/xlsx';
  const formData = new FormData();

  const isProtected = password != null;

  formData.append('xlsx_file', file);
  formData.append('filename', file.name.normalize('NFC'));
  formData.append('isProtected', `${isProtected}`);
  if (isProtected) {
    formData.append('password', password);
  }

  const response = await api.post<ReadExcelResponse>(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return response.data;
}
